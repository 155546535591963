import React from "react";
import { noTransaction } from "../../assets/images";

const PastTasks = ({ pastTasks, query }: any) => {
  return (
    <div className="bg-[#0D0D0D] overflow-auto scrollbar-thumb-[#60B7F6] scrollbar-track-white mb-8  scrollbar-thin  rounded-tl-lg  w-full h-[552px] mt-8 ">
      <table className="w-full">
        <thead>
          <tr className="h-[56px] bg-[#1C2227] flex justify-start px-16 items-center rounded-t-lg">
            <th className="text-[#617889] text-[15px] overflow-hidden w-1/5  mr-4 text-left whitespace-nowrap">
              Name
            </th>
            <th className="text-[#617889] text-[15px] overflow-hidden w-1/5 text-left whitespace-nowrap">
              Email
            </th>

            <th className="text-[#617889] text-[15px] overflow-hidden w-2/5 text-left whitespace-nowrap">
              Task
            </th>
            <th className="text-[#617889] text-[15px] overflow-hidden w-1/5 text-left whitespace-nowrap">
              Points
            </th>
            <th className="text-[#617889] text-[15px] overflow-hidden w-1/5 text-center whitespace-nowrap">
              Action
            </th>
          </tr>
        </thead>

        <div className="w-full">
          {pastTasks.length >= 1 ? (
            <table className="w-full">
              {pastTasks
                .filter(
                  (searchValue: {
                    task: any;
                    user: { profile: { firstName: string; lastName: string } };
                  }) => {
                    return query.toLowerCase() === ""
                      ? searchValue
                      : searchValue.task.name.toLowerCase().includes(query) ||
                          searchValue.user.profile.firstName
                            .toLowerCase()
                            .includes(query) ||
                          searchValue.user.profile.lastName
                            .toLowerCase()
                            .includes(query);
                  }
                )
                .map((task: any, index: any) => (
                  <tbody className="w-full" key={index}>
                    <tr className="h-[56px] flex justify-start px-16 items-center">
                      <td className="text-white text-[15px] w-1/5 mr-4 overflow-hidden whitespace-nowrap ">
                        {task.user.profile.firstName}{" "}
                        {task.user.profile.lastName}
                      </td>
                      <td className="text-white text-[15px] w-1/5 mr-4 overflow-hidden whitespace-nowrap">
                        {task.user.email.address}
                      </td>
                      <td className="text-white text-[15px] w-2/5 mr-4 overflow-hidden whitespace-nowrap">
                        {task.task.name}
                      </td>

                      <td className="text-white text-[15px] w-1/5 overflow-hidden whitespace-nowrap ">
                        {task.task.points}
                      </td>
                      <td className="w-1/5   flex justify-center items-center  ">
                        <h4 className=" px-4  py-3 rounded-full text-white font-bold bg-gray-500 text-[11px] ">
                          Approved
                        </h4>
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="mt-1 w-full h-[1px] bg-[#13181B]" />
                    </tr>
                  </tbody>
                ))}
            </table>
          ) : (
            <div className="flex flex-col justify-center mt-12  lgss:mt-12 items-center">
              <img src={noTransaction} alt="" />
              <h4 className="font-bold text-[#869AA9] pt-2">
                No approved task at the moment
              </h4>
              <p className=" text-[#869AA9] pt-2 text-[12px]">
                All approved tasks will show up here
              </p>
            </div>
          )}
        </div>
      </table>
    </div>
  );
};

export default PastTasks;
