import { ApexOptions } from "apexcharts";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { API_URL } from "../../constant";
import { useAuth } from "../../context/auth-context";

const GiftcardGraph = () => {
  const { token } = useAuth();

  const [giftcardYearlyPayout, setGiftcardYearlyPayout] = useState<any[]>([]);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); // Get the current month (0-indexed)
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    axios
      .get(`${API_URL}api/stats/graph-data/GIFT-CARD/YEAR`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        setGiftcardYearlyPayout(r.data.data);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, [token]);

  const returnMonthData = (arr: any[]) => {
    const emptyArr = Array(12).fill(0);

    for (let i = 1; i <= emptyArr.length; i++) {
      const monthItem = arr.find(
        (item: { _id: { month: number } }) => item._id.month === i
      );
      emptyArr[i - 1] = monthItem ? monthItem.total.toFixed(2) : 0;
    }

    return emptyArr;
  };

  const xAxisCategories = months.slice(0, currentMonth + 1);
  const chartData = {
    series: [
      {
        name: "Giftcard",
        data: returnMonthData(giftcardYearlyPayout).slice(0, currentMonth + 1),
        color: "#F28A0F",
        fill: {
          type: "solid",
          colors: "#262E35",
        },
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "category",
        categories: xAxisCategories,
        tickAmount: 12, // Show all 12 months on the x-axis
        labels: {
          style: {
            fontSize: "12px", // Adjust font size
            fontFamily: "Manrope, sans-serif", // Change font family
            fontWeight: "normal", // Set font weight
            colors: "#999999", // Set font color
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "12px", // Adjust font size
            fontFamily: "Manrope, sans-serif", // Change font family
            fontWeight: "normal", // Set font weight
            colors: "#999999", // Set font color
          },
          formatter: (value: any) =>
            // `${
            //   payoutCurrency === "USD"
            //     ? `$${value}k`
            //     : payoutCurrency === "NGN"
            //     ? `N${value}k`
            //     : null
            // }}`
            `${`$${value.toLocaleString("en-US")}`}`, // Format y-axis labels as dollars
        },
        tickValues: [0, 200, 400, 800, 1000],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },

        style: {
          fontSize: "12px", // Adjust font size
          fontFamily: "Manrope, sans-serif", // Change font family
          fontWeight: "normal", // Set font weight
          colors: "#FFFFFF", // Set font color
        },
        theme: "dark", // Use the dark theme for the tooltip
      },
      grid: {
        show: false, // Remove the horizontal and vertical grid lines
      },
    },
  };
  return (
    <div className="w-full">
      <ReactApexChart
        options={chartData.options as ApexOptions}
        series={chartData.series}
        type="area"
        height={400}
      />
    </div>
  );
};

export default GiftcardGraph;
