import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { BiCopy } from "react-icons/bi";
import { titan } from "../../../assets/images";

const DepositFiat = ({ setDepositFiatModal }: any) => {
  const [copy, setCopy] = useState<boolean>(false);

  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-60 backdrop-blur-sm">
      <div className="w-11/12 mds:w-10/12 lgss:w-[35%] transition-all p-6 ease-in duration-1000  rounded-xl bg-[#1F1F1F] ">
        <div className="flex  pt-4 justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Fund wallet
          </h4>
          <h4
            onClick={() => setDepositFiatModal(false)}
            className="text-[#78C2F7] font-medium text-[14px] cursor-pointer"
          >
            Close
          </h4>
        </div>
        <div className="w-full mt-4">
          <div className="flex w-1/2 flex-col justify-center items-center border-2 border-[#F28A0F] rounded-xl p-4 mx-auto mt-10">
            <div className="w-[60px] h-[60px] bg-transparent rounded-full">
              <img src={titan} alt="" className="w-full h-full bg-cover" />
            </div>
            <h4 className="font-sora text-center mt-4 text-white text-[15px] font-semibold ">
              Titan Trust Bank
            </h4>
          </div>
          <div className="w-full mt-8">
            <h4 className="text-[13px] font-bold text-white">Account Name</h4>
            <div className="p-4 border w-full border-[#141414] flex justify-start items-center rounded-lg bg-[#1C2227] mt-4">
              <h4 className="text-[15px] font-medium text-[#A0A9AD]">
                HIZO TECHNOLOGY LIMITED
              </h4>
            </div>
            <div className="p-4 border w-full border-[#141414] flex justify-between items-center rounded-lg bg-[#1C2227] mt-4">
              <h4 className="text-[32px] font-medium text-[#A0A9AD]">
                0000508813
              </h4>
              <CopyToClipboard
                text="0000508813"
                onCopy={() => {
                  setCopy(true);
                  setTimeout(() => {
                    setCopy(false);
                  }, 3000);
                }}
              >
                <button className="w-[25%] text-secondary_button text-[12px] font-medium flex justify-center items-center gap-2 rounded-xl h-[40px] bg-[#D4EBFD]">
                  <BiCopy className="text-xl" />
                  {copy ? "Copied" : "Copy"}
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositFiat;
