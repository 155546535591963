import React, { useState } from "react";
import { BiErrorCircle } from "react-icons/bi";

const GiftcardFeeModal = ({
  setGiftcardFeeModal,
  sellGiftcardType,
  setSellGiftcardType,
  sellGiftcardFee,
  setSellGiftcardFee,
  setGiftcardReviewModal,
}: any) => {
  const [error, setError] = useState<string>("");
  const isNumber = (str: any) => {
    if (str.trim() === "") {
      return false;
    }

    return !isNaN(str);
  };
  const handleSellChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNumber(value)) {
      setSellGiftcardFee(Number(value));
    } else {
      setSellGiftcardFee("");
    }
  };
  const handleSellTypeChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSellGiftcardType(event.target.value);
  };

  const validate = (sellGiftcardFee: any) => {
    if (!sellGiftcardFee) {
      setError("Kindly add the sell fee");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    setError("");
    return true;
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validate(sellGiftcardFee)) {
      return;
    }
    setGiftcardFeeModal((prev: any) => !prev);
    setGiftcardReviewModal((prev: any) => !prev);
  };

  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-60 backdrop-blur-sm">
      <div className="w-11/12 mds:w-[80%] md:w-3/5 lgss:w-2/5  px-8 pt-8   rounded-xl bg-[#1F1F1F] pb-4">
        <div className="flex   justify-between items-center">
          <h4 className="font-bold text-[18px] font-sora text-white">
            Giftcard Trading Fee
          </h4>
          <button
            onClick={() => {
              setGiftcardFeeModal((prev: any) => !prev);
            }}
            className="text-[#78C2F7] font-medium text-[13px]  cursor-pointer"
          >
            {" "}
            Close
          </button>
        </div>
        <form className="w-full" onSubmit={handleSubmit}>
          <div className="w-full mt-4">
            <h4 className="font-medium text-[12px] lgss:text-[14px] text-white">
              SELL
            </h4>
            <div className="flex justify-center w-full items-center gap-4  mt-4">
              <div className="w-1/2 ">
                <label
                  htmlFor=""
                  className="text-white text-[12px] lgss:text-[14px] font-bold"
                >
                  Currency
                </label>
                <select
                  value={sellGiftcardType}
                  onChange={handleSellTypeChange}
                  className="w-full mt-2 font-bold text-[13px] lgss:text-[15px]  text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-lg border border-[#141414]"
                >
                  <option value="PERCENTILE">Percentile</option>
                  <option value="FIXED">Naira</option>
                </select>
              </div>
              <div className="w-1/2 ">
                <label
                  htmlFor=""
                  className="text-white text-[12px] lgss:text-[14px] font-bold"
                >
                  Fee (
                  {sellGiftcardType === "PERCENTILE"
                    ? "%"
                    : sellGiftcardType === "FIXED"
                    ? "NGN"
                    : ""}
                  )
                </label>
                <input
                  type="number"
                  value={sellGiftcardFee}
                  autoFocus
                  placeholder="Kindly add the new fee"
                  onChange={handleSellChange}
                  style={{ direction: "ltr", textAlign: "right" }}
                  className="w-full  text-[13px] lgss:text-[15px]  mt-2  text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]"
                />
              </div>
            </div>
            {/* <div className="w-full rounded-full bg-[#273037] h-[1px] mt-4" /> */}
          </div>
          {error && (
            <div className="bg-red-600 text-white mb-4 text-[13px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-semibold mt-4">
              <BiErrorCircle className="text-xl" />
              {error}
            </div>
          )}
          <button
            type="submit"
            className="text-white font-medium text-[16px] mt-4 h-[48px] w-full bg-secondary_button rounded-lg"
          >
            Proceed
          </button>
        </form>
      </div>
    </div>
  );
};

export default GiftcardFeeModal;
