import axios from "axios";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { noTransaction } from "../../../assets/images";
import { API_URL } from "../../../constant";
import { useAuth } from "../../../context/auth-context";

const Past = ({ customerId }: any) => {
  const { token } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [tasks, setTasks] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}api/tasks/${customerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        // console.log(r.data.data);
        const filtered = r.data.data.tasks.filter((item: any) => {
          return item.applied === true && item.applicationStatus === "approved";
        });
        setTasks(filtered);
        setLoading(false);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
        setLoading(false);
      });
  }, [token, customerId]);
  return (
    <div className="w-full">
      {loading ? (
        <div className="mt-12 w-full pb-20 flex justify-center items-start">
          <TailSpin color="#0B75C2" height={70} width={90} />
        </div>
      ) : (
        <div className="w-full">
          {tasks.length >= 1 ? (
            <div className="w-full mt-12 pt-6 pb-20 grid gap-6 grid-cols-1 md:grid-cols-2 overflow-auto scrollbar-thumb-[#60B7F6] dark:scrollbar-thumb-[#AEBCC6]    scrollbar-thin lg:grid-cols-3">
              {tasks.map((task, index) => (
                <div
                  key={index}
                  className="flex justify-start h-[200px] rounded-xl items-center"
                >
                  <div className="w-[30%] bg-[#323233] rounded-l-xl h-full flex flex-col justify-center items-center">
                    <h4 className="font-sora font-semibold text-[36px] text-[#D9E6EF]">
                      {task.points}
                    </h4>
                    <h4 className="  text-[#D9E6EF] text-[14px]">Points</h4>
                  </div>
                  <div className="w-[70%] bg-[#0D0D0D] rounded-r-xl h-full pt-8 px-6">
                    <h4 className="font-medium text-white text-[14px]">
                      {task.name}
                    </h4>
                    <h4 className=" text-[#617889] mt-2 text-[13px]">
                      {task.description}
                    </h4>
                    <button
                      disabled={true}
                      className="w-[80px] h-[40px] flex justify-center items-center rounded-xl text-[12px] font-medium text-white mt-6 bg-gray-600 "
                    >
                      Approved
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col justify-center mt-8 h-[600px] lgss:h-[400px]  lgss:mt-12 items-center">
              <img src={noTransaction} alt="" />
              <h4 className="font-bold text-[#869AA9] pt-2">
                No approved task at the moment
              </h4>
              <p className=" text-[#869AA9] pt-2 text-[12px]">
                All approved tasks will show up here
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Past;
