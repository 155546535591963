import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useAuth } from "../../context/auth-context";
import { BiErrorCircle } from "react-icons/bi";
import axios from "axios";
import { API_URL } from "../../constant";

const CreateClientModal = ({
  setCreateClientModal,
  setSuccessClientModal,
  setClientId,
}: any) => {
  const { token } = useAuth();
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>("");
  const [name, setName] = useState<string>("");

  const validateInput = (name: string, phone: string) => {
    if (!name) {
      setError("Kindly enter client name");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (!phone || phone.length < 11) {
      setError("Kindly enter client name");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    setError("");
    return true;
  };

  const handleSubmit = () => {
    if (!validateInput(name, phone)) {
      return;
    }
    if (!loading) {
      setLoading(true);
      axios
        .post(
          `${API_URL}api/deex-clients`,
          {
            name: "Test create client",
            phone: "0963564323434",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          setClientId(r.data.data.client._id);
          setCreateClientModal(false);
          setSuccessClientModal(true);
          setLoading(false);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
          setLoading(false);
          setError(e.response.data.message);
          setTimeout(() => {
            setError("");
          }, 2000);
        });
    }
  };
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-60 backdrop-blur-sm">
      <div className="w-11/12 mds:w-10/12 lgss:w-[35%] transition-all p-6 ease-in duration-1000  rounded-xl bg-[#1F1F1F] ">
        <div className="flex  pt-4 justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Create client
          </h4>
          <h4
            onClick={() => setCreateClientModal(false)}
            className="text-[#78C2F7] font-medium text-[14px] cursor-pointer"
          >
            Close
          </h4>
        </div>
        <div className="w-full mt-6">
          <label
            htmlFor=""
            className="text-white text-[14px] lgss:text-[14px] font-bold"
          >
            Name
          </label>
          <input
            type="text"
            value={name}
            placeholder="Enter client name"
            onChange={(e) => setName(e.target.value)}
            className="w-full spin-button-none text-[13px] lgss:text-[15px]  mt-2  text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]"
          />
        </div>
        <div className="w-full mt-6">
          <label
            htmlFor=""
            className="text-white text-[14px] lgss:text-[14px] font-bold"
          >
            Enter phone number
          </label>
          <input
            type="tel"
            value={phone}
            placeholder="+234"
            onChange={(e) => setPhone(e.target.value)}
            className="w-full spin-button-none text-[13px] lgss:text-[15px]  mt-2  text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]"
          />
        </div>

        {error && (
          <div className="bg-red-600 text-white mb-4 text-[13px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-semibold mt-4">
            <BiErrorCircle className="text-xl" />
            {error}
          </div>
        )}
        <button
          onClick={handleSubmit}
          disabled={phone.length < 11}
          className={
            phone.length >= 11
              ? " w-full rounded-xl py-3 flex justify-center items-center text-white text-[16px] font-bold mt-6 bg-secondary_button"
              : " w-full rounded-xl py-3 flex justify-center items-center text-gray-200 text-[16px] font-bold mt-6 bg-gray-600"
          }
        >
          {loading ? (
            <TailSpin color="#FFFFFF" height={30} width={30} />
          ) : (
            "Generate wallet"
          )}
        </button>
      </div>
    </div>
  );
};

export default CreateClientModal;
