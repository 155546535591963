import { validate } from 'multicoin-address-validator';

// Function to validate BTC address
export const validateBTCAddress = (address: string) => {
  // Regular expression for BTC address validation
  const btcAddressRegex = /^(1|3|bc1)[a-zA-HJ-NP-Z0-9]{25,39}$/;

  // Check if the address matches the regex pattern
  return btcAddressRegex.test(address);
};

// Function to validate USDT address (TRC-20, BEP-20, ERC-20)
// Validate BEP-20 address (Binance Smart Chain)
export const validateBEP20Address = (address: string) => {
  // Regular expression for BEP-20 address validation
  const bep20AddressRegex = /^(0x)?[a-fA-F0-9]{40}$/;

  // Check if the address matches the regex pattern
  return bep20AddressRegex.test(address);
};

// Validate ERC-20 address (Ethereum Network)
export const validateERC20Address = (address: string) => {
  // Regular expression for ERC-20 address validation
  const erc20AddressRegex = /^(0x)?[a-fA-F0-9]{40}$/;

  // Check if the address matches the regex pattern
  return erc20AddressRegex.test(address);
};

// Validate TRC-20 address (Tron Network)
export const validateTRC20Address = (address: string) => {
  // Regular expression for TRC-20 address validation
  const trc20AddressRegex = /^T[0-9a-zA-Z]{33}$/;

  // Check if the address matches the regex pattern
  return trc20AddressRegex.test(address);
};
