import axios from "axios";
import React, { useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BiErrorCircle } from "react-icons/bi";
import { TailSpin } from "react-loader-spinner";
import { API_URL } from "../../constant";
import { useAuth } from "../../context/auth-context";

const AwardPoints = ({ setOpenAwardModal, customerId }: any) => {
  const { token } = useAuth();
  const [error, setError] = useState<string>("");
  const [points, setPoints] = useState<number | "">("");
  const [reason, setReason] = useState<string>("fpl");
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>("");

  const isNumber = (str: any) => {
    if (str.trim() === "") {
      return false;
    }

    return !isNaN(str);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNumber(value)) {
      setPoints(Number(value));
    } else {
      setPoints("");
    }
  };
  const handleTypeChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setReason("fpl");
  };
  const validate = (message: string, points: number | string) => {
    if (!message) {
      setError("Kindly add the push message");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (!points) {
      setError("Kindly add the points");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    setError("");
    return true;
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validate(message, points)) {
      return;
    }
    if (!loading) {
      setLoading(true);
      axios
        .post(
          `${API_URL}api/tasks/${customerId}/award-points`,
          {
            reason: reason,
            message: message,
            points: points,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          setLoading(false);

          setSuccess(`${points} Points awarded successfully`);
          setTimeout(() => {
            setSuccess("");
            setOpenAwardModal((prev: any) => !prev);
          }, 3000);
        })
        .catch((e: any) => {
          setError(e.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setError("");
          }, 3000);
          console.log(e.response.data.message);
        });
    }
  };
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-80 pr-10 backdrop-blur-sm">
      <div className=" w-11/12 lgss:w-1/3 transition-all p-6 ease-in duration-1000  rounded-xl bg-[#1F1F1F] ">
        <div className="flex   justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Award Points
          </h4>
          <h4
            onClick={() => setOpenAwardModal((prev: any) => !prev)}
            className="text-[#78C2F7] text-[14px] font-medium cursor-pointer"
          >
            Close
          </h4>
        </div>

        <form className="w-full mt-4" onSubmit={handleSubmit}>
          {success && (
            <div className="bg-green-600 w-full text-white  text-[16px] rounded-xl justify-start items-center gap-4 flex h-[60px] px-2 font-bold mt-4 mb-4">
              <AiOutlineCheckCircle className="text-3xl text-white" />
              {success}
            </div>
          )}

          <div className="w-full mt-4">
            <label className="text-[14px] font-bold text-white">Reason</label>
            <select
              value={reason}
              onChange={handleTypeChange}
              className="w-full mt-2 capitalize font-bold text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]"
            >
              <option value="Football Fantasy League">
                Football Fantasy League
              </option>
            </select>
          </div>
          <div className="w-full mt-4">
            <label className="text-[14px] font-bold text-white">Points</label>
            <input
              value={points}
              type="number"
              placeholder="Enter desired award points"
              onChange={handleChange}
              className="w-full mt-2 capitalize font-medium text-[#A0A9AD] bg-[#1C2227] px-4 outline-none text-[15px] h-[48px] rounded-xl border border-[#141414]"
            />
          </div>
          <div className="w-full mt-2">
            <label className="text-[14px] font-bold text-white">Message</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type here..."
              className="w-full mt-2 text-[15px] tracking-wider placeholder:text-[15px]  text-[#A0A9AD] pt-2 bg-[#1C2227] px-4 outline-none h-[120px] rounded-xl border border-[#141414]"
            />
          </div>

          {error && (
            <div className="bg-red-600 text-white  text-[12px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-bold mt-4">
              <BiErrorCircle className="text-xl" />
              {error}
            </div>
          )}
          <button
            type="submit"
            className="w-full h-[46px] flex justify-center items-center font-manrope font-medium rounded-xl text-white bg-secondary_button mt-4 lgss:mt-4"
          >
            {loading ? (
              <TailSpin color="#FFFFFF" height={30} width={30} />
            ) : (
              "Send now"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AwardPoints;
