import React, { useEffect, useState } from "react";
import { HiOutlineBars3 } from "react-icons/hi2";
import { IoNotifications } from "react-icons/io5";
import { useAuth } from "../context/auth-context";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constant";
import { Link } from "react-router-dom";
import NotificationModal from "./NotificationModal";
import { IoIosArrowDown, IoMdArrowDropdown } from "react-icons/io";
import { MdArrowRight } from "react-icons/md";
import { AiOutlineRight } from "react-icons/ai";
import SearchModal from "./SearchModal";
import { RiSearchLine } from "react-icons/ri";

const Navbar = ({ setUploadGiftcard, setRateScreen, setMessageModal }: any) => {
  const location = useLocation();
  const {
    isNotified,
    setIsNotified,
    token,
    setIsOpened,
    isOpened,
    setOpenRange,
    openRange,
    filter,
    setFilter,
    openButton,
    setOpenButton,
    query,
    setQuery,
  } = useAuth();
  const [unreadNotify, setUnreadNotify] = useState<number>(0);
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const handleUpdateCryptoRates = () => {
    if (window.location.pathname === "/dashboard") {
      setUploadGiftcard(true);
      setRateScreen(1);
    } else {
      const url = new URL("/dashboard", window.location.origin);
      url.searchParams.append("uploadGiftcard", "true");
      url.searchParams.append("rateScreen", "1");
      window.location.href = url.toString();
    }
  };

  const handleUpdateGiftcardRates = () => {
    if (window.location.pathname === "/dashboard") {
      setUploadGiftcard(true);
      setRateScreen(2);
    } else {
      const url = new URL("/dashboard", window.location.origin);
      url.searchParams.append("uploadGiftcard", "true");
      url.searchParams.append("rateScreen", "2");
      window.location.href = url.toString();
    }
  };

  const handleMessageModal = () => {
    if (window.location.pathname === "/dashboard") {
      setMessageModal(true);
    } else {
      const url = new URL("/dashboard", window.location.origin);
      url.searchParams.append("messageModal", "true");
      window.location.href = url.toString();
    }
  };

  useEffect(() => {
    axios
      .get(`${API_URL}api/activities/?page=1&limit=5`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        if (r.data.data.meta.noOfUnreadInAppNotifications >= 1) {
          setUnreadNotify(r.data.data.meta.noOfUnreadInAppNotifications);
        }
      })

      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, [token, unreadNotify]);

  useEffect(() => {
    if (unreadNotify >= 1 && isNotified) {
      setTimeout(() => {
        setUnreadNotify(0);
      }, 2000);
    }
  }, [isNotified, unreadNotify]);

  return (
    <div className="flex flex-col   font-manrope text-white items-center pt-4 pb-4 bg-[#0D0D0D] lgss:pt-4 lgss:w-full lgss:pb-2 px-5 lgss:px-10">
      <div className="flex w-full justify-between font-manrope text-white items-center  ">
        <div className="flex justify-center items-center gap-4 lgss:gap-2 ">
          <HiOutlineBars3
            className=" text-[white] lgss:hidden text-[32px]"
            onClick={() => setIsOpened((prev: any) => !prev)}
          />
          <div className="w-[14px] h-[5px] bg-secondary_button hidden lgss:flex rounded-lg" />
          <h4 className="font-sora">
            {location.pathname === "/dashboard"
              ? "Dashboard"
              : location.pathname === "/wallet"
              ? "Wallets"
              : location.pathname === "/users"
              ? "Users"
              : location.pathname === "/transactions"
              ? "Transactions"
              : location.pathname === "/reports"
              ? "Reports"
              : location.pathname === "/tasks"
              ? "Tasks"
              : location.pathname === "/settings"
              ? "Settings"
              : null}
          </h4>
        </div>
        <div className="flex gap-4  justify-center items-center ">
          <div className="relative">
            <IoNotifications
              onClick={() => setIsNotified((prev: any) => !prev)}
              className="text-2xl lgss:text-3xl text-white cursor-pointer"
            />
            {unreadNotify >= 1 && (
              <div className="bell-light absolute top-0 animate-pulse -right-[1px] w-2 h-2 bg-secondary_button rounded-full" />
            )}
          </div>
          <Link
            to="/settings"
            // state={{ screen: 1 }}
            className="rounded-full w-[42px] h-[42px] bg-[#032843] flex items-center justify-center font-medium text-[#0C84DA] cursor-pointer"
          >
            AD
          </Link>
        </div>
      </div>
      <div className="w-full flex justify-start pt-6 items-start mds:justify-between flex-col mds:flex-row mds:items-center">
        <h2 className="font-bold  font-sora text-[17px] text-white">
          Welcome, Admin 👋🏽
        </h2>
        <div className="flex justify-start lgss:justify-end mt-4 items-center gap-2 w-full lgss:w-1/2 ">
          <div
            className={
              isOpened || isNotified
                ? "w-1/3  flex lgss:justify-end lgss:relative"
                : `lgss:w-1/3 ${
                    openRange ? "w-[25%]" : ""
                  }  flex lgss:justify-end relative`
            }
          >
            <button
              onClick={() => {
                // setOpenButton(false);
                setOpenRange((prev: any) => !prev);
              }}
              className="px-3 py-2 bg-[#273037] rounded-xl  text-[14px] gap-2 text-[#869AA9] flex justify-center items-center"
            >
              {filter === ""
                ? "All"
                : filter === "today"
                ? "Today"
                : filter === "thisWeek"
                ? "This week"
                : filter === "thisMonth"
                ? "This month"
                : filter === "last3Months"
                ? "Last 3 months"
                : ""}
              {openRange ? (
                <AiOutlineRight className="text-[16px]" />
              ) : (
                <IoIosArrowDown className="text-[18px]" />
              )}
            </button>
            {openRange && (
              <div className="w-full mt-12 px-2 sidebar absolute bg-[#273037] z-50   flex flex-col    ">
                <button
                  onClick={() => {
                    setFilter("today");
                    setOpenRange((prev: any) => !prev);
                  }}
                  className="w-full hover:bg-[#273037] border-b border-gray-700 text-white text-[16px] font-bold py-4 flex justify-center items-center"
                >
                  Today
                </button>
                <button
                  onClick={() => {
                    setFilter("thisWeek");
                    setOpenRange((prev: any) => !prev);
                  }}
                  className="w-full hover:bg-[#273037] border-b border-gray-700 text-white text-[16px] font-bold py-4 flex justify-center items-center"
                >
                  This week
                </button>
                <button
                  onClick={() => {
                    setFilter("thisMonth");
                    setOpenRange((prev: any) => !prev);
                  }}
                  className="w-full hover:bg-[#273037] border-b border-gray-700 text-white text-[16px] font-bold py-4 flex justify-center items-center"
                >
                  This Month
                </button>
                <button
                  onClick={() => {
                    setFilter("last3Months");
                    setOpenRange((prev: any) => !prev);
                  }}
                  className="w-full hover:bg-[#273037] border-b border-gray-700 text-white text-[16px] font-bold py-4 flex justify-center items-center"
                >
                  Last 3 months
                </button>
                <button
                  onClick={() => {
                    setFilter("");
                    setOpenRange((prev: any) => !prev);
                  }}
                  className="w-full hover:bg-[#273037] border-b border-gray-700 text-white text-[16px] font-bold py-4 flex justify-center items-center"
                >
                  All
                </button>
              </div>
            )}
          </div>
          <div
            onClick={() => setOpenSearch((prev: any) => !prev)}
            className="flex cursor-pointer items-center w-2/5 lgss:w-[28%]  rounded-lg  bg-[#273037] px-4 "
          >
            <input
              className="flex  py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none   disabled:cursor-not-allowed disabled:opacity-50 w-full text-[#869AA9] placeholder:text-[#869AA9] text-[14px] placeholder:text-[14px] border-none bg-transparent focus:ring-0 "
              placeholder="Search"
              type="search"
            />
            <button className="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none  focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 w-10 rounded-full">
              <RiSearchLine className="h-5 w-5 text-[#667085]" />
            </button>
          </div>
          <div
            className={
              openButton
                ? "w-3/5 lgss:w-1/3 flex justify-center lgss:justify-end px-2 lgss:px-0 relative"
                : " flex justify-end relative"
            }
          >
            <button
              onClick={() => {
                setOpenRange(false);
                setOpenButton((prev: any) => !prev);
              }}
              className="flex justify-between px-2 mds:px-4 outline-none  py-2 rounded-xl  items-center  text-[#E7EBEE] bg-secondary_button text-[13px]  mds:text-[14px] "
            >
              <h4>Quick Action</h4>
              {openButton ? (
                <MdArrowRight className="text-[18px]" />
              ) : (
                <IoMdArrowDropdown className="text-[18px]" />
              )}
            </button>
            {openButton && (
              <div className="w-full sidebar absolute z-50 bg-[#1C2227] mt-12 flex flex-col   rounded-xl">
                <Link
                  to="/tasks"
                  onClick={() => {
                    setOpenButton((prev: any) => !prev);
                  }}
                  className="w-full hover:bg-[#273037] rounded-t-xl border-b border-gray-700 text-white text-[14px] font-bold py-4 flex justify-center items-center"
                >
                  Create Task
                </Link>
                <button
                  onClick={() => {
                    setOpenButton((prev: any) => !prev);
                    handleUpdateCryptoRates();
                  }}
                  className="w-full hover:bg-[#273037] border-b border-gray-700 text-white text-[14px] font-bold py-4  flex justify-center items-center"
                >
                  Update Crypto Rates
                </button>
                <button
                  onClick={() => {
                    setOpenButton((prev: any) => !prev);
                    handleUpdateGiftcardRates();
                  }}
                  className="w-full hover:bg-[#273037] border-b border-gray-700 text-white text-[14px] font-bold   py-4 flex justify-center items-center"
                >
                  Update Giftcard rates
                </button>
                <button
                  onClick={() => {
                    setOpenButton((prev: any) => !prev);
                    handleMessageModal();
                  }}
                  className="w-full hover:bg-[#273037] rounded-b-xl text-white text-[14px]  py-4 font-bold  flex justify-center items-center"
                >
                  Send message
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {isNotified && (
        <NotificationModal
          setIsNotified={setIsNotified}
          unreadNotify={unreadNotify}
          setUnreadNotify={setUnreadNotify}
        />
      )}
      {openSearch && <SearchModal setOpenSearch={setOpenSearch} />}
    </div>
  );
};

export default Navbar;
