import axios from "axios";
import React, { useState } from "react";
import { TiWarning } from "react-icons/ti";
import { API_URL } from "../../constant";
import { useAuth } from "../../context/auth-context";
import { TailSpin } from "react-loader-spinner";
import { AiOutlineCheckCircle } from "react-icons/ai";

const Confirmation = ({
  setConfirmedModal,
  setOrderModal,
  txnId,
  userId,
  currencyUSD,
  currencyNGN,
  cardType,
  setConfirmed,
  setRefetchCount,
}: any) => {
  const { token } = useAuth();
  const [loader, setLoader] = useState<boolean>(false);

  const [success, setSuccess] = useState<string>("");

  const confirmTransaction = () => {
    setLoader(true);
    axios
      .post(
        `${API_URL}api/payouts`,
        {
          asset: {
            name: cardType.card.name,
            amountInUSD: currencyUSD,
          },
          payoutAmount: currencyNGN,
          userId: userId,
          transaction: {
            id: txnId,
            type: "GIFT-CARD",
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r: any) => {
        // console.log(r.data.data);
        setSuccess("The order has been confirmed");
        setRefetchCount((prev: number) => prev + 1);
        setTimeout(() => {
          setSuccess("");
        }, 2500);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
        setLoader(false);
      });
    axios
      .patch(
        `${API_URL}api/gift-cards-transactions/${txnId}`,
        {
          status: "COMPLETED",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r: any) => {
        // console.log(r.data);

        setConfirmed(true);

        setLoader(false);
        setTimeout(() => {
          setConfirmedModal((prev: any) => !prev);
        }, 2300);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
        setLoader(false);
      });
  };

  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-60 backdrop-blur-sm">
      <div className="w-11/12 lgss:w-2/5 transition-all px-8 pt-8 ease-in duration-1000  rounded-xl bg-[#1F1F1F] pb-4">
        <div className="flex   justify-between items-center">
          <h4 className="font-bold text-[18px] font-sora text-white">
            Confirm transaction
          </h4>
          <button
            onClick={() => {
              setConfirmedModal((prev: any) => !prev);
              setOrderModal((prev: any) => !prev);
            }}
            className="text-[#78C2F7] font-medium text-[13px]  cursor-pointer"
          >
            {" "}
            Close
          </button>
        </div>
        {success && (
          <div className="bg-green-600 w-full text-white  text-[16px] rounded-xl justify-start items-center gap-4 flex h-[60px] px-2 font-bold mt-4 mb-4">
            <AiOutlineCheckCircle className="text-3xl text-white" />
            {success}
          </div>
        )}
        <div className="w-full h-[80px] flex justify-start items-start pt-4 gap-4 mt-6 border border-[#141414] px-4 rounded-xl bg-transparent">
          <TiWarning className="text-3xl text-secondary_button" />
          <h4 className="text-[#A0A9AD] text-[12px] md:text-[14px]">
            Make sure you cross check profile name with user bank’s account name
            before you send cash.
          </h4>
        </div>
        <div className="w-full mt-6 flex justify-center items-center gap-4 ">
          <button
            onClick={confirmTransaction}
            className="bg-secondary_button text-white flex text-[14px] font-medium justify-center items-center rounded-xl h-[52px] w-1/2"
          >
            {loader ? (
              <TailSpin color="#FFFFFF" height={30} width={30} />
            ) : (
              "Confirm"
            )}
          </button>
          <button
            onClick={() => {
              setConfirmedModal((prev: any) => !prev);
              setOrderModal((prev: any) => !prev);
            }}
            className="bg-transparent border  border-secondary_button text-secondary_button flex text-[14px] font-medium justify-center items-center rounded-xl h-[52px] w-1/2"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
