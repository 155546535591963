import axios from "axios";
import React, { useEffect, useState } from "react";
import { Bars, TailSpin } from "react-loader-spinner";
import { API_URL } from "../../../constant";
import { useAuth } from "../../../context/auth-context";
import { IoIosArrowDown, IoMdAdd } from "react-icons/io";
import { noTransaction } from "../../../assets/images";
import { AiOutlineCloseCircle, AiOutlineExpandAlt } from "react-icons/ai";

interface GiftcardRateFlowProps {
  setCardModal: any;
  cardId: string;
  allGiftcards: any[];
  activeCard: boolean;
  setActiveCard: any;
  setAddGiftcard: any;
  cardDetails: any;
  setCardDetails: any;
  setRefetchCount: any;
  refetchCount: number;
}

interface CountryDetail {
  _id: string;
  type: string;
  country: string;
  range: {
    min: number;
    max: number;
  };
  rate: number;
  isActive: boolean;
}

const GiftcardRateFlow: React.FC<GiftcardRateFlowProps> = ({
  setCardModal,
  cardId,
  allGiftcards,
  activeCard,
  setActiveCard,
  setAddGiftcard,
  cardDetails,
  setCardDetails,
  setRefetchCount,
  refetchCount,
}) => {
  const { token } = useAuth();
  const [detailsLoader, setDetailsLoader] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [countryDetail, setCountryDetail] = useState<CountryDetail[]>([]);
  const [newRate, setNewRate] = useState({ id: null, value: "" });
  const [minRate, setMinRate] = useState<number | string>("");
  const [maxRate, setMaxRate] = useState<number | string>("");
  const [createRate, setCreateRate] = useState<number | string>("");
  const [loader, setLoader] = useState(false);
  const [addNewRate, setAddNewRate] = useState(false);
  const [addLoader, setAddLoader] = useState(false);
  const [openSettings, setOpenSettings] = useState<Record<number, boolean>>({});
  const [updateToggle, setUpdateToggle] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const toggleModal = (objectId: number) => {
    setOpenSettings((prev) => ({
      ...prev,
      [objectId]: !prev[objectId], // Toggle the state for the specific object
    }));
  };

  const closeModal = (objectId: number) => {
    setOpenSettings((prev) => ({
      ...prev,
      [objectId]: false, // Close the modal for the specific object
    }));
  };

  const isNumber = (str: any) => {
    if (str.trim() === "") {
      return false;
    }
    return !isNaN(str);
  };

  const handleRateChange = (id: any, event: any) => {
    setNewRate({ id, value: event.target.value });
  };

  const handleMinRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMinRate(isNumber(value) ? Number(value) : "");
  };

  const handleMaxRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMaxRate(isNumber(value) ? Number(value) : "");
  };

  const handleCreateRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCreateRate(isNumber(value) ? Number(value) : "");
  };

  useEffect(() => {
    if (cardId) {
      setDetailsLoader(true);
      axios
        .get(`${API_URL}api/gift-cards/${cardId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const { giftCard } = response.data.data;
          setCardDetails(giftCard);
          setCountryDetail(giftCard.rates || []);
          setActiveCard(giftCard.isActive);
          setDetailsLoader(false);
        })
        .catch((error) => {
          console.error(error.response.data.message);
          setDetailsLoader(false);
        });
    }
  }, [cardId, token, refetchCount]);

  const handleStatusChange = () => {
    if (!statusLoader) {
      setStatusLoader(true);
      axios
        .patch(
          `${API_URL}api/gift-cards/${cardId}`,
          {
            isActive: !activeCard,
            thumbnail: cardDetails?.thumbnail,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setActiveCard((prev: any) => !prev);
          setStatusLoader(false);
        })
        .catch((error) => {
          console.error(error.response.data.message);
          setStatusLoader(false);
        });
    }
  };

  const updateNewRate = () => {
    if (!addLoader) {
      setAddLoader(true);
      axios
        .post(
          `${API_URL}api/gift-cards/${cardDetails._id}/rates`,
          {
            type: "physicalCashReceipt",
            isActive: false,
            range: {
              min: minRate,
              max: maxRate,
            },
            rate: createRate,
            country: "USA",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setAddLoader(false);
          setMinRate("");
          setMaxRate("");
          setCreateRate("");
          setAddNewRate((prev) => !prev);
          setRefetchCount((prev: number) => prev + 1);
        })
        .catch((error) => {
          setAddLoader(false);
          console.error(error.response.data.message);
        });
    }
  };

  return (
    <div className="w-full font-manrope overflow-auto scrollbar-thumb-[#60B7F6] scrollbar-track-white  scrollbar-thin">
      <div className="w-full  justify-between h-[62px] bg-[#0D0D0D] px-6 flex items-center">
        <h4
          onClick={() => setCardModal((prev: any) => !prev)}
          className="font-bold text-[20px] cursor-pointer flex justify-start items-center gap-2 font-sora text-white"
        >
          {cardDetails?.name}
          <IoIosArrowDown />
        </h4>
        {statusLoader ? (
          <TailSpin color="#0B75C2" height={30} width={30} />
        ) : (
          <div className="flex justify-center gap-2 lgss:gap-4 mx-4 lgss:mx-0 items-center flex-col lgss:flex-row">
            {allGiftcards.length >= 1 && (
              <div
                onClick={handleStatusChange}
                className={`flex w-12 cursor-pointer h-6 rounded-full transition-all duration-500 ${
                  activeCard ? "bg-secondary_button" : "bg-gray-600"
                }`}
              >
                <span
                  className={`h-6 w-6 rounded-full transition-all duration-500 bg-gray-100 ${
                    activeCard ? "ml-6" : ""
                  }`}
                ></span>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="w-full h-[58px] flex justify-between items-center overflow-auto text-[#617889] text-[14px] font-medium bg-[#1C2227] px-8">
        <h4 className="w-[10%]">Name</h4>
        <h4 className="text-left w-[10%]">Receipt</h4>
        <h4 className="w-[10%] text-right">Country</h4>
        <h4 className=" w-[10%] text-right">Trade Range</h4>
        <h4 className="w-[10%]">Current Rate </h4>
        <h4 className="w-[10%]">New Rate </h4>
        <h4 className="w-[10%]">Settings</h4>
      </div>
      <div className="w-full h-[400px] bg-[#0D0D0D] pb-10 overflow-auto scrollbar-thumb-[#AEBCC6] scrollbar-thin">
        {detailsLoader ? (
          <div className="flex pt-8 justify-center items-center">
            <Bars color="#0B75C2" height={80} width={80} />
          </div>
        ) : (
          <div className="w-full">
            {countryDetail.length >= 1 ? (
              <div className="w-full">
                {countryDetail.map((country, index) => {
                  const updateRate = () => {
                    if (!loader) {
                      setLoader(true);
                      axios
                        .patch(
                          `${API_URL}api/gift-cards/${cardDetails._id}/rates/${country._id}`,
                          {
                            rate: newRate.value,
                          },
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        )
                        .then(() => {
                          setLoader(false);
                          setNewRate({ id: null, value: "" });
                          setRefetchCount((prev: number) => prev + 1);
                        })
                        .catch((error) => {
                          setLoader(false);
                          console.error(error.response.data.message);
                        });
                    }
                  };

                  const deleteRate = () => {
                    if (!loader) {
                      setDeleteLoader(true);
                      axios
                        .delete(
                          `${API_URL}api/gift-cards/${cardDetails._id}/rates/${country._id}`,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        )
                        .then((r: any) => {
                          setDeleteLoader(false);
                          setRefetchCount((prev: number) => prev + 1);
                          closeModal(index);
                        })
                        .catch((e: any) => {
                          setDeleteLoader(false);
                          console.log(e.response.data.message);
                        });
                    }
                  };
                  const updateToggleState = () => {
                    if (!updateToggle) {
                      if (country.isActive) {
                        setUpdateToggle(true);
                        axios
                          .patch(
                            `${API_URL}api/gift-cards/${cardDetails._id}/rates/${country._id}`,
                            {
                              isActive: false,
                            },
                            {
                              headers: {
                                Authorization: `Bearer ${token}`,
                              },
                            }
                          )
                          .then((r: any) => {
                            setUpdateToggle(false);
                            setRefetchCount((prev: number) => prev + 1);
                            closeModal(index);
                          })
                          .catch((e: any) => {
                            setUpdateToggle(false);
                            console.log(e.response.data.message);
                          });
                      } else {
                        setUpdateToggle(true);
                        axios
                          .patch(
                            `${API_URL}api/gift-cards/${cardDetails._id}/rates/${country._id}`,
                            {
                              isActive: true,
                            },
                            {
                              headers: {
                                Authorization: `Bearer ${token}`,
                              },
                            }
                          )
                          .then((r: any) => {
                            setUpdateToggle(false);
                            setRefetchCount((prev: number) => prev + 1);
                            closeModal(index);
                          })
                          .catch((e: any) => {
                            setUpdateToggle(false);
                            console.log(e.response.data.message);
                          });
                      }
                    }
                  };

                  return (
                    <div
                      key={index}
                      className="w-full   h-[76px] flex justify-between items-center text-[#AEBCC6] text-[16px] bg-[#1C2227] mt-4 px-8"
                    >
                      <div className="flex justify-start items-center gap-3">
                        <div className="w-[30px] h-[30px] rounded-lg">
                          <img
                            src={cardDetails?.thumbnail}
                            alt=""
                            className="w-full h-full"
                          />
                        </div>
                        <h4 className="text-[14px]">
                          {cardDetails?.name || "No name"}
                        </h4>
                      </div>
                      <h4 className="text-[14px] font-bold uppercase w-[10%]">
                        {" "}
                        {country.type === "physicalCashReceipt"
                          ? "Cash"
                          : country.type === "physicalDebitCardReceipt"
                          ? "Debit"
                          : country.type === "physicalCreditCardReceipt"
                          ? "Credit"
                          : country.type === "physicalActivationReceipt"
                          ? "Activation"
                          : country.type === "physicalNoReceipt"
                          ? "No Receipt"
                          : country.type === "ecode"
                          ? "Ecode"
                          : ""}
                      </h4>
                      <h4 className="px-2 h-[28px] w-[100px] rounded-sm  text-[14px] font-medium bg-[#1D1D1D] tracking-wider text-white flex justify-center items-center">
                        {country.country}
                      </h4>
                      <h4 className="w-[10%]">{`${country.range.min}-${country.range.max}`}</h4>
                      <h4 className="">{country.rate}</h4>
                      <div className=" flex items-center">
                        <input
                          type="text"
                          className="w-20 h-8 bg-[#0D0D0D] border border-[#617889] rounded-md px-2 outline-none text-[13px] "
                          value={
                            country._id === newRate.id ? newRate.value : ""
                          }
                          onChange={(e) => handleRateChange(country._id, e)}
                        />
                        <button
                          onClick={updateRate}
                          className="ml-2 h-8 px-2 bg-[#0B75C2] rounded-md text-white"
                        >
                          {loader ? (
                            <TailSpin color="#fff" height={15} width={15} />
                          ) : (
                            "Update"
                          )}
                        </button>
                      </div>
                      <div className="ml-4  flex items-center">
                        <button
                          onClick={() => toggleModal(index)}
                          className="h-8 w-8 bg-[#0B75C2] rounded-md text-white flex justify-center items-center"
                        >
                          <AiOutlineExpandAlt />
                        </button>
                        {openSettings[index] && (
                          <div className=" fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-50  backdrop-blur-sm">
                            <div className="w-[190px] rounded-xl h-[160px] bg-[#1C2227] ">
                              <div className="w-full px-6 text-[16px] font-bold rounded-t-xl h-[50px] flex justify-between items-center bg-[#273037]">
                                Edit
                                <AiOutlineCloseCircle
                                  onClick={() => closeModal(index)}
                                  className="text-[20px] text-secondary_button"
                                />
                              </div>
                              <div className="mt-4 w-full flex justify-between px-6 items-center">
                                <h4 className="text-white text-[16px] font-bold">
                                  Turn {country.isActive ? "Off" : "On"}
                                </h4>
                                {updateToggle ? (
                                  <TailSpin
                                    color="#0B75C2"
                                    height={30}
                                    width={30}
                                  />
                                ) : (
                                  <div
                                    onClick={updateToggleState}
                                    className={`flex w-8  cursor-pointer h-4   rounded-full transition-all duration-500 ${
                                      country.isActive
                                        ? "bg-secondary_button"
                                        : "bg-gray-600"
                                    }`}
                                  >
                                    <span
                                      className={`h-4 w-4  rounded-full transition-all duration-500 bg-gray-100 ${
                                        country.isActive ? "ml-4" : ""
                                      }`}
                                    ></span>
                                  </div>
                                )}
                              </div>
                              <div className="mt-4 w-full flex justify-between px-6 items-center">
                                {deleteLoader ? (
                                  <TailSpin
                                    color="#FFFFFF"
                                    height={25}
                                    width={25}
                                  />
                                ) : (
                                  <button
                                    onClick={deleteRate}
                                    className="text-[#DF4E4E] cursor-pointer  text-[16px] font-bold"
                                  >
                                    Delete
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="w-full flex justify-center mt-10 flex-col items-center">
                <img
                  src={noTransaction}
                  alt="no_transaction"
                  className="h-[60px] w-[60px] object-contain"
                />
                <h4 className="text-[14px] font-semibold text-[#617889] mt-4">
                  No Giftcard Rates Yet
                </h4>
              </div>
            )}
          </div>
        )}
      </div>
      <div
        onClick={() => setAddGiftcard((prev: any) => !prev)}
        className="w-full h-[60px] flex items-center cursor-pointer justify-end bg-[#1C2227] text-[16px] text-white px-6 gap-2"
      >
        <h4 className="flex  gap-2 items-center">
          Add New Rate
          <IoMdAdd />
        </h4>
      </div>
      {addNewRate && (
        <div className="w-full p-6 bg-[#1C2227] flex flex-col justify-start items-start text-[#AEBCC6] text-[16px]">
          <h4 className="text-white text-[20px] mb-4">Add New Rate</h4>
          <input
            type="text"
            placeholder="Minimum Rate"
            className="w-full h-12 bg-[#0D0D0D] border border-[#617889] rounded-md px-4 mb-4"
            value={minRate}
            onChange={handleMinRateChange}
          />
          <input
            type="text"
            placeholder="Maximum Rate"
            className="w-full h-12 bg-[#0D0D0D] border border-[#617889] rounded-md px-4 mb-4"
            value={maxRate}
            onChange={handleMaxRateChange}
          />
          <input
            type="text"
            placeholder="Create Rate"
            className="w-full h-12 bg-[#0D0D0D] border border-[#617889] rounded-md px-4 mb-4"
            value={createRate}
            onChange={handleCreateRateChange}
          />
          <button
            onClick={updateNewRate}
            className="h-12 w-full bg-[#0B75C2] rounded-md text-white"
          >
            {addLoader ? (
              <TailSpin color="#fff" height={20} width={20} />
            ) : (
              "Create Rate"
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default GiftcardRateFlow;
