import React from "react";

const WalletReview = ({
  setWalletFeeModal,
  setWalletReviewModal,
  fiatFee,
}: any) => {
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-60 backdrop-blur-sm">
      <div className="w-10/12 mds:w-[60%] md:w-2/5 lgss:w-1/3  px-8 pt-8   rounded-xl bg-[#1F1F1F] pb-4">
        <div className="flex   justify-between items-center">
          <h4 className="font-bold text-[18px] font-sora text-white">
            Wallet withdrawal review
          </h4>
          <button
            onClick={() => {
              setWalletReviewModal((prev: any) => !prev);
            }}
            className="text-[#78C2F7] font-medium text-[13px]  cursor-pointer"
          >
            {" "}
            Close
          </button>
        </div>
        <h4 className="text-center font-medium text-[16px] mt-6 text-[#A0A9AD]">
          Are you sure you want to confirm
          <br />
          this fee changes?
        </h4>
        <div className="w-full mt-4">
          <div className="w-full mt-2 flex justify-between items-center text-[#A0A9AD] bg-[#1C2227] px-4  h-[48px]  rounded-lg border border-[#141414]">
            <h4 className="font-medium text-[15px] text-[#A0A9AD]">Fiat</h4>
            <h4 className="font-medium text-[15px] text-[#A0A9AD]">
              ₦{fiatFee}
            </h4>
          </div>
        </div>
        <div className="w-full justify-center flex gap-4 mt-10 items-center">
          <button
            onClick={() => {}}
            className="text-white font-medium text-[16px]  h-[48px] w-1/2 bg-secondary_button rounded-lg"
          >
            Confirm
          </button>
          <button
            onClick={() => {
              setWalletReviewModal((prev: any) => !prev);
              setWalletFeeModal((prev: any) => !prev);
            }}
            className="text-secondary_button font-medium text-[16px]  h-[48px] w-1/2 bg-transparent border border-secondary_button rounded-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default WalletReview;
