import React from "react";

const Security = () => {
  return (
    <div className="px-8 mds:px-12 lgss:px-20 pb-16 pt-16">
      <div className="flex justify-start gap-16 mds:gap-24 lgss:gap-44 items-center">
        <div className="">
          <h4 className="text-[16px] font-semibold text-white">
            Reset password
          </h4>
          <h4 className="text-[13px] text-[#F7F8F9] pt-2">
            Considering changing your password?
            <br />
            Tap to reset password
          </h4>
        </div>
        <button
          // onClick={resetPassword}
          className="flex font-bold justify-center  whitespace-nowrap items-center text-secondary_button"
        >
          {/* {loading ? (
        <div className="flex justify-center items-center">
          <TailSpin color="#0B75C2" height={30} width={30} />
        </div>
      ) : (
        "Reset Password"
      )} */}{" "}
          Reset Password
        </button>
      </div>
      <div className="flex justify-start gap-8 lgss:gap-28 mt-16 items-center">
        <div className="">
          <h4 className="text-[16px] font-semibold text-white">
            Delete account
          </h4>
          <h4 className="text-[13px] text-[#F7F8F9] pt-2">
            This is really sad and we don’t want to lose you,
            <br />
            but if you really must, tap to delete.
          </h4>
        </div>
        <button className="flex w-[180px] font-bold justify-center items-center text-[#D92B2B] bg-[#FDE8E8] h-[54px] lgss:h-[48px] rounded-lg">
          Deactivate account
        </button>
      </div>
    </div>
  );
};

export default Security;
