import React, { useEffect, useState, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import { useAuth } from "../context/auth-context";
import axios from "axios";
import { API_URL } from "../constant";
import { TailSpin } from "react-loader-spinner";
import { noTransaction } from "../assets/images";
import { useInView } from "react-intersection-observer";

const NotificationModal = ({
  setIsNotified,
  unreadNotify,
  setUnreadNotify,
}: any) => {
  const { token } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [activityLogs, setActivityLogs] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const divRef = useRef<HTMLDivElement | null>(null);
  const [ref, inView] = useInView();

  useEffect(() => {
    setLoading(true);

    axios
      .get(`${API_URL}api/activities/?page=${page}&limit=5`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        if (r.data.data.activityLogs.length >= 1) {
          const newActivityLogs = [
            ...activityLogs,
            ...r.data.data.activityLogs,
          ];
          setActivityLogs(newActivityLogs);
        }
        if (r.data.data.meta.noOfUnreadInAppNotifications >= 1) {
          setUnreadNotify(r.data.data.meta.noOfUnreadInAppNotifications);
        }

        setTotalPages(r.data.data.meta.totalPages);
        setLoading(false);
      })
      .catch((e: any) => {
        console.log(e.response);
        setLoading(false);
      });
  }, [token, page]);

  useEffect(() => {
    if (inView) {
      if (page < totalPages) {
        setPage((prev) => prev + 1);
      }
    }
  }, [inView, totalPages]);

  function formatDate(timestamp: string | number | Date) {
    const now = new Date();
    const date = new Date(timestamp);

    const isToday = date.toDateString() === now.toDateString();
    const isYesterday =
      date.toDateString() ===
      new Date(now.setDate(now.getDate() - 1)).toDateString();

    if (isToday) {
      return "Today " + formatTime(date);
    } else if (isYesterday) {
      return "Yesterday " + formatTime(date);
    } else {
      return formatDateOnly(date) + " , " + formatTime(date);
    }
  }

  function formatTime(date: Date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  function formatDateOnly(date: Date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  return (
    <div className="fixed w-full inset-0 flex font-manrope justify-end px-10 items-start pt-[70px] bg-[#000000] bg-opacity-60 ">
      <div className="relative w-full lgss:left-[65%]">
        <div className="absolute -top-3 left-[88%] lgss:left-[28%] transform -translate-x-1/2">
          <div className="w-6 h-6 bg-[#192025] transform rotate-45 "></div>
        </div>
        <div className="w-full lgss:w-1/3  transition-all ease-in duration-1000   rounded-xl bg-[#192025] h-[610px] lgss:h-[570px]">
          <div className="flex px-10 pt-4 shadow-md pb-4 justify-between items-center">
            <div className="flex justify-center items-center gap-2">
              <h4 className="font-semibold text-[15px] text-white">
                Notifications
              </h4>
              {unreadNotify >= 1 && (
                <div
                  className="w-[24px] h-[24px] rounded-full flex justify-center items-center text-secondary_button bg-[#D4EBFD] font-semibold
               text-[12px]"
                >
                  {unreadNotify}
                </div>
              )}
            </div>
            <FaTimes
              onClick={() => {
                setIsNotified((prev: any) => !prev);
              }}
              className="text-white text-[20px] cursor-pointer"
            />
          </div>
          <div
            ref={divRef}
            className="w-full px-6 pt-4 pb-4 h-[530px] lgss:h-[490px]  overflow-auto scrollbar-thumb-[#AEBCC6]    scrollbar-thin"
          >
            {activityLogs.length >= 1 ? (
              <>
                <div className="w-full">
                  {activityLogs.map((notification, index) => {
                    const formattedDate = formatDate(notification.createdAt);
                    return (
                      <div
                        className={
                          notification.readAt
                            ? "mt-4 "
                            : "mt-4 bg-[#FCF3E866] p-2"
                        }
                        key={index}
                      >
                        <div className="text-[13px] flex justify-start items-center gap-2 font-medium  text-gray-300">
                          {!notification.readAt && (
                            <div className="w-[8px] h-[8px] rounded-full bg-[#F28A0F]"></div>
                          )}{" "}
                          {notification.content}
                        </div>
                        {/* <h5 className="text-[13px] font-medium mt-3 text-[#617889]">
                          {notification.content}
                        </h5> */}
                        <h5 className="text-[11px] font-bold mt-4 text-gray-300">
                          {formattedDate}
                        </h5>
                        <div className=" h-[1px] bg-gray-600 w-full rounded-full mt-4" />
                      </div>
                    );
                  })}

                  <div ref={ref} className=""></div>
                </div>
              </>
            ) : (
              !loading && (
                <div className="flex flex-col justify-center mt-16 lgss:mt-24 items-center">
                  <img src={noTransaction} alt="" />
                  <h4 className="font-bold text-[#869AA9] pt-2">
                    No notification
                  </h4>
                  <p className=" text-[#869AA9] pt-2 text-[12px]">
                    All your notifications will show up here
                  </p>
                </div>
              )
            )}
            {loading && (
              <div className="mt-6 flex justify-center items-center">
                <TailSpin color="#0B75C2" height={50} width={70} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationModal;
