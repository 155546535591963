// dateUtils.ts

// Interface for the date range object
interface DateRange {
  from: string | null;
  to: string | null;
}

// Function to calculate date range based on time filter
export const getDateRange = (filter: string): { current: DateRange, previous: DateRange } => {
  const today = new Date();
  let fromDate: Date | null = null;
  let toDate: Date | null = null;
  let prevFromDate: Date | null = null;
  let prevToDate: Date | null = null;

  switch (filter) {
    case 'today':
      fromDate = new Date(today);
      fromDate.setHours(0, 0, 0, 0); // Start of today
      toDate = new Date(today);
      toDate.setHours(23, 59, 59, 999); // End of today

      // Previous day
      prevFromDate = new Date(fromDate);
      prevFromDate.setDate(fromDate.getDate() - 1);
      prevFromDate.setHours(0, 0, 0, 0);
      prevToDate = new Date(toDate);
      prevToDate.setDate(toDate.getDate() - 1);
      prevToDate.setHours(23, 59, 59, 999);
      break;
    case 'thisWeek':
      const dayOfWeek = today.getDay();
      fromDate = new Date(today);
      fromDate.setDate(today.getDate() - dayOfWeek); // Start of the week
      fromDate.setHours(0, 0, 0, 0);
      toDate = new Date(today);
      toDate.setDate(today.getDate() + (6 - dayOfWeek)); // End of the week
      toDate.setHours(23, 59, 59, 999);

      // Previous week
      prevFromDate = new Date(fromDate);
      prevFromDate.setDate(fromDate.getDate() - 7);
      prevToDate = new Date(toDate);
      prevToDate.setDate(toDate.getDate() - 7);
      break;
    case 'thisMonth':
      fromDate = new Date(today.getFullYear(), today.getMonth(), 1); // Start of the month
      fromDate.setHours(0, 0, 0, 0);
      toDate = new Date(today.getFullYear(), today.getMonth() + 1, 0); // End of the month
      toDate.setHours(23, 59, 59, 999);

      // Previous month
      prevFromDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      prevFromDate.setHours(0, 0, 0, 0);
      prevToDate = new Date(today.getFullYear(), today.getMonth(), 0);
      prevToDate.setHours(23, 59, 59, 999);
      break;
    case 'last3Months':
      fromDate = new Date(today.getFullYear(), today.getMonth() - 2, 1); // Start of 3 months ago
      fromDate.setHours(0, 0, 0, 0);
      toDate = new Date(today);
      toDate.setHours(23, 59, 59, 999); // End of today

      // Previous 3 months
      prevFromDate = new Date(today.getFullYear(), today.getMonth() - 5, 1);
      prevFromDate.setHours(0, 0, 0, 0);
      prevToDate = new Date(today.getFullYear(), today.getMonth() - 2, 0);
      prevToDate.setHours(23, 59, 59, 999);
      break;
    default:
      // Default case (e.g., no filter selected)
      break;
  }

  return { 
    current: { from: fromDate?.toISOString() || null, to: toDate?.toISOString() || null },
    previous: { from: prevFromDate?.toISOString() || null, to: prevToDate?.toISOString() || null }
  };
};
