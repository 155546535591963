import React, { useEffect, useState } from "react";
import UsdtWithdraw from "./UsdtWithdraw";
import { FaTimesCircle } from "react-icons/fa";
import { useAuth } from "../../../context/auth-context";
import { btc, eth, tether } from "../../../assets/images";
import { BiErrorCircle } from "react-icons/bi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { TailSpin } from "react-loader-spinner";
import ConfirmWithdraw from "./ConfirmWithdraw";
import SuccessModalWallet from "./SuccessModalWallet";
import { validateBTCAddress } from "../../../utils/ValidateWallet";
import { API_URL } from "../../../constant";
import axios from "axios";

const Withdraw = ({ btcValue, usdtValue }: any) => {
  const { setWithdrawModal } = useAuth();
  const [ratePage, setRatePage] = useState<number>(1);

  const [withdrawAmount, setWithdrawAmount] = useState<number | string>("");
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [error, setError] = useState<string>("");

  const [withdrawAmountUSDT, setWithdrawAmountUSDT] = useState<number | string>(
    ""
  );

  const [loading, setLoading] = useState<boolean>(false);
  const { token } = useAuth();
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [successModall, setSuccessModall] = useState<boolean>(false);
  const [selectedValueUSDT, setSelectedValueUSDT] = useState<string>("BSC");
  const [walletAddressUSDT, setWalletAddressUSDT] = useState<string>("");
  //   const [ratePage, setRatePage] = useState<number>(1);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    setWithdrawAmount(numericValue);
  };

  const validateForm = (withdraw: number | string, walletAddress: string) => {
    if (!walletAddress) {
      setError("Kindly add your wallet address");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (!validateBTCAddress(walletAddress)) {
      setError("Invalid BTC address");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (typeof btcValue === "undefined") {
      setError("Kindly top up your wallet and try again");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (!withdraw) {
      setError("Kindly add amount you want to withdraw");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (withdraw > btcValue) {
      setError("Kindly top up your wallet and try again");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }

    setError("");
    return true;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm(withdrawAmount, walletAddress)) {
      return;
    }

    if (!loading) {
      axios
        .post(
          `${API_URL}api/users/me/request-otp`,
          {
            purpose: "transaction",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          // console.log(r.data.data.transactions);
          setConfirmModal(true);
          setLoading(false);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
          setLoading(false);
          setError(e.response.data.message);
          setTimeout(() => {
            setError("");
          }, 2000);
        });
    }
  };
  const [percentage, setPercentage] = useState<number>(0);
  useEffect(() => {
    if (typeof btcValue !== "undefined") {
      if (percentage === 1) {
        setWithdrawAmount((25 / 100) * btcValue);
      } else if (percentage === 2) {
        setWithdrawAmount((50 / 100) * btcValue);
      } else if (percentage === 3) {
        setWithdrawAmount(btcValue);
      }
    }
  }, [btcValue, percentage]);
  return (
    <div className="fixed inset-0 flex font-manrope justify-center lgss:justify-center items-center bg-black bg-opacity-80 backdrop-blur-sm">
      <div className="w-11/12 lgss:w-2/5 transition-all p-6 ease-in duration-1000  rounded-xl bg-[#1F1F1F]">
        <div className="flex  px-4 justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Withdraw
          </h4>
          <FaTimesCircle
            onClick={() => setWithdrawModal((prev: any) => !prev)}
            className="text-[#78C2F7] text-[32px] cursor-pointer"
          />
        </div>
        {/* <div className="w-full px-4 flex mt-6 justify-start items-center gap-4">
          <h4 className=" text-[16px] text-[#4E606E] font-bold ">Crypto</h4>
          <h4 className=" text-[14px] text-[#333F48] font-bold ">Giftcard</h4>
        </div> */}
        <div className="w-full grid grid-cols-3 mt-8 justify-center items-center gap-4 px-4 ">
          <button
            onClick={() => setRatePage(1)}
            className={
              ratePage === 1
                ? "flex justify-center flex-col gap-3 items-center border-2 border-[#F9AA4B] rounded-xl h-[120px]"
                : "flex justify-center flex-col gap-3 items-center  rounded-xl h-[120px]"
            }
          >
            <img src={btc} alt="" />
            <h4 className="font-sora text-[14px] text-white ">BITCOIN</h4>
          </button>
          <button
            onClick={() => setRatePage(2)}
            className={
              ratePage === 2
                ? "flex justify-center flex-col gap-3 items-center border-2 border-[#F9AA4B] rounded-xl h-[120px]"
                : "flex justify-center flex-col gap-3 items-center  rounded-xl h-[120px]"
            }
          >
            <img src={tether} alt="" />
            <h4 className="font-sora text-[14px] text-white ">USDT</h4>
          </button>
          <button
            // onClick={() => setRatePage(3)}
            className={
              ratePage === 3
                ? "flex justify-center flex-col gap-3 items-center border-2 border-[#F9AA4B] rounded-xl h-[120px]"
                : "flex justify-center flex-col gap-3 items-center  rounded-xl h-[120px]"
            }
          >
            <img src={eth} alt="" />
            <h4 className="font-sora text-[14px] text-white ">ETHERIUM</h4>
          </button>
        </div>
        {ratePage === 1 ? (
          <>
            <div className="mt-4 w-full flex justify-between items-center px-4">
              <h4 className="text-[15px] font-bold text-[#E59C27]">
                Bal: {typeof btcValue !== "undefined" ? btcValue : 0} BTC
              </h4>
              <div className="flex justify-end gap-4 items-center">
                <button
                  className={
                    percentage === 1
                      ? "text-16px text-[#279DF3]"
                      : "text-16px text-[#095B97]"
                  }
                  onClick={() => setPercentage(1)}
                >
                  25%
                </button>
                <button
                  className={
                    percentage === 2
                      ? "text-16px text-[#279DF3]"
                      : "text-16px text-[#095B97]"
                  }
                  onClick={() => setPercentage(2)}
                >
                  50%
                </button>
                <button
                  className={
                    percentage === 3
                      ? "text-16px text-[#279DF3]"
                      : "text-16px text-[#095B97]"
                  }
                  onClick={() => setPercentage(3)}
                >
                  Max
                </button>
              </div>
            </div>
            <form action="" className="p-4 w-full " onSubmit={handleSubmit}>
              <div className="mt-4">
                <label className="text-white text-[14px] font-bold">
                  How much:
                </label>
                <input
                  type="text"
                  value={withdrawAmount}
                  onChange={handleChange}
                  placeholder="Enter your desired amount"
                  className="w-full spin-button-none mt-2  mb-6 placeholder:text-[13px] font-medium text-[#A0A9AD] placeholder:text-[#A0A9AD] outline-none bg-[#1C2227] border rounded-xl px-4  border-[#141414] border-opacity-20 h-[50px]"
                />
              </div>
              <div className="mt-4">
                <label className="text-white text-[14px] font-bold">
                  Wallet address:
                </label>
                <input
                  type="text"
                  value={walletAddress}
                  onChange={(e) => setWalletAddress(e.target.value)}
                  placeholder="Enter your wallet address "
                  className="w-full spin-button-none mt-2 placeholder:text-[13px] font-medium text-[#A0A9AD] text-[14px] outline-none bg-[#1C2227] border rounded-xl px-4  border-[#141414] border-opacity-20 h-[50px]"
                />
              </div>
              {error && (
                <div className="bg-red-600 text-white text-[12px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-bold mt-2">
                  <BiErrorCircle className="text-xl" />
                  {error}
                </div>
              )}
              <button
                type="submit"
                className="w-full flex justify-center items-center mt-8 h-[52px] rounded-xl font-medium text-white text-[16px] bg-[#0B84CB] "
              >
                Confirm
              </button>
            </form>
          </>
        ) : ratePage === 2 ? (
          <UsdtWithdraw
            usdtValue={usdtValue}
            withdrawAmountUSDT={withdrawAmountUSDT}
            setWithdrawAmountUSDT={setWithdrawAmountUSDT}
            selectedValueUSDT={selectedValueUSDT}
            setSelectedValueUSDT={setSelectedValueUSDT}
            walletAddressUSDT={walletAddressUSDT}
            setWalletAddressUSDT={setWalletAddressUSDT}
            setWithdrawModal={setWithdrawModal}
            setConfirmModal={setConfirmModal}
          />
        ) : null}
        {confirmModal && (
          <ConfirmWithdraw
            setConfirmModal={setConfirmModal}
            withdrawAmountUSDT={withdrawAmountUSDT}
            selectedValueUSDT={selectedValueUSDT}
            walletAddressUSDT={walletAddressUSDT}
            setSuccessModal={setSuccessModall}
            ratePage={ratePage}
            walletAddress={walletAddress}
            withdrawAmount={withdrawAmount}
          />
        )}
        {successModall && (
          <SuccessModalWallet
            setSuccessModal={setSuccessModall}
            setWithdrawModal={setWithdrawModal}
          />
        )}
      </div>
    </div>
  );
};

export default Withdraw;
