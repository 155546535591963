import axios from "axios";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { API_URL } from "../../constant";
import { useAuth } from "../../context/auth-context";
interface Bank {
  accountNumber: string;
  bankCode: string;
  accountName: string;
  isDefault: boolean;
  _id: string;
}

const Kyc = () => {
  const { customerId, token } = useAuth();

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailConfirm, setEmailConfirm] = useState<boolean>();
  const [copyMail, setCopyMail] = useState<boolean>(false);
  const [copyMailKin, setCopyMailKin] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");

  const [copyAccount, setCopyAccount] = useState<boolean>(false);
  const [copyId, setCopyId] = useState<boolean>(false);
  const [copyNumber, setCopyNumber] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [device, setDevice] = useState<string>("");
  const [banks, setBanks] = useState<Bank | null>(null);
  const [allBanks, setAllBanks] = useState<any[]>([]);

  useEffect(() => {
    axios
      .get(`${API_URL}api/users/${customerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        const userData = r.data.data.user;

        setEmail(userData.email?.address || "");
        setEmailConfirm(userData.email?.isVerified || false);

        // Set profile details if available
        if (userData.profile) {
          setFirstName(userData.profile.firstName || "");
          setLastName(userData.profile.lastName || "");
          setUserName(userData.profile.username || "");
        }
        // Set device if available
        setDevice(userData.account?.devices[0]?.os || "");

        // Set bank details if available
        const bankList: Bank[] = r.data.data.user?.banks || [];

        // Find the bank with isDefault set to true
        const defaultBank =
          bankList.find((bank: Bank) => bank.isDefault) || null;
        setBanks(defaultBank);

        // Set phone number if available
        setPhoneNumber(userData.phone?.number || "");
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, [token, customerId]);

  useEffect(() => {
    axios
      .get(`${API_URL}api/banks`)
      .then((r: any) => {
        setAllBanks(r.data.data);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, []);

  return (
    <div className="w-full p-10 bg-[#192025] font-manrope ">
      <div className="w-full  bg-[#0D0D0D] p-8">
        <h4 className="text-[#4E606E] text-[16px] ">Profile Completion</h4>
        <div className="w-full flex-col mt-8 justify-center   items-center ">
          <div className="text-[36px]  mb-4 w-[95px] uppercase h-[95px] bg-[#032843] rounded-full text-[#0C84DA] flex justify-center items-center font-bold font-sora ">
            {firstName.charAt(0)}
            {lastName.charAt(0)}
          </div>
          <div className="w-full flex justify-center gap-20 items-start mt-10">
            <div className="w-1/2">
              <h4 className="text-[#869AA9] text-[8px] font-semibold">
                PERSONAL
              </h4>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-semibold">
                  Email{" "}
                </h4>
                <CopyToClipboard
                  text={email}
                  onCopy={() => {
                    setCopyMail(true);
                    setTimeout(() => {
                      setCopyMail(false);
                    }, 3000);
                  }}
                >
                  {copyMail ? (
                    <span className="text-[12px] xs:text-[14px] text-[#869AA9]">
                      Copied
                    </span>
                  ) : (
                    <span className="text-[#0C84DA] font-bold text-[13px] cursor-pointer">
                      {email}
                    </span>
                  )}
                </CopyToClipboard>
              </div>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-semibold">
                  Email verification{" "}
                </h4>
                {emailConfirm === true ? (
                  <h4 className="text-[#03C668] font-bold text-[13px] cursor-pointer">
                    Confirmed{" "}
                  </h4>
                ) : (
                  <h4 className="text-[#F28A0F] font-bold text-[13px] cursor-pointer">
                    Pending{" "}
                  </h4>
                )}
              </div>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-semibold">
                  Firstname{" "}
                </h4>
                <h4 className="text-[#0C84DA] capitalize font-bold text-[13px] cursor-pointer">
                  {firstName}
                </h4>
              </div>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-semibold">
                  Lastname{" "}
                </h4>
                <h4 className="text-[#0C84DA] capitalize font-bold text-[13px] cursor-pointer">
                  {lastName}
                </h4>
              </div>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-semibold">
                  DeexTag{" "}
                </h4>
                <h4 className="text-[#0C84DA] font-bold text-[13px] cursor-pointer">
                  {userName ? `@${userName}` : "Not added"}
                </h4>
              </div>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-semibold">
                  Birthday{" "}
                </h4>
                <h4 className="text-[#0C84DA] font-bold text-[13px] cursor-pointer">
                  ---{" "}
                </h4>
              </div>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-bold">
                  Phone Number
                </h4>
                {phoneNumber ? (
                  <CopyToClipboard
                    text={phoneNumber}
                    onCopy={() => {
                      setCopyNumber(true);
                      setTimeout(() => {
                        setCopyNumber(false);
                      }, 3000);
                    }}
                  >
                    {copyNumber ? (
                      <span className="text-[12px] xs:text-[13px] font-bold text-[#869AA9]">
                        Copied
                      </span>
                    ) : (
                      <span className="text-[#869AA9] text-[13px] font-bold cursor-pointer">
                        {phoneNumber}
                      </span>
                    )}
                  </CopyToClipboard>
                ) : (
                  <h4 className="text-[#0C84DA] text-[13px] font-bold">
                    Not added
                  </h4>
                )}
              </div>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-bold">
                  Bank Name{" "}
                </h4>

                <div className="text-[#0C84DA] cursor-pointer flex gap-2 justify-center items-center font-bold text-[13px] ">
                  {allBanks
                    .filter((allBank) => allBank.code === banks?.bankCode)
                    .map((bank, index) => (
                      <h5 key={index} className="text-[#869AA9]">
                        {bank.name ? bank.name : "------"}
                      </h5>
                    ))}
                </div>
              </div>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-bold">
                  Account Name{" "}
                </h4>
                <h4 className="text-[#0C84DA] font-bold text-[13px] cursor-pointer">
                  {banks ? banks.accountName : "----"}
                </h4>
              </div>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-bold">
                  Account Number
                </h4>

                <CopyToClipboard
                  text={banks ? banks?.accountNumber : ""}
                  onCopy={() => {
                    setCopyAccount(true);
                    setTimeout(() => {
                      setCopyAccount(false);
                    }, 3000);
                  }}
                >
                  {copyAccount ? (
                    <span className="text-[12px] xs:text-[13px] text-[#869AA9]">
                      Copied
                    </span>
                  ) : (
                    <span className="text-[#0C84DA] cursor-pointer flex gap-2 justify-center items-center font-bold text-[13px] ">
                      {banks ? banks.accountNumber : "-----"}
                    </span>
                  )}
                </CopyToClipboard>
              </div>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-semibold">
                  Customer ID{" "}
                </h4>
                <h4 className="text-[#0C84DA] font-bold text-[13px] cursor-pointer">
                  <CopyToClipboard
                    text={customerId}
                    onCopy={() => {
                      setCopyId(true);
                      setTimeout(() => {
                        setCopyId(false);
                      }, 3000);
                    }}
                  >
                    {copyId ? (
                      <span className="text-[12px] xs:text-[14px] text-[#869AA9]">
                        Copied
                      </span>
                    ) : (
                      <span className="text-[#0C84DA] font-bold text-[13px] cursor-pointer">
                        {customerId}
                      </span>
                    )}
                  </CopyToClipboard>
                </h4>
              </div>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-bold">
                  Last Login{" "}
                </h4>
                <h4 className="text-[#0C84DA] font-bold text-[13px] cursor-pointer">
                  {"-----"}
                </h4>
              </div>
            </div>
            <div className="w-1/2">
              <h4 className="text-[#869AA9] text-[8px] font-semibold">
                Next of KIN
              </h4>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-semibold">
                  Email
                </h4>
                <CopyToClipboard
                  text={"---"}
                  onCopy={() => {
                    setCopyMailKin(true);
                    setTimeout(() => {
                      setCopyMail(false);
                    }, 3000);
                  }}
                >
                  {copyMailKin ? (
                    <span className="text-[12px] xs:text-[13px] text-[#869AA9]">
                      Copied
                    </span>
                  ) : (
                    <span className="text-[#0C84DA] text-[13px] cursor-pointer">
                      ---
                    </span>
                  )}
                </CopyToClipboard>
              </div>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-bold">
                  Full Name{" "}
                </h4>
                <h4 className="text-[#0C84DA] font-bold text-[13px] cursor-pointer">
                  ---
                </h4>
              </div>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-bold">
                  Relationship{" "}
                </h4>
                <h4 className="text-[#0C84DA] font-bold text-[13px] cursor-pointer">
                  ---
                </h4>
              </div>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-bold">
                  Phone Number{" "}
                </h4>
                <h4 className="text-[#0C84DA] font-bold text-[13px] cursor-pointer">
                  ----
                </h4>
              </div>
              <div className="w-full mt-4 flex  gap-4 justify-between items-start">
                <h4 className="text-[#AEBCC6] whitespace-nowrap text-[13px] font-bold">
                  Residential Address{" "}
                </h4>
                <h4 className="text-[#869AA9] font-bold text-right text-[13px] cursor-pointer">
                  ---
                </h4>
              </div>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-bold">
                  Refer Id
                </h4>
                <h4 className="text-[#0C84DA] font-bold text-[13px] cursor-pointer">
                  ---{" "}
                </h4>
              </div>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-bold">---</h4>
                <h4 className="text-[#0C84DA] font-bold text-[13px] cursor-pointer">
                  ---
                </h4>
              </div>
              <h4 className="text-[#869AA9] mt-6 text-[13px] font-semibold">
                IP and DEVICE
              </h4>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-bold">Device</h4>
                <h4 className="text-[#0C84DA] font-bold text-[13px] cursor-pointer">
                  {device ? device : "----"}
                </h4>
              </div>
              <div className="w-full mt-4 flex justify-between items-center">
                <h4 className="text-[#AEBCC6] text-[13px] font-bold">IP</h4>
                <h4 className="text-[#0C84DA] font-bold text-[13px] cursor-pointer">
                  ----
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kyc;
