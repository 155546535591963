import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logo } from "../assets/images";
import { FaRegCalendarAlt, FaTasks, FaUsers } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { useAuth } from "../context/auth-context";
import { BiBarChartSquare, BiLogOut } from "react-icons/bi";
import { IoWalletOutline } from "react-icons/io5";
import { BsBarChartLine } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";

const Sidebar = () => {
  const { logout, activeStatus, isOpened, setIsOpened } = useAuth();

  return (
    <div className="lgss:w-1/6 lgss:h-screen  bg-[#0D0D0D]">
      <div className="hidden lgss:flex lgss:flex-col h-full  w-full font-manrope bg-[#0D0D0D] pt-[35px]  text-white">
        <div className="px-8">
          <img src={logo} className="w-[90px] h-[25px]" alt="logo" />
        </div>
        <div className="mt-12 w-full">
          <Link
            to="/dashboard"
            className={
              activeStatus === 1
                ? "flex cursor-pointer justify-between transform transition-transform duration-300  h-[60px] px-4 bg-[#1E1E1E]  items-center"
                : "flex cursor-pointer justify-between  h-[60px] px-4  items-center"
            }
          >
            <div className="flex gap-2 justify-center items-center pl-4">
              <BiBarChartSquare
                className={
                  activeStatus === 1
                    ? "text-white font-bold transform transition-transform duration-300"
                    : "text-text_feint font-normal"
                }
              />
              <h4
                className={
                  activeStatus === 1
                    ? "font-semibold text-white transform transition-transform duration-300"
                    : "font-normal text-text_feint"
                }
              >
                Dashboard
              </h4>
            </div>
            {activeStatus === 1 && (
              <div className="bg-white h-6 w-[3px] transform transition-transform duration-300" />
            )}
          </Link>
          <Link
            to="/wallet"
            className={
              activeStatus === 2
                ? "flex cursor-pointer justify-between transform transition-transform duration-300  h-[60px] px-4 bg-[#1E1E1E]  items-center"
                : "flex cursor-pointer justify-between  h-[60px] px-4  items-center"
            }
          >
            <div className="flex gap-2 justify-center items-center pl-4">
              <IoWalletOutline
                className={
                  activeStatus === 2
                    ? "text-white font-bold transform transition-transform duration-300"
                    : "text-text_feint font-normal"
                }
              />
              <h4
                className={
                  activeStatus === 2
                    ? "font-semibold text-white transform transition-transform duration-300"
                    : "font-normal text-text_feint"
                }
              >
                Wallets
              </h4>
            </div>
            {activeStatus === 2 && (
              <div className="bg-white h-6 w-[3px] transform transition-transform duration-300" />
            )}
          </Link>
          <Link
            to="/transactions"
            className={
              activeStatus === 8
                ? "flex cursor-pointer justify-between transform transition-transform duration-300  h-[60px] px-4 bg-[#1E1E1E]  items-center"
                : "flex cursor-pointer justify-between  h-[60px] px-4  items-center"
            }
          >
            <div className="flex gap-2 justify-center items-center pl-4">
              <FaRegCalendarAlt
                className={
                  activeStatus === 8
                    ? "text-white font-bold transform transition-transform duration-300"
                    : "text-text_feint font-normal"
                }
              />
              <h4
                className={
                  activeStatus === 8
                    ? "font-semibold text-white transform transition-transform duration-300"
                    : "font-normal text-text_feint"
                }
              >
                Orders
              </h4>
            </div>
            {activeStatus === 8 && (
              <div className="bg-white h-6 w-[3px] transform transition-transform duration-300" />
            )}
          </Link>
          <Link
            to="/users"
            className={
              activeStatus === 5
                ? "flex cursor-pointer justify-between transform transition-transform duration-300  h-[60px] px-4 bg-[#1E1E1E]  items-center"
                : "flex cursor-pointer justify-between  h-[60px] px-4  items-center"
            }
          >
            <div className="flex gap-2 justify-center items-center pl-4">
              <FaUsers
                className={
                  activeStatus === 5
                    ? "text-white font-bold transform transition-transform duration-300"
                    : "text-text_feint font-normal"
                }
              />
              <h4
                className={
                  activeStatus === 5
                    ? "font-semibold text-white transform transition-transform duration-300"
                    : "font-normal text-text_feint"
                }
              >
                Users
              </h4>
            </div>
            {activeStatus === 5 && (
              <div className="bg-white h-6 w-[3px] transform transition-transform duration-300" />
            )}
          </Link>
          <Link
            to="/tasks"
            className={
              activeStatus === 11
                ? "flex cursor-pointer justify-between transform transition-transform duration-300 h-[60px] px-4 bg-[#1E1E1E]  items-center"
                : "flex cursor-pointer justify-between  h-[60px] px-4  items-center"
            }
          >
            <div className="flex gap-2 justify-center items-center pl-4">
              <FaTasks
                className={
                  activeStatus === 11
                    ? "text-white font-bold transform transition-transform duration-300"
                    : "text-text_feint font-normal"
                }
              />
              <h4
                className={
                  activeStatus === 11
                    ? "font-semibold text-white transform transition-transform duration-300"
                    : "font-normal text-text_feint"
                }
              >
                Tasks
              </h4>
            </div>
            {activeStatus === 11 && (
              <div className="bg-white h-6 w-[3px] transform transition-transform duration-300" />
            )}
          </Link>
          <Link
            to="/reports"
            className={
              activeStatus === 10
                ? "flex cursor-pointer justify-between transform transition-transform duration-300 h-[60px] px-4 bg-[#1E1E1E]  items-center"
                : "flex cursor-pointer justify-between  h-[60px] px-4  items-center"
            }
          >
            <div className="flex gap-2 justify-center items-center pl-4">
              <BsBarChartLine
                className={
                  activeStatus === 10
                    ? "text-white font-bold transform transition-transform duration-300"
                    : "text-text_feint font-normal"
                }
              />
              <h4
                className={
                  activeStatus === 10
                    ? "font-semibold text-white transform transition-transform duration-300"
                    : "font-normal text-text_feint"
                }
              >
                Reports
              </h4>
            </div>
            {activeStatus === 10 && (
              <div className="bg-white h-6 w-[3px] transform transition-transform duration-300" />
            )}
          </Link>
          <Link
            to="/settings"
            className={
              activeStatus === 7
                ? "flex cursor-pointer justify-between transform transition-transform duration-300 h-[60px] px-4 bg-[#1E1E1E]  items-center"
                : "flex cursor-pointer justify-between  h-[60px] px-4  items-center"
            }
          >
            <div className="flex gap-2 justify-center items-center pl-4">
              <FiSettings
                className={
                  activeStatus === 7
                    ? "text-white font-bold transform transition-transform duration-300"
                    : "text-text_feint font-normal"
                }
              />
              <h4
                className={
                  activeStatus === 7
                    ? "font-semibold text-white transform transition-transform duration-300"
                    : "font-normal text-text_feint"
                }
              >
                Settings
              </h4>
            </div>
            {activeStatus === 7 && (
              <div className="bg-white h-6 w-[3px] transform transition-transform duration-300" />
            )}
          </Link>
        </div>
        <div className="w-10/12 h-[0.5px] mt-28 lg:mt-16 mx-auto bg-text_feint" />
        <div
          onClick={logout}
          className="flex justify-between cursor-pointer mt-4 h-[60px] px-4   items-center"
        >
          <div className="flex gap-2 justify-center items-center pl-4">
            <BiLogOut className="text-text_feint font-bold" />
            <h4 className="font-semibold text-text_feint">Logout</h4>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-0 pt-0  px-4 lgss:hidden ">
        <aside
          className={`absolute z-50 top-0 left-0 h-screen   bg-side_bar_black dark:bg-[#0D0D0D] w-3/4 py-4 px-0 pt-12   transform transition-transform duration-300 ${
            isOpened ? "translate-x-0 " : "-translate-x-full"
          }`}
        >
          <div className="">
            <div className="flex justify-between px-8 items-center">
              <div className="">
                <img src={logo} className="w-[90px] h-[25px]" alt="logo" />
              </div>
              <AiOutlineCloseCircle
                className="text-text_feint text-3xl"
                onClick={() => setIsOpened((prev: any) => !prev)}
              />
            </div>
            <div className="mt-16 bg-side_bar_black dark:bg-[#0D0D0D]">
              <Link
                to="/dashboard"
                onClick={() => setIsOpened((prev: any) => !prev)}
                className={
                  activeStatus === 1
                    ? "flex cursor-pointer justify-between transform transition-transform duration-300  h-[60px] px-4 bg-[#1E1E1E]  items-center"
                    : "flex cursor-pointer justify-between  h-[60px] px-4  items-center"
                }
              >
                <div className="flex gap-2 justify-center items-center pl-4">
                  <BiBarChartSquare
                    className={
                      activeStatus === 1
                        ? "text-white font-bold transform transition-transform duration-300"
                        : "text-text_feint font-normal"
                    }
                  />
                  <h4
                    className={
                      activeStatus === 1
                        ? "font-semibold text-white transform transition-transform duration-300"
                        : "font-normal text-text_feint"
                    }
                  >
                    Dashboard
                  </h4>
                </div>
                {activeStatus === 1 && (
                  <div className="bg-white h-6 w-[3px] transform transition-transform duration-300" />
                )}
              </Link>
              <Link
                to="/wallet"
                onClick={() => setIsOpened((prev: any) => !prev)}
                className={
                  activeStatus === 2
                    ? "flex cursor-pointer justify-between transform transition-transform duration-300  h-[60px] px-4 bg-[#1E1E1E]  items-center"
                    : "flex cursor-pointer justify-between  h-[60px] px-4  items-center"
                }
              >
                <div className="flex gap-2 justify-center items-center pl-4">
                  <IoWalletOutline
                    className={
                      activeStatus === 2
                        ? "text-white font-bold transform transition-transform duration-300"
                        : "text-text_feint font-normal"
                    }
                  />
                  <h4
                    className={
                      activeStatus === 2
                        ? "font-semibold text-white transform transition-transform duration-300"
                        : "font-normal text-text_feint"
                    }
                  >
                    Wallets
                  </h4>
                </div>
                {activeStatus === 2 && (
                  <div className="bg-white h-6 w-[3px] transform transition-transform duration-300" />
                )}
              </Link>
              <Link
                to="/transactions"
                onClick={() => setIsOpened((prev: any) => !prev)}
                className={
                  activeStatus === 8
                    ? "flex cursor-pointer justify-between transform transition-transform duration-300  h-[60px] px-4 bg-[#1E1E1E]  items-center"
                    : "flex cursor-pointer justify-between  h-[60px] px-4  items-center"
                }
              >
                <div className="flex gap-2 justify-center items-center pl-4">
                  <FaRegCalendarAlt
                    className={
                      activeStatus === 8
                        ? "text-white font-bold transform transition-transform duration-300"
                        : "text-text_feint font-normal"
                    }
                  />
                  <h4
                    className={
                      activeStatus === 8
                        ? "font-semibold text-white transform transition-transform duration-300"
                        : "font-normal text-text_feint"
                    }
                  >
                    Orders
                  </h4>
                </div>
                {activeStatus === 8 && (
                  <div className="bg-white h-6 w-[3px] transform transition-transform duration-300" />
                )}
              </Link>
              <Link
                to="/users"
                onClick={() => setIsOpened((prev: any) => !prev)}
                className={
                  activeStatus === 5
                    ? "flex cursor-pointer justify-between transform transition-transform duration-300  h-[60px] px-4 bg-[#1E1E1E]  items-center"
                    : "flex cursor-pointer justify-between  h-[60px] px-4  items-center"
                }
              >
                <div className="flex gap-2 justify-center items-center pl-4">
                  <FaUsers
                    className={
                      activeStatus === 5
                        ? "text-white font-bold transform transition-transform duration-300"
                        : "text-text_feint font-normal"
                    }
                  />
                  <h4
                    className={
                      activeStatus === 5
                        ? "font-semibold text-white transform transition-transform duration-300"
                        : "font-normal text-text_feint"
                    }
                  >
                    Users
                  </h4>
                </div>
                {activeStatus === 5 && (
                  <div className="bg-white h-6 w-[3px] transform transition-transform duration-300" />
                )}
              </Link>

              <Link
                to="/reports"
                onClick={() => setIsOpened((prev: any) => !prev)}
                className={
                  activeStatus === 10
                    ? "flex cursor-pointer justify-between transform transition-transform duration-300 h-[60px] px-4 bg-[#1E1E1E]  items-center"
                    : "flex cursor-pointer justify-between  h-[60px] px-4  items-center"
                }
              >
                <div className="flex gap-2 justify-center items-center pl-4">
                  <BsBarChartLine
                    className={
                      activeStatus === 10
                        ? "text-white font-bold transform transition-transform duration-300"
                        : "text-text_feint font-normal"
                    }
                  />
                  <h4
                    className={
                      activeStatus === 10
                        ? "font-semibold text-white transform transition-transform duration-300"
                        : "font-normal text-text_feint"
                    }
                  >
                    Reports
                  </h4>
                </div>
                {activeStatus === 10 && (
                  <div className="bg-white h-6 w-[3px] transform transition-transform duration-300" />
                )}
              </Link>
              <Link
                to="/settings"
                onClick={() => setIsOpened((prev: any) => !prev)}
                className={
                  activeStatus === 7
                    ? "flex cursor-pointer justify-between transform transition-transform duration-300 h-[60px] px-4 bg-[#1E1E1E]  items-center"
                    : "flex cursor-pointer justify-between  h-[60px] px-4  items-center"
                }
              >
                <div className="flex gap-2 justify-center items-center pl-4">
                  <FiSettings
                    className={
                      activeStatus === 7
                        ? "text-white font-bold transform transition-transform duration-300"
                        : "text-text_feint font-normal"
                    }
                  />
                  <h4
                    className={
                      activeStatus === 7
                        ? "font-semibold text-white transform transition-transform duration-300"
                        : "font-normal text-text_feint"
                    }
                  >
                    Settings
                  </h4>
                </div>
                {activeStatus === 7 && (
                  <div className="bg-white h-6 w-[3px] transform transition-transform duration-300" />
                )}
              </Link>
              <div className="w-10/12 h-[0.5px] mt-28 lg:mt-16 mx-auto bg-text_feint" />
              <div
                onClick={logout}
                className="flex justify-between cursor-pointer mt-4 h-[60px] px-4   items-center"
              >
                <div className="flex gap-2 justify-center items-center pl-4">
                  <BiLogOut className="text-text_feint font-bold" />
                  <h4 className="font-semibold text-text_feint">Logout</h4>
                </div>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default Sidebar;
