import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import {
  depositIcon,
  noTransaction,
  rewardIcon,
  swapIcon,
} from "../../assets/images";
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";
import axios from "axios";
import { API_URL } from "../../constant";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { IoArrowDownCircle, IoArrowUpCircle } from "react-icons/io5";

const WalletActivities = ({ setNewActivities }: any) => {
  const { token } = useAuth();
  const [payouts, setPayouts] = useState<any[]>([]);
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [payoutLoader, setPayoutLoader] = useState<boolean>(false);
  const [sortOrderDate, setSortOrderDate] = useState<"asc" | "desc" | "">("");
  const [page, setPage] = useState<number>(1);

  const [currentpage, setCurrentPage] = useState<number>();

  const [totalPages, setTotalPages] = useState<number>(1);
  const [transactionCount, setTransactionCount] = useState<number>(1);
  const location = useLocation();

  useEffect(() => {
    setPayoutLoader(true);
    setTimeout(() => {
      axios
        .get(
          `${API_URL}api/transactions?page=${page}&excludeTypes=gift-card-transactions&limit=50`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          //   console.log(r.data.data.transactions);
          setPayouts(r.data.data.transactions);
          setTotalPages(r.data.data.meta.totalPages);
          setCurrentPage(r.data.data.meta.page);
          setTransactionCount(r.data.data.meta.transactionsCount);
          setPayoutLoader(false);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
        });
    }, 1500);
  }, [token, page]);
  useEffect(() => {
    setTimeout(() => {
      axios
        .get(
          `${API_URL}api/transactions?excludeTypes=gift-card-transactions&limit=${transactionCount}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          setNewActivities(r.data.data.transactions);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
        });
    }, 1000);
  }, [token, page, transactionCount]);

  useEffect(() => {
    if (sortOrderDate === "asc") {
      setSortedData(
        [...payouts].sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        )
      );
    } else if (sortOrderDate === "desc") {
      setSortedData(
        [...payouts].sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      );
    } else {
      setSortedData(payouts);
    }
  }, [sortOrderDate, payouts]);

  //

  return (
    <div className="w-full  h-full">
      {payoutLoader ? (
        <div className="lgss:flex gap-5 w-full hidden  h-[520px]">
          <div className="relative w-full space-y-3 overflow-hidden  bg-[#5c6b73] bg-opacity-30 p-8 shadow before:absolute before:inset-0 before:-translate-x-full before:bg-gradient-to-r before:from-transparent before:via-white/40 hover:shadow-lg before:animate-[shimmer_1.3s_infinite]">
            <div className="h-24 w-full  bg-[#E7EBEE]"></div>

            <div className="grid grid-cols-5 gap-2 mt-4">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-5 gap-2 mt-4">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-5 gap-2 mt-4">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-5 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-5 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-5 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-5 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-5 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
          </div>
        </div>
      ) : payouts.length >= 1 ? (
        <div className="bg-[#0D0D0D]  overflow-auto scrollbar-thumb-[#60B7F6] scrollbar-track-white  scrollbar-thin  flex lgss:flex-col w-full h-[712px] mb-8 ">
          <table className="w-full">
            <thead>
              <tr className="h-[56px] bg-[#1C2227] flex justify-start px-8 items-center ">
                <th className="text-[#617889] gap-12 cursor-pointer flex justify-start items-center text-[15px] w-2/12  whitespace-nowrap ">
                  Type
                  {/* <div className="flex flex-col justify-center items-center">
                    <TiArrowSortedUp
                      className={
                        sortOrderType === "asc"
                          ? "text-[#BDBDBE] text-[14px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                    <TiArrowSortedDown
                      className={
                        sortOrderType === "desc"
                          ? "text-[#BDBDBE] text-[14px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                  </div> */}
                </th>

                <th className="text-[#617889] gap-8 cursor-pointer text-left text-[15px] w-2/12  whitespace-nowrap ">
                  Amount
                </th>
                <th className="text-[#617889] gap-8 cursor-pointer text-left text-[15px] w-2/12  whitespace-nowrap ">
                  Amount(NGN)
                </th>
                <th className="text-[#617889] text-[15px]  whitespace-nowrap w-2/12 text-left">
                  Trans ID
                </th>
                <th className="text-[#617889] text-[15px] whitespace-nowrap ml-4 w-2/12 text-left">
                  Status
                </th>
                <th
                  onClick={() => {
                    if (sortOrderDate === "desc") {
                      setSortOrderDate("asc");
                    } else {
                      setSortOrderDate("desc");
                    }
                  }}
                  className="text-[#617889] gap-12 cursor-pointer flex justify-start items-center text-[15px] w-2/12  whitespace-nowrap "
                >
                  Date
                  <div className="flex flex-col justify-center items-center">
                    <TiArrowSortedUp
                      className={
                        sortOrderDate === "desc"
                          ? "text-[#BDBDBE] text-[12px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                    <TiArrowSortedDown
                      className={
                        sortOrderDate === "asc"
                          ? "text-[#BDBDBE] text-[12px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                  </div>
                </th>
              </tr>
            </thead>
            {sortedData.map((payout, index) => {
              return (
                <tbody key={index} className="w-full cursor-pointer">
                  <tr className="h-[56px] w-full flex justify-start px-8   items-center">
                    <td className="text-white text-[14px] w-2/12 capitalize whitespace-nowrap mr-4 text-left overflow-hidden">
                      <span className="flex justify-start items-center text-white text-[15px] gap-2">
                        {payout.transactionType === "payouts" ||
                        payout.transactionType === "deex-crypto-withdrawals" ? (
                          <IoArrowUpCircle className="text-20px" />
                        ) : (
                          <IoArrowDownCircle className="text-20px" />
                        )}
                        {payout.transactionType === "payouts" ||
                        payout.transactionType === "deex-crypto-withdrawals"
                          ? "Debit"
                          : "Credit"}
                      </span>
                      <span className="text-[12px] text-[#869AA9] capitalize ml-[60px]">
                        {payout.transactionType === "payouts" ||
                        payout.transactionType === "deex-fiat-deposits"
                          ? "Hizo"
                          : payout.transactionType === "deex-deposits"
                          ? payout.provider.name
                          : "Quidax"}
                      </span>
                    </td>
                    <td className="text-white text-[13px] w-2/12 capitalize whitespace-nowrap text-left overflow-hidden">
                      {payout.transactionType === "payouts" ||
                      payout.transactionType === "deex-crypto-withdrawals"
                        ? "-"
                        : "+"}{" "}
                      {payout.transactionType === "deex-asset-swaps"
                        ? payout.to.amount.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : payout.transactionType === "deex-deposits"
                        ? payout.amount
                        : payout.transactionType === "payouts"
                        ? payout.asset.amountInUSD.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : payout.transactionType === "deex-fiat-deposits"
                        ? "----"
                        : payout.usdtAmount}{" "}
                      {payout.transactionType === "deex-deposits" &&
                      payout.currency === "btc"
                        ? "BTC"
                        : "USD"}
                      <span className="flex justify-start mt-1 text-[12px] text-[#869AA9] items-center gap-2">
                        <span className="w-[14px] h-[14px]">
                          <img
                            src={
                              payout.transactionType === "deex-asset-swaps"
                                ? swapIcon
                                : payout.transactionType === "payouts" &&
                                  payout.transaction.type === "DEEX-POINT"
                                ? rewardIcon
                                : depositIcon
                            }
                            className="w-full h-full bg-cover"
                            alt=""
                          />
                        </span>
                        {payout.transactionType === "deex-asset-swaps"
                          ? "Autoswap"
                          : payout.transactionType === "payouts" &&
                            payout.transaction.type === "DEEX-POINT"
                          ? "Reward"
                          : payout.transactionType === "payouts"
                          ? "Payout"
                          : "Deposit"}
                      </span>
                    </td>

                    <td className="text-white text-[14px] w-2/12 whitespace-nowrap text-left overflow-hidden">
                      {payout.transactionType === "payouts"
                        ? payout.payoutAmount.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : payout.transactionType === "deex-assets-swaps"
                        ? "----"
                        : "----"}
                    </td>
                    <td className="text-white text-[13px]  w-2/12 whitespace-nowrap text-left overflow-hidden">
                      {payout._id}
                    </td>
                    {payout.transactionType === "payouts" ? (
                      <td className="text-white text-[13px] w-2/12 whitespace-nowrap flex justify-start ml-4  overflow-hidden">
                        <h4
                          className={
                            payout.status === "PENDING"
                              ? "text-[#CB9200] flex justify-center items-center w-4/5 bg-[#181818] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                              : payout.status === "FAILED"
                              ? "text-[#b14444] flex justify-center items-center w-4/5 bg-[#181818] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                              : payout.status === "COMPLETED"
                              ? "text-[#03C668] flex justify-center items-center w-4/5 bg-[#0E2B1F] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                              : ""
                          }
                        >
                          {payout.status}
                        </h4>
                      </td>
                    ) : (
                      <td className="text-white text-[15px] w-2/12 whitespace-nowrap flex justify-start ml-4  overflow-hidden">
                        <h4 className="text-[#03C668] flex justify-center items-center w-4/5 bg-[#013019] tracking-wider h-[26px] rounded-full text-[9px] font-bold">
                          COMPLETED
                        </h4>
                      </td>
                    )}
                    <td className="text-white text-[14px] w-2/12 whitespace-nowrap text-left overflow-hidden">
                      {(() => {
                        const dateString = payout.createdAt;
                        const date = new Date(dateString);

                        const day = date.getDate();
                        const ordinalSuffix = (day: number) => {
                          switch (day) {
                            case 1:
                            case 21:
                            case 31:
                              return "st";
                            case 2:
                            case 22:
                              return "nd";
                            case 3:
                            case 23:
                              return "rd";
                            default:
                              return "th";
                          }
                        };

                        const formattedDate = `${date.toLocaleString(
                          "default",
                          {
                            month: "short",
                          }
                        )} ${day}${ordinalSuffix(
                          day
                        )}, ${date.getFullYear()}, ${date.toLocaleString(
                          "default",
                          {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          }
                        )}`;

                        return formattedDate;
                      })()}
                    </td>
                  </tr>
                  <tr className="w-full">
                    <td className="mt-1 w-full h-[1px] bg-[#13181B]" />
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      ) : (
        <div className="flex flex-col justify-center mt-16 mb-60 w-full lgss:mt-24 items-center">
          <img src={noTransaction} alt="" />
          <h4 className="font-bold text-[#869AA9] pt-2 text-[14px]">
            No wallet activity
          </h4>
          <p className=" text-[#869AA9] pt-2 text-[12px]">
            All wallet activities will show up here.
          </p>
        </div>
      )}
      {payouts.length >= 1 && location.pathname !== "/dashboard" ? (
        <div className="flex gap-4 pb-10 justify-end items-center">
          <h4 className="font-bold font-sora text-[14px] text-white">
            Page {currentpage} of {totalPages}
          </h4>
          <button className="flex justify-center items-center">
            <BsArrowLeftSquare
              onClick={() => {
                if (page > 1) {
                  setPage((prev) => prev - 1);
                }
              }}
              className="text-3xl cursor-pointer text-[#C6D0D7] font-bold"
            />
          </button>

          <button>
            <BsArrowRightSquare
              onClick={() => {
                if (page < totalPages) {
                  setPage((prev) => prev + 1);
                }
              }}
              className="text-3xl cursor-pointer text-[#C6D0D7]"
            />
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default WalletActivities;
