import axios from "axios";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { AiOutlineDelete } from "react-icons/ai";
import { BiCopy } from "react-icons/bi";
import { API_URL } from "../../constant";

const Info = ({
  userFn,
  userLn,
  userBankNo,
  userPhoneNo,
  userMail,
  userRefCode,
  userAccName,
  userBankName,
  kycStatus,
  tradeRange,
  username,
  banks,
}: any) => {
  const [isCopy, setIsCopy] = useState<boolean>(false);
  const [allBanks, setAllBanks] = useState<any[]>([]);

  useEffect(() => {
    axios
      .get(`${API_URL}api/banks`)
      .then((r: any) => {
        setAllBanks(r.data.data);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, []);

  return (
    <div className="mt-6 w-full font-manrope pb-10">
      <div className="lgss:flex justify-between  items-center">
        <div className="w-full lgss:w-1/3">
          <h4 className="font-bold text-[15px] text-white">Full Name</h4>
        </div>

        <div className="flex justify-center items-center w-full mds:justify-start mds:items-center lgss:w-2/3 gap-2 mt-6">
          <div className="w-full">
            <h4 className="text-[13px] text-white">First Name</h4>
            <div className="h-[44px] w-full flex justify-start items-center capitalize text-sm mt-1  px-[16px] font-manrope  bg-[#273037] rounded-lg  outline-none  text-[#617889] transition duration-200">
              {userFn}
            </div>
          </div>
          <div className="w-full">
            <h4 className="text-[13px] text-white">Last Name</h4>
            <div className="h-[44px] w-full flex justify-start items-center capitalize  text-sm mt-1  px-[16px] font-manrope  bg-[#273037] rounded-lg  outline-none  text-[#617889] transition duration-200">
              {userLn}
            </div>
          </div>
        </div>
      </div>
      <div className="lgss:flex justify-between mt-4 items-center">
        <div className="w-full lgss:w-1/3">
          <h4 className="font-bold text-[15px] text-white">Email Address</h4>
        </div>

        <div className="flex justify-center items-center w-full mds:justify-start mds:items-center lgss:w-2/3 gap-2 mt-6">
          <div className="w-full">
            <div className="h-[44px] w-full flex justify-start items-center  text-sm mt-1  px-[16px] font-manrope  bg-[#273037] rounded-lg  outline-none  text-[#617889] transition duration-200">
              {userMail}
            </div>
          </div>
        </div>
      </div>
      <div className="lgss:flex justify-between mt-4 items-center">
        <div className="w-full lgss:w-1/3">
          <h4 className="font-bold text-[15px] text-white">Phone Number</h4>
        </div>

        <div className="flex justify-center items-center w-full mds:justify-start mds:items-center lgss:w-2/3 gap-2 mt-6">
          <div className="w-full">
            <div className="h-[44px] w-full flex justify-start items-center  text-sm mt-1  px-[16px] font-manrope  bg-[#273037] rounded-lg  outline-none  text-[#617889] transition duration-200">
              {userPhoneNo ? userPhoneNo : "Not added"}
            </div>
          </div>
        </div>
      </div>
      <div className="lgss:flex justify-between mt-4 items-center">
        <div className="w-full lgss:w-1/3">
          <h4 className="font-bold text-[15px] text-white">Customer Type</h4>
        </div>

        <div className="flex justify-center items-center w-full mds:justify-start mds:items-center lgss:w-2/3 gap-2 mt-6">
          <div className="w-full">
            <div className="h-[44px] w-full flex justify-start items-center  text-sm mt-1  px-[16px] font-manrope  bg-[#273037] rounded-lg  outline-none  text-[#617889] transition duration-200">
              {tradeRange
                ? `Trader(${tradeRange.min}-${tradeRange.max})`
                : "-----"}
            </div>
          </div>
        </div>
      </div>
      <div className="lgss:flex justify-between mt-4 items-center">
        <div className="w-full lgss:w-1/3">
          <h4 className="font-bold text-[15px] text-white">Referral Links</h4>
        </div>

        <div className="flex justify-center items-center w-full mds:justify-start mds:items-center lgss:w-2/3 gap-2 mt-6">
          <div className="w-full">
            <div className="h-[44px] w-full flex justify-between items-center  text-sm mt-1  px-[16px] font-manrope  bg-[#273037] rounded-lg  outline-none  text-[#617889] transition duration-200">
              {`https://www.deexoptions.com?r=${
                username ? username : userRefCode
              }`}
              <CopyToClipboard
                text={`https://www.deexoptions.com?r=${
                  username ? username : userRefCode
                }`}
                onCopy={() => {
                  setIsCopy(true);
                  setTimeout(() => {
                    setIsCopy(false);
                  }, 5000);
                }}
              >
                {isCopy ? (
                  <h4 className="text-[12px] xs:text-[14px] text-[#869AA9] dark:text-[#dddada]">
                    Copied
                  </h4>
                ) : (
                  <BiCopy className="text-[24px] cursor-pointer text-[#869AA9]" />
                )}
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          banks.length >= 1
            ? "lgss:flex justify-between mt-8 items-start"
            : "lgss:flex justify-between mt-4 items-center"
        }
      >
        <div className="w-full lgss:w-1/3">
          <h4 className="font-bold text-[15px] text-white">
            Linked account number
          </h4>
        </div>

        <div className="flex justify-center items-center w-full mds:justify-start mds:items-center lgss:w-2/3 gap-2 ">
          <div className="w-full ">
            {banks.length >= 1 ? (
              <div className="w-full grid grid-cols-1 gap-2 lgss:gap-4 lgss:grid-cols-2 h-[250px] overflow-auto ">
                {banks
                  .sort((a: any, b: any) => b.isDefault - a.isDefault)
                  .map((bank: any, index: React.Key | null | undefined) => (
                    <div
                      key={index}
                      className=" rounded-lg px-4 py-6 bg-[#273037] flex justify-center items-center flex-col"
                    >
                      <h4
                        className={
                          bank.isDefault
                            ? "font-bold text-[14px] text-[#bacad6]"
                            : "font-bold text-[14px] text-[#617889]"
                        }
                      >
                        {bank.accountName}
                      </h4>
                      <h5
                        className={
                          bank.isDefault
                            ? "text-[#bacad6] text-[13px] font-semibold mt-2"
                            : "text-[#617889] text-[13px] mt-2"
                        }
                      >
                        {bank.accountNumber}
                      </h5>
                      {allBanks
                        .filter((allBank) => allBank.code === bank.bankCode)
                        .map((banki, index) => (
                          <h5
                            key={index}
                            className={
                              bank.isDefault
                                ? "text-[#bacad6] text-[13px] font-semibold mt-2"
                                : "text-[#617889] text-[13px] mt-2"
                            }
                          >
                            {banki.name}
                          </h5>
                        ))}
                    </div>
                  ))}
              </div>
            ) : (
              <div className="h-[44px] w-full flex justify-start items-center  text-sm mt-1  px-[16px] font-manrope  bg-[#273037] rounded-lg  outline-none  text-[#617889] transition duration-200">
                Not added
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="lgss:flex justify-between mt-4 items-center">
        <div className="w-full lgss:w-1/3">
          <h4 className="font-bold text-[15px] text-white">KYC Status</h4>
        </div>

        <div className="flex justify-center items-center w-full mds:justify-start mds:items-center lgss:w-2/3 gap-2 mt-6">
          <div className="w-full">
            <h4
              className={`text-[15px] ${
                kycStatus === "VERIFIED"
                  ? "font-bold text-[#0D851D]"
                  : "text-[#617889] font-medium"
              }`}
            >
              {kycStatus === "VERIFIED" ? "DONE" : "Not Done"}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
