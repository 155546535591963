import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import {
  IoIosArrowDown,
  IoIosArrowDropdown,
  IoIosArrowUp,
} from "react-icons/io";
import axios from "axios";
import { API_URL, PROD_LINK, STAGING_LINK } from "../../constant";
import { useAuth } from "../../context/auth-context";
import { Link } from "react-router-dom";
import SendMessage from "./SendMessage";
import GiftcardModal from "../orders/GiftcardModal";
import OrderConfrimation from "../orders/OrderConfrimation";
import Confirmation from "../orders/Confirmation";
import { BsArrowUpRight } from "react-icons/bs";
import Graph from "./Graph";
import CryptoConfirmation from "../orders/CryptoConfirmation";
import NewRateFlow from "./newTradeFlow/NewRateFlow";
import CryptoOrders from "../orders/CryptoOrders";
import { getDateRange } from "../../utils/GetDateRange";
import CryptoGraph from "./CryptoGraph";
import GiftcardGraph from "./GiftcardGraph";

interface TotalPayoutState {
  amountChange: number | null;
  percentageChange: number | null;
}

const Dashboard = () => {
  const {
    token,
    uploadGiftcard,
    isNotified,
    isOpened,
    setUploadGiftcard,
    openRange,
    filter,
    logout,
  } = useAuth();
  const [refetchCount, setRefetchCount] = useState<number>(0);

  const [cryptoRate, setCryptoRate] = useState<any | number>();
  const [imageURL, setImageURL] = useState<any[]>([]);
  const [payoutStatus, setPayoutStatus] = useState<boolean>(false);
  const [orderModal, setOrderModal] = useState<boolean>(false);
  const [messageModal, setMessageModal] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [status, setStatus] = useState<any | number>();
  const [date, setDate] = useState<string>("");
  const [cryptoDate, setCryptoDate] = useState<string>("");
  const [currencyUSD, setCurrencyUSD] = useState<any | number>();
  const [currencyNGN, setCurrencyNGN] = useState<any | number>();
  const [userId, setUserId] = useState<string>("");
  const [txnId, setTxnId] = useState<string>("");
  const [cardType, setCardType] = useState<any[]>([]);
  const [ecode, setEcode] = useState<string>("");
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [cryptoOrderModal, setCryptoOrderModal] = useState<boolean>(false);
  const [cryptoUserId, setCryptoUserId] = useState<string>("");
  const [cryptoTsxnId, setCryptoTsxnId] = useState<string>("");
  const [cryptoCurrency, setCryptoCurrency] = useState<string>("");
  const [cryptoCurrencyUSD, setCryptoCurrencyUSD] = useState<any | number>();
  const [cryptoCurrencyNGN, setCryptoCurrencyNGN] = useState<any | number>();
  const [cryptoStatus, setCryptoStatus] = useState<any | number>();
  const [cryptoNetwork, setCryptoNetwork] = useState<string>("");
  const [confirmedModal, setConfirmedModal] = useState<boolean>(false);
  const [dropDown, setDropDown] = useState<boolean>(false);
  const [dataAllTime, setDataAllTime] = useState<any>({
    totalAmountInUSD: 0,
    totalPayoutAmount: 0,
    totalCryptoPayout: 0,
    totalCryptoPayoutInUSD: 0,
    totalGiftCardPayout: 0,
    totalGiftCardPayoutInUSD: 0,
    totalDeposits: 0,
    activeUsers: 0,
    upgradedUsers: 0,
    activatedUsers: 0,
    totalMerchants: 0,
    totalCustomers: 0,
    totalClients: 0,
    newCustomersCount: 0,
    newMerchantsCount: 0,
  });
  const [dataAllTimeCurrent, setDataAllTimeCurrent] = useState<any>({
    totalAmountInUSD: 0,
    totalPayoutAmount: 0,
    totalCryptoPayout: 0,
    totalCryptoPayoutInUSD: 0,
    totalGiftCardPayout: 0,
    totalGiftCardPayoutInUSD: 0,
    totalDeposits: 0,
    activeUsers: 0,
    upgradedUsers: 0,
    activatedUsers: 0,
    totalMerchants: 0,
    totalCustomers: 0,
    totalClients: 0,
    newCustomersCount: 0,
    newMerchantsCount: 0,
  });
  const [totalPayout, setTotalPayout] = useState<TotalPayoutState>({
    amountChange: null,
    percentageChange: null,
  });
  const [totalActiveUsers, setTotalActiveUsers] = useState<TotalPayoutState>({
    amountChange: null,
    percentageChange: null,
  });
  const [totalCustomers, setTotalCustomers] = useState<TotalPayoutState>({
    amountChange: null,
    percentageChange: null,
  });
  const [totalMerchants, setTotalMerchants] = useState<TotalPayoutState>({
    amountChange: null,
    percentageChange: null,
  });
  const [graphType, setGraphType] = useState<string>("All");
  const [cryptoConfirmationModal, setCryptoConfirmationModal] =
    useState<boolean>(false);
  const [orderss, setOrderss] = useState<any[]>([]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };
  const handleMouseEnter3 = () => {
    setIsHovered3(true);
  };

  const handleMouseLeave3 = () => {
    setIsHovered3(false);
  };
  const handleMouseEnter4 = () => {
    setIsHovered4(true);
  };

  const handleMouseLeave4 = () => {
    setIsHovered4(false);
  };
  useEffect(() => {
    const fetchStats = async (filter: string) => {
      try {
        const { current, previous } = getDateRange(filter);

        // Fetch current period data
        const currentResponse = await axios.get(
          `${API_URL}${
            filter === ""
              ? `api/stats`
              : `api/stats?from=${current.from}&to=${current.to}`
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (filter === "") {
          setDataAllTime(currentResponse.data.data);
        }

        // Fetch previous period data
        const previousResponse = await axios.get(
          `${API_URL}${
            filter === ""
              ? `api/stats`
              : `api/stats?from=${previous.from}&to=${previous.to}`
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (
          currentResponse.data.status === "success" &&
          previousResponse.data.status === "success" &&
          filter !== ""
        ) {
          const currentData = currentResponse.data.data;
          const previousData = previousResponse.data.data;
          setDataAllTimeCurrent(currentData);

          // Extract required metrics
          const currentMetrics = {
            totalPayoutAmount: currentData.totalPayoutAmount,
            activeUsers: currentData.activeUsers,
            totalCustomers: currentData.totalCustomers,
            totalMerchants: currentData.totalMerchants,
          };

          const previousMetrics = {
            totalPayoutAmount: previousData.totalPayoutAmount,
            activeUsers: previousData.activeUsers,
            totalCustomers: previousData.totalCustomers,
            totalMerchants: previousData.totalMerchants,
          };

          // Compare metrics
          const calculatePercentageChange = (
            current: number,
            previous: number
          ) => {
            if (previous === 0) {
              return current === 0 ? 0 : 100; // Handle case where previous value is 0
            }
            return ((current - previous) / previous) * 100;
          };

          const totalPayoutAmountPercenatge = calculatePercentageChange(
            currentMetrics.totalPayoutAmount,
            previousMetrics.totalPayoutAmount
          );
          const activeUsersPercentage = calculatePercentageChange(
            currentMetrics.activeUsers,
            previousMetrics.activeUsers
          );
          const totalCustomersPercentage = calculatePercentageChange(
            currentMetrics.totalCustomers,
            previousMetrics.totalCustomers
          );
          const totalMerchantsPercentage = calculatePercentageChange(
            currentMetrics.totalMerchants,
            previousMetrics.totalMerchants
          );

          setTotalPayout({
            amountChange:
              currentMetrics.totalPayoutAmount -
              previousMetrics.totalPayoutAmount,
            percentageChange: totalPayoutAmountPercenatge,
          });
          setTotalActiveUsers({
            amountChange:
              currentMetrics.activeUsers - previousMetrics.activeUsers,
            percentageChange: activeUsersPercentage,
          });
          setTotalCustomers({
            amountChange:
              currentMetrics.totalCustomers - previousMetrics.totalCustomers,
            percentageChange: totalCustomersPercentage,
          });
          setTotalMerchants({
            amountChange:
              currentMetrics.totalMerchants - previousMetrics.totalMerchants,
            percentageChange: totalMerchantsPercentage,
          });
        }
      } catch (e: any) {
        console.error("Error fetching stats:", e);
        if (
          e.response.status === 401 &&
          e.response.data.message === "Inavlid token. Please login again!"
        ) {
          logout();
        }
        // You can set some error state here if you want to display an error message
      }
    };

    fetchStats(filter);
  }, [filter, logout, token]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const uploadGiftcard = params.get("uploadGiftcard");
    const rateScreen = params.get("rateScreen");
    const messageModal = params.get("messageModal");

    if (uploadGiftcard === "true") {
      setUploadGiftcard(true);
    }
    if (rateScreen === "1") {
      setRateScreen(1);
    }
    if (rateScreen === "2") {
      setRateScreen(2);
    }
    if (messageModal === "true") {
      setMessageModal(true);
    }
  }, []);

  const [rateScreen, setRateScreen] = useState<number>(1);

  return (
    <div className="w-full">
      <div className="w-full h-[31px] bg-[#1B1B1B] flex justify-center items-center">
        {API_URL?.includes("staging") ? (
          <h4 className="font-medium text-[#AEBCC6] text-[12px]">
            You are in Staging:{" "}
            <span>
              <a href={PROD_LINK} className="text-[#03C668]">
                Switch to Prod
              </a>
            </span>
          </h4>
        ) : (
          <h4 className="font-medium text-[#AEBCC6] text-[12px]">
            You are in PROD:{" "}
            <span>
              <a href={STAGING_LINK} className="text-[#DF4E4E]">
                Switch to Staging
              </a>
            </span>
          </h4>
        )}
      </div>
      <div className="w-full lgss:flex lgss:flex-row  h-screen">
        <Sidebar />
        <div className="w-full lgss:w-10/12 h-full overflow-auto bg-[#13181B]">
          <Navbar
            setUploadGiftcard={setUploadGiftcard}
            setRateScreen={setRateScreen}
            setMessageModal={setMessageModal}
          />
          {!uploadGiftcard ? (
            <div className="w-full pt-8 pb-8  lgss:px-12 px-6 bg-[#13181B]">
              <div className="w-full mt-10 grid gap-4 grid-cols-2 lgss:grid-cols-4 ">
                <div
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className={
                    isNotified || isOpened || openRange
                      ? "px-6 pt-4 h-[137.25px]  bg-transparent border border-[#2C363E] rounded-lg "
                      : "px-6 pt-4 h-[137.25px]  bg-transparent border border-[#2C363E] rounded-lg relative"
                  }
                >
                  <h4 className="text-[10px] xs:text-[13px] font-bold text-[#0A6AAF]">
                    Total payout
                  </h4>
                  <h4 className="font-sora font-bold text-[15px] xs:text-[17px] mds:text-[18px] text-[#BEE1FB] mt-2">
                    ₦{" "}
                    {filter === ""
                      ? dataAllTime.totalPayoutAmount.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : dataAllTimeCurrent.totalPayoutAmount.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </h4>
                  <div className="flex items-center justify-start mt-3 gap-2">
                    <div className="px-4 py-2 rounded-lg bg-[#273037] text-white  text-[9px] lgss:text-[10px]">
                      {filter === ""
                        ? `$${dataAllTime.totalAmountInUSD.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}`
                        : `${
                            totalPayout.amountChange !== null &&
                            totalPayout.amountChange > 0
                              ? `+${totalPayout.amountChange.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}`
                              : totalPayout.amountChange !== null &&
                                totalPayout.amountChange < 0
                              ? `${totalPayout.amountChange.toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}`
                              : 0
                          }`}
                    </div>
                    {filter !== "" && (
                      <div>
                        {totalPayout.amountChange !== null ? (
                          <div
                            className={
                              totalPayout.percentageChange! < 0
                                ? "p-2 gap-1  rounded-lg bg-[#0D0D0D] flex justify-center items-center text-[#E94F4F]  text-[9px] lgss:text-[10px]"
                                : totalPayout.percentageChange! === 0
                                ? "p-2 gap-1  rounded-lg bg-[#0D0D0D] flex justify-center items-center text-[#E4A400]  text-[9px] lgss:text-[10px]"
                                : "p-2 gap-1  rounded-lg bg-[#0D0D0D] flex justify-center items-center text-[#3CAC4B]  text-[9px] lgss:text-[10px]"
                            }
                          >
                            {Math.abs(totalPayout.percentageChange!).toFixed(1)}
                            % {/* Display percentage without sign */}
                            {totalPayout.percentageChange! > 0 ? (
                              <IoIosArrowUp className="text-[#3CAC4B] text-[14px]" />
                            ) : totalPayout.percentageChange! < 0 ? (
                              <IoIosArrowDown className="text-[#E94F4F] text-[14px]" />
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                  {isHovered ? (
                    <div className="absolute hidden lgss:flex w-[80%] h-[80px] transition-all transform ease-out duration-2000  top-32 left-24 rounded-lg bg-[#617889] text-[#F7F8F9] text-[12px] p-4">
                      <div className="absolute -top-3  lgss:left-[50%] transform -translate-x-1/2">
                        <div className="w-6 h-6 bg-[#617889] transform rotate-45 "></div>
                      </div>
                      Total amount of payout made to users over a period of time
                    </div>
                  ) : null}
                </div>
                <div
                  onMouseEnter={handleMouseEnter2}
                  onMouseLeave={handleMouseLeave2}
                  className={
                    isHovered || isNotified || isOpened || openRange
                      ? "px-6 pt-4 h-[137.25px]  bg-transparent border border-[#2C363E] rounded-lg"
                      : "px-6 pt-4 h-[137.25px] relative bg-transparent border border-[#2C363E] rounded-lg"
                  }
                >
                  <h4 className="text-[10px] xs:text-[13px] font-bold text-[#D6780B]">
                    Active Users
                  </h4>
                  <h4 className="font-sora font-bold text-[15px] xs:text-[17px] mds:text-[18px] text-[#FBD8AE] mt-2">
                    {filter === ""
                      ? dataAllTime.activeUsers.toLocaleString("en-US")
                      : dataAllTimeCurrent.activeUsers.toLocaleString("en-US")}
                  </h4>
                  <div className="flex items-center justify-start mt-3 gap-2">
                    <div className="px-4 py-2 rounded-lg bg-[#273037] text-white  text-[9px] lgss:text-[10px]">
                      {filter === ""
                        ? `${dataAllTime.activatedUsers.toLocaleString(
                            "en-US"
                          )}`
                        : `${
                            totalActiveUsers.amountChange !== null &&
                            totalActiveUsers.amountChange > 0
                              ? `+${totalActiveUsers.amountChange.toLocaleString(
                                  "en-US"
                                )}`
                              : totalActiveUsers.amountChange !== null &&
                                totalActiveUsers.amountChange < 0
                              ? `${totalActiveUsers.amountChange.toLocaleString(
                                  "en-US"
                                )}`
                              : 0
                          }`}
                    </div>
                    {filter !== "" && (
                      <div>
                        {totalActiveUsers.amountChange !== null ? (
                          <div
                            className={
                              totalActiveUsers.percentageChange! < 0
                                ? "p-2 gap-1  rounded-lg bg-[#0D0D0D] flex justify-center items-center text-[#E94F4F]  text-[9px] lgss:text-[10px]"
                                : totalActiveUsers.percentageChange! === 0
                                ? "p-2 gap-1  rounded-lg bg-[#0D0D0D] flex justify-center items-center text-[#E4A400]  text-[9px] lgss:text-[10px]"
                                : "p-2 gap-1  rounded-lg bg-[#0D0D0D] flex justify-center items-center text-[#3CAC4B]  text-[9px] lgss:text-[10px]"
                            }
                          >
                            {Math.abs(
                              totalActiveUsers.percentageChange!
                            ).toFixed(1)}
                            % {/* Display percentage without sign */}
                            {totalActiveUsers.percentageChange! > 0 ? (
                              <IoIosArrowUp className="text-[#3CAC4B] text-[14px]" />
                            ) : totalActiveUsers.percentageChange! < 0 ? (
                              <IoIosArrowDown className="text-[#E94F4F] text-[14px]" />
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                  {isHovered2 ? (
                    <div className="absolute hidden lgss:flex w-[80%] h-[80px] transition-all transform ease-out duration-2000  top-32 left-24 rounded-lg bg-[#617889] text-[#F7F8F9] text-[12px] p-4">
                      <div className="absolute -top-3  lgss:left-[50%] transform -translate-x-1/2">
                        <div className="w-6 h-6 bg-[#617889] transform rotate-45 "></div>
                      </div>
                      Number of user that traded or logged in recently with
                      30days over a period of time
                    </div>
                  ) : null}
                </div>
                <div
                  onMouseEnter={handleMouseEnter3}
                  onMouseLeave={handleMouseLeave3}
                  className={
                    isHovered2 || isNotified || isOpened || openRange
                      ? "px-6 pt-4 h-[137.25px]   bg-transparent border border-[#2C363E] rounded-lg"
                      : "px-6 pt-4 h-[137.25px] relative  bg-transparent border border-[#2C363E] rounded-lg"
                  }
                >
                  <h4 className="text-[10px] xs:text-[13px] font-bold text-[#4E606E]">
                    Total customers
                  </h4>
                  <h4 className="font-sora font-bold text-[15px] xs:text-[17px] mds:text-[18px] text-[#BEE1FB] mt-2">
                    {filter === ""
                      ? dataAllTime.totalCustomers.toLocaleString("en-US")
                      : dataAllTimeCurrent.totalCustomers.toLocaleString(
                          "en-US"
                        )}
                  </h4>
                  <div className="flex items-center justify-start mt-3 gap-2">
                    <div className="px-4 py-2 rounded-lg bg-[#273037] text-white  text-[9px] lgss:text-[10px]">
                      {filter === ""
                        ? `${dataAllTime.newCustomersCount.toLocaleString(
                            "en-US"
                          )}`
                        : `${
                            totalCustomers.amountChange !== null &&
                            totalCustomers.amountChange > 0
                              ? `+${totalCustomers.amountChange.toLocaleString(
                                  "en-US"
                                )}`
                              : totalCustomers.amountChange !== null &&
                                totalCustomers.amountChange < 0
                              ? `${totalCustomers.amountChange.toLocaleString(
                                  "en-US"
                                )}`
                              : 0
                          }`}
                    </div>
                    {filter !== "" && (
                      <div>
                        {totalCustomers.amountChange !== null ? (
                          <div
                            className={
                              totalCustomers.percentageChange! < 0
                                ? "p-2 gap-1  rounded-lg bg-[#0D0D0D] flex justify-center items-center text-[#E94F4F]  text-[9px] lgss:text-[10px]"
                                : totalCustomers.percentageChange! === 0
                                ? "p-2 gap-1  rounded-lg bg-[#0D0D0D] flex justify-center items-center text-[#E4A400]  text-[9px] lgss:text-[10px]"
                                : "p-2 gap-1  rounded-lg bg-[#0D0D0D] flex justify-center items-center text-[#3CAC4B]  text-[9px] lgss:text-[10px]"
                            }
                          >
                            {Math.abs(totalCustomers.percentageChange!).toFixed(
                              1
                            )}
                            % {/* Display percentage without sign */}
                            {totalCustomers.percentageChange! > 0 ? (
                              <IoIosArrowUp className="text-[#3CAC4B] text-[14px]" />
                            ) : totalCustomers.percentageChange! < 0 ? (
                              <IoIosArrowDown className="text-[#E94F4F] text-[14px]" />
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                  {isHovered3 ? (
                    <div className="absolute hidden lgss:flex w-[80%] h-[80px] transition-all transform ease-out duration-2000  top-32 left-24 rounded-lg bg-[#617889] text-[#F7F8F9] text-[12px] p-4">
                      <div className="absolute -top-3  lgss:left-[50%] transform -translate-x-1/2">
                        <div className="w-6 h-6 bg-[#617889] transform rotate-45 "></div>
                      </div>
                      Total number of registered customers over a period of time
                    </div>
                  ) : null}
                </div>
                <div
                  onMouseEnter={handleMouseEnter4}
                  onMouseLeave={handleMouseLeave4}
                  className={
                    isHovered3 || isNotified || isOpened || openRange
                      ? "px-6 pt-4 h-[137.25px]   bg-transparent border border-[#2C363E] rounded-lg"
                      : "px-6 pt-4 h-[137.25px] relative  bg-transparent border border-[#2C363E] rounded-lg"
                  }
                >
                  <h4 className="text-[10px] xs:text-[13px] font-bold text-[#4E606E]">
                    Total merchants
                  </h4>
                  <h4 className="font-sora font-bold text-[15px] xs:text-[17px] mds:text-[18px] text-[#BEE1FB] mt-2">
                    {filter === ""
                      ? dataAllTime.totalMerchants.toLocaleString("en-US")
                      : dataAllTimeCurrent.totalMerchants.toLocaleString(
                          "en-US"
                        )}
                  </h4>
                  <div className="flex items-center justify-start mt-3 gap-2">
                    <div className="px-4 py-2 rounded-lg bg-[#273037] text-white  text-[9px] lgss:text-[10px]">
                      {filter === ""
                        ? `${dataAllTime.newMerchantsCount.toLocaleString(
                            "en-US"
                          )}`
                        : `${
                            totalMerchants.amountChange !== null &&
                            totalMerchants.amountChange > 0
                              ? `+${totalMerchants.amountChange.toLocaleString(
                                  "en-US"
                                )}`
                              : totalMerchants.amountChange !== null &&
                                totalMerchants.amountChange < 0
                              ? `${totalMerchants.amountChange.toLocaleString(
                                  "en-US"
                                )}`
                              : 0
                          }`}
                    </div>
                    {filter !== "" && (
                      <div>
                        {totalMerchants.amountChange !== null ? (
                          <div
                            className={
                              totalMerchants.percentageChange! < 0
                                ? "p-2 gap-1  rounded-lg bg-[#0D0D0D] flex justify-center items-center text-[#E94F4F]  text-[9px] lgss:text-[10px]"
                                : totalMerchants.percentageChange! === 0
                                ? "p-2 gap-1  rounded-lg bg-[#0D0D0D] flex justify-center items-center text-[#E4A400]  text-[9px] lgss:text-[10px]"
                                : "p-2 gap-1  rounded-lg bg-[#0D0D0D] flex justify-center items-center text-[#3CAC4B]  text-[9px] lgss:text-[10px]"
                            }
                          >
                            {Math.abs(totalMerchants.percentageChange!).toFixed(
                              1
                            )}
                            % {/* Display percentage without sign */}
                            {totalMerchants.percentageChange! > 0 ? (
                              <IoIosArrowUp className="text-[#3CAC4B] text-[14px]" />
                            ) : totalMerchants.percentageChange! < 0 ? (
                              <IoIosArrowDown className="text-[#E94F4F] text-[14px]" />
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                  {isHovered4 ? (
                    <div className="absolute hidden lgss:flex w-[80%] h-[80px] transition-all transform ease-out duration-2000  top-32 left-10 rounded-lg bg-[#617889] text-[#F7F8F9] text-[12px] p-4">
                      <div className="absolute -top-3  lgss:left-[50%] transform -translate-x-1/2">
                        <div className="w-6 h-6 bg-[#617889] transform rotate-45 "></div>
                      </div>
                      Total number of registered merchants over a period of time
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="w-full mt-6">
                <div className="bg-[#0D0D0D] w-full flex justify-start items-center pr-12 h-[48px] mt-4">
                  <button
                    onClick={() => {
                      setGraphType("All");
                    }}
                    className={
                      graphType === "All"
                        ? "border-secondary_button h-full px-6 text-[16px] font-semibold text-secondary_button border-b-2 "
                        : " h-full px-6 text-[14px]  text-[#C6D0D7]  "
                    }
                  >
                    All
                  </button>
                  <button
                    onClick={() => {
                      setGraphType("Crypto");
                    }}
                    className={
                      graphType === "Crypto"
                        ? "border-secondary_button h-full px-6 text-[16px] font-semibold text-secondary_button border-b-2 "
                        : " h-full px-6 text-[14px]  text-[#C6D0D7]  "
                    }
                  >
                    Crypto
                  </button>
                  <button
                    onClick={() => {
                      setGraphType("Giftcard");
                    }}
                    className={
                      graphType === "Giftcard"
                        ? "border-secondary_button h-full px-6 text-[16px] font-semibold text-secondary_button border-b-2 "
                        : " h-full px-6 text-[14px]  text-[#C6D0D7]  "
                    }
                  >
                    Giftcard
                  </button>
                </div>
                <div className="w-full flex  gap-4 justify-center items-center">
                  <div className="w-[75%] h-[500px] bg-[#1C2227] hidden lgss:flex flex-col rounded-lg px-6 pt-6 pb-4">
                    <div className="flex justify-between items-start ">
                      <div className="">
                        <h4 className="text-[12px] font-bold text-[#FFFFFF] capitalize">
                          Total{" "}
                          {graphType === "All"
                            ? "traded"
                            : graphType === "Crypto"
                            ? "Crypto traded"
                            : graphType === "Giftcard"
                            ? "Giftcard traded"
                            : ""}
                        </h4>
                        <h4 className="font-sora font-bold text-[15px] xs:text-[22px] text-[#BEE1FB] mt-2">
                          ₦
                          {graphType === "All"
                            ? dataAllTime.totalPayoutAmount.toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 1,
                                  maximumFractionDigits: 1,
                                }
                              )
                            : graphType === "Crypto"
                            ? dataAllTime.totalCryptoPayout.toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 1,
                                  maximumFractionDigits: 1,
                                }
                              )
                            : graphType === "Giftcard"
                            ? dataAllTime.totalGiftCardPayout.toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 1,
                                  maximumFractionDigits: 1,
                                }
                              )
                            : 0}
                        </h4>
                      </div>
                    </div>
                    <div
                      className={
                        isNotified ||
                        messageModal ||
                        orderModal ||
                        cryptoOrderModal
                          ? "hidden"
                          : "w-full"
                      }
                    >
                      {graphType === "All" ? (
                        <Graph />
                      ) : graphType === "Crypto" ? (
                        <CryptoGraph />
                      ) : graphType === "Giftcard" ? (
                        <GiftcardGraph />
                      ) : null}
                    </div>
                  </div>
                  <div
                    className={
                      dropDown
                        ? "w-full lgss:hidden lgss:w-[24%] py-4 px-4 rounded-xl flex flex-col items-start justify-center bg-[#1C2227]"
                        : "w-full lgss:hidden lgss:w-[24%] h-[60px] px-4 rounded-xl flex flex-col items-start justify-center bg-[#1C2227]"
                    }
                  >
                    <button
                      onClick={() => setDropDown((prev) => !prev)}
                      className="flex cursor-pointer w-full justify-between items-center"
                    >
                      <h4
                        className={
                          dropDown
                            ? "text-[14px] font-bold text-white "
                            : "text-[16px] font-bold text-white "
                        }
                      >
                        Quick Links
                      </h4>
                      <IoIosArrowDropdown
                        className={
                          dropDown
                            ? "text-white text-[24px]"
                            : "text-white text-[24px]"
                        }
                      />
                    </button>
                    {dropDown && (
                      <div className="w-full transition-all ease-in duration-1000">
                        <a
                          href="http://"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="h-[40px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
                        >
                          One Liquidity Dashboard
                          <BsArrowUpRight />
                        </a>
                        <a
                          href="https://app.quidax.ng/signin?_gl=1*wqrbe8*_ga*MTAxMTk2NjU2Ny4xNzExMTA5NjYy*_ga_96PZ7PJMYV*MTcxNTk0NDYzMi4zLjAuMTcxNTk0NDYzMi42MC4wLjA"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="h-[40px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
                        >
                          Quidax
                          <BsArrowUpRight />
                        </a>

                        <a
                          href="http://"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="h-[40px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
                        >
                          Aws Console
                          <BsArrowUpRight />
                        </a>
                        <a
                          href="https://join.slack.com/t/deexoptions/shared_invite/zt-1zm355s5v-5dITBJbJl5RuqtsmX2j4mg"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="h-[40px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
                        >
                          Slack
                          <BsArrowUpRight />
                        </a>
                        <a
                          href="https://wa.link/1zlp0c"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="h-[40px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
                        >
                          Customer care line
                          <BsArrowUpRight />
                        </a>
                        <a
                          href="http://"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="h-[40px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
                        >
                          Telegram Settings
                          <BsArrowUpRight />
                        </a>
                        <a
                          href="http://"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="h-[40px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
                        >
                          How to use this dashboard
                          <BsArrowUpRight />
                        </a>
                        <a
                          href="https://app.dojah.io/quick-actions"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="h-[40px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
                        >
                          Dojah Dashboard
                          <BsArrowUpRight />
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="w-full hidden lgss:w-[24%] h-[500px] px-4 rounded-xl lgss:flex flex-col items-start justify-center bg-[#1C2227]">
                    <h4 className="text-[14px] font-bold text-white ">
                      Quick Links
                    </h4>
                    <a
                      href="http://"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="h-[40px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
                    >
                      One Liquidity Dashboard
                      <BsArrowUpRight />
                    </a>
                    <a
                      href="https://app.quidax.ng/signin?_gl=1*wqrbe8*_ga*MTAxMTk2NjU2Ny4xNzExMTA5NjYy*_ga_96PZ7PJMYV*MTcxNTk0NDYzMi4zLjAuMTcxNTk0NDYzMi42MC4wLjA"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="h-[40px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
                    >
                      Quidax
                      <BsArrowUpRight />
                    </a>

                    <a
                      href="http://"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="h-[40px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
                    >
                      Aws Console
                      <BsArrowUpRight />
                    </a>
                    <a
                      href="https://join.slack.com/t/deexoptions/shared_invite/zt-1zm355s5v-5dITBJbJl5RuqtsmX2j4mg"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="h-[40px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
                    >
                      Slack
                      <BsArrowUpRight />
                    </a>
                    <a
                      href="https://wa.link/1zlp0c"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="h-[40px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
                    >
                      Customer care line
                      <BsArrowUpRight />
                    </a>
                    <a
                      href="http://"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="h-[40px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
                    >
                      Telegram settings
                      <BsArrowUpRight />
                    </a>
                    <a
                      href="http://"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="h-[40px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
                    >
                      How to use this dashboard
                      <BsArrowUpRight />
                    </a>
                    <a
                      href="https://app.dojah.io/quick-actions"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="h-[40px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
                    >
                      Dojah Dashboard
                      <BsArrowUpRight />
                    </a>
                  </div>
                </div>
              </div>
              <div className="w-full flex mt-12 mb-8  justify-between items-center">
                <h4 className="text-[20px] font-bold text-white font-sora">
                  New orders
                </h4>
                <Link
                  to="/transactions"
                  className="text-[16px] hover:text-[#7abef0] font-bold text-[#60B7F6]"
                >
                  View all
                </Link>
              </div>

              <CryptoOrders
                setOrderModal={setCryptoOrderModal}
                setUserId={setCryptoUserId}
                setTsxnId={setCryptoTsxnId}
                setCurrency={setCryptoCurrency}
                setCurrencyUSD={setCryptoCurrencyUSD}
                setCurrencyNGN={setCryptoCurrencyNGN}
                setStatus={setCryptoStatus}
                setPayoutStatus={setPayoutStatus}
                setNetwork={setCryptoNetwork}
                setDate={setCryptoDate}
                refetchCount={refetchCount}
                setCryptoRate={setCryptoRate}
                status={cryptoStatus}
                payoutStatus={payoutStatus}
                setOrderGiftcardModal={setOrderModal}
                setImageURL={setImageURL}
                setStatusGiftcard={setStatus}
                setDateGiftcard={setDate}
                setCurrencyUSDGiftcard={setCurrencyUSD}
                setCurrencyNGNGiftcard={setCurrencyNGN}
                currencyNGNGiftcard={currencyNGN}
                setUserIdGiftcard={setUserId}
                setTxnId={setTxnId}
                setCardType={setCardType}
                setEcode={setEcode}
                setOrderss={setOrderss}
              />
            </div>
          ) : (
            <NewRateFlow
              setUploadGiftcard={setUploadGiftcard}
              rateScreen={rateScreen}
              setRateScreen={setRateScreen}
            />
          )}
        </div>
        {cryptoOrderModal && (
          <OrderConfrimation
            currency={cryptoCurrency}
            currencyUSD={cryptoCurrencyUSD}
            currencyNGN={cryptoCurrencyNGN}
            status={cryptoStatus}
            network={cryptoNetwork}
            date={cryptoDate}
            tsxnId={cryptoTsxnId}
            userId={cryptoUserId}
            setOrderModal={setCryptoOrderModal}
            payoutStatus={payoutStatus}
            setRefetchCount={setRefetchCount}
            setCryptoConfirmationModal={setCryptoConfirmationModal}
            cryptoRate={cryptoRate}
            orderss={orderss}
          />
        )}
        {cryptoConfirmationModal && (
          <CryptoConfirmation
            setOrderModal={setCryptoOrderModal}
            tsxnId={cryptoTsxnId}
            setRefetchCount={setRefetchCount}
            setCryptoConfirmationModal={setCryptoConfirmationModal}
            currency={cryptoCurrency}
            currencyUSD={cryptoCurrencyUSD}
            currencyNGN={cryptoCurrencyNGN}
            userId={cryptoUserId}
          />
        )}
        {orderModal && (
          <GiftcardModal
            setOrderModal={setOrderModal}
            userId={userId}
            currencyUSD={currencyUSD}
            currencyNGN={currencyNGN}
            status={status}
            imageURL={imageURL}
            cardType={cardType}
            date={date}
            txnId={txnId}
            confirmed={confirmed}
            setConfirmed={setConfirmed}
            ecode={ecode}
            setConfirmedModal={setConfirmedModal}
          />
        )}
        {confirmedModal && (
          <Confirmation
            setConfirmedModal={setConfirmedModal}
            setOrderModal={setOrderModal}
            txnId={txnId}
            userId={userId}
            currencyUSD={currencyUSD}
            currencyNGN={currencyNGN}
            cardType={cardType}
            setConfirmed={setConfirmed}
          />
        )}
        {messageModal && <SendMessage setMessageModal={setMessageModal} />}
      </div>
    </div>
  );
};

export default Dashboard;
