import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { API_URL } from "../../../constant";
import { useAuth } from "../../../context/auth-context";
import { TailSpin } from "react-loader-spinner";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BiErrorCircle } from "react-icons/bi";

const ExpandAdd = ({ setAddGiftcard, cardDetails, setRefetchCount }: any) => {
  const { token } = useAuth();
  const [active, setActive] = useState<boolean>(false);
  const [openType, setOpenType] = useState<boolean>(false);
  const [openReceiptType, setOpenReceiptType] = useState<boolean>(false);
  const [type, setType] = useState<string>("Physical");
  const [receiptType, setReceiptType] = useState<string>("physicalCashReceipt");
  const [country, setCountry] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>("");
  const [error, setError] = useState<string>("");

  const [minRate, setMinRate] = useState<number | string>("");
  const [maxRate, setMaxRate] = useState<number | string>("");
  const [createRate, setCreateRate] = useState<number | string>("");

  const isNumber = (str: any) => {
    if (str.trim() === "") {
      return false;
    }

    return !isNaN(str);
  };

  const handleMinRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNumber(value)) {
      setMinRate(Number(value));
    } else {
      setMinRate("");
    }
  };
  const handleMaxRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNumber(value)) {
      setMaxRate(Number(value));
    } else {
      setMaxRate("");
    }
  };
  const handleCreateRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNumber(value)) {
      setCreateRate(Number(value));
    } else {
      setCreateRate("");
    }
  };

  const validateFields = (country: string, min: any, max: any, rate: any) => {
    if (!country) {
      setError("Kindly add the country");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (!min) {
      setError("Kindly add the minimum trade range");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (!max) {
      setError("Kindly add the maximum trade range");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (!rate) {
      setError("Kindly add the rate");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    setError("");
    return true;
  };
  useEffect(() => {
    if (type === "Ecode") {
      setReceiptType("ecode");
    }
  }, [type]);
  const saveGiftcard = () => {
    if (!validateFields(country, minRate, maxRate, createRate)) return;
    if (!loading) {
      setLoading(true);
      axios
        .post(
          `${API_URL}api/gift-cards/${cardDetails._id}/rates`,
          {
            type: receiptType,
            isActive: active,
            range: {
              min: minRate,
              max: maxRate,
            },
            rate: createRate,
            country: country,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          setLoading(false);
          setSuccess("Country added successfully!!!");
          setRefetchCount((prev: number) => prev + 1);
          setTimeout(() => {
            setSuccess("");
            setCountry("");
            setMaxRate("");
            setMinRate("");
            setCreateRate("");
            setAddGiftcard((prev: any) => !prev);
          }, 2000);
        })
        .catch((e: any) => {
          setError(e.response.data.message);
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          console.log(e.response.data.message);
        });
    }
  };

  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-50  backdrop-blur-sm">
      <div
        className={
          " w-11/12 lgss:w-[40%] pb-10 transition-all p-8 ease-in duration-1000  rounded-xl bg-[#1F1F1F] "
        }
      >
        <div className="flex   justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Add new card
          </h4>
          <h4
            onClick={() => setAddGiftcard((prev: any) => !prev)}
            className="text-secondary_button text-[14px] font-medium cursor-pointer"
          >
            Close
          </h4>
        </div>
        <div className="w-full mt-8">
          {success && (
            <div className="bg-green-600 w-full text-white  text-[16px] rounded-xl justify-start items-center gap-4 flex h-[60px] px-2 font-bold mt-4 mb-4">
              <AiOutlineCheckCircle className="text-3xl text-white" />
              {success}
            </div>
          )}
          <div className="w-full flex justify-between items-center">
            <h4 className="text-[16px] text-[#617889]">Card Name</h4>
            <div className="flex justify-start gap-3 items-center">
              <div className="w-[30px] h-[30px] rounded-lg">
                <img
                  src={cardDetails?.thumbnail}
                  alt=""
                  className="w-full h-full"
                />
              </div>
              <h4 className="text-[16px]">{cardDetails?.name}</h4>
            </div>
          </div>
          <div className="w-full flex justify-between items-center mt-4">
            <h4 className="text-[16px] text-[#617889]">Card status</h4>
            <div
              onClick={() => setActive((prev) => !prev)}
              className={`flex w-8  cursor-pointer h-4   rounded-full transition-all duration-500 ${
                active ? "bg-secondary_button" : "bg-gray-600"
              }`}
            >
              <span
                className={`h-4 w-4   rounded-full transition-all duration-500 bg-gray-100 ${
                  active ? "ml-4" : ""
                }`}
              ></span>
            </div>
          </div>
          <div className="w-full h-[1px] rounded-full bg-gray-600 mt-8 mb-4" />
          <div className="w-full flex justify-between  items-center mt-4">
            <h4 className="text-[16px] text-[#617889]">Type</h4>
            <div>
              <div
                onClick={() => setOpenType((prev) => !prev)}
                className="flex cursor-pointer justify-center px-2 gap-2 items-center w-[100px] h-[32px] bg-[black] text-[#8F8F8F] rounded-[2px]"
              >
                {type}
                <IoIosArrowDown className="text-[24px]" />
              </div>
              {openType && (
                <div className="absolute bg-[black]">
                  <div
                    onClick={() => {
                      setType("Physical");
                      setOpenType((prev) => !prev);
                    }}
                    className="flex justify-center px-2 gap-2 items-center w-[100px] h-[32px] bg-[black] text-[#8F8F8F] rounded-[2px]"
                  >
                    Physical
                  </div>
                  <div
                    onClick={() => {
                      setType("Ecode");
                      setOpenType((prev) => !prev);
                    }}
                    className="flex justify-center px-2 gap-2 items-center w-[100px] h-[32px] bg-[black] text-[#8F8F8F] rounded-[2px]"
                  >
                    Ecode
                  </div>
                </div>
              )}
            </div>
          </div>
          {type === "Physical" && (
            <div className="w-full flex justify-between  items-center mt-4">
              <h4 className="text-[16px] text-[#617889]">Receipt Type</h4>
              <div className={openReceiptType ? "w-[150px]" : "w-[150px]"}>
                <div>
                  {!openReceiptType && (
                    <div
                      onClick={() => setOpenReceiptType((prev) => !prev)}
                      className={`flex cursor-pointer  justify-between px-4 gap-2 items-center w-full h-[40px] ${
                        receiptType === "physicalCashReceipt"
                          ? "bg-[#30B887]"
                          : receiptType === "physicalDebitCardReceipt"
                          ? "bg-[#B83038] w-[160px]"
                          : receiptType === "physicalCreditCardReceipt"
                          ? "bg-[#B87130] w-[160px]"
                          : receiptType === "physicalActivationReceipt"
                          ? "bg-[#5C30B8]"
                          : receiptType === "physicalNoReceipt"
                          ? "bg-[#4C4C4C4C]"
                          : ""
                      } text-white text-[16px] font-bold rounded-[2px]`}
                    >
                      {receiptType === "physicalCashReceipt"
                        ? "Cash"
                        : receiptType === "physicalDebitCardReceipt"
                        ? "Debit Card"
                        : receiptType === "physicalCreditCardReceipt"
                        ? "Credit Card"
                        : receiptType === "physicalActivationReceipt"
                        ? "Activation"
                        : receiptType === "physicalNoReceipt"
                        ? "No Receipt"
                        : ""}
                      <IoIosArrowDown className="text-[24px]" />
                    </div>
                  )}
                </div>
                {openReceiptType && (
                  <div className="absolute w-[150px]">
                    <div
                      onClick={() => {
                        setReceiptType("physicalCashReceipt");
                        setOpenReceiptType((prev) => !prev);
                      }}
                      className="flex justify-end text-[16px] font-bold px-2 gap-2  items-center w-full  h-[40px] bg-[#30B887] text-white "
                    >
                      Cash
                    </div>
                    <div
                      onClick={() => {
                        setReceiptType("physicalDebitCardReceipt");
                        setOpenReceiptType((prev) => !prev);
                      }}
                      className="flex justify-end text-[16px] font-bold px-2 gap-2 items-center w-full h-[40px] bg-[#B83038] text-white rounded-[2px]"
                    >
                      Debit Card
                    </div>
                    <div
                      onClick={() => {
                        setReceiptType("physicalCreditCardReceipt");
                        setOpenReceiptType((prev) => !prev);
                      }}
                      className="flex justify-end text-[16px] font-bold px-2 gap-2 items-center w-full h-[40px] bg-[#B87130] text-white rounded-[2px]"
                    >
                      Credit Card
                    </div>
                    <div
                      onClick={() => {
                        setReceiptType("physicalActivationReceipt");
                        setOpenReceiptType((prev) => !prev);
                      }}
                      className="flex justify-end px-2 text-[16px] font-bold gap-2 items-center w-full h-[40px] bg-[#5C30B8] text-white rounded-[2px]"
                    >
                      Activation
                    </div>

                    <div
                      onClick={() => {
                        setReceiptType("physicalNoReceipt");
                        setOpenReceiptType((prev) => !prev);
                      }}
                      className="flex justify-end px-2 text-[16px] font-bold rounded-b-lg gap-2 items-center w-full h-[40px] bg-[#4C4C4C4C] text-white rounded-[2px]"
                    >
                      No Receipt
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="flex justify-between items-center mt-4 w-full">
            <h4 className="text-[16px] text-[#617889]">Country</h4>
            <input
              type="text"
              value={country}
              placeholder="Add country"
              onChange={(e) => setCountry(e.target.value)}
              autoFocus
              className="w-[150px]  outline-none bg-[#2B2B2B] text-white rounded-lg h-[40px] text-[14px] px-4"
            />
          </div>
          <div className="flex justify-between items-center mt-4 w-full">
            <h4 className="text-[16px] text-[#617889]">Trade Range</h4>
            <div className="flex justify-start items-center gap-2 ml-12">
              <input
                type="text"
                value={minRate}
                placeholder="Min"
                onChange={handleMinRateChange}
                className="px-2 h-[36px] w-[60px] rounded-sm  text-[14px] bg-[#2B2B2B] outline-none"
              />
              <h4>-</h4>
              <input
                type="text"
                value={maxRate}
                placeholder="Max"
                onChange={handleMaxRateChange}
                className="px-2 h-[36px] w-[60px] rounded-sm  text-[14px] bg-[#2B2B2B] outline-none"
              />
            </div>
          </div>
          <div className="w-full h-[1px] rounded-full bg-gray-600 mt-8 mb-4" />
          <div className="flex justify-between items-center mt-4 w-full">
            <h4 className="text-[16px] text-[#617889]">New Rate</h4>
            <input
              type="text"
              value={createRate}
              onChange={handleCreateRateChange}
              placeholder="Add Rate"
              className="w-[150px]  outline-none bg-[#2B2B2B] text-white rounded-lg h-[40px] text-[14px] px-4"
            />
          </div>
          {error && (
            <div className="bg-red-600 w-full text-white  text-[16px] rounded-xl justify-start items-center gap-4 flex h-[60px] px-2 font-bold mt-4 mb-4">
              <BiErrorCircle className="text-3xl text-white" />
              {error}
            </div>
          )}
          <div
            onClick={saveGiftcard}
            className="w-full flex justify-between mt-8 items-center"
          >
            <button className="w-[120px] h-[52px] bg-secondary_button rounded-sm text-[16px] flex justify-center items-center text-white font-medium">
              {loading ? (
                <TailSpin color="#FFFFFF" height={30} width={30} />
              ) : (
                "Save"
              )}
            </button>
            <button
              onClick={() => setAddGiftcard((prev: any) => !prev)}
              className="font-bold text-[16px] text-[#DF4E4E]"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpandAdd;
