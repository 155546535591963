import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import Redirect from "./components/utils/Redirect";
import Dashboard from "./pages/dashboard/Dashboard";
import Settings from "./pages/settings/Settings";
import Wallets from "./pages/wallets/Wallets";
import VerifyMail from "./components/VerifyMail";
import Reports from "./pages/reports/Reports";
import TaskPage from "./pages/tasks/TaskPage";
import Orders from "./pages/orders/Orders";
import Users from "./pages/users/Users";
import Wallet from "./pages/wallets/Wallet";

const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<Redirect to="/login" />} />
      <Route path="/verify-mail" element={<VerifyMail />} />
      <Route path="/" element={<Redirect to="/login" />} />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/wallet"
        element={
          <PrivateRoute>
            <Wallet />
          </PrivateRoute>
        }
      />

      <Route
        path="/transactions"
        element={
          <PrivateRoute>
            <Orders />
          </PrivateRoute>
        }
      />
      <Route
        path="/users"
        element={
          <PrivateRoute>
            <Users />
          </PrivateRoute>
        }
      />

      <Route
        path="/tasks"
        element={
          <PrivateRoute>
            <TaskPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports"
        element={
          <PrivateRoute>
            <Reports />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default App;
