import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { API_URL } from "../../constant";
import { useAuth } from "../../context/auth-context";
import { TailSpin } from "react-loader-spinner";
import CopyToClipboard from "react-copy-to-clipboard";

const SuccessClientModal = ({
  setSuccessClientModal,
  clientId,
  setRefetchCount,
}: any) => {
  const { token } = useAuth();
  const [copyId, setCopyId] = useState<boolean>(false);
  const [copyBtcWallet, setCopyBtcWallet] = useState<boolean>(false);
  const [copyBepWallet, setCopyBepWallet] = useState<boolean>(false);
  const [copyPhone, setCopyPhone] = useState<boolean>(false);
  const [bepWallet, setBepWallet] = useState<string>("");
  const [btcWallet, setBtcWallet] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!loading) {
      setLoading(true);
      axios
        .get(`${API_URL}api/deex-clients/${clientId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r: any) => {
          setPhone(r.data.data.client.phone);
          setBepWallet(r.data.data.client.wallets.usdt.addresses.address);
          setBtcWallet(r.data.data.client.wallets.btc.addresses.address);
          setLoading(false);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
          setLoading(false);
          setError(e.response.data.message);
          setTimeout(() => {
            setError("");
          }, 2000);
        });
    }
  }, [clientId, token]);
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-60 backdrop-blur-sm">
      <div className="w-11/12 mds:w-10/12 lgss:w-[35%] transition-all p-6 ease-in duration-1000  rounded-xl bg-[#1F1F1F] ">
        <div className="flex  pt-4 justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Client details
          </h4>
          <h4
            onClick={() => setSuccessClientModal(false)}
            className="text-[#78C2F7] font-medium text-[14px] cursor-pointer"
          >
            Close
          </h4>
        </div>
        {loading ? (
          <div className="w-full flex justify-center items-center mt-4 mb-4">
            <TailSpin color="#0B84CB" height={100} width={100} />
          </div>
        ) : (
          <div className="mt-6 flex justify-center items-center flex-col">
            <div className="w-full mt-4 flex justify-center gap-1 items-center">
              <h4 className="text-[#AEBCC6] text-[15px] font-semibold">
                Account created for:
              </h4>
              <CopyToClipboard
                text={phone}
                onCopy={() => {
                  setCopyPhone(true);
                  setTimeout(() => {
                    setCopyPhone(false);
                  }, 3000);
                }}
              >
                {copyPhone ? (
                  <h4 className="text-[12px] xs:text-[14px] text-[#869AA9]">
                    Copied
                  </h4>
                ) : (
                  <h4 className="text-white text-[15px] font-bold cursor-pointer">
                    {phone}
                  </h4>
                )}
              </CopyToClipboard>
            </div>
            <div className="w-full mt-4 flex justify-center gap-2 px-4 items-center">
              <h4 className="text-[#AEBCC6] text-[15px] font-medium">
                User ID:
              </h4>
              <CopyToClipboard
                text={clientId}
                onCopy={() => {
                  setCopyId(true);
                  setTimeout(() => {
                    setCopyId(false);
                  }, 3000);
                }}
              >
                {copyId ? (
                  <h4 className="text-[12px] xs:text-[14px] text-[#869AA9]">
                    Copied
                  </h4>
                ) : (
                  <h4 className="text-[#0C84DA] text-[15px] font-medium cursor-pointer">
                    {clientId}
                  </h4>
                )}
              </CopyToClipboard>
            </div>
            <div className="w-full mt-4 flex justify-center gap-2 px-4 items-center">
              <h4 className="text-[#AEBCC6] text-[15px] font-medium">BTC:</h4>
              <CopyToClipboard
                text={btcWallet}
                onCopy={() => {
                  setCopyBtcWallet(true);
                  setTimeout(() => {
                    setCopyBtcWallet(false);
                  }, 3000);
                }}
              >
                {copyBtcWallet ? (
                  <h4 className="text-[12px] xs:text-[14px] text-[#869AA9]">
                    Copied
                  </h4>
                ) : (
                  <h4 className="text-[#0C84DA] text-[15px] font-medium cursor-pointer">
                    {btcWallet}
                  </h4>
                )}
              </CopyToClipboard>
            </div>
            <div className="w-full mt-4 flex justify-center gap-2 px-4 items-center">
              <h4 className="text-[#AEBCC6] text-[15px] font-medium">
                USDT(BEP-20):
              </h4>
              <CopyToClipboard
                text={bepWallet}
                onCopy={() => {
                  setCopyBepWallet(true);
                  setTimeout(() => {
                    setCopyBepWallet(false);
                  }, 3000);
                }}
              >
                {copyBepWallet ? (
                  <h4 className="text-[12px] xs:text-[14px] text-[#869AA9]">
                    Copied
                  </h4>
                ) : (
                  <h4 className="text-[#0C84DA] text-[15px] font-medium cursor-pointer">
                    {bepWallet}
                  </h4>
                )}
              </CopyToClipboard>
            </div>
          </div>
        )}

        {error && (
          <div className="bg-red-600 text-white mb-4 text-[13px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-semibold mt-4">
            <BiErrorCircle className="text-xl" />
            {error}
          </div>
        )}
        <button
          onClick={() => {
            setRefetchCount((prev: number) => prev + 1);
            setSuccessClientModal(false);
          }}
          className=" w-full rounded-xl py-3 flex justify-center items-center text-white text-[16px] font-bold mt-6 bg-secondary_button"
        >
          Confirmed
        </button>
      </div>
    </div>
  );
};

export default SuccessClientModal;
