import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";

const AssetsPie = () => {
  const chartData = {
    series: [44, 55, 41, 17],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              color: "#ffffff",
              fontSize: "20px",
              fontFamily: "Sora, sans-serif",
              fontWeight: 700,
            },
            value: {
              show: true,

              color: "#BEE1FB",
              formatter: function (val: string) {
                return "$" + val;
              },
            },
          },
        },
      },
    },

    options: {
      chart: {
        type: "donut",
      },
      labels: ["BTC", "USDT", "NAIRA", "Others"],
      colors: ["#F28A0F", "#279DF3", "#03C668", "#4E606E"],
      stroke: {
        width: 0, // This removes the border
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              markers: {
                fillColors: ["#F28A0F", "#279DF3", "#03C668", "#4E606E"], // Add your desired colors here
              },
            },
          },
        },
      ],
      legend: {
        position: "bottom",
        markers: {
          fillColors: ["#F28A0F", "#279DF3", "#03C668", "#4E606E"], // Add your desired colors here
        },
        fontSize: "12px",
        fontFamily: "Manrope, sans-serif",
        fontWeight: 500,
        labels: {
          colors: "#ffffff",
          useSeriesColors: false,
        },
      },
    },
  };
  return (
    <div className="w-full ">
      <ReactApexChart
        options={chartData.options as ApexOptions}
        series={chartData.series}
        type="donut"
        height={300}
      />
    </div>
  );
};

export default AssetsPie;
