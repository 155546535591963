import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { API_URL } from "../../constant";
import { useAuth } from "../../context/auth-context";
import { useInView } from "react-intersection-observer";
import { TailSpin } from "react-loader-spinner";
import { noTransaction } from "../../assets/images";

const MessageHistory = () => {
  const { token } = useAuth();
  const [historyLoader, setHistoryLoader] = useState<boolean>(false);
  const [histories, setHistories] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const divRef = useRef<HTMLDivElement | null>(null);
  const [ref, inView] = useInView();

  useEffect(() => {
    setHistoryLoader(true);
    axios
      .get(`${API_URL}api/notifications/bc?page=${page}&limit=5`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        if (r.data.data.history.length >= 1) {
          const newHistories = [...histories, ...r.data.data.history];
          setHistories(newHistories);
        }
        setTotalPages(r.data.data.meta.totalPages);
        setHistoryLoader(false);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
        setHistoryLoader(false);
      });
  }, [page, token]);

  useEffect(() => {
    if (inView) {
      if (page < totalPages) {
        setPage((prev) => prev + 1);
      }
    }
  }, [inView, totalPages]);

  function formatDate(timestamp: string | number | Date) {
    const now = new Date();
    const date = new Date(timestamp);

    const isToday = date.toDateString() === now.toDateString();
    const isYesterday =
      date.toDateString() ===
      new Date(now.setDate(now.getDate() - 1)).toDateString();

    if (isToday) {
      return "Today " + formatTime(date);
    } else if (isYesterday) {
      return "Yesterday " + formatTime(date);
    } else {
      return formatDateOnly(date) + " , " + formatTime(date);
    }
  }

  function formatTime(date: Date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  function formatDateOnly(date: Date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  return (
    <div
      ref={divRef}
      className="w-full  pt-4 pb-4  lgss:h-[420px]  overflow-auto scrollbar-thumb-[#AEBCC6]    scrollbar-thin"
    >
      {histories.length >= 1 ? (
        <>
          <div className="w-full">
            {histories.map((history, index) => {
              const formattedDate = formatDate(history.createdAt);
              return (
                <div className={"mt-4 "} key={index}>
                  <div className="text-[13px] flex justify-start items-center gap-2 font-medium  text-gray-300">
                    {history.title}
                  </div>
                  <h5 className="text-[13px] font-medium mt-3 text-[#617889]">
                    {history.content}
                  </h5>
                  <h5 className="text-[11px] font-bold mt-4 text-gray-300">
                    {formattedDate}
                  </h5>
                  <div className=" h-[1px] bg-gray-600 w-full rounded-full mt-4" />
                </div>
              );
            })}

            <div ref={ref} className=""></div>
          </div>
        </>
      ) : (
        !historyLoader && (
          <div className="flex flex-col justify-center mt-16 lgss:mt-24 items-center">
            <img src={noTransaction} alt="" />
            <h4 className="font-bold text-[#869AA9] pt-2">No history</h4>
            <p className=" text-[#869AA9] pt-2 text-[12px]">
              All your messages will show up here
            </p>
          </div>
        )
      )}
      {historyLoader && (
        <div className="mt-6 flex justify-center items-center">
          <TailSpin color="#0B75C2" height={50} width={70} />
        </div>
      )}
    </div>
  );
};

export default MessageHistory;
