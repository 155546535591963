import React, { useEffect, useState } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { RiErrorWarningFill } from "react-icons/ri";
import { useAuth } from "../../../context/auth-context";
import axios from "axios";
import { API_URL } from "../../../constant";
import {
  validateBEP20Address,
  validateERC20Address,
  validateTRC20Address,
} from "../../../utils/ValidateWallet";

const UsdtWithdraw = ({
  usdtValue,
  withdrawAmountUSDT,
  setWithdrawAmountUSDT,
  selectedValueUSDT,
  setSelectedValueUSDT,
  walletAddressUSDT,
  setWalletAddressUSDT,
  setWithdrawModal,
  setConfirmModal,
}: any) => {
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { token } = useAuth();
  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedValueUSDT(event.target.value);
  };
  const isNumber = (str: any) => {
    if (str.trim() === "") {
      return false;
    }

    return !isNaN(str);
  };
  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNumber(value)) {
      if (percentage) setPercentage(0);
      setWithdrawAmountUSDT(Number(value));
    } else {
      setWithdrawAmountUSDT("");
    }
  };

  const validateForm = (withdraw: number | string, walletAddress: string) => {
    if (!walletAddress) {
      setError("Kindly add your wallet address");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (selectedValueUSDT === "BSC") {
      if (!validateBEP20Address(walletAddress)) {
        setError("Invalid BEP-20 address");
        setTimeout(() => {
          setError("");
        }, 3000);
        return false;
      }
    }
    if (selectedValueUSDT === "ETH") {
      if (!validateERC20Address(walletAddress)) {
        setError("Invalid ERC-20 address");
        setTimeout(() => {
          setError("");
        }, 3000);
        return false;
      }
    }
    if (selectedValueUSDT === "TRX") {
      if (!validateTRC20Address(walletAddress)) {
        setError("Invalid TRC-20 address");
        setTimeout(() => {
          setError("");
        }, 3000);
        return false;
      }
    }
    if (typeof usdtValue === "undefined") {
      setError("Kindly top up your wallet");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (!withdraw) {
      setError("Kindly add amount you want to withdraw");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (withdraw > usdtValue) {
      setError("Kindly top up your wallet and try again ");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }

    setError("");
    return true;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm(withdrawAmountUSDT, walletAddressUSDT)) {
      return;
    }

    if (!loading) {
      axios
        .post(
          `${API_URL}api/users/me/request-otp`,
          {
            purpose: "transaction",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          // console.log(r.data.data.transactions);
          setConfirmModal(true);
          setLoading(false);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
          setLoading(false);
          setError(e.response.data.message);
          setTimeout(() => {
            setError("");
          }, 2000);
        });
    }
  };
  const [percentage, setPercentage] = useState<number>(0);
  useEffect(() => {
    if (typeof usdtValue !== "undefined") {
      if (percentage === 1) {
        setWithdrawAmountUSDT((25 / 100) * usdtValue);
      } else if (percentage === 2) {
        setWithdrawAmountUSDT((50 / 100) * usdtValue);
      } else if (percentage === 3) {
        setWithdrawAmountUSDT(usdtValue);
      } else if (usdtValue === 0) {
        setWithdrawAmountUSDT(0);
      }
    }
  }, [usdtValue, percentage]);
  return (
    <div className=" w-full">
      <div className="mt-4 w-full flex justify-between items-center px-4">
        <h4 className="text-[15px] font-bold text-[#E59C27]">
          Bal:{" "}
          {usdtValue !== null
            ? usdtValue.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "0.00"}{" "}
          USDT
        </h4>
        <div className="flex justify-end gap-4 items-center">
          <button
            className={
              percentage === 1
                ? "text-16px text-[#279DF3]"
                : "text-16px text-[#095B97]"
            }
            onClick={() => setPercentage(1)}
          >
            25%
          </button>
          <button
            className={
              percentage === 2
                ? "text-16px text-[#279DF3]"
                : "text-16px text-[#095B97]"
            }
            onClick={() => setPercentage(2)}
          >
            50%
          </button>
          <button
            className={
              percentage === 3
                ? "text-16px text-[#279DF3]"
                : "text-16px text-[#095B97]"
            }
            onClick={() => setPercentage(3)}
          >
            Max
          </button>
        </div>
      </div>
      <form action="" className=" p-4" onSubmit={handleSubmit}>
        <select
          value={selectedValueUSDT}
          onChange={handleChange}
          className="w-full  mt-2 text-[14px]  font-medium flex justify-center items-center gap-2 text-[#A0A9AD] outline-none bg-[#1C2227] border rounded-xl px-4  border-[#141414] border-opacity-20 h-[50px]"
        >
          {/* <option className="font-semibold ">
           {optionValue === "USDT"
             ? "Binance Smart Chain - (BEP20)"
             : optionValue === "BTC"
             ? "Bitcoin Network"
             : null}
         </option> */}
          <option value="BSC">Binance Smart Chain - (BEP-20)</option>
          <option value="ETH">Etherium Chain - (ERC-20)</option>
          <option value="TRX">TRON Chain - (TRC-20)</option>
        </select>
        <div className="mt-4">
          <label className="text-white text-[14px] font-bold">How much:</label>
          <input
            type="number"
            value={withdrawAmountUSDT}
            onChange={handleAmountChange}
            className="w-full spin-button-none mt-2 placeholder:text-[13px] font-medium text-[#A0A9AD] outline-none bg-[#1C2227] border rounded-xl px-4  border-[#141414] border-opacity-20 h-[50px]"
          />
        </div>
        <div className="mt-4">
          <label className="text-white text-[14px] font-bold">
            Wallet address:
          </label>
          <input
            type="tel"
            value={walletAddressUSDT}
            onChange={(e) => setWalletAddressUSDT(e.target.value)}
            placeholder="Enter your wallet address "
            className="w-full spin-button-none mt-2 placeholder:text-[13px] font-medium text-[#A0A9AD] outline-none bg-[#1C2227] border rounded-xl px-4  border-[#141414] border-opacity-20 h-[50px]"
          />
        </div>
        {error && (
          <div className="bg-red-600 text-white text-[12px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-bold mt-2">
            <BiErrorCircle className="text-xl" />
            {error}
          </div>
        )}
        <div className="w-full  mt-2  font-medium flex justify-start text-[14px] items-center gap-2 text-[#A0A9AD] outline-none bg-[#1C2227] border rounded-xl px-4  border-[#141414] border-opacity-20 py-4">
          <RiErrorWarningFill className="text-[#0B75C2] text-xl" />
          Make sure you add the correct address here
        </div>
        <button
          type="submit"
          className="w-full flex justify-center items-center mt-8 h-[52px] rounded-xl font-medium text-white text-[16px] bg-[#0B84CB] "
        >
          Confirm
        </button>
      </form>
    </div>
  );
};

export default UsdtWithdraw;
