import axios from "axios";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { noTransaction } from "../../assets/images";
import { API_URL } from "../../constant";
import { useAuth } from "../../context/auth-context";

const Referrals = ({ customerId }: any) => {
  const { token } = useAuth();
  const [record, setRecord] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}api/users/${customerId}/referrals`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        // console.log(r.data.data);
        setRecord(r.data.data.records);
        setLoading(false);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
        setLoading(false);
      });
  }, [customerId, token]);
  return (
    <div className="w-full">
      {loading ? (
        <div className=" flex mt-10 justify-center items-start">
          <TailSpin color="#0B75C2" height={50} width={70} />
        </div>
      ) : record.length >= 1 ? (
        <div className="w-full">
          <div className="bg-[#192025]  overflow-auto scrollbar-thumb-[#60B7F6] scrollbar-track-white  scrollbar-thin cursor-pointer lgss:flex lgss:flex-col w-full h-[612px] ">
            <table className="w-full">
              <thead>
                <tr className=" border-b border-[#3A4852] mt-8 pb-6  flex justify-start px-10 items-center ">
                  <th className="text-[#617889] text-[12px] w-1/5 text-left whitespace-nowrap overflow-hidden  ">
                    Date
                  </th>
                  <th className="text-[#617889] text-[12px] w-3/5 text-left whitespace-nowrap overflow-hidden ">
                    Email
                  </th>
                  <th className="text-[#617889] text-[12px] w-1/5 text-right whitespace-nowrap overflow-hidden  mr-8">
                    Points
                  </th>
                </tr>
              </thead>
              {record.map((ref, index) => (
                <tbody key={index} className="w-full">
                  <tr className="py-6 w-full flex justify-start items-center px-10    ">
                    <td className="text-white text-[13px] w-1/5 text-left whitespace-nowrap overflow-hidden ">
                      {(() => {
                        const dateString = ref.createdAt;
                        const date = new Date(dateString);

                        const day = date.getDate();
                        const ordinalSuffix = (day: number) => {
                          switch (day) {
                            case 1:
                            case 21:
                            case 31:
                              return "st";
                            case 2:
                            case 22:
                              return "nd";
                            case 3:
                            case 23:
                              return "rd";
                            default:
                              return "th";
                          }
                        };

                        const formattedDate = `${date.toLocaleString(
                          "default",
                          {
                            month: "short",
                          }
                        )} ${day}${ordinalSuffix(
                          day
                        )}, ${date.getFullYear()} | ${date.toLocaleString(
                          "default",
                          {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          }
                        )}`;

                        return formattedDate;
                      })()}
                    </td>
                    <td className="text-white  text-[13px] w-3/5 text-left whitespace-nowrap overflow-hidden">
                      {ref.referred.email}
                    </td>
                    <td className="text-white  text-[13px] w-1/5 text-right whitespace-nowrap overflow-hidden">
                      {ref.points}
                    </td>
                  </tr>
                  <tr className="w-full">
                    <td className="mt-1 w-full h-[1px] bg-[#3A4852]" />
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center h-[550px] items-center">
          <img src={noTransaction} alt="" />

          <h4 className="font-bold text-[#869AA9] pt-2">No recent referrals</h4>
          <p className=" text-[#869AA9] pt-2 text-[12px]">
            All user referrals will show up here
          </p>
        </div>
      )}
    </div>
  );
};

export default Referrals;
