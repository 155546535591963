import React, { useEffect } from "react";
import { useAuth } from "../../../context/auth-context";
import axios from "axios";
import { API_URL } from "../../../constant";

const Fees = ({
  setCryptoFeeModal,
  setGiftcardFeeModal,
  setWalletFeeModal,
  previousSellValue,
  setPreviousSellValue,
  previousSellType,
  setPreviousSellType,
  previousSellInternalType,
  setPreviousSellInternalType,
  previousSellExternalType,
  setPreviousSellExternalType,
  previousSellInternalValue,
  setPreviousSellInternalValue,
  previousSellExternalValue,
  setPreviousSellExternalValue,
  cryptoRefetch,
  setPreviousGiftcardFee,
  setPreviousGiftcardType,
  previousGiftcardFee,
  previousGiftcardType,
}: any) => {
  const { token } = useAuth();
  useEffect(() => {
    axios
      .get(
        `${API_URL}api/fees`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r: any) => {
        if (r.data.data.fees !== null) {
          if (r.data.data.fees.crypto) {
            setPreviousSellValue(r.data.data.fees?.crypto.sell.value);
            setPreviousSellType(r.data.data.fees?.crypto.sell.type);
            setPreviousSellInternalType(
              r.data.data.fees?.crypto.sendInternal.type
            );
            setPreviousSellInternalValue(
              r.data.data.fees?.crypto.sendInternal.value
            );
            setPreviousSellExternalType(
              r.data.data.fees?.crypto.sendExternal.type
            );
            setPreviousSellExternalValue(
              r.data.data.fees?.crypto.sendExternal.value
            );
          }
          if (r.data.data.fees.giftCard) {
            setPreviousGiftcardFee(r.data.data.fees?.giftCard.sell.value);
            setPreviousGiftcardType(r.data.data.fees?.giftCard.sell.type);
          }
        }
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, [token, cryptoRefetch]);
  return (
    <div className="px-8 mds:px-12 pb-16 lgss:px-20 pt-10 lgss:pt-16 font-manrope">
      <h4 className="font-medium text-[16px]">Current Fees</h4>
      <div className="flex flex-col lgss:flex-row items-start justify-start lgss:items-center gap-6 mt-8 w-full">
        <div className="w-[30%] h-[52px]">
          <h4 className="font-bold text-[14px]">Crypto trading</h4>
          <button
            onClick={() => {
              setCryptoFeeModal((prev: any) => !prev);
              //   setOrderModal((prev: any) => !prev);
            }}
            className="text-[#78C2F7] font-medium text-[15px]"
          >
            Edit
          </button>
        </div>
        <div className="lgss:w-[70%] w-full h-[52px] grid grid-cols-3 justify-center mt-4 items-center gap-4">
          <div className="h-full rounded-lg bg-[#1C2227] flex justify-between px-2 gap-2 lgss:px-4  items-center">
            <h4 className="font-medium text-[13px] lgss:text-[15px] text-[#A0A9AD]">
              Sell
            </h4>
            {previousSellType ? (
              <h4 className="font-medium text-[13px] lgss:text-[15px] text-[#A0A9AD]">
                {previousSellType === "PERCENTILE"
                  ? `${previousSellValue}%`
                  : previousSellType === "FIXED"
                  ? `$${previousSellValue}`
                  : ""}
              </h4>
            ) : (
              "0"
            )}
          </div>
          <div className="h-full rounded-lg bg-[#1C2227] flex justify-between px-2 gap-2 lgss:px-4  items-center">
            <h4 className="font-medium text-[13px] lgss:text-[15px] text-[#A0A9AD]">
              Send (Internal)
            </h4>
            {previousSellInternalType ? (
              <h4 className="font-medium text-[13px] lgss:text-[15px] text-[#A0A9AD]">
                {previousSellInternalType === "PERCENTILE"
                  ? `${previousSellInternalValue}%`
                  : previousSellInternalType === "FIXED"
                  ? `$${previousSellInternalValue}`
                  : ""}
              </h4>
            ) : (
              "0"
            )}
          </div>
          <div className="h-full rounded-lg bg-[#1C2227] flex justify-between  px-2 gap-2 lgss:px-4 items-center">
            <h4 className="font-medium text-[13px] lgss:text-[15px] text-[#A0A9AD]">
              Send (External)
            </h4>
            {previousSellExternalType ? (
              <h4 className="font-medium text-[13px] lgss:text-[15px] text-[#A0A9AD]">
                {previousSellExternalType === "PERCENTILE"
                  ? `${previousSellExternalValue}%`
                  : previousSellExternalType === "FIXED"
                  ? `$${previousSellExternalValue}`
                  : ""}
              </h4>
            ) : (
              "0"
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col lgss:flex-row items-start justify-start lgss:items-center gap-6 mt-12 w-full">
        <div className="w-[30%] h-[52px]">
          <h4 className="font-bold text-[14px]">Giftcard trading</h4>
          <button
            onClick={() => {
              setGiftcardFeeModal((prev: any) => !prev);
              //   setOrderModal((prev: any) => !prev);
            }}
            className="text-[#78C2F7] font-medium text-[15px]"
          >
            Edit
          </button>
        </div>
        <div className="lgss:w-[70%] w-full h-[52px] grid grid-cols-1 lgss:grid-cols-3 mt-4 justify-center items-center gap-4">
          <div className="h-full rounded-lg bg-[#1C2227] flex justify-between px-4 items-center">
            <h4 className="font-medium text-[15px] text-[#A0A9AD]">Sell</h4>
            {previousGiftcardType ? (
              <h4 className="font-medium text-[15px] text-[#A0A9AD]">
                {" "}
                {previousGiftcardType === "PERCENTILE"
                  ? `${previousGiftcardFee}%`
                  : previousGiftcardType === "FIXED"
                  ? ` ₦${previousGiftcardFee}`
                  : ""}
              </h4>
            ) : (
              "0"
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col lgss:flex-row items-start justify-start lgss:items-center gap-6 mt-12 w-full">
        <div className="w-[30%] h-[52px]">
          <h4 className="font-bold text-[14px]">Bills</h4>
          <button
            onClick={() => {
              //   setCryptoFeeModal((prev: any) => !prev);
            }}
            className="text-[#78C2F7] font-medium text-[15px]"
          >
            Edit
          </button>
        </div>
        <div className="lgss:w-[70%] w-full h-[52px] grid grid-cols-3 justify-center items-center gap-4">
          <div className="h-[52px] rounded-lg bg-[#1C2227] flex justify-between lgss:px-4 px-2 gap-2 items-center">
            <h4 className="font-medium text-[13px] lgss:text-[15px] text-[#A0A9AD]">
              Airtime
            </h4>
            <h4 className="font-medium text-[13px] lgss:text-[15px] text-[#A0A9AD]">
              $0
            </h4>
          </div>
          <div className="h-[52px] rounded-lg bg-[#1C2227] flex justify-between lgss:px-4 px-2 gap-2 items-center">
            <h4 className="font-medium text-[13px] lgss:text-[15px] text-[#A0A9AD]">
              Data
            </h4>
            <h4 className="font-medium text-[13px] lgss:text-[15px] text-[#A0A9AD]">
              $0
            </h4>
          </div>
          <div className="h-[52px] rounded-lg bg-[#1C2227] flex justify-between lgss:px-4 px-2 gap-2 items-center">
            <h4 className="font-medium text-[13px] lgss:text-[15px] text-[#A0A9AD]">
              TV
            </h4>
            <h4 className="font-medium text-[13px] lgss:text-[15px] text-[#A0A9AD]">
              $0
            </h4>
          </div>
          <div className="h-[52px] rounded-lg bg-[#1C2227] flex justify-between lgss:px-4 px-2 gap-2 items-center">
            <h4 className="font-medium text-[13px] lgss:text-[15px] text-[#A0A9AD]">
              Electricity
            </h4>
            <h4 className="font-medium text-[13px] lgss:text-[15px] text-[#A0A9AD]">
              $0
            </h4>
          </div>
        </div>
      </div>
      <div className="flex flex-col lgss:flex-row items-start justify-start lgss:items-center gap-6 mt-28  w-full">
        <div className="w-[30%] h-[52px]">
          <h4 className="font-bold text-[14px]">Wallet Withdrawal</h4>
          <button
            onClick={() => {
              //   setWalletFeeModal((prev: any) => !prev);
              //   setOrderModal((prev: any) => !prev);
            }}
            className="text-[#78C2F7] font-medium text-[15px]"
          >
            Edit
          </button>
        </div>
        <div className="lgss:w-[70%] w-full h-[52px] grid grid-cols-1 lgss:grid-cols-3 mt-4 justify-center items-center gap-4">
          <div className="h-full rounded-lg bg-[#1C2227] flex justify-between px-4 items-center">
            <h4 className="font-medium text-[15px] text-[#A0A9AD]">Fiat</h4>
            <h4 className="font-medium text-[15px] text-[#A0A9AD]">₦0</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fees;
