import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../constant";
import { useAuth } from "../../context/auth-context";
import { FaTimesCircle } from "react-icons/fa";
import CopyToClipboard from "react-copy-to-clipboard";

interface Bank {
  accountNumber: string;
  bankCode: string;
  accountName: string;
  isDefault: boolean;
  _id: string;
}
const GiftcardConfirmation = ({
  currency,
  currencyUSD,
  status,
  date,
  setGiftcardModal,
  currencyNGN,
}: any) => {
  const { payoutUserId, token } = useAuth();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [banks, setBanks] = useState<Bank | null>(null);
  const [allBanks, setAllBanks] = useState<any[]>([]);
  const [copyMail, setCopyMail] = useState<boolean>(false);
  const [copyAccount, setCopyAccount] = useState<boolean>(false);
  const [copyAmount, setCopyAmount] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get(`${API_URL}api/users/${payoutUserId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        // console.log(r.data.data);
        setEmail(r.data.data.user.email.address);
        setFirstName(r.data.data.user.profile.firstName);
        setLastName(r.data.data.user.profile.lastName);
        const bankList: Bank[] = r.data.data.user?.banks || [];

        // Find the bank with isDefault set to true
        const defaultBank =
          bankList.find((bank: Bank) => bank.isDefault) || null;
        setBanks(defaultBank);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, [token, payoutUserId]);

  useEffect(() => {
    axios
      .get(`${API_URL}api/banks`)
      .then((r: any) => {
        setAllBanks(r.data.data);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, []);
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-40 backdrop-blur-sm">
      <div className="w-11/12 mds:w-10/12 lgss:w-2/5 transition-all p-6 ease-in duration-1000  rounded-xl bg-[#0D0D0D] ">
        <div className="flex  pt-4 justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Giftcard Payout review
          </h4>
          <FaTimesCircle
            onClick={() => setGiftcardModal((prev: any) => !prev)}
            className="text-white text-[32px] cursor-pointer"
          />
        </div>
        <div className="w-full flex-col mt-12 justify-center   items-center ">
          <h4 className="text-[48px] text-[#DEE1E6] text-center font-bold font-sora ">
            $
            {currencyUSD.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h4>
          <h4 className="text-[24px] text-[#DEE1E6] text-center font-bold font-sora ">
            {firstName} {lastName}
          </h4>
        </div>

        <div className="w-full h-[1px] bg-[#AEBCC6] mt-12 mb-4" />
        <div className="w-full mt-2 flex justify-between items-center">
          <h4 className="text-[#AEBCC6] text-[16px] font-semibold">
            Digital Asset
          </h4>
          <h4 className="text-[#869AA9] text-[14px] mds:text-[16px] ">
            {currency} giftcard
          </h4>
        </div>

        <div className="w-full mt-3 flex justify-between items-center">
          <h4 className="text-[#AEBCC6] text-[16px] font-semibold">
            Recipient address
          </h4>
          <CopyToClipboard
            text={email}
            onCopy={() => {
              setCopyMail(true);
              setTimeout(() => {
                setCopyMail(false);
              }, 3000);
            }}
          >
            {copyMail ? (
              <h4 className="text-[12px] xs:text-[14px] text-[#869AA9]">
                Copied
              </h4>
            ) : (
              <h4 className="text-[#0C84DA] text-[14px] cursor-pointer">
                {email}
              </h4>
            )}
          </CopyToClipboard>
        </div>
        <div className="w-full mt-3 flex justify-between items-center">
          <h4 className="text-[#AEBCC6] text-[16px] font-semibold">
            Amount paid
          </h4>
          <CopyToClipboard
            text={currencyNGN}
            onCopy={() => {
              setCopyAmount(true);
              setTimeout(() => {
                setCopyAmount(false);
              }, 3000);
            }}
          >
            {copyAmount ? (
              <h4 className="text-[12px] xs:text-[13px] text-[#869AA9]">
                Copied
              </h4>
            ) : (
              <h4 className="text-[#869AA9] text-[16px] cursor-pointer">
                ₦{" "}
                {currencyNGN.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h4>
            )}
          </CopyToClipboard>
        </div>
        <div className="w-full mt-3 flex justify-between items-center">
          <h4 className="text-[#AEBCC6] text-[16px] font-semibold">
            Cash destination
          </h4>
          <CopyToClipboard
            text={banks ? banks?.accountNumber : ""}
            onCopy={() => {
              setCopyAccount(true);
              setTimeout(() => {
                setCopyAccount(false);
              }, 3000);
            }}
          >
            {copyAccount ? (
              <h4 className="text-[12px] xs:text-[14px] text-[#869AA9]">
                Copied
              </h4>
            ) : (
              <div className="text-[#0C84DA] cursor-pointer flex gap-2 justify-center items-center font-bold text-[13px] ">
                {banks && banks.accountNumber}{" "}
                {allBanks
                  .filter((allBank) => allBank.code === banks?.bankCode)
                  .map((bank, index) => (
                    <h5 key={index} className="text-[#869AA9]">
                      {bank.name}
                    </h5>
                  ))}
              </div>
            )}
          </CopyToClipboard>
        </div>
        <div className="w-full mt-3 flex justify-between items-center">
          <h4 className="text-[#AEBCC6] text-[16px] font-semibold">Status</h4>
          <h4
            className={
              status === "PENDING"
                ? "text-[#CB9200] flex justify-center items-center w-[90px] bg-[#181818] tracking-wider h-[26px] rounded-full text-[11px] font-bold"
                : status === "IN PROGRESS"
                ? "text-[#60B7F6] flex justify-center items-center w-[90px] bg-[#181818] tracking-wider h-[26px] rounded-full text-[11px] font-bold"
                : status === "COMPLETED"
                ? "text-[#03C668] flex justify-center items-center w-[90px] bg-[#0E2B1F] tracking-wider h-[26px] rounded-full text-[11px] font-bold"
                : ""
            }
          >
            {status}
          </h4>
        </div>
        <div className="w-full mt-3 flex justify-between items-center">
          <h4 className="text-[#AEBCC6] text-[16px] font-semibold">Date</h4>
          <h4 className="text-[#869AA9] text-[16px] ">{date}</h4>
        </div>
        <div className="flex flex-col w-full gap-4 mt-8">
          <a
            href={`mailto:${email}`}
            className="flex justify-center items-center h-[47px] rounded-lg border bg-transparent border-[#0B84CB] text-white font-medium "
          >
            Contact the client
          </a>
        </div>
      </div>
    </div>
  );
};

export default GiftcardConfirmation;
