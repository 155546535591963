import axios from "axios";
import React, { useState } from "react";
import { API_URL } from "../../../constant";
import { useAuth } from "../../../context/auth-context";
import { BiErrorCircle } from "react-icons/bi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { TailSpin } from "react-loader-spinner";

const GiftcardReview = ({
  setGiftcardReviewModal,
  setGiftcardFeeModal,
  sellGiftcardType,
  sellGiftcardFee,
  setCryptoRefetch,
}: any) => {
  const { token } = useAuth();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const updateGiftcardFee = () => {
    if (!loading) {
      setLoading(true);
      axios
        .patch(
          `${API_URL}api/fees/gift-card`,
          {
            value: sellGiftcardFee,
            type: sellGiftcardType,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          // console.log(r.data);
          setLoading(false);
          setSuccess("Giftcard fee updated successfully");
          setCryptoRefetch((prev: number) => prev + 1);
          setTimeout(() => {
            setSuccess("");
            setGiftcardReviewModal((prev: any) => !prev);
          }, 1500);
        })
        .catch((e: any) => {
          setError(e.response.data.message);
          setTimeout(() => {
            setError("");
          }, 1500);
          setLoading(false);
          console.log(e.response.data.message);
        });
    }
  };
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-60 backdrop-blur-sm">
      <div className="w-10/12 mds:w-[60%] md:w-2/5 lgss:w-1/3  px-8 pt-8   rounded-xl bg-[#1F1F1F] pb-4">
        <div className="flex   justify-between items-center">
          <h4 className="font-bold text-[18px] font-sora text-white">
            Giftcard fee review
          </h4>
          <button
            onClick={() => {
              setGiftcardReviewModal((prev: any) => !prev);
              //   setCryptoFeeModal((prev: any) => !prev);
            }}
            className="text-[#78C2F7] font-medium text-[13px]  cursor-pointer"
          >
            {" "}
            Close
          </button>
        </div>
        {success && (
          <div className="bg-green-600 w-full text-white  text-[14px] rounded-xl justify-start items-center gap-4 flex h-[50px] px-2 font-semibold mt-4 ">
            <AiOutlineCheckCircle className="text-3xl text-white" />
            {success}
          </div>
        )}
        <h4 className="text-center font-medium text-[16px] mt-6 text-[#A0A9AD]">
          Are you sure you want to confirm
          <br />
          this fee changes?
        </h4>
        <div className="w-full mt-4">
          <div className="w-full mt-2 flex justify-between items-center text-[#A0A9AD] bg-[#1C2227] px-4  h-[48px]  rounded-lg border border-[#141414]">
            <h4 className="font-medium text-[15px] text-[#A0A9AD]">Sell</h4>
            <h4 className="font-medium text-[15px] text-[#A0A9AD]">
              {sellGiftcardType === "PERCENTILE"
                ? `${sellGiftcardFee}%`
                : sellGiftcardType === "FIXED"
                ? `₦${sellGiftcardFee}`
                : ""}
            </h4>
          </div>
        </div>
        {error && (
          <div className="bg-red-600 text-white mb-4 text-[14px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-semibold mt-4">
            <BiErrorCircle className="text-xl" />
            {error}
          </div>
        )}
        <div className="w-full justify-center flex gap-4 mt-10 items-center">
          <button
            onClick={updateGiftcardFee}
            className="text-white font-medium flex justify-center items-center text-[16px]  h-[48px] w-1/2 bg-secondary_button rounded-lg"
          >
            {loading ? (
              <TailSpin color="#FFFFFF" height={30} width={30} />
            ) : (
              "Confirm"
            )}
          </button>
          <button
            onClick={() => {
              setGiftcardReviewModal((prev: any) => !prev);
              setGiftcardFeeModal((prev: any) => !prev);
            }}
            className="text-secondary_button font-medium text-[16px]  h-[48px] w-1/2 bg-transparent border border-secondary_button rounded-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default GiftcardReview;
