import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/auth-context";
import axios from "axios";
import { API_URL } from "../../constant";
import { FaTimesCircle } from "react-icons/fa";
import CopyToClipboard from "react-copy-to-clipboard";

type Props = {
  date: string;
  setUserActivities: any;

  kycLevel: string;
  setCustomerModal: any;
};
interface Bank {
  accountNumber: string;
  bankCode: string;
  accountName: string;
  isDefault: boolean;
  _id: string;
}
const CustomerModal = ({
  date,

  setUserActivities,

  kycLevel,
  setCustomerModal,
}: Props) => {
  const { customerId, token } = useAuth();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [banks, setBanks] = useState<Bank | null>(null);
  const [bankAvail, setBankAvail] = useState<boolean>(false);
  const [copyMail, setCopyMail] = useState<boolean>(false);
  const [copyAccount, setCopyAccount] = useState<boolean>(false);
  const [copyNumber, setCopyNumber] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [allBanks, setAllBanks] = useState<any[]>([]);

  useEffect(() => {
    axios
      .get(`${API_URL}api/users/${customerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        // console.log(r.data.data);
        setEmail(r.data.data.user.email.address);
        setFirstName(r.data.data.user.profile.firstName);
        setLastName(r.data.data.user.profile.lastName);
        const bankList: Bank[] = r.data.data.user?.banks || [];

        // Find the bank with isDefault set to true
        const defaultBank =
          bankList.find((bank: Bank) => bank.isDefault) || null;
        setBanks(defaultBank);

        if (
          !r.data.data.user.bankDetails ||
          (typeof r.data.data.user.bankDetails === "object" &&
            Object.keys(r.data.data.user.bankDetails).length === 0)
        ) {
          setBankAvail(true);
        }
        if (r.data.data.user.phone.number) {
          setPhoneNumber(r.data.data.user.phone?.number);
        }
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, [token, customerId]);

  useEffect(() => {
    axios
      .get(`${API_URL}api/banks`)
      .then((r: any) => {
        setAllBanks(r.data.data);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, []);
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-80 backdrop-blur-sm">
      <div className="w-11/12 lgss:w-2/5 transition-all p-6 ease-in duration-1000  rounded-xl bg-[#0D0D0D] ">
        <div className="flex  pt-4 justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Contact details
          </h4>
          <FaTimesCircle
            onClick={() => setCustomerModal((prev: any) => !prev)}
            className="text-white text-[32px] cursor-pointer"
          />
        </div>
        <div className="w-full flex-col mt-8 justify-center   items-center ">
          <div className="text-[36px] mx-auto mb-4 w-[95px] uppercase h-[95px] bg-[#032843] rounded-full text-[#0C84DA] flex justify-center items-center font-bold font-sora ">
            {firstName.charAt(0)}
            {lastName.charAt(0)}
          </div>
          <h4 className="text-[24px] text-[#DEE1E6] capitalize text-center font-bold font-sora ">
            {firstName} {lastName}
          </h4>
        </div>

        <div className="w-full h-[1px] bg-[#AEBCC6] mt-12 mb-4" />

        <div className="w-full mt-4 flex justify-between items-center">
          <h4 className="text-[#AEBCC6] text-[16px] font-semibold">
            Recipient address
          </h4>
          <CopyToClipboard
            text={email}
            onCopy={() => {
              setCopyMail(true);
              setTimeout(() => {
                setCopyMail(false);
              }, 3000);
            }}
          >
            {copyMail ? (
              <h4 className="text-[12px] xs:text-[14px] text-[#869AA9]">
                Copied
              </h4>
            ) : (
              <h4 className="text-[#0C84DA] text-[14px] cursor-pointer">
                {email}
              </h4>
            )}
          </CopyToClipboard>
        </div>
        <div className="w-full mt-4 flex justify-between items-center">
          <h4 className="text-[#AEBCC6] text-[16px] font-semibold">
            Phone Number
          </h4>
          {phoneNumber ? (
            <CopyToClipboard
              text={phoneNumber}
              onCopy={() => {
                setCopyNumber(true);
                setTimeout(() => {
                  setCopyNumber(false);
                }, 3000);
              }}
            >
              {copyNumber ? (
                <h4 className="text-[12px] xs:text-[14px] text-[#869AA9]">
                  Copied
                </h4>
              ) : (
                <h4 className="text-[#869AA9] text-[16px] cursor-pointer">
                  {phoneNumber}
                </h4>
              )}
            </CopyToClipboard>
          ) : (
            <h4 className="text-[#0C84DA] text-[14px] ">Not Added</h4>
          )}
        </div>

        <div className="w-full mt-4 flex justify-between items-center">
          <h4 className="text-[#AEBCC6] text-[16px] font-semibold">
            Cash destination
          </h4>
          {bankAvail ? (
            <h4 className="text-[#AEBCC6] text-[16px] font-medium">
              Not Added
            </h4>
          ) : (
            <CopyToClipboard
              text={banks ? banks?.accountNumber : ""}
              onCopy={() => {
                setCopyAccount(true);
                setTimeout(() => {
                  setCopyAccount(false);
                }, 3000);
              }}
            >
              {copyAccount ? (
                <h4 className="text-[12px] xs:text-[14px] text-[#869AA9]">
                  Copied
                </h4>
              ) : (
                <div className="text-[#0C84DA] cursor-pointer flex gap-2 justify-center items-center font-bold text-[13px] ">
                  {banks && banks.accountNumber}{" "}
                  {allBanks
                    .filter((allBank) => allBank.code === banks?.bankCode)
                    .map((bank, index) => (
                      <h5 key={index} className="text-[#869AA9]">
                        {bank.name}
                      </h5>
                    ))}
                </div>
              )}
            </CopyToClipboard>
          )}
        </div>
        <div className="w-full mt-4 flex justify-between items-center">
          <h4 className="text-[#AEBCC6] text-[16px] font-semibold">
            KYC Status
          </h4>
          <h4 className="text-[#60B7F6] whitespace-nowrap ml-12 flex items-center justify-center bg-[#3A4852] w-[90px] h-[25px] rounded-full text-[11px] font-bold">
            {kycLevel}
          </h4>
        </div>
        <div className="w-full mt-4 flex justify-between items-center">
          <h4 className="text-[#AEBCC6] text-[16px] font-semibold">
            Last login
          </h4>
          <h4 className="text-[#869AA9] text-[16px] ">{date}</h4>
        </div>
        <div className="flex flex-col w-full gap-4 mt-12">
          <a
            href={`mailto:${email}`}
            className="flex justify-center items-center h-[47px] rounded-lg border bg-transparent border-[#0B84CB] text-white font-medium "
          >
            Contact the client
          </a>
          <button
            onClick={() => {
              setCustomerModal((prev: any) => !prev);

              setUserActivities(true);
            }}
            className="flex justify-center items-center   text-[#AEBCC6] font-semibold "
          >
            See user activities
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerModal;
