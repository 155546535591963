import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { RiErrorWarningFill, RiH4 } from "react-icons/ri";
import { BiExport } from "react-icons/bi";
import CryptoOrders from "./CryptoOrders";
import OrderConfrimation from "./OrderConfrimation";
import CryptoConfirmation from "./CryptoConfirmation";
import { useAuth } from "../../context/auth-context";
import CryptoPayoutConfirmation from "../payouts/CryptoConfirmation";
import GiftcardConfirmation from "../payouts/GiftcardConfirmation";
import Payouts from "../payouts/Crypto";
import { API_URL, PROD_LINK, STAGING_LINK } from "../../constant";
import GiftcardModal from "./GiftcardModal";
import Confirmation from "./Confirmation";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { FaArrowCircleRight, FaTimesCircle } from "react-icons/fa";
import ResolveModal from "./ResolveModal";
import ConfirmResolveModal from "./ConfirmResolveModal";
import SuccessModal from "./SuccessModal";
import { getDateRange } from "../../utils/GetDateRange";
import { CSVLink } from "react-csv";

const Orders = () => {
  const {
    cryptoModal,
    token,
    setUrlStatus,
    urlStatus,
    filter,
    transactionId,
    setTransactionId,
    logout,
  } = useAuth();
  // const [active, setActive] = useState<boolean>(false);
  const [transacion, setTransaction] = useState<boolean>(false);

  const [currency, setCurrency] = useState<string>("");
  const [status, setStatus] = useState<any | number>();
  const [network, setNetwork] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [tsxnId, setTsxnId] = useState<string>("");
  const [currencyUSD, setCurrencyUSD] = useState<any | number>();
  const [currencyNGN, setCurrencyNGN] = useState<any | number>();
  const [userId, setUserId] = useState<string>("");
  const [orderModal, setOrderModal] = useState<boolean>(false);
  const [payoutStatus, setPayoutStatus] = useState<boolean>(false);
  const [refetchCount, setRefetchCount] = useState<number>(0);
  const [cryptoConfirmationModal, setCryptoConfirmationModal] =
    useState<boolean>(false);

  const [cryptoRate, setCryptoRate] = useState<any | number>();

  const [currencyPayout, setCurrencyPayout] = useState<string>("");
  const [statusPayout, setStatusPayout] = useState<string>("");
  const [datePayout, setDatePayout] = useState<string>("");
  const [currencyUSDPayout, setCurrencyUSDPayout] = useState<any | number>();
  const [currencyNGNPayout, setCurrencyNGNPayout] = useState<any | number>();
  const [giftcardPayoutModal, setGiftcardPayoutModal] =
    useState<boolean>(false);

  const [imageURL, setImageURL] = useState<any[]>([]);
  const [orderGiftcardModal, setOrderGiftcardModal] = useState<boolean>(false);
  const [statusGiftcard, setStatusGiftcard] = useState<any | number>();
  const [dateGiftcard, setDateGiftcard] = useState<string>("");
  const [currencyUSDGiftcard, setCurrencyUSDGiftcard] = useState<
    any | number
  >();
  const [currencyNGNGiftcard, setCurrencyNGNGiftcard] = useState<
    any | number
  >();
  const [banks, setBanks] = useState<any[]>([]);

  const [userIdGiftcard, setUserIdGiftcard] = useState<string>("");
  const [txnId, setTxnId] = useState<string>("");
  const [cardType, setCardType] = useState<any[]>([]);
  const [confirmed, setConfirmed] = useState<boolean>();
  const [confirmedModal, setConfirmedModal] = useState<boolean>(false);
  const [ecode, setEcode] = useState<string>("");
  const [autoPay, setAutoPay] = useState<string>("");
  const [payLoader, setPayLoader] = useState<boolean>(false);
  const [refetchCounter, setRefetchCounter] = useState<number>(0);
  const [orderss, setOrderss] = useState<any[]>([]);
  const [summaryUsdt, setSummaryUsdt] = useState<any | number>();
  const [summaryNaira, setSummaryNaira] = useState<any | number>();
  const [totalCount, setTotalCount] = useState<any | number>();
  const [walletCount, setWalletCount] = useState<any | number>();
  const [cryptoCount, setCryptoCount] = useState<any | number>();
  const [giftcardCount, setGiftcardCount] = useState<any | number>();
  const [failedCount, setFailedCount] = useState<any | number>();
  const [resolveModal, setResolveModal] = useState<boolean>(false);
  const [resolveConfirmModal, setResolveConfirmModal] =
    useState<boolean>(false);
  const [resolveSuccessModal, setResolveSuccessModal] =
    useState<boolean>(false);
  const [refetchCounting, setRefetchCounting] = useState<number>(0);
  const [selectedPayouts, setSelectedPayouts] = useState<any[]>([]);
  const { current } = getDateRange(filter);
  const [newOrders, setNewOrders] = useState<any[]>([]);
  const [newPayout, setNewPayout] = useState<any[]>([]);

  useEffect(() => {
    setPayLoader(true);
    axios
      .get(`${API_URL}api/settings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        setAutoPay(r.data.data.autoPayStatus);

        setPayLoader(false);
      })
      .catch((e: any) => {
        setPayLoader(false);
        console.log(e.response.data.message);
      });
  }, [token, refetchCounter]);

  const handleChange = () => {
    if (autoPay === "inactive") {
      if (!payLoader) {
        setPayLoader(true);
        axios
          .patch(
            `${API_URL}api/settings/autopay-status`,
            {
              status: "active",
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((r: any) => {
            setRefetchCounter((prev) => prev + 1);
            setPayLoader(false);
            // .location.reload();
          })
          .catch((e: any) => {
            console.log(e.response.data.message);
          });
      }
    } else {
      if (!payLoader) {
        setPayLoader(true);
        axios
          .patch(
            `${API_URL}api/settings/autopay-status`,
            {
              status: "inactive",
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((r: any) => {
            setRefetchCounter((prev) => prev + 1);
            setPayLoader(false);
            // window.location.reload();
          })
          .catch((e: any) => {
            console.log(e.response.data.message);
          });
      }
    }
  };

  useEffect(() => {
    axios
      .get(
        `${API_URL}${
          filter === ""
            ? `api/payouts`
            : `api/payouts?from=${current.from}&to=${current.to}`
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r: any) => {
        if (r.data.data.payouts.length >= 1) {
          setSummaryUsdt(r.data.data.summary.usdAmount);
          setSummaryNaira(r.data.data.summary.amount);
          setTotalCount(r.data.data.summary.totalCount);
          setCryptoCount(r.data.data.summary.cryptoCount);
          setGiftcardCount(r.data.data.summary.giftCardCount);
          setWalletCount(r.data.data.summary.walletCount);
          setFailedCount(r.data.data.summary.failedPayoutCount);
        } else {
          setSummaryUsdt(0);
          setSummaryNaira(0);
          setTotalCount(0);
          setCryptoCount(0);
          setGiftcardCount(0);
          setWalletCount(0);
          setFailedCount(0);
        }
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
        if (
          e.response.status === 401 &&
          e.response.data.message === "Inavlid token. Please login again!"
        ) {
          logout();
        }
      });
  }, [token, refetchCounting, filter, current.from, current.to, logout]);

  useEffect(() => {
    setTimeout(() => {
      axios
        .get(`${API_URL}api/banks`)
        .then((r: any) => {
          setBanks(r.data.data);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
        });
    }, 2000);
  }, []);

  const payoutData = newPayout.map((payout) => ({
    userName: `${payout.user.profile.firstName} ${payout.user.profile.lastName}`,
    assetName: `${payout.asset.name}`,
    transactionType: "Sell",
    assetAmountInUSD: `$${payout.asset.amountInUSD.toFixed(2)}`,
    transactionId: `${payout._id}`,
    transactionDate: (() => {
      const dateString = payout.createdAt;
      const date = new Date(dateString);

      const day = date.getDate();
      const ordinalSuffix = (day: number) => {
        switch (day) {
          case 1:
          case 21:
          case 31:
            return "st";
          case 2:
          case 22:
            return "nd";
          case 3:
          case 23:
            return "rd";
          default:
            return "th";
        }
      };

      const formattedDate = `${date.toLocaleString("default", {
        month: "short",
      })} ${day}${ordinalSuffix(
        day
      )}, ${date.getFullYear()} | ${date.toLocaleString("default", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })}`;

      return formattedDate;
    })(),

    transactionStatus: `${payout.status}`,
  }));

  const orderData = newOrders.map((order) => ({
    userName: `${order.userDetails.profile.firstName} ${order.userDetails.profile.lastName}`,
    assetPurchased: `${
      order.transactionType === "gift-card-transactions"
        ? order.card.name
        : order.currency
    }`,
    transactionType: "Sell",
    assetPurchasedValue: `${
      order.transactionType === "gift-card-transactions"
        ? order.tradeValue
            .reduce(
              (total: any, item: { amount: any }) => total + item.amount,
              0
            )
            .toLocaleString("en-US")
        : order?.usdtAmount?.toFixed(2)
    }`,
    transactionId: `${order._id}`,
    transactionDate: (() => {
      const dateString = order.createdAt;
      const date = new Date(dateString);

      const day = date.getDate();
      const ordinalSuffix = (day: number) => {
        switch (day) {
          case 1:
          case 21:
          case 31:
            return "st";
          case 2:
          case 22:
            return "nd";
          case 3:
          case 23:
            return "rd";
          default:
            return "th";
        }
      };

      const formattedDate = `${date.toLocaleString("default", {
        month: "short",
      })} ${day}${ordinalSuffix(
        day
      )}, ${date.getFullYear()}, ${date.toLocaleString("default", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })}`;

      return formattedDate;
    })(),
    transactionStatus: `${
      order.transactionType === "crypto-deposit" ? "COMPLETED" : order.status
    }`,
  }));

  return (
    <div className="w-full">
      <div className="w-full h-[31px] bg-[#1B1B1B] flex justify-center items-center">
        {API_URL?.includes("staging") ? (
          <h4 className="font-medium text-[#AEBCC6] text-[12px]">
            You are in Staging:{" "}
            <span>
              <a href={PROD_LINK} className="text-[#03C668]">
                Switch to Prod
              </a>
            </span>
          </h4>
        ) : (
          <h4 className="font-medium text-[#AEBCC6] text-[12px]">
            You are in PROD:{" "}
            <span>
              <a href={STAGING_LINK} className="text-[#DF4E4E]">
                Switch to Staging
              </a>
            </span>
          </h4>
        )}
      </div>
      <div className="w-full lgss:flex lgss:flex-row  h-screen">
        <Sidebar />
        <div className="w-full lgss:w-10/12 h-full overflow-auto bg-[#13181B]">
          <Navbar />
          <div className="w-full px-5 h-full pt-16 mds:px-12 font-manrope lgss:px-10 pb-24 bg-[#13181B]">
            <div
              className={
                failedCount > 0 && !transactionId
                  ? "w-full  mds:w-10/12 md:w-[70%] lgss:w-2/5  rounded-t-xl bg-[#1C2227] p-4 lgss:p-6"
                  : "w-full mds:w-10/12 md:w-[70%] lgss:w-2/5  rounded-xl bg-[#1C2227] p-4 lgss:p-6"
              }
            >
              <h4 className="text-[10px] xs:text-[12px] font-bold text-[#E7EBEE]">
                Total payout
              </h4>
              <h4 className="font-sora font-bold text-[24px] text-[#BEE1FB] mt-2">
                $
                {summaryUsdt
                  ? summaryUsdt.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0}
              </h4>
              <h4 className="font-sora font-bold text-[12px]  text-[#869AA9] mt-2">
                ₦
                {summaryNaira
                  ? summaryNaira.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0}
              </h4>
              <div className="w-full mt-8 flex gap-2 justify-center items-center">
                {[
                  { count: cryptoCount, color: "#5EAE68", label: "Crypto" },
                  { count: giftcardCount, color: "#BE6B0A", label: "Giftcard" },
                  { count: walletCount, color: "#869AA9", label: "Wallet" },
                ].map(({ count, color, label }) => {
                  const percentage = totalCount
                    ? (count / totalCount) * 100
                    : 0;
                  const displayPercentage = percentage.toFixed(1);
                  const barWidth = `${Math.max(percentage, 1)}%`; // Ensure minimum width
                  return (
                    <div
                      key={label}
                      style={{ width: barWidth }}
                      className="min-w-[13%]"
                    >
                      <div
                        className="px-2 w-full flex justify-start items-center text-[12px] rounded-[4px] h-[24px] text-[#E8F3FC]"
                        style={{ backgroundColor: color }}
                      >
                        {displayPercentage}%
                      </div>
                      <h4 className="text-[11px] mt-1 text-[#869AA9] text-left">
                        {label}
                      </h4>
                    </div>
                  );
                })}
              </div>
            </div>
            {failedCount > 0 && !transactionId ? (
              <div className="w-full mds:w-10/12 md:w-[70%] lgss:w-2/5 rounded-b-xl bg-[#3A4852] px-4 lgss:px-6 py-4">
                <div className="flex justify-between items-center w-full">
                  <div className="flex justify-start gap-2 items-start">
                    <RiErrorWarningFill className="text-[#DF4E4E] text-[18px]" />
                    <div>
                      <h4 className="font-bold text-[12px] text-[#E7EBEE]">
                        Some payouts failed
                      </h4>
                      <h4 className=" mt-2 text-[12px] text-[#869AA9]">
                        You have {failedCount} payout
                        {failedCount > 1 ? "s" : ""} uncompleted.
                      </h4>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      setUrlStatus("FAILED");
                      setTransaction(true);
                    }}
                    className="text-white px-4 py-1 rounded-lg text-[12px] bg-secondary_button"
                  >
                    Review
                  </button>
                </div>
              </div>
            ) : null}
            <div className="w-full flex justify-between items-center mt-20">
              <h4 className="text-white font-sora font-semibold text-[12px]">
                Activity
              </h4>
              <div className="flex gap-4 items-center">
                <div className="flex items-center gap-2">
                  {payLoader ? (
                    <TailSpin color="#0B75C2" height={20} width={20} />
                  ) : (
                    <div
                      onClick={handleChange}
                      className={`flex w-8  cursor-pointer h-4   rounded-full transition-all duration-500 ${
                        autoPay === "active"
                          ? "bg-secondary_button"
                          : "bg-gray-600"
                      }`}
                    >
                      <span
                        className={`h-4 w-4   rounded-full transition-all duration-500 bg-gray-100 ${
                          autoPay === "active" ? "ml-4" : ""
                        }`}
                      ></span>
                    </div>
                  )}
                  <h4 className="uppercase font-sora font-semibold text-[#869AA9] text-[10px]">
                    Auto Pay:{" "}
                    <span
                      className={autoPay === "active" ? "text-[#359943]" : ""}
                    >
                      {autoPay === "active" ? " ON" : " OFF"}
                    </span>
                  </h4>
                </div>
                <h4 className="text-[#3A4852]">|</h4>
                <CSVLink
                  data={transacion ? payoutData : orderData}
                  filename={transacion ? "payout-list" : `order-list`}
                  className="flex items-center justify-center  gap-2"
                >
                  <BiExport className="text-[white]" />
                  <h4 className="uppercase font-sora font-semibold mt-1 text-[#869AA9] text-[10px]">
                    Export
                  </h4>
                </CSVLink>
              </div>
            </div>
            <div className="bg-[#0D0D0D] w-full flex justify-between items-center pr-12 h-[67px] mt-4">
              <div className="w-1/2 h-full flex justify-start items-center">
                <button
                  onClick={() => {
                    setUrlStatus("");
                    setTransaction(false);
                  }}
                  className={
                    !transacion
                      ? "border-secondary_button h-full px-6 text-[16px] font-semibold text-secondary_button border-b-2 "
                      : " h-full px-6 text-[14px]  text-[#C6D0D7]  "
                  }
                >
                  Orders
                </button>
                <button
                  onClick={() => setTransaction(true)}
                  className={
                    transacion
                      ? "border-secondary_button h-full px-6 text-[16px] font-semibold text-secondary_button border-b-2 "
                      : " h-full px-6 text-[14px]  text-[#C6D0D7]  "
                  }
                >
                  Payouts
                </button>
              </div>
              {urlStatus === "FAILED" && (
                <button
                  onClick={() => setResolveModal(true)}
                  className="px-4 py-2 rounded-xl gap-2 bg-secondary_button text-white text-[15px] justify-center items-center flex"
                >
                  <h4>Resolve all</h4>
                  <FaArrowCircleRight className="text-[11px]" />
                </button>
              )}
              {transactionId && (
                <button>
                  <FaTimesCircle
                    onClick={() => setTransactionId("")}
                    className="text-secondary_button text-[24px] cursor-pointer"
                  />
                </button>
              )}
            </div>
            <div>
              {!transacion ? (
                <CryptoOrders
                  setOrderModal={setOrderModal}
                  setUserId={setUserId}
                  setTsxnId={setTsxnId}
                  setCurrency={setCurrency}
                  setCurrencyUSD={setCurrencyUSD}
                  setCurrencyNGN={setCurrencyNGN}
                  setStatus={setStatus}
                  setPayoutStatus={setPayoutStatus}
                  setNetwork={setNetwork}
                  setDate={setDate}
                  refetchCount={refetchCount}
                  setCryptoRate={setCryptoRate}
                  status={status}
                  payoutStatus={payoutStatus}
                  setOrderGiftcardModal={setOrderGiftcardModal}
                  setImageURL={setImageURL}
                  setStatusGiftcard={setStatusGiftcard}
                  setDateGiftcard={setDateGiftcard}
                  setCurrencyUSDGiftcard={setCurrencyUSDGiftcard}
                  setCurrencyNGNGiftcard={setCurrencyNGNGiftcard}
                  currencyNGNGiftcard={currencyNGNGiftcard}
                  setUserIdGiftcard={setUserIdGiftcard}
                  setTxnId={setTxnId}
                  setCardType={setCardType}
                  setEcode={setEcode}
                  setOrderss={setOrderss}
                  setNewOrders={setNewOrders}
                />
              ) : (
                <Payouts
                  setStatus={setStatusPayout}
                  setCurrencyUSD={setCurrencyUSDPayout}
                  setCurrencyNGN={setCurrencyNGNPayout}
                  setCurrency={setCurrencyPayout}
                  setDate={setDatePayout}
                  setGiftcardPayoutModal={setGiftcardPayoutModal}
                  setNewPayout={setNewPayout}
                />
              )}
            </div>
          </div>
        </div>
        {orderModal ? (
          <OrderConfrimation
            currency={currency}
            currencyUSD={currencyUSD}
            currencyNGN={currencyNGN}
            status={status}
            network={network}
            date={date}
            tsxnId={tsxnId}
            userId={userId}
            setOrderModal={setOrderModal}
            payoutStatus={payoutStatus}
            setRefetchCount={setRefetchCount}
            setCryptoConfirmationModal={setCryptoConfirmationModal}
            cryptoRate={cryptoRate}
            orderss={orderss}
          />
        ) : null}
        {cryptoConfirmationModal && (
          <CryptoConfirmation
            setOrderModal={setOrderModal}
            tsxnId={tsxnId}
            setRefetchCount={setRefetchCount}
            setCryptoConfirmationModal={setCryptoConfirmationModal}
            currency={currency}
            currencyUSD={currencyUSD}
            currencyNGN={currencyNGN}
            userId={userId}
          />
        )}
        {cryptoModal ? (
          <CryptoPayoutConfirmation
            currency={currencyPayout}
            currencyUSD={currencyUSDPayout}
            currencyNGN={currencyNGNPayout}
            status={statusPayout}
            date={datePayout}
          />
        ) : null}
        {giftcardPayoutModal ? (
          <GiftcardConfirmation
            currency={currencyPayout}
            currencyUSD={currencyUSDPayout}
            currencyNGN={currencyNGNPayout}
            status={statusPayout}
            date={datePayout}
            setGiftcardModal={setGiftcardPayoutModal}
          />
        ) : null}

        {orderGiftcardModal ? (
          <GiftcardModal
            setOrderModal={setOrderGiftcardModal}
            userId={userIdGiftcard}
            currencyUSD={currencyUSDGiftcard}
            currencyNGN={currencyNGNGiftcard}
            status={statusGiftcard}
            imageURL={imageURL}
            cardType={cardType}
            date={dateGiftcard}
            txnId={txnId}
            confirmed={confirmed}
            setConfirmed={setConfirmed}
            ecode={ecode}
            setConfirmedModal={setConfirmedModal}
          />
        ) : null}
        {confirmedModal && (
          <Confirmation
            setConfirmedModal={setConfirmedModal}
            setOrderModal={setOrderGiftcardModal}
            txnId={txnId}
            userId={userIdGiftcard}
            currencyUSD={currencyUSDGiftcard}
            currencyNGN={currencyNGNGiftcard}
            cardType={cardType}
            setConfirmed={setConfirmed}
            setRefetchCount={setRefetchCount}
          />
        )}
        {resolveModal && (
          <ResolveModal
            setResolveModal={setResolveModal}
            setResolveConfirmModal={setResolveConfirmModal}
            selectedPayouts={selectedPayouts}
            setSelectedPayouts={setSelectedPayouts}
          />
        )}
        {resolveConfirmModal && (
          <ConfirmResolveModal
            setResolveConfirmModal={setResolveConfirmModal}
            setResolveSuccessModal={setResolveSuccessModal}
            selectedPayouts={selectedPayouts}
          />
        )}
        {resolveSuccessModal && (
          <SuccessModal
            setResolveSuccessModal={setResolveSuccessModal}
            setRefetchCounting={setRefetchCounting}
          />
        )}
      </div>
    </div>
  );
};

export default Orders;
