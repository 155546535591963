import React, { useEffect, useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import CryptoRate from "./CryptoRate";
import PointRate from "./PointRate";
import GiftcardRateFlow from "./GiftcardRateFlow";
import CardModal from "./CardModal";
import UploadCardImage from "../UploadCardImage";
import ExpandAdd from "./ExpandAdd";

interface MyData {
  _id: string;
  name: string;
  thumbnail: string;
}

const NewRateFlow = ({ setUploadGiftcard, rateScreen, setRateScreen }: any) => {
  const [cardModal, setCardModal] = useState<boolean>(false);
  const [cardId, setCardId] = useState<string>("");
  const [allGiftcards, setAllGiftcards] = useState<any[]>([]);
  const [activeCard, setActiveCard] = useState<any>();
  const [uploadCardImage, setUploadCardImage] = useState<boolean>(false);
  const [addGiftcard, setAddGiftcard] = useState<boolean>(false);
  const [cardDetails, setCardDetails] = useState<MyData | null>(null);
  const [refetchCount, setRefetchCount] = useState(0);

  useEffect(() => {
    if (rateScreen === 2) setCardModal(true);
    else setCardModal(false);
  }, [rateScreen]);

  return (
    <div className="w-full pt-8 text-white font-manrope pb-8 h-screen px-6 lgss:px-12  bg-[#13181B]">
      <div className="flex justify-end items-center">
        <FaTimesCircle
          onClick={() => {
            setUploadGiftcard(false);
          }}
          className="text-[#78C2F7] text-[24px] cursor-pointer"
        />
      </div>
      <div className="mt-8">
        <div className="justify-start items-center flex gap-8">
          <button
            onClick={() => setRateScreen(1)}
            className={
              rateScreen === 1
                ? "border-b border-secondary_button text-secondary_button text-[18px] font-semibold h-[48px]"
                : " text-[#C6D0D7] text-[16px] font-medium h-[48px]"
            }
          >
            Crypto
          </button>
          <button
            onClick={() => setRateScreen(2)}
            className={
              rateScreen === 2
                ? "border-b border-secondary_button text-secondary_button text-[18px] font-semibold h-[48px]"
                : " text-[#C6D0D7] text-[16px] font-medium h-[48px]"
            }
          >
            Giftcard
          </button>
          <button
            onClick={() => setRateScreen(3)}
            className={
              rateScreen === 3
                ? "border-b border-secondary_button text-secondary_button text-[18px] font-semibold h-[48px]"
                : " text-[#C6D0D7] text-[16px] font-medium h-[48px]"
            }
          >
            Point
          </button>
        </div>
        <div className="w-full rounded-full h-[1px] bg-[#3A4852]" />
        <div className="w-full mt-8 pb-20">
          {rateScreen === 1 ? (
            <CryptoRate />
          ) : rateScreen === 2 ? (
            <GiftcardRateFlow
              setCardModal={setCardModal}
              cardId={cardId}
              allGiftcards={allGiftcards}
              setActiveCard={setActiveCard}
              activeCard={activeCard}
              setAddGiftcard={setAddGiftcard}
              cardDetails={cardDetails}
              setCardDetails={setCardDetails}
              setRefetchCount={setRefetchCount}
              refetchCount={refetchCount}
            />
          ) : rateScreen === 3 ? (
            <PointRate />
          ) : null}
        </div>
      </div>
      {cardModal && (
        <CardModal
          cardId={cardId}
          setCardId={setCardId}
          setAllGiftcards={setAllGiftcards}
          allGiftcards={allGiftcards}
          activeCard={activeCard}
          uploadCardImage={uploadCardImage}
          setUploadCardImage={setUploadCardImage}
          setCardModal={setCardModal}
        />
      )}
      {uploadCardImage && (
        <UploadCardImage
          setUploadCardImage={setUploadCardImage}
          setCardModal={setCardModal}
        />
      )}
      {addGiftcard && (
        <ExpandAdd
          setRefetchCount={setRefetchCount}
          setAddGiftcard={setAddGiftcard}
          cardDetails={cardDetails}
        />
      )}
    </div>
  );
};

export default NewRateFlow;
