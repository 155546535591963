import React, { useState } from "react";

const PointRate = () => {
  const [addPoint, setAddPoint] = useState<boolean>(false);
  return (
    <table className="w-full font-manrope">
      <thead className="bg-[#1C2227] h-[53px] ">
        <tr>
          <th className="text-[16px] text-[#617889] text-left p-3 pl-8">
            Name
          </th>
          <th className="text-[16px] text-[#617889] text-right p-3">
            Current Rate (₦)
          </th>
          <th className="text-[16px] text-[#617889] text-center p-3">
            Add Rate
          </th>
        </tr>
      </thead>
      <tbody className=" bg-[#0D0D0D]">
        <tr>
          <td className="p-4 text-left  text-[16px] pl-8 ">Points (1)</td>
          <td className="p-4 text-right  text-[16px] ">10</td>
          {addPoint ? (
            <td className="p-3 text-right w-2/12">
              <div className="flex justify-end items-center gap-2">
                <input
                  type="text"
                  autoFocus
                  className="w-[100px] border outline-none bg-transparent rounded-lg border-gray-600 h-[32px] text-[14px] px-2"
                />
                <button className="w-[100px] h-[32px] rounded-lg bg-secondary_button text-white text-[14px] ">
                  Confirm
                </button>
              </div>
            </td>
          ) : (
            <td
              className="p-3 text-center text-[16px] cursor-pointer"
              onClick={() => setAddPoint((prev) => !prev)}
            >
              Add
            </td>
          )}
        </tr>
      </tbody>
    </table>
  );
};

export default PointRate;
