/* eslint-disable no-param-reassign */
import React from "react";
import { CookiesProvider } from "react-cookie";
// import {Cookies, useCookies} from 'react-cookie';
// import toast from 'react-hot-toast';
// import {Provider, CachePolicies} from 'use-http';

import { AuthProvider } from "./auth-context";
// import {UserProvider} from './user-context';
interface Props {
  children: React.ReactNode;
}
// import isJwtExpired from 'constants/isJwtExpired';

const AppProviders: React.FC<Props> = ({ children }) => {
  // const [, , removeCookies] = useCookies(['token']);

  return (
    <CookiesProvider>
      <AuthProvider>{children}</AuthProvider>
    </CookiesProvider>
  );
};

export default AppProviders;
