import React, { useEffect, useState } from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import { useAuth } from "../../context/auth-context";
import axios from "axios";
import { API_URL } from "../../constant";
import { BiErrorCircle } from "react-icons/bi";
import { TailSpin } from "react-loader-spinner";

const ResolveModal = ({
  setResolveModal,
  setResolveConfirmModal,
  selectedPayouts,
  setSelectedPayouts,
}: any) => {
  const { failedPayouts, token } = useAuth();
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  // Function to handle radio button selection
  const handleSelectPayout = (payoutId: any) => {
    // Check if the payout ID is already selected
    const index = selectedPayouts.indexOf(payoutId);

    // If the payout ID is not in the array, add it; otherwise, remove it
    if (index === -1) {
      setSelectedPayouts([...selectedPayouts, payoutId]);
    } else {
      const updatedPayouts = [...selectedPayouts];
      updatedPayouts.splice(index, 1);
      setSelectedPayouts(updatedPayouts);
    }
  };
  const totalPayoutAmount = selectedPayouts.reduce(
    (total: any, payoutId: any) => {
      // Find the corresponding payout object
      const selectedPayout = failedPayouts.find(
        (payout: { _id: any }) => payout._id === payoutId
      );
      // If the payout object exists, add its payoutAmount to the total
      return selectedPayout ? total + selectedPayout.payoutAmount : total;
    },
    0
  );
  //   useEffect(() => {
  //     console.log(selectedPayouts);
  //   }, [selectedPayouts]);

  const getOtp = () => {
    if (!loading) {
      setLoading(true);
      axios
        .post(
          `${API_URL}api/users/me/request-otp`,
          {
            purpose: "transaction",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          // console.log(r.data.data.transactions);
          setResolveModal(false);
          setResolveConfirmModal(true);
          setLoading(false);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
          setLoading(false);
          setError(e.response.data.message);
          setTimeout(() => {
            setError("");
          }, 2000);
        });
    }
  };
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-60 backdrop-blur-sm">
      <div className="w-11/12 mds:w-10/12 lgss:w-[35%] transition-all p-6 ease-in duration-1000  rounded-xl bg-[#1F1F1F] ">
        <div className="flex  pt-4 justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Resolve Payouts
          </h4>
          <h4
            onClick={() => setResolveModal(false)}
            className="text-[#78C2F7] font-medium text-[14px] cursor-pointer"
          >
            Close
          </h4>
        </div>
        <div className="w-full mt-10 h-[350px]  overflow-auto">
          {failedPayouts.map((payout: any, index: any) => {
            return (
              <div
                key={index}
                className={`flex justify-between py-4 mt-2 px-4  ${
                  selectedPayouts.includes(payout._id)
                    ? "bg-[#1C2227]"
                    : "bg-transparent"
                } border border-[#141414] rounded-lg items-center`}
              >
                <div className="flex justify-start items-center gap-4">
                  <input
                    type="checkbox"
                    checked={selectedPayouts.includes(payout._id)}
                    onChange={() => handleSelectPayout(payout._id)}
                  />
                  <h4 className="text-[15px] text-white">
                    {payout.user.profile.firstName}{" "}
                    {payout.user.profile.lastName}
                  </h4>
                </div>
                <h4 className="text-[18px] text-white">
                  N
                  {payout.payoutAmount.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h4>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col justify-center items-center mt-8">
          <h4 className="text-white font-bold text-[14px]">Total</h4>
          <h4 className="text-[#A0A9AD] font-medium text-[36px] mt-2">
            N{" "}
            {selectedPayouts.length >= 1
              ? totalPayoutAmount.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0}
          </h4>
        </div>
        <div className="p-4 bg-[#FCF3E8] mt-4 w-full flex gap-2 justify-start items-start rounded-lg">
          <RiErrorWarningFill className="text-[30px] text-[#F6AD57]" />
          <h4 className="text-[14px] text-[#BE6B0A] font-medium">
            Make sure you haven’t paid manually. This action cannot be reverse.
            Alternately, you can resolve payment one after the other.
          </h4>
        </div>
        {error && (
          <div className="bg-red-600 text-white mb-4 text-[13px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-semibold mt-4">
            <BiErrorCircle className="text-xl" />
            {error}
          </div>
        )}
        <button
          onClick={getOtp}
          disabled={selectedPayouts.length === 0}
          className={
            selectedPayouts.length >= 1
              ? " w-full rounded-xl py-3 flex justify-center items-center text-white text-[16px] font-bold mt-6 bg-secondary_button"
              : " w-full rounded-xl py-3 flex justify-center items-center text-gray-200 text-[16px] font-bold mt-6 bg-gray-600"
          }
        >
          {loading ? (
            <TailSpin color="#FFFFFF" height={30} width={30} />
          ) : (
            "Confirm"
          )}
        </button>
      </div>
    </div>
  );
};

export default ResolveModal;
