import isJwtExpired from "../constant/isJwtExpired";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

import { ContextValueType, PropsType } from "../types/index";

// import isJwtExpired from 'constants/isJwtExpired';

export const AuthContext = createContext<ContextValueType>({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<PropsType> = ({ children }) => {
  const location = useLocation();
  const [, setCookie, removeCookie] = useCookies(["admin-token"]);
  const [token, setToken] = useState<string>("");
  const [userOpen, setUserOpen] = useState(false);
  const [orderOpen, setOrderOpen] = useState(false);
  const [activeStatus, setActiveStatus] = useState<number>(1);
  const [activeSetting, setActiveSetting] = useState<number>(1);
  // const [page, setPage] = useState<string>("/dashboard");
  const [payoutUserId, setPayoutUserId] = useState<string>("");
  const [customerId, setCustomerId] = useState<string>("");
  const [transactionId, setTransactionId] = useState<string>("");
  const [cryptoModal, setCryptoModal] = useState<boolean>(false);
  const [withdrawModal, setWithdrawModal] = useState<boolean>(false);
  const [uploadGiftcard, setUploadGiftcard] = useState<boolean>(false);
  const [isNotified, setIsNotified] = useState<boolean>(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [urlStatus, setUrlStatus] = useState<string>("");
  const [failedPayouts, setFailedpayouts] = useState<any[]>([]);
  const [openRange, setOpenRange] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");
  const [openButton, setOpenButton] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [userActivities, setUserActivities] = useState<boolean>(false);
  const [clientId, setClientId] = useState<string>("");
  const [customer, setCustomer] = useState<number>(1);

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("admin-token");

    if (!token || isJwtExpired(token)) {
      logout();
    } else {
      setToken(token);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      setActiveStatus(1);
    } else if (location.pathname === "/wallet") {
      setActiveStatus(2);
    } else if (location.pathname === "/users") {
      setActiveStatus(5);
    } else if (location.pathname === "/settings") {
      setActiveStatus(7);
    } else if (location.pathname === "/transactions") {
      setActiveStatus(8);
    } else if (location.pathname === "/reports") {
      setActiveStatus(10);
    } else if (location.pathname === "/tasks") {
      setActiveStatus(11);
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   if (!sellPage) {
  //     setCryptoPages(1);
  //   }
  // }, [sellPage]);

  const authenticate = async (token: string) => {
    try {
      setCookie("admin-token", token);
      setToken(token);
      return Promise.resolve("");
    } catch (error) {
      removeCookie("admin-token");
      return null;
    }
  };

  const logout = () => {
    removeCookie("admin-token");
    setToken("null");
  };
  return (
    <AuthContext.Provider
      value={{
        customer,
        setCustomer,
        clientId,
        setClientId,
        setTransactionId,
        transactionId,
        userActivities,
        setUserActivities,
        query,
        setQuery,
        openButton,
        setOpenButton,
        filter,
        setFilter,
        openRange,
        setOpenRange,
        failedPayouts,
        setFailedpayouts,
        urlStatus,
        setUrlStatus,
        isOpened,
        setIsOpened,
        isNotified,
        setIsNotified,
        uploadGiftcard,
        setUploadGiftcard,
        withdrawModal,
        setWithdrawModal,
        customerId,
        setCustomerId,
        payoutUserId,
        setPayoutUserId,

        cryptoModal,
        setCryptoModal,

        token,
        setToken,
        authenticate,
        logout,
        userOpen,
        setUserOpen,
        activeStatus,
        orderOpen,
        setOrderOpen,
        activeSetting,
        setActiveSetting,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
