import axios from "axios";
import React, { useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BiErrorCircle } from "react-icons/bi";
import { IoIosArrowDown } from "react-icons/io";
import { TailSpin } from "react-loader-spinner";
import { API_URL } from "../../constant";
import { useAuth } from "../../context/auth-context";

const CreateTask = ({ setOpenCreateTaskModal }: any) => {
  const { token } = useAuth();
  const [message, setMessage] = useState<string>("");
  const [devices, setDevices] = useState<string[]>([]);
  const [recipients, setRecipients] = useState<string>("");
  const [isChecked, setIsChecked] = useState(false);
  const [allDevicesChecked, setAllDevicesChecked] = useState(false);
  const [mobileChecked, setMobileChecked] = useState(false);
  const [webChecked, setWebChecked] = useState(false);
  const [error, setError] = useState<string>("");
  const [dropDown, setDropDown] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>("");
  const [taskName, setTaskName] = useState<string>("");

  const [points, setPoints] = useState<number | "">("");
  const handleTypeChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setTaskName(event.target.value);
  };

  const isNumber = (str: any) => {
    if (str.trim() === "") {
      return false;
    }

    return !isNaN(str);
  };

  const handlePointChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNumber(value)) {
      setPoints(Number(value));
    } else {
      setPoints("");
    }
  };

  const validate = (
    // devices: string[],
    recipients: string,
    message: string,
    points: number | string
  ) => {
    // if (devices.length === 0) {
    //   setError("Kindly select a device");
    //   setTimeout(() => {
    //     setError("");
    //   }, 3000);
    //   return false;
    // }
    if (!recipients) {
      setError("Kindly select recipients");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (!message) {
      setError("Kindly add the push message");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }

    if (!points) {
      setError("Kindly add the points for this task");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }

    setError("");
    return true;
  };
  const addDevice = (device: string) => {
    let updatedDevices: string[] = [];

    if (device === "all") {
      updatedDevices = ["all"];
      setAllDevicesChecked(true);
      setMobileChecked(false);
      setWebChecked(false);
    } else if (device === "mobile") {
      updatedDevices = ["mobile"];
      setAllDevicesChecked(false);
      setMobileChecked(true);
      setWebChecked(false);
    } else if (device === "web") {
      updatedDevices = ["web"];
      setAllDevicesChecked(false);
      setMobileChecked(false);
      setWebChecked(true);
    }

    setDevices(updatedDevices);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validate(recipients, message, points)) {
      return;
    }

    if (!loading) {
      setLoading(true);
      const data: { [key: string]: any } = {
        name: taskName,
        description: message,
        recipients: recipients,
        points: points,
      };
      if (isChecked) {
        const currentDateTime = new Date();
        const next24Hours = new Date(
          currentDateTime.getTime() + 24 * 60 * 60 * 1000
        );
        const isoTimestamp = next24Hours.toISOString();
        data.expiryDate = isoTimestamp;
      }
      axios
        .post(`${API_URL}api/tasks`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r: any) => {
          setLoading(false);
          setPoints("");
          setMessage("");
          setSuccess("Task created successfully");
          setTimeout(() => {
            setSuccess("");
            setOpenCreateTaskModal((prev: any) => !prev);
          }, 3000);
        })
        .catch((e: any) => {
          setError(e.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setError("");
          }, 6000);
          console.log(e.response.data.message);
        });
    }
  };
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-80 pr-10 backdrop-blur-sm">
      <div
        className={
          " w-11/12 lgss:w-1/3 transition-all p-6 ease-in duration-1000  rounded-xl bg-[#1F1F1F] h-[full]"
        }
      >
        <div className="flex   justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Create task
          </h4>
          <h4
            onClick={() => setOpenCreateTaskModal((prev: any) => !prev)}
            className="text-[#78C2F7] text-[14px] font-medium cursor-pointer"
          >
            Close
          </h4>
        </div>

        <form className="w-full mt-4" onSubmit={handleSubmit}>
          {success && (
            <div className="bg-green-600 w-full text-white  text-[16px] rounded-xl justify-start items-center gap-4 flex h-[60px] px-2 font-bold mt-4 mb-4">
              <AiOutlineCheckCircle className="text-3xl text-white" />
              {success}
            </div>
          )}
          <div className="w-full mt-4">
            <label className="text-[14px] font-bold text-white">
              Task name
            </label>
            <input
              value={taskName}
              type="text"
              placeholder="Enter the task name"
              onChange={handleTypeChange}
              className="w-full mt-2  text-[15px] font-medium text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]"
            />
          </div>
          <div className="w-full flex justify-between items-center mt-4 gap-2">
            <div className="w-1/2">
              <label className="text-[14px] font-bold text-white">
                Select Device
              </label>
              <div
                onClick={() => {
                  if (dropDown === 1) {
                    setDropDown(0);
                  } else {
                    setDropDown(1);
                  }
                }}
                className={
                  dropDown === 1
                    ? "flex  justify-between items-center w-full mt-2  capitalize font-bold text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-lg border border-secondary_button"
                    : "flex  justify-between items-center w-full mt-2  capitalize font-bold text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-lg border border-[#141414]"
                }
              >
                {devices.length >= 1 ? (
                  devices.map((device, index) => (
                    <h4 key={index} className="text-[12px]">
                      {device === "all"
                        ? "All devices"
                        : device === "web"
                        ? "Web App users only"
                        : device === "mobile"
                        ? "Mobile App users only"
                        : ""}
                    </h4>
                  ))
                ) : (
                  <h4>Choose</h4>
                )}

                <IoIosArrowDown
                  className={dropDown === 1 ? "text-secondary_button" : ""}
                />
              </div>
            </div>
            <div className="w-1/2">
              <label className="text-[14px] font-bold text-white">
                Select Recipient
              </label>
              <select
                value={recipients}
                onChange={(e) => setRecipients(e.target.value)}
                className="flex  justify-between items-center w-full mt-2  capitalize font-bold text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-lg border border-[#141414]"
              >
                <option value="">Choose</option>
                <option value="customers">Customers</option>
              </select>
            </div>
          </div>

          <div className="w-full ">
            <label className="text-[14px] font-bold text-white">Points</label>
            <input
              value={points}
              type="number"
              placeholder="Enter desired points"
              onChange={handlePointChange}
              className="w-full mt-2 capitalize font-medium text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]"
            />
          </div>

          <div className="w-full mt-2">
            <label className="text-[14px] font-bold text-white">Message</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type here..."
              className="w-full mt-2 text-[15px] tracking-wider placeholder:text-[15px]  text-[#A0A9AD] pt-2 bg-[#1C2227] px-4 outline-none h-[80px] rounded-xl border border-[#141414]"
            />
          </div>
          <div className="flex  justify-start mt-4 w-full items-center gap-4">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
              className="h-[20px] w-[20px]"
            />
            <h4 className="text-[16px] font-bold text-white">
              Expire after 24 hours
            </h4>
          </div>
          {error && (
            <div className="bg-red-600 text-white  text-[12px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-bold mt-4">
              <BiErrorCircle className="text-xl" />
              {error}
            </div>
          )}
          <button
            type="submit"
            className="w-full h-[46px] flex justify-center items-center font-manrope font-medium rounded-xl text-white bg-secondary_button mt-4 lgss:mt-4"
          >
            {loading ? (
              <TailSpin color="#FFFFFF" height={30} width={30} />
            ) : (
              "Send now"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateTask;
