import React, { useEffect, useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { useAuth } from "../../context/auth-context";
import CopyToClipboard from "react-copy-to-clipboard";
import Activities from "./Activities";
import { CSVLink } from "react-csv";
import axios from "axios";
import { API_URL } from "../../constant";
import { TailSpin } from "react-loader-spinner";
import { noTransaction } from "../../assets/images";
import UserDeatails from "./UserDeatails";
import { IoMdArrowDropdown } from "react-icons/io";
import {
  BsArrowLeftSquare,
  BsArrowRightSquare,
  BsPatchCheckFill,
} from "react-icons/bs";
import { MdArrowRight } from "react-icons/md";
import SendUserMessage from "./SendUserMessage";
import AwardPoints from "./AwardPoints";
import SuspendUser from "./SuspendUser";
import MakeInfluencer from "./MakeInfluencer";
import ReactivateUser from "./ReactivateUser";
import Tasks from "./task/Tasks";
import Rewards from "./Rewards";
import Referrals from "./Referrals";
import Kyc from "./Kyc";
import Info from "./Info";
import { BiExport } from "react-icons/bi";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";

const UserActivity = ({
  setUserActivities,

  kycLevel,
}: any) => {
  const { token, customerId, query } = useAuth();
  const [activeSetting, setActiveSetting] = useState<number>(7);
  const [totalPayout, setTotalPayout] = useState<any | number>();
  const [totalPoints, setTotalPoints] = useState<any | number>();
  const [autoPay, setAutoPay] = useState<string>("active");

  const [payouts, setPayouts] = useState<any[]>([]);
  const [newPayouts, setNewPayouts] = useState<any[]>([]);
  const [payoutLoader, setPayoutLoader] = useState<boolean>(false);
  const [openUserModal, setOpenUserModal] = useState<boolean>(false);
  const [openAwardModal, setOpenAwardModal] = useState<boolean>(false);
  const [openSuspendUserModal, setOpenSuspendUserModal] =
    useState<boolean>(false);
  const [openSendUserMessageModal, setOpenSendUserMessageModal] =
    useState<boolean>(false);
  const [openButton, setOpenButton] = useState<boolean>(false);
  const [copyMail, setCopyMail] = useState<boolean>(false);
  const [referralCount, setReferralCount] = useState<any | number>();
  const [makeInfluencerModal, setMakeInfluencerModal] =
    useState<boolean>(false);
  const [userStatus, setUserStatus] = useState<string>("");
  const [openReactivateModal, setOpenReactivateModal] =
    useState<boolean>(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUserName] = useState("");

  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const [userRefCode, setUserRefcode] = useState<string>("");
  const [kycStatus, setKycStatus] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [banks, setBanks] = useState<any[]>([]);

  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | "">("");
  const [sortOrderType, setSortOrderType] = useState<"asc" | "desc" | "">("");
  const [sortOrderDate, setSortOrderDate] = useState<"asc" | "desc" | "">("");
  const [sortOrderAmount, setSortOrderAmount] = useState<"asc" | "desc" | "">(
    ""
  );

  const [currentpage, setCurrentPage] = useState<number>();
  const [transactionCount, setTransactionCount] = useState<number>(1);
  const [tradeRange, setTradeRange] = useState({ min: "", max: "" });
  const [totalPages, setTotalPages] = useState<number>(1);
  const formatNumber = (number: number): string => {
    if (number >= 1e9) return (number / 1e9).toFixed(0) + "B";
    if (number >= 1e6) return (number / 1e6).toFixed(0) + "M";
    if (number >= 1e3) return (number / 1e3).toFixed(0) + "k";
    if (number >= 100) return (number / 100).toFixed(0) + "h";
    return number.toString();
  };
  useEffect(() => {
    setPayoutLoader(true);
    setTimeout(() => {
      axios
        .get(
          `${API_URL}api/payouts?userId=${customerId}&page=${page}&limit=25`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          setPayoutLoader(false);
          setPayouts(r.data.data.payouts);
          setTotalPages(r.data.data.meta.totalPages);
          setCurrentPage(r.data.data.meta.currentPage);
          setTransactionCount(r.data.data.meta.totalPayouts);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
        });
    }, 2000);
  }, [token, customerId, page]);
  useEffect(() => {
    setTimeout(() => {
      axios
        .get(
          `${API_URL}api/payouts?userid=${customerId}&limit=${transactionCount}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          setNewPayouts(r.data.data.payouts);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
        });
    }, 1400);
  }, [token, customerId, transactionCount]);

  useEffect(() => {
    axios
      .get(`${API_URL}api/users/${customerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        setEmail(r.data.data.user.email.address);
        setFirstName(r.data.data.user.profile.firstName);
        setLastName(r.data.data.user.profile.lastName);
        setUserName(r.data.data.user.profile.username);
        setBanks(r.data.data.user?.banks);
        setUserRefcode(r.data.data.user.referralCode);
        setKycStatus(r.data.data.user.kyc.status);
        setTotalPayout(r.data.data.summary.totalPayoutAmount);
        setTotalPoints(r.data.data.user.referralPointsBalance);
        setReferralCount(r.data.data.user.referralCount);
        setUserStatus(r.data.data.user.account.status);

        // if (
        //   !r.data.data.user.bankDetails ||
        //   (typeof r.data.data.user.bankDetails === "object" &&
        //     Object.keys(r.data.data.user.bankDetails).length === 0)
        // ) {
        //   setBankAvail(true);
        // }
        if (r.data.data.user.phone.number) {
          setPhoneNumber(r.data.data.user.phone?.number);
        }
        if (r.data.data.user.surveyDetails) {
          const surveyDetails = r.data.data.user?.surveyDetails;
          const min = surveyDetails.volumeRange.min;
          const max = surveyDetails.volumeRange.max;

          setTradeRange({
            min: formatNumber(min),
            max: formatNumber(max),
          });
        }
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, [token, customerId, openReactivateModal, openSuspendUserModal]);
  const payoutData = newPayouts.map((payout) => ({
    userName: `${payout.user.profile.firstName} ${payout.user.profile.lastName}`,
    assetName: `${payout.asset.name}`,
    transactionType: "Sell",
    assetAmountInUSD: `$${payout.asset.amountInUSD.toFixed(2)}`,
    transactionId: `${payout._id}`,
    transactionDate: (() => {
      const dateString = payout.createdAt;
      const date = new Date(dateString);

      const day = date.getDate();
      const ordinalSuffix = (day: number) => {
        switch (day) {
          case 1:
          case 21:
          case 31:
            return "st";
          case 2:
          case 22:
            return "nd";
          case 3:
          case 23:
            return "rd";
          default:
            return "th";
        }
      };

      const formattedDate = `${date.toLocaleString("default", {
        month: "short",
      })} ${day}${ordinalSuffix(
        day
      )}, ${date.getFullYear()} | ${date.toLocaleString("default", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })}`;

      return formattedDate;
    })(),

    transactionStatus: `${payout.status}`,
  }));

  useEffect(() => {
    if (sortOrder === "asc") {
      setSortedData(
        [...payouts].sort((a, b) => {
          const nameA = `${a.user.profile.firstName} ${a.user.profile.lastName}`;
          const nameB = `${b.user.profile.firstName} ${b.user.profile.lastName}`;
          return nameA.localeCompare(nameB);
        })
      );
    } else if (sortOrder === "desc") {
      setSortedData(
        [...payouts].sort((a, b) => {
          const nameA = `${a.user.profile.firstName} ${a.user.profile.lastName}`;
          const nameB = `${b.user.profile.firstName} ${b.user.profile.lastName}`;
          return nameB.localeCompare(nameA);
        })
      );
    } else {
      setSortedData(payouts);
    }
  }, [sortOrder, payouts]);
  useEffect(() => {
    if (sortOrderType === "asc") {
      setSortedData(
        [...payouts].sort((a, b) => {
          const nameA = `${a.asset.name}`;
          const nameB = `${b.asset.name}`;
          return nameA.localeCompare(nameB);
        })
      );
    } else if (sortOrderType === "desc") {
      setSortedData(
        [...payouts].sort((a, b) => {
          const nameA = `${a.asset.name}`;
          const nameB = `${b.asset.name}`;
          return nameB.localeCompare(nameA);
        })
      );
    } else {
      setSortedData(payouts);
    }
  }, [sortOrderType, payouts]);

  useEffect(() => {
    if (sortOrderAmount === "asc") {
      setSortedData(
        [...payouts].sort((a, b) => {
          const amountA = a.asset.amountInUSD.toFixed(2);
          const amountB = b.asset.amountInUSD.toFixed(2);
          return amountA - amountB;
        })
      );
    } else if (sortOrderAmount === "desc") {
      setSortedData(
        [...payouts].sort((a, b) => {
          const amountA = a.asset.amountInUSD.toFixed(2);
          const amountB = b.asset.amountInUSD.toFixed(2);
          return amountB - amountA;
        })
      );
    } else {
      setSortedData(payouts);
    }
  }, [sortOrderAmount, payouts]);

  useEffect(() => {
    if (sortOrderDate === "asc") {
      setSortedData(
        [...payouts].sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        )
      );
    } else if (sortOrderDate === "desc") {
      setSortedData(
        [...payouts].sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      );
    } else {
      setSortedData(payouts);
    }
  }, [sortOrderDate, payouts]);

  return (
    <div className="font-manrope w-full  h-full    bg-[#13181B] ">
      <div className="flex justify-end items-center">
        <FaTimesCircle
          onClick={() => {
            setUserActivities(false);
          }}
          className="text-[#78C2F7] text-[18px] cursor-pointer"
        />
      </div>
      <div className="flex justify-between items-center w-full mt-6">
        <div className="flex justify-start items-center gap-4">
          <div className="text-[24px] mx-auto  w-[60px] uppercase h-[60px] bg-[#032843] rounded-full text-[#0C84DA] flex justify-center items-center font-bold font-sora ">
            {firstName.charAt(0)}
            {lastName.charAt(0)}
          </div>
          <div>
            <h4 className="font-sora text-[20px] capitalize text-[#DEE1E6] font-extrabold">
              {firstName} {lastName}
            </h4>
            <CopyToClipboard
              text={email}
              onCopy={() => {
                setCopyMail(true);
                setTimeout(() => {
                  setCopyMail(false);
                }, 3000);
              }}
            >
              {copyMail ? (
                <h4 className="text-[12px] mt-2 xs:text-[14px] text-[#869AA9]">
                  Copied
                </h4>
              ) : (
                <h4 className="text-[#0C84DA]  text-[14px] font-medium  cursor-pointer">
                  {email}
                </h4>
              )}
            </CopyToClipboard>
          </div>
        </div>
        <div className="w-2/5 lgss:w-1/5 relative">
          <div className="flex w-full items-center justify-end">
            <button
              onClick={() => setOpenButton((prev) => !prev)}
              className="flex justify-between px-4 outline-none gap-4 h-[48px] rounded-xl w-2/3 items-center  text-[#095B97] bg-[#F7F8F9] font-medium text-[16px] "
            >
              Action
              {openButton ? (
                <MdArrowRight className="text-[24px]" />
              ) : (
                <IoMdArrowDropdown className="text-[24px]" />
              )}
            </button>
          </div>
          {openButton && (
            <div
              className={`w-full sidebar absolute bg-[#1C2227] mt-2 flex flex-col ${
                userStatus === "SUSPENDED" ? "h-[90px]" : "h-[230px]"
              }   rounded-xl`}
            >
              {/* <button
                onClick={() => {
                  setOpenButton((prev) => !prev);
                  setOpenUserModal(true);
                }}
                className="w-full hover:bg-[#273037] rounded-t-xl text-white text-[16px] font-bold h-[46px] flex justify-center items-center"
              >
                View Full profile
              </button> */}
              {userStatus === "ACTIVE" ? (
                <>
                  <button
                    onClick={() => {
                      setOpenButton((prev) => !prev);
                      setOpenSendUserMessageModal(true);
                    }}
                    className="w-full hover:bg-[#273037] text-white text-[16px] font-bold h-[46px] flex justify-center items-center"
                  >
                    Send message
                  </button>
                  <button
                    onClick={() => {
                      setOpenButton((prev) => !prev);
                      setOpenAwardModal(true);
                    }}
                    className="w-full hover:bg-[#273037] text-white text-[16px] font-bold h-[46px] flex justify-center items-center"
                  >
                    Award points
                  </button>
                  <button
                    onClick={() => {
                      setOpenButton((prev) => !prev);
                      setMakeInfluencerModal(true);
                    }}
                    className="w-full hover:bg-[#273037] text-white  text-[16px] font-bold h-[46px] flex justify-center items-center"
                  >
                    Make Influencer
                    <BsPatchCheckFill className="text-[20px] ml-2" />
                  </button>
                  <button
                    onClick={() => {
                      setOpenButton((prev) => !prev);
                      setOpenSuspendUserModal(true);
                    }}
                    className="text-[15px]  mt-2  font-semibold text-[#DF4E4E]"
                  >
                    Suspend user
                  </button>
                </>
              ) : userStatus === "SUSPENDED" ? (
                <button
                  onClick={() => {
                    setOpenButton((prev) => !prev);
                    setOpenReactivateModal(true);
                  }}
                  className="text-[15px]  mt-2  font-semibold text-[#60B7F6]"
                >
                  Reactivate User
                </button>
              ) : null}
            </div>
          )}
        </div>
      </div>
      <div className=" grid grid-cols-1 mds:grid-cols-2 mt-8 lgss:grid-cols-4 gap-4">
        <div className="px-6 pt-4 h-[120px] lgss:h-[90px]  bg-transparent border border-[#2C363E] rounded-lg">
          <h4 className="text-[13px] font-bold text-[#0A6AAF]">
            Total transaction payout
          </h4>
          <h4 className="font-sora font-bold text-[23.41px] text-[#BEE1FB] mt-2">
            {totalPayout ? `₦ ${totalPayout.toLocaleString("en-US")}` : 0}
          </h4>
        </div>
        <div className="px-6 pt-4 h-[120px] lgss:h-[90px]  bg-transparent border border-[#2C363E] rounded-lg">
          <h4 className="text-[13px] font-bold text-[#F28A0F]">
            Total referrals
          </h4>
          <h4 className="font-sora font-bold text-[23.41px] text-[#BEE1FB] mt-2">
            {referralCount}
          </h4>
        </div>
        <div className="px-6 pt-4 h-[120px] lgss:h-[90px]  bg-transparent border border-[#2C363E] rounded-lg">
          <h4 className="text-[13px] font-bold text-[#0FBCF2]">
            Points earned
          </h4>
          <h4 className="font-sora font-bold text-[23.41px] text-[#BEE1FB] mt-2">
            {totalPoints ? totalPoints.toLocaleString("en-US") : 0}
          </h4>
        </div>
        <div className="px-6 pt-4 h-[120px] lgss:h-[90px]  bg-transparent border border-[#2C363E] rounded-lg">
          <h4 className="text-[13px] font-bold text-[#9747FF]">
            Total commission earned
          </h4>
          <h4 className="font-sora font-bold text-[23.41px] text-[#BEE1FB] mt-2">
            --
          </h4>
        </div>
      </div>
      <div className="w-full flex justify-between items-center mt-8">
        <h4 className="text-white font-sora font-semibold text-[12px]">
          Activity
        </h4>
        <div className="flex gap-4 items-center">
          <div className="flex items-center gap-2">
            <div
              // onClick={handleChange}
              className={`flex w-8  cursor-pointer h-4   rounded-full transition-all duration-500 ${
                autoPay === "active" ? "bg-secondary_button" : "bg-gray-600"
              }`}
            >
              <span
                className={`h-4 w-4   rounded-full transition-all duration-500 bg-gray-100 ${
                  autoPay === "active" ? "ml-4" : ""
                }`}
              ></span>
            </div>
            <h4 className="uppercase font-sora font-semibold text-[#869AA9] text-[10px]">
              Auto Pay:{" "}
              <span className={autoPay === "active" ? "text-[#359943]" : ""}>
                {autoPay === "active" ? " ON" : " OFF"}
              </span>
            </h4>
          </div>
          <h4 className="text-[#3A4852]">|</h4>
          <CSVLink
            data={payoutData}
            filename={`${firstName} ${lastName}-transactions-list.csv`}
            className="flex items-center justify-center  gap-2"
          >
            <BiExport className="text-[white]" />
            <h4 className="uppercase font-sora font-semibold mt-1 text-[#869AA9] text-[10px]">
              Export
            </h4>
          </CSVLink>
        </div>
      </div>
      <div className="flex justify-center  gap-6 mt-4 w-full items-start">
        <div className="w-full h-full ">
          <div className="bg-[#0D0D0D] w-full flex justify-start items-center gap-6 pr-12 h-[67px] mt-4">
            <h4
              onClick={() => setActiveSetting(7)}
              className={
                activeSetting === 7
                  ? "border-secondary_button flex justify-center items-center cursor-pointer h-full px-6 text-[16px] font-semibold text-secondary_button border-b-2 "
                  : "  px-6 text-[16px]  text-[#C6D0D7]  cursor-pointer"
              }
            >
              Info
            </h4>
            <h4
              onClick={() => setActiveSetting(1)}
              className={
                activeSetting === 1
                  ? "border-secondary_button flex justify-center items-center cursor-pointer h-full px-6 text-[16px] font-semibold text-secondary_button border-b-2 "
                  : "  px-6 text-[16px]  text-[#C6D0D7]  cursor-pointer"
              }
            >
              Transactions
            </h4>
            <h4
              onClick={() => setActiveSetting(2)}
              className={
                activeSetting === 2
                  ? "border-secondary_button flex justify-center items-center cursor-pointer h-full px-6 text-[16px] font-semibold text-secondary_button border-b-2 "
                  : "  px-6 text-[16px]  text-[#C6D0D7]  cursor-pointer"
              }
            >
              Activities
            </h4>
            <h4
              onClick={() => setActiveSetting(3)}
              className={
                activeSetting === 3
                  ? "border-secondary_button cursor-pointer flex justify-center items-center h-full px-6 text-[16px] font-semibold text-secondary_button border-b-2 "
                  : "  px-6 text-[14px] cursor-pointer  text-[#C6D0D7]  "
              }
            >
              Rewards
            </h4>
            <h4
              onClick={() => setActiveSetting(4)}
              className={
                activeSetting === 4
                  ? "border-secondary_button flex justify-center items-center cursor-pointer h-full px-6 text-[16px] font-semibold text-secondary_button border-b-2 "
                  : "  px-6 text-[16px]  text-[#C6D0D7]  cursor-pointer"
              }
            >
              Task
            </h4>
            <h4
              onClick={() => setActiveSetting(5)}
              className={
                activeSetting === 5
                  ? "border-secondary_button flex justify-center items-center cursor-pointer h-full px-6 text-[16px] font-semibold text-secondary_button border-b-2 "
                  : "  px-6 text-[16px]  text-[#C6D0D7]  cursor-pointer"
              }
            >
              Referrals
            </h4>
            <h4
              onClick={() => setActiveSetting(6)}
              className={
                activeSetting === 6
                  ? "border-secondary_button flex justify-center items-center cursor-pointer h-full px-6 text-[16px] font-semibold text-secondary_button border-b-2 "
                  : "  px-6 text-[16px]  text-[#C6D0D7]  cursor-pointer"
              }
            >
              KYC
            </h4>
          </div>

          {activeSetting === 1 ? (
            <div className="w-full ">
              <div className="flex  items-center flex-col lgss:flex-row justify-start lgss:justify-between  w-full"></div>
              <div className="w-full">
                {payoutLoader ? (
                  <div className="mt-6 flex h-[400px] justify-center items-start">
                    <TailSpin color="#0B75C2" height={50} width={70} />
                  </div>
                ) : payouts.length >= 1 ? (
                  <div className="bg-[#0D0D0D] hidden overflow-auto scrollbar-thumb-[#60B7F6] mb-10 scrollbar-track-white  scrollbar-thin   cursor-pointer lgss:flex lgss:flex-col w-full h-[612px] ">
                    <table className="w-full">
                      <thead>
                        <tr className="h-[56px] bg-[#1C2227] flex justify-start px-8 items-center ">
                          <th
                            onClick={() => {
                              if (sortOrder === "desc") {
                                setSortOrder("asc");
                              } else {
                                setSortOrder("desc");
                              }
                            }}
                            className="text-[#617889] gap-12 cursor-pointer mr-4 flex justify-start items-center text-[15px] w-2/12  whitespace-nowrap "
                          >
                            Name
                            <div className="flex flex-col justify-center items-center">
                              <TiArrowSortedUp
                                className={
                                  sortOrder === "asc"
                                    ? "text-[#BDBDBE] text-[14px]"
                                    : "text-[#617889] text-[12px]"
                                }
                              />
                              <TiArrowSortedDown
                                className={
                                  sortOrder === "desc"
                                    ? "text-[#BDBDBE] text-[14px]"
                                    : "text-[#617889] text-[12px]"
                                }
                              />
                            </div>
                          </th>
                          <th
                            onClick={() => {
                              if (sortOrderType === "desc") {
                                setSortOrderType("asc");
                              } else {
                                setSortOrderType("desc");
                              }
                            }}
                            className="text-[#617889] gap-12 cursor-pointer flex justify-start items-center text-[15px] w-2/12  whitespace-nowrap "
                          >
                            Asset
                            <div className="flex flex-col justify-center items-center">
                              <TiArrowSortedUp
                                className={
                                  sortOrderType === "asc"
                                    ? "text-[#BDBDBE] text-[14px]"
                                    : "text-[#617889] text-[12px]"
                                }
                              />
                              <TiArrowSortedDown
                                className={
                                  sortOrderType === "desc"
                                    ? "text-[#BDBDBE] text-[14px]"
                                    : "text-[#617889] text-[12px]"
                                }
                              />
                            </div>
                          </th>
                          <th className="text-[#617889] text-[15px] whitespace-nowrap w-2/12 text-left">
                            Type
                          </th>
                          <th
                            onClick={() => {
                              if (sortOrderAmount === "desc") {
                                setSortOrderAmount("asc");
                              } else {
                                setSortOrderAmount("desc");
                              }
                            }}
                            className="text-[#617889] gap-8 cursor-pointer flex justify-start items-center text-[15px] w-2/12  whitespace-nowrap "
                          >
                            Amount
                            <div className="flex flex-col justify-center items-center">
                              <TiArrowSortedUp
                                className={
                                  sortOrderAmount === "asc"
                                    ? "text-[#BDBDBE] text-[14px]"
                                    : "text-[#617889] text-[12px]"
                                }
                              />
                              <TiArrowSortedDown
                                className={
                                  sortOrderAmount === "desc"
                                    ? "text-[#BDBDBE] text-[14px]"
                                    : "text-[#617889] text-[12px]"
                                }
                              />
                            </div>
                          </th>
                          <th className="text-[#617889] text-[15px] mr-3 whitespace-nowrap w-2/12 text-left">
                            Trans ID
                          </th>
                          <th
                            onClick={() => {
                              if (sortOrderDate === "desc") {
                                setSortOrderDate("asc");
                              } else {
                                setSortOrderDate("desc");
                              }
                            }}
                            className="text-[#617889] gap-12 cursor-pointer flex justify-start items-center text-[15px] w-2/12  whitespace-nowrap "
                          >
                            Date
                            <div className="flex flex-col justify-center items-center">
                              <TiArrowSortedUp
                                className={
                                  sortOrderDate === "desc"
                                    ? "text-[#BDBDBE] text-[12px]"
                                    : "text-[#617889] text-[12px]"
                                }
                              />
                              <TiArrowSortedDown
                                className={
                                  sortOrderDate === "asc"
                                    ? "text-[#BDBDBE] text-[12px]"
                                    : "text-[#617889] text-[12px]"
                                }
                              />
                            </div>
                          </th>
                          <th className="text-[#617889] text-[15px] whitespace-nowrap w-2/12 text-center">
                            Status
                          </th>
                        </tr>
                      </thead>
                      {sortedData.map((payout, index) => (
                        <tbody key={index} className="w-full cursor-pointer">
                          <tr className="h-[56px] w-full flex justify-start px-8   items-center">
                            <td className="text-white text-[14px] w-2/12 mr-4 whitespace-nowrap capitalize text-left overflow-hidden">
                              {payout.user.profile.firstName}{" "}
                              {payout.user.profile.firstName}
                            </td>
                            <td className="text-white text-[14px] w-2/12 uppercase whitespace-nowrap text-left overflow-hidden">
                              {payout.asset.name}
                            </td>
                            <td className="text-white text-[14px] w-2/12 whitespace-nowrap text-left overflow-hidden">
                              Sell
                            </td>
                            <td className="text-white text-[14px] w-2/12 whitespace-nowrap text-left overflow-hidden">
                              $ {payout.asset.amountInUSD.toFixed(2)}
                            </td>
                            <td className="text-white text-[14px] mr-4 w-2/12 whitespace-nowrap text-left overflow-hidden">
                              {payout._id}
                            </td>

                            <td className="text-white text-[14px] w-2/12 whitespace-nowrap text-left overflow-hidden">
                              {(() => {
                                const dateString = payout.createdAt;
                                const date = new Date(dateString);

                                const day = date.getDate();
                                const ordinalSuffix = (day: number) => {
                                  switch (day) {
                                    case 1:
                                    case 21:
                                    case 31:
                                      return "st";
                                    case 2:
                                    case 22:
                                      return "nd";
                                    case 3:
                                    case 23:
                                      return "rd";
                                    default:
                                      return "th";
                                  }
                                };

                                const formattedDate = `${date.toLocaleString(
                                  "default",
                                  {
                                    month: "short",
                                  }
                                )} ${day}${ordinalSuffix(
                                  day
                                )}, ${date.getFullYear()} | ${date.toLocaleString(
                                  "default",
                                  {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  }
                                )}`;

                                return formattedDate;
                              })()}
                            </td>
                            <td className="text-white text-[15px] w-2/12 whitespace-nowrap flex justify-end  overflow-hidden">
                              <h4
                                className={
                                  payout.status === "PENDING"
                                    ? "text-[#CB9200] flex justify-center items-center w-4/5 bg-[#181818] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                                    : payout.status === "FAILED"
                                    ? "text-[#e37979] flex justify-center items-center w-4/5 bg-[#6d3030] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                                    : payout.status === "COMPLETED"
                                    ? "text-[#03C668] flex justify-center items-center w-4/5 bg-[#013019] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                                    : ""
                                }
                              >
                                {payout.status}
                              </h4>
                            </td>
                          </tr>
                          <tr className="w-full">
                            <td className="mt-1 w-full h-[1px] bg-[#13181B]" />
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                ) : (
                  <div className="flex flex-col justify-center w-full mt-16 mb-60 lgss:mt-24 items-center">
                    <img src={noTransaction} alt="" />
                    <h4 className="font-bold text-[#869AA9] text-[14px] pt-2">
                      No recent payout
                    </h4>
                    <p className=" text-[#869AA9] pt-2 text-[12px]">
                      All payouts will show up here
                    </p>
                  </div>
                )}
                {payouts.length >= 1 && (
                  <div className="flex gap-4 pb-20 justify-end items-center">
                    <h4 className="font-bold font-sora text-[14px] text-white">
                      Page {currentpage} of {totalPages}
                    </h4>
                    <button className="flex justify-center items-center">
                      <BsArrowLeftSquare
                        onClick={() => {
                          if (page > 1) {
                            setPage((prev) => prev - 1);
                          }
                        }}
                        className="text-3xl cursor-pointer text-[#C6D0D7] font-bold"
                      />
                    </button>

                    <button>
                      <BsArrowRightSquare
                        onClick={() => {
                          if (page < totalPages) {
                            setPage((prev) => prev + 1);
                          }
                        }}
                        className="text-3xl cursor-pointer text-[#C6D0D7]"
                      />
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : activeSetting === 2 ? (
            <Activities customerId={customerId} />
          ) : activeSetting === 3 ? (
            <Rewards />
          ) : activeSetting === 4 ? (
            <Tasks customerId={customerId} />
          ) : activeSetting === 5 ? (
            <Referrals customerId={customerId} />
          ) : activeSetting === 6 ? (
            <Kyc />
          ) : activeSetting === 7 ? (
            <Info
              userFn={firstName}
              userLn={lastName}
              userPhoneNo={phoneNumber}
              userMail={email}
              kycLevel={kycLevel}
              userRefCode={userRefCode}
              kycStatus={kycStatus}
              tradeRange={tradeRange}
              username={username}
              banks={banks}
            />
          ) : null}
        </div>
      </div>
      {/* {openUserModal && (
        <UserDeatails
          userFn={firstName}
          userLn={lastName}
          userPhoneNo={phoneNumber}
          userMail={email}
          kycLevel={kycLevel}
          setOpenUserModal={setOpenUserModal}
        />
      )} */}
      {openSendUserMessageModal && (
        <SendUserMessage
          setOpenSendUserMessageModal={setOpenSendUserMessageModal}
          customerId={customerId}
        />
      )}
      {openAwardModal && (
        <AwardPoints
          setOpenAwardModal={setOpenAwardModal}
          customerId={customerId}
        />
      )}
      {openSuspendUserModal && (
        <SuspendUser
          setOpenSuspendUserModal={setOpenSuspendUserModal}
          customerId={customerId}
        />
      )}
      {makeInfluencerModal && (
        <MakeInfluencer
          setMakeInfluencerModal={setMakeInfluencerModal}
          userMail={email}
          userFn={firstName}
          userLn={lastName}
        />
      )}
      {openReactivateModal && (
        <ReactivateUser
          setOpenReactivateModal={setOpenReactivateModal}
          customerId={customerId}
          userFn={firstName}
          userLn={lastName}
        />
      )}
    </div>
  );
};

export default UserActivity;
