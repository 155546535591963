import React, { useEffect, useState } from "react";
import { API_URL } from "../../constant";
import axios from "axios";
import { useAuth } from "../../context/auth-context";
import { TailSpin } from "react-loader-spinner";
import { noTransaction } from "../../assets/images";

const Activities = ({ customerId }: any) => {
  const { token } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [userActivities, setUserActivities] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}api/users/${customerId}/activities`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        setUserActivities(r.data.data.activityLogs);

        setLoading(false);
      })
      .catch((e: any) => {
        console.log(e.response);
        setLoading(false);
      });
  }, [customerId, token]);
  function formatDate(timestamp: string | number | Date) {
    const now = new Date();
    const date = new Date(timestamp);

    const isToday = date.toDateString() === now.toDateString();
    const isYesterday =
      date.toDateString() ===
      new Date(now.setDate(now.getDate() - 1)).toDateString();

    if (isToday) {
      return "Today " + formatTime(date);
    } else if (isYesterday) {
      return "Yesterday " + formatTime(date);
    } else {
      return formatDateOnly(date) + " , " + formatTime(date);
    }
  }

  function formatTime(date: Date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  function formatDateOnly(date: Date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }
  return (
    <div className="w-full h-[600px] overflow-auto px-8 pt-4  bg-[#192025] scrollbar-thumb-[#AEBCC6]    scrollbar-thin">
      {loading ? (
        <div className="mt-6 flex justify-center items-center">
          <TailSpin color="#0B75C2" height={50} width={70} />
        </div>
      ) : (
        <div className="w-full ">
          {userActivities.length >= 1 ? (
            <div>
              {userActivities.map((activity, index) => {
                const formattedDate = formatDate(activity.createdAt);
                return (
                  <div className="w-full mt-4" key={index}>
                    <div className="text-[16px] flex justify-start items-center gap-2 font-bold text-white">
                      {!activity.readAt && (
                        <div className="w-[8px] h-[8px] rounded-full bg-[#F28A0F]"></div>
                      )}{" "}
                      {activity.title}
                    </div>
                    <h5 className="text-[14px] font-medium mt-3 text-[#617889]">
                      {activity.content}
                    </h5>
                    <h5 className="text-[12px] font-medium mt-4 text-[#617889]">
                      {formattedDate}
                    </h5>
                    <div className=" h-[1px] bg-[#2F3A44] w-full rounded-full mt-4" />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className=" flex flex-col justify-center mt-16 lgss:mb-40  lgss:mt-24 items-center">
              <img src={noTransaction} alt="" />

              <h4 className="font-bold text-[#869AA9] pt-2">
                No recent activities
              </h4>
              <p className=" text-[#869AA9] pt-2 text-[12px]">
                All the user activities will show up here
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Activities;
