import axios from "axios";
import React, { useState } from "react";
import { useAuth } from "../../context/auth-context";
import { API_URL } from "../../constant";
import { BiErrorCircle } from "react-icons/bi";
import { TailSpin } from "react-loader-spinner";

const ConfirmResolveModal = ({
  setResolveConfirmModal,
  setResolveSuccessModal,
  selectedPayouts,
}: any) => {
  const { token } = useAuth();
  const [otp, setOtp] = useState<number | string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters

    setOtp(numericValue);
  };

  const confirmTransaction = () => {
    if (!loading) {
      setLoading(true);
      axios
        .post(
          `${API_URL}api/payouts/resolve-failed-payouts`,
          {
            otp,
            payoutIds: selectedPayouts,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          // console.log(r.data.data.transactions);
          setResolveConfirmModal(false);
          setResolveSuccessModal(true);
          setLoading(false);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
          setLoading(false);
          setError(e.response.data.message);
          setTimeout(() => {
            setError("");
          }, 2000);
        });
    }
  };
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-60 backdrop-blur-sm">
      <div className="w-11/12 mds:w-10/12 lgss:w-[35%] transition-all p-6 ease-in duration-1000  rounded-xl bg-[#1F1F1F] ">
        <div className="flex  pt-4 justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Confirm transaction
          </h4>
          <h4
            onClick={() => setResolveConfirmModal(false)}
            className="text-[#78C2F7] font-medium text-[14px] cursor-pointer"
          >
            Close
          </h4>
        </div>
        <div className="pt-6">
          <h4 className="text-[15px] text-[#A0A9AD] font-medium">
            Enter your admin OTP to confirm this transaction
          </h4>
          <div className="w-full mt-6">
            <label
              htmlFor=""
              className="text-white text-[14px] lgss:text-[14px] font-bold"
            >
              OTP
            </label>
            <input
              type="text"
              value={otp}
              placeholder="Paste 6-digits code"
              onChange={handleChange}
              //   style={{ direction: "ltr", textAlign: "right" }}
              className="w-full spin-button-none text-[13px] lgss:text-[15px]  mt-2  text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]"
            />
          </div>
          {error && (
            <div className="bg-red-600 text-white mb-4 text-[13px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-semibold mt-4">
              <BiErrorCircle className="text-xl" />
              {error}
            </div>
          )}
          <button
            onClick={confirmTransaction}
            disabled={typeof otp === "string" && otp.length !== 6}
            className={
              typeof otp === "string" && otp.length !== 6
                ? " w-full rounded-xl py-3 flex justify-center items-center text-gray-200 text-[16px] font-bold mt-6 bg-gray-600"
                : " w-full rounded-xl py-3 flex justify-center items-center text-white text-[16px] font-bold mt-6 bg-secondary_button"
            }
          >
            {loading ? (
              <TailSpin color="#FFFFFF" height={30} width={30} />
            ) : (
              "Confirm"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmResolveModal;
