import axios from "axios";
import React, { useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BiErrorCircle } from "react-icons/bi";
import { TailSpin } from "react-loader-spinner";
import { API_URL } from "../../constant";
import { useAuth } from "../../context/auth-context";

const SuspendUser = ({ setOpenSuspendUserModal, customerId }: any) => {
  const { token } = useAuth();
  const [error, setError] = useState<string>("");
  const [reason, setReason] = useState<string>("Unseriousness");
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>("");

  const handleTypeChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setReason(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
      axios
        .patch(
          `${API_URL}api/users/${customerId}/update-account-status`,
          {
            status: "SUSPENDED",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          setLoading(false);

          setSuccess("User suspended successfully");
          setTimeout(() => {
            setSuccess("");
            setOpenSuspendUserModal((prev: any) => !prev);
          }, 3000);
        })
        .catch((e: any) => {
          setError(e.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setError("");
          }, 3000);
          console.log(e.response.data.message);
        });
    }
  };
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-80 pr-10 backdrop-blur-sm">
      <div
        className={
          error
            ? " w-11/12 lgss:w-1/3 transition-all p-6 ease-in duration-1000  rounded-xl bg-[#1F1F1F] h-[full]"
            : "w-11/12 lgss:w-1/3 transition-all p-6 ease-in duration-1000  rounded-xl bg-[#1F1F1F] h-[full]"
        }
      >
        <div className="flex   justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Suspend User
          </h4>
          <h4
            onClick={() => setOpenSuspendUserModal((prev: any) => !prev)}
            className="text-[#78C2F7] text-[14px] font-medium cursor-pointer"
          >
            Close
          </h4>
        </div>

        <form className="w-full mt-4" onSubmit={handleSubmit}>
          {success && (
            <div className="bg-green-600 w-full text-white  text-[16px] rounded-xl justify-start items-center gap-4 flex h-[60px] px-2 font-bold mt-4 mb-4">
              <AiOutlineCheckCircle className="text-3xl text-white" />
              {success}
            </div>
          )}

          <div className="w-full mt-4">
            <label className="text-[14px] font-bold text-white">Reason</label>
            <select
              value={reason}
              onChange={handleTypeChange}
              className="w-full mt-2 capitalize font-bold text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]"
            >
              <option value="Unseriousness">Unseriousness</option>
              <option value="Violation of terms">Violation of terms</option>
            </select>
          </div>

          {error && (
            <div className="bg-red-600 text-white  text-[12px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-bold mt-4">
              <BiErrorCircle className="text-xl" />
              {error}
            </div>
          )}
          <div className="w-full flex justify-center gap-4 items-center">
            <button
              type="submit"
              className="w-1/2 h-[46px] flex text-[13px] justify-center items-center font-manrope font-medium rounded-xl text-[#D92B2B] bg-[#FDE8E8] mt-4 lgss:mt-4"
            >
              {loading ? (
                <TailSpin color="#D92B2B" height={30} width={30} />
              ) : (
                "Delete account"
              )}
            </button>
            <button
              onClick={() => setOpenSuspendUserModal((prev: any) => !prev)}
              className="w-1/2 h-[46px] text-[13px] flex justify-center items-center font-manrope font-medium rounded-xl text-secondary_button bg-transparent border border-secondary_button mt-4 lgss:mt-4"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SuspendUser;
