import axios from "axios";
import { error } from "console";
import React, { useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BiErrorCircle } from "react-icons/bi";
import { TailSpin } from "react-loader-spinner";
import { influencerBg } from "../../assets/images";
import { API_URL } from "../../constant";
import { useAuth } from "../../context/auth-context";

const MakeInfluencer = ({
  setMakeInfluencerModal,
  userMail,
  userFn,
  userLn,
}: any) => {
  const { token } = useAuth();
  const [error, setError] = useState<string>("");
  const [commision, setCommision] = useState<number | "">("");
  const [tradeValue, setTradeValue] = useState<number | "">("");
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>("");

  const isNumber = (str: any) => {
    if (str.trim() === "") {
      return false;
    }

    return !isNaN(str);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNumber(value)) {
      setCommision(Number(value));
    } else {
      setCommision("");
    }
  };
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\$/g, "");
    if (isNumber(value)) {
      setTradeValue(Number(value));
    } else {
      setTradeValue("");
    }
  };
  const validate = (
    commision: number | string,
    tradeValue: number | string
  ) => {
    if (!commision) {
      setError("Kindly add the commision");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (!tradeValue) {
      setError("Kindly add the trade value");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    setError("");
    return true;
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validate(commision, tradeValue)) {
      return;
    }
    if (!loading) {
      setLoading(true);
      axios
        .patch(
          `${API_URL}api/users/${userMail}/make-influencer`,
          {
            minTradeVal: tradeValue,
            compensationPercent: commision,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          setSuccess("User succesfullu made an influencer");
          setLoading(false);
          setTimeout(() => {
            setSuccess("");
            setMakeInfluencerModal((prev: any) => !prev);
          }, 2500);
          // console.log(r.data.data);
        })
        .catch((e: any) => {
          setError(e.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setError("");
          }, 3000);
          console.log(e.response.data.message);
        });
    }
  };
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-80 pr-10 backdrop-blur-sm">
      <div className=" w-11/12 lgss:w-1/3 transition-all p-6 ease-in duration-1000  rounded-xl bg-[#1F1F1F] h-[full]">
        <div className="flex   justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Create Influencer
          </h4>
          <h4
            onClick={() => setMakeInfluencerModal((prev: any) => !prev)}
            className="text-[#78C2F7] text-[14px] font-medium cursor-pointer"
          >
            Close
          </h4>
        </div>

        <form className="w-full mt-4" onSubmit={handleSubmit}>
          {success && (
            <div className="bg-green-600 w-full text-white  text-[13px] rounded-xl justify-start items-center gap-4 flex h-[60px] px-2 font-bold mt-4 mb-4">
              <AiOutlineCheckCircle className="text-3xl text-white" />
              {success}
            </div>
          )}
          <div className="w-full">
            <img src={influencerBg} alt="" className="w-full bg-cover" />
          </div>

          <div className="w-full mt-4">
            <label className="text-[14px] font-bold text-white">
              Full Name
            </label>
            <div className="w-full flex justify-start items-center mt-2 capitalize text-[14px] font-medium text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]">
              {userLn} {userFn}
            </div>
          </div>
          <div className="w-full mt-4">
            <label className="text-[14px] font-bold text-white">
              Email Address
            </label>
            <div className="w-full mt-2 flex justify-start items-center text-[14px] font-medium text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]">
              {userMail}
            </div>
          </div>
          <div className="flex justify-center items-center gap-4 mt-4">
            <div className="w-1/2 ">
              <label className="text-[14px] font-bold text-white">
                Compensation %
              </label>
              <input
                value={commision}
                type="number"
                placeholder="0"
                onChange={handleChange}
                className="w-full mt-2 capitalize text-[14px] font-medium text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]"
              />
            </div>
            <div className="w-1/2 ">
              <label className="text-[14px] font-bold text-white">
                Minimum Trade Value
              </label>
              <div className="relative w-full">
                <span className="absolute left-[6px] top-[22px] capitalize text-[14px] font-medium text-[#A0A9AD]">
                  $
                </span>
                <input
                  value={tradeValue}
                  type="number"
                  placeholder="0"
                  onChange={handleValueChange}
                  className="w-full mt-2 capitalize text-[14px] font-medium text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]"
                />
              </div>
            </div>
          </div>
          <p className="mt-4 text-[12px] text-[#A0A9AD]">
            {commision ? commision : 0}% bonus per customer for every successful
            transaction of ${tradeValue ? tradeValue : 0}
            (or more) worth of cryptocurrency or gift cards facilitated through
            the DeeX platform.
          </p>

          {error && (
            <div className="bg-red-600 text-white  text-[12px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-bold mt-4">
              <BiErrorCircle className="text-xl" />
              {error}
            </div>
          )}
          <button
            type="submit"
            className="w-full h-[46px] flex justify-center items-center font-manrope font-medium rounded-xl text-white bg-secondary_button mt-4 lgss:mt-4"
          >
            {loading ? (
              <TailSpin color="#FFFFFF" height={30} width={30} />
            ) : (
              "Make Influencer"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default MakeInfluencer;
