import axios from "axios";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import { noTransaction } from "../../assets/images";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { API_URL, PROD_LINK, STAGING_LINK } from "../../constant";
import { useAuth } from "../../context/auth-context";
import CreateTask from "../dashboard/CreateTask";
import OngoingTasks from "./OngoingTasks";

import "react-toastify/dist/ReactToastify.css";
import PastTasks from "./PastTasks";

const TaskPage = () => {
  const { query, token, logout } = useAuth();
  const [openCreateTaskModal, setOpenCreateTaskModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tasks, setTasks] = useState<any[]>([]);
  const [pastTasks, setPastTasks] = useState<any[]>([]);
  const [refetchCount, setRefetchCount] = useState<number>(0);
  const [taskScreen, setTaskScreen] = useState<number>(1);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}api/tasks/applications`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        const filtered = r.data.data.applications.filter((item: any) => {
          return item.status === "pending";
        });
        const filteredPast = r.data.data.applications.filter((item: any) => {
          return item.status === "approved";
        });
        setTasks(filtered);
        setPastTasks(filteredPast);
        setLoading(false);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
        if (
          e.response.status === 401 &&
          e.response.data.message === "Inavlid token. Please login again!"
        ) {
          logout();
        }
        setLoading(false);
      });
  }, [token, refetchCount, logout]);
  return (
    <div className="w-full">
      <div className="w-full h-[31px] bg-[#1B1B1B] flex justify-center items-center">
        {API_URL?.includes("staging") ? (
          <h4 className="font-medium text-[#AEBCC6] text-[12px]">
            You are in Staging:{" "}
            <span>
              <a href={PROD_LINK} className="text-[#03C668]">
                Switch to Prod
              </a>
            </span>
          </h4>
        ) : (
          <h4 className="font-medium text-[#AEBCC6] text-[12px]">
            You are in PROD:{" "}
            <span>
              <a href={STAGING_LINK} className="text-[#DF4E4E]">
                Switch to Staging
              </a>
            </span>
          </h4>
        )}
      </div>
      <div className="w-full lgss:flex lgss:flex-row  h-screen">
        <Sidebar />
        <div className="w-full lgss:w-10/12 h-full overflow-auto bg-[#13181B]">
          <Navbar />

          <div className="w-full px-5 h-full pt-8 mds:px-12 font-manrope lgss:px-10  bg-[#13181B]">
            <div className="flex justify-start items-center lgss:justify-between lgss:gap-0 lgss:w-full">
              <div className="lgss:flex hidden justify-start items-center lgss:w-2/5 px-4 gap-8  bg-transparent  rounded-lg w-2/5 h-[50px]">
                <button
                  onClick={() => setTaskScreen(1)}
                  className="text-[#4E606E] font-bold text-[16px]"
                >
                  All ongoing tasks({tasks.length})
                </button>
                <button
                  onClick={() => setTaskScreen(2)}
                  className="text-[#333F48] font-bold text-[15px]"
                >
                  Approved tasks({pastTasks.length})
                </button>
              </div>
              <div className="flex justify-end items-center w-full lgss:w-[40%] gap-4">
                <button
                  onClick={() => setOpenCreateTaskModal((prev) => !prev)}
                  className="bg-secondary_button px-4 py-2 rounded-xl text-white  text-[15px] font-medium"
                >
                  Create Task
                </button>
              </div>
            </div>
            {taskScreen === 1 ? (
              <div className="bg-[#0D0D0D] overflow-auto scrollbar-thumb-[#60B7F6] scrollbar-track-white mb-8  scrollbar-thin  rounded-tl-lg  w-full h-[552px] mt-8 ">
                <table className="w-full">
                  <thead>
                    <tr className="h-[56px] bg-[#1C2227] flex justify-start px-16 items-center rounded-t-lg">
                      <th className="text-[#617889] text-[15px] overflow-hidden w-1/5  mr-4 text-left whitespace-nowrap">
                        Name
                      </th>
                      <th className="text-[#617889] text-[15px] overflow-hidden w-1/5 text-left whitespace-nowrap">
                        Email
                      </th>

                      <th className="text-[#617889] text-[15px] overflow-hidden w-2/5 text-left whitespace-nowrap">
                        Task
                      </th>
                      <th className="text-[#617889] text-[15px] overflow-hidden w-1/5 text-left whitespace-nowrap">
                        Points
                      </th>
                      <th className="text-[#617889] text-[15px] overflow-hidden w-1/5 text-center whitespace-nowrap">
                        Action
                      </th>
                    </tr>
                  </thead>
                  {loading ? (
                    <div className="mt-12 w-full   flex justify-center items-start">
                      <TailSpin color="#0B75C2" height={70} width={90} />
                    </div>
                  ) : (
                    <div className="w-full">
                      {tasks.length >= 1 ? (
                        <table className="w-full">
                          {tasks
                            .filter((searchValue) => {
                              return query.toLowerCase() === ""
                                ? searchValue
                                : searchValue.task.name
                                    .toLowerCase()
                                    .includes(query) ||
                                    searchValue.user.profile.firstName
                                      .toLowerCase()
                                      .includes(query) ||
                                    searchValue.user.profile.lastName
                                      .toLowerCase()
                                      .includes(query);
                            })
                            .map((task, index) => (
                              <OngoingTasks
                                task={task}
                                key={index}
                                setRefetchCount={setRefetchCount}
                              />
                            ))}
                        </table>
                      ) : (
                        <div className="flex flex-col justify-center mt-12 w-full lgss:mt-12 items-center">
                          <img src={noTransaction} alt="" />
                          <h4 className="font-bold text-[#869AA9] pt-2">
                            No applied task at the moment
                          </h4>
                          <p className=" text-[#869AA9] pt-2 text-[12px]">
                            All applied tasks will show up here
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </table>
              </div>
            ) : taskScreen === 2 ? (
              <PastTasks pastTasks={pastTasks} query={query} />
            ) : null}
          </div>
        </div>
        {openCreateTaskModal && (
          <CreateTask setOpenCreateTaskModal={setOpenCreateTaskModal} />
        )}
        <ToastContainer autoClose={2000} />
      </div>
    </div>
  );
};

export default TaskPage;
