import React from "react";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { logoNew, payout } from "../assets/images";

const Register = () => {
  return (
    <div className=" w-2/5 px-[45px] hidden lgss:flex flex-col justify-center items-start h-full font-manrope pt-[25px]  bg-hero bg-cover text-white">
      <div className="">
        <img src={logoNew} className="w-[110px] h-[29px]" alt="logo" />
      </div>
      <div className="flex flex-col items-center h-5/6 gap-6 justify-center xxl:mt-16  mt-24">
        <div>
          <img src={payout} alt="" className="mx-auto" />
        </div>
        <h4 className="font-sora font-bold text-[28px] text-black">
          Manage clients
        </h4>
        <p className="mt-2 text-center text-black text-[14px] font-medium">
          Trading with us is completely safe and secure. Your gift cards &
          bitcoins are safe because we redeem them directly. We don't give out
          cards to third party.
        </p>
        <div className="flex gap-6 justify-center mt-4 items-center">
          <AiFillLeftCircle
            className="text-3xl cursor-pointer text-black"
            // onClick={() => setSwiper((prev) => !prev)}
          />
          <div className="w-[10px] h-[10px] rounded-full bg-black" />

          <AiFillRightCircle
            className="text-3xl cursor-pointer text-black"
            // onClick={() => setSwiper((prev) => !prev)}
          />
        </div>
      </div>
    </div>
  );
};

export default Register;
