import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth-context";
import Cookies from "js-cookie";
import { Cookies as Cookie, useCookies } from "react-cookie";

const VerifyMail = () => {
  const { authenticate } = useAuth();
  const location = useLocation();
  const { state } = location;
  const { from = "/dashboard" } = state || {};
  const navigate = useNavigate();

  const [,] = useCookies(["admin-token"]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    if (token) {
      Cookies.set("admin-token", token);
    }
  }, [location.search]);

  useEffect(() => {
    const cookies = new Cookie();
    const newCookie = cookies.get("admin-token");
    authenticate(newCookie);
    navigate(from);
  }, [authenticate, from, navigate]);

  return <div>VerifyMail</div>;
};

export default VerifyMail;
