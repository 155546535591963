import React from "react";

const WalletFee = ({
  setWalletFeeModal,
  setWalletReviewModal,
  fiatFee,
  setFiatFee,
}: any) => {
  const isNumber = (str: any) => {
    if (str.trim() === "") {
      return false;
    }

    return !isNaN(str);
  };
  const handleFiatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNumber(value)) {
      setFiatFee(Number(value));
    } else {
      setFiatFee("");
    }
  };

  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-60 backdrop-blur-sm">
      <div className="w-11/12 mds:w-[80%] md:w-3/5 lgss:w-2/5  px-8 pt-8   rounded-xl bg-[#1F1F1F] pb-4">
        <div className="flex   justify-between items-center">
          <h4 className="font-bold text-[18px] font-sora text-white">
            Wallet withdrawal
          </h4>
          <button
            onClick={() => {
              setWalletFeeModal((prev: any) => !prev);
            }}
            className="text-[#78C2F7] font-medium text-[13px]  cursor-pointer"
          >
            {" "}
            Close
          </button>
        </div>
        <div className="w-full mt-4">
          <h4 className="font-medium text-[14px] text-white">Fiat</h4>
          <div className="flex justify-center w-full items-center gap-4  mt-4">
            <div className="w-1/2 ">
              <label htmlFor="" className="text-white text-[14px] font-bold">
                Currency
              </label>
              <div className="w-full flex justify-start items-center mt-2 font-bold text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-lg border border-[#141414]">
                Naira
              </div>
            </div>
            <div className="w-1/2 ">
              <label htmlFor="" className="text-white text-[14px] font-bold">
                Fee (NGN)
              </label>
              <input
                type="number"
                value={fiatFee}
                autoFocus
                onChange={handleFiatChange}
                style={{ direction: "ltr", textAlign: "right" }}
                className="w-full  text-[15px]  mt-2  text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]"
              />
            </div>
          </div>
          <div className="w-full rounded-full bg-[#273037] h-[1px] mt-4" />
        </div>
        <button
          onClick={() => {
            setWalletFeeModal((prev: any) => !prev);
            setWalletReviewModal((prev: any) => !prev);
          }}
          className="text-white font-medium text-[16px] mt-4 h-[48px] w-full bg-secondary_button rounded-lg"
        >
          Proceed
        </button>
      </div>
    </div>
  );
};

export default WalletFee;
