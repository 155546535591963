import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { TailSpin } from "react-loader-spinner";
import { API_URL } from "../../../constant";
import { useAuth } from "../../../context/auth-context";

const WithdrawFiat = ({
  setWithdrawFiatModal,
  setConfirmFiatModal,
  withdrawAmount,
  setWithdrawAmount,
  bankCode,
  setBankCode,
  bankNumber,
  setBankNumber,
}: any) => {
  const [bankName, setBankName] = useState<any[]>([]);
  const [error, setError] = useState<string>("");
  const { token } = useAuth();
  const [userName, setUserName] = useState<string>("");
  const [bankLoading, setBankLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    setWithdrawAmount(numericValue);
  };

  const validateAccount = (bankNumber: string) => {
    if (!bankNumber) {
      setError("kindly provide your account Number");
      setTimeout(() => {
        setError("");
      }, 5000);
      return false;
    }
    if (bankNumber.length !== 10) {
      setError("Account number must be 10 digits");
      setTimeout(() => {
        setError("");
      }, 5000);
      return false;
    }

    setError("");
    return true;
  };

  useEffect(() => {
    axios
      .get(`${API_URL}api/banks`)
      .then((r: any) => {
        setBankName(r.data.data);
      })
      .catch((e: any) => {
        setError(e.response.data.message);
        setLoading(false);
        setTimeout(() => {
          setError("");
        }, 6000);
        console.log(e.response.data.message);
      });
  }, []);

  const handleChange = (event: any) => {
    const selectedBank = bankName.find(
      (bank) => bank.name === event.target.value
    );
    if (selectedBank) {
      setBankCode(selectedBank.code);
    }
  };

  useEffect(() => {
    if (bankNumber.length === 10 && bankCode !== "") {
      setBankLoading(true);
      axios
        .get(
          `${API_URL}api/banks/account-name?accountNumber=${bankNumber}&bankCode=${bankCode}`
        )
        .then((r: any) => {
          setBankLoading(false);
          setUserName(r.data.data.account_name);
        })
        .catch((e: any) => {
          setUserName("");
          setBankLoading(false);
          setLoading(false);
          setError(e.response.data.message);
          setTimeout(() => {
            setError("");
          }, 6000);
          console.log(e.response.data.message);
        });
    }
    setUserName("");
  }, [bankNumber, bankCode]);
  useEffect(() => {
    if (!userName || !withdrawAmount) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [userName, withdrawAmount]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateAccount(bankNumber)) {
      return;
    }

    if (!loading) {
      setLoading(true);
      axios
        .post(
          `${API_URL}api/users/me/request-otp`,
          {
            purpose: "transaction",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          // console.log(r.data.data.transactions);
          setWithdrawFiatModal(false);
          setConfirmFiatModal(true);
          setLoading(false);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
          setLoading(false);
          setError(e.response.data.message);
          setTimeout(() => {
            setError("");
          }, 2000);
        });
    }
  };
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-60 backdrop-blur-sm">
      <div className="w-11/12 mds:w-10/12 lgss:w-[35%] transition-all p-6 ease-in duration-1000  rounded-xl bg-[#1F1F1F] ">
        <div className="flex  pt-4 justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Withdraw fiat
          </h4>
          <h4
            onClick={() => setWithdrawFiatModal(false)}
            className="text-[#78C2F7] font-medium text-[14px] cursor-pointer"
          >
            Close
          </h4>
        </div>
        <div className="w-full flex flex-col justify-center pt-8 items-center">
          <form onSubmit={handleSubmit} className="  ">
            <div className="mt-4">
              <label className="text-white text-[13px]  font-bold">
                How much:
              </label>
              <input
                type="text"
                value={withdrawAmount}
                onChange={handleAmountChange}
                placeholder="₦"
                className="w-full spin-button-none mt-2  mb-6 placeholder:text-[13px] font-medium text-[#A0A9AD] placeholder:text-[#A0A9AD] outline-none bg-[#1C2227] border rounded-xl px-4  border-[#141414] border-opacity-20 h-[50px]"
              />
            </div>
            <label className=" font-bold text-white text-[13px] ">
              Account number
            </label>
            <input
              type="number"
              value={bankNumber}
              onChange={(e) => setBankNumber(e.target.value)}
              placeholder="Enter your account number"
              className="w-full spin-button-none mt-2 placeholder:text-[13px] mb-6 font-medium text-[#A0A9AD] outline-none bg-[#1C2227] border rounded-xl px-4  border-[#141414] border-opacity-20 h-[50px]"
            />
            <label className="  font-bold text-[13px]  text-white">
              Bank name
            </label>
            <select
              onChange={handleChange}
              className="w-full spin-button-none mt-2 placeholder:text-[13px] mb-4 font-medium text-[#A0A9AD] outline-none bg-[#1C2227] border rounded-xl px-4  border-[#141414] border-opacity-20 h-[50px]"
            >
              <option>Select your bank</option>
              {bankName.map((bank, index) => (
                <option key={index} className="p-4">
                  {bank.name}
                </option>
              ))}
            </select>
            <h4 className="text-[14px] mt-2 font-bold text-[#F28A0F] ">
              {bankLoading ? (
                <TailSpin color="#FFFFFF" height={20} width={30} />
              ) : (
                `${userName}`
              )}
            </h4>
            {error && (
              <div className="bg-red-600 w-full  text-white text-[12px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-bold mt-4">
                <BiErrorCircle className="text-2xl" />
                {error}
              </div>
            )}

            <button
              onClick={() => handleSubmit}
              disabled={disabled}
              className={
                disabled
                  ? "w-full h-[60px] flex justify-center items-center font-manrope mx-auto   rounded-xl bg-gray-400 dark:bg-[#8f9294] text-white font-bold mt-4 lgss:mt-8"
                  : "w-full h-[60px] flex justify-center items-center font-manrope mx-auto  font-medium rounded-xl text-white bg-secondary_button mt-4 lgss:mt-8"
              }
            >
              {loading ? (
                <TailSpin color="#FFFFFF" height={30} width={30} />
              ) : (
                "Confirm"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WithdrawFiat;
