import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../constant";
import { useAuth } from "../../../context/auth-context";
import { CgAddR } from "react-icons/cg";
import { TailSpin } from "react-loader-spinner";
import { AiOutlineCloseCircle } from "react-icons/ai";

const CardModal = ({
  setCardId,
  setAllGiftcards,
  cardId,
  allGiftcards,
  activeCard,
  uploadCardImage,
  setUploadCardImage,
  setCardModal,
}: any) => {
  const { token } = useAuth();
  const [allCardLoader, setAllCardLoader] = useState<boolean>(false);
  const [category, setCategory] = useState(1);
  const [inactiveCardLoader, setInactiveCardLoader] = useState<boolean>(false);
  const [activeCardLoader, setActiveCardLoader] = useState<boolean>(false);
  const [deactivatedGiftcards, setDeactivatedGiftcards] = useState<any[]>([]);

  const [activatedGiftcards, setActivatedGiftcards] = useState<any[]>([]);

  useEffect(() => {
    if (category === 1) {
      setAllCardLoader(true);
      axios
        .get(`${API_URL}api/gift-cards?status=all`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r: any) => {
          if (r.data && r.data.data && r.data.data.giftCards.length >= 1) {
            setAllGiftcards(r.data.data.giftCards);
            const activeGiftCard = r.data.data.giftCards.find(
              (giftCard: any) => giftCard._id === cardId
            );
            if (!activeGiftCard) {
              setCardId(r.data.data.giftCards[0]._id);
            }
          }
          setAllCardLoader(false);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
          setAllCardLoader(false);
        });
    }
  }, [
    category,
    token,
    activeCard,
    cardId,
    uploadCardImage,
    setAllGiftcards,
    setCardId,
  ]);
  const sortedGiftCards = allGiftcards?.sort(
    (a: { isActive: any }, b: { isActive: any }) => {
      if (a.isActive && !b.isActive) {
        return -1;
      } else if (!a.isActive && b.isActive) {
        return 1;
      } else {
        return 0;
      }
    }
  );
  useEffect(() => {
    if (category === 2) {
      setActiveCardLoader(true);
      axios
        .get(`${API_URL}api/gift-cards?status=active`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r: any) => {
          if (r.data && r.data.data && r.data.data.giftCards) {
            setActivatedGiftcards(r.data.data.giftCards);
            if (r.data.data.giftCards.length > 0) {
              setCardId(r.data.data.giftCards[0]._id);
            }
          }
          setActiveCardLoader(false);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
          setActiveCardLoader(false);
        });
    }
  }, [category, token, activeCard, setCardId]);
  useEffect(() => {
    if (category === 3) {
      setInactiveCardLoader(true);
      axios
        .get(`${API_URL}api/gift-cards?status=inactive`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r: any) => {
          if (r.data && r.data.data && r.data.data.giftCards) {
            setDeactivatedGiftcards(r.data.data.giftCards);
            if (r.data.data.giftCards.length > 0) {
              setCardId(r.data.data.giftCards[0]._id);
            }
          }
          setInactiveCardLoader(false);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
          setInactiveCardLoader(false);
        });
    }
  }, [category, token, activeCard, setCardId]);
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-30  backdrop-blur-sm">
      <div
        className={
          " w-11/12 lgss:w-2/3 transition-all p-8 ease-in duration-1000  rounded-xl bg-[#0D0D0D] h-[400px] "
        }
      >
        {" "}
        <div className="flex justify-between items-center">
          <div className="flex justify-start mt-4 items-start gap-6">
            <div onClick={() => setCategory(1)}>
              <h4
                className={
                  category === 1
                    ? "font-semibold cursor-pointer text-[13px] text-secondary_button"
                    : " cursor-pointer text-[13px] text-[#C6D0D7]"
                }
              >
                All cards
              </h4>
              {category === 1 && (
                <div className="w-full h-[4px] bg-secondary_button rounded-full mt-3" />
              )}
            </div>
            <div onClick={() => setCategory(2)}>
              <h4
                className={
                  category === 2
                    ? "font-semibold cursor-pointer text-[13px] text-secondary_button"
                    : " cursor-pointer text-[13px] text-[#C6D0D7]"
                }
              >
                Active cards
              </h4>
              {category === 2 && (
                <div className="w-full h-[4px] bg-secondary_button rounded-full mt-3" />
              )}
            </div>
            <div onClick={() => setCategory(3)}>
              <h4
                className={
                  category === 3
                    ? "font-semibold cursor-pointer text-[13px] text-secondary_button"
                    : " cursor-pointer text-[13px] text-[#C6D0D7]"
                }
              >
                Deactivated cards
              </h4>
              {category === 3 && (
                <div className="w-full h-[4px] bg-secondary_button rounded-full mt-3" />
              )}
            </div>
          </div>
          <AiOutlineCloseCircle
            onClick={() => setCardModal((prev: any) => !prev)}
            className="text-[24px] text-secondary_button"
          />
        </div>
        <div className="w-full rounded-full h-[1px] bg-[#3A4852]" />
        {category === 1 ? (
          <div className="w-full mt-6">
            {allCardLoader ? (
              <TailSpin color="#0B84CB" height={40} width={40} />
            ) : allGiftcards.length >= 1 ? (
              <div className="w-full mt-6 flex flex-wrap items-center overflow-auto justify-start gap-4 scrollbar-thumb-[#60B7F6]  scrollbar-track-white  scrollbar-thin">
                {sortedGiftCards.map(
                  (
                    allCard: {
                      thumbnail: string | undefined;
                      _id: any;
                      isActive: any;
                    },
                    index: React.Key | null | undefined
                  ) => (
                    <div key={index} className="h-[58px] w-[87px]">
                      <img
                        src={allCard.thumbnail}
                        alt=""
                        onClick={() => {
                          setCardId(allCard._id);
                          setCardModal((prev: any) => !prev);
                        }}
                        className={
                          allCard.isActive
                            ? "h-full w-full object-cover  cursor-pointer"
                            : "h-full w-full object-cover filter grayscale cursor-pointer"
                        }
                      />
                    </div>
                  )
                )}
                <CgAddR
                  onClick={() => {
                    setCardModal((prev: any) => !prev);
                    setUploadCardImage((prev: any) => !prev);
                  }}
                  className=" cursor-pointer text-secondary_button text-[40px]"
                />
              </div>
            ) : (
              <CgAddR
                onClick={() => {
                  setCardModal((prev: any) => !prev);
                  setUploadCardImage((prev: any) => !prev);
                }}
                className=" cursor-pointer text-secondary_button text-[40px]"
              />
            )}
          </div>
        ) : category === 2 ? (
          <div className="w-full mt-6">
            {activeCardLoader ? (
              <TailSpin color="#0B84CB" height={40} width={40} />
            ) : activatedGiftcards.length >= 1 ? (
              <div className="w-full mt-6 flex items-center justify-start gap-4 scrollbar-thumb-[#60B7F6] overflow-auto scrollbar-track-white  scrollbar-thin">
                {activatedGiftcards.map((activeCard, index) => (
                  <div key={index} className="h-[58px] w-[87px]">
                    <img
                      src={activeCard.thumbnail}
                      alt=""
                      onClick={() => {
                        setCardId(activeCard._id);
                        setCardModal((prev: any) => !prev);
                      }}
                      className="h-full w-full object-cover cursor-pointer"
                    />
                  </div>
                ))}
              </div>
            ) : (
              <h4 className="font-bold text-[12px] font-sora text-white">
                No activated card
              </h4>
            )}
          </div>
        ) : category === 3 ? (
          <div className="w-full mt-6">
            {inactiveCardLoader ? (
              <TailSpin color="#0B84CB" height={40} width={40} />
            ) : deactivatedGiftcards.length >= 1 ? (
              <div className="w-full mt-6 flex items-center justify-start gap-4 scrollbar-thumb-[#60B7F6] overflow-auto scrollbar-track-white  scrollbar-thin">
                {deactivatedGiftcards.map((inactiveCard, index) => (
                  <div key={index} className="h-[58px] w-[87px]">
                    <img
                      src={inactiveCard.thumbnail}
                      alt=""
                      onClick={() => {
                        setCardId(inactiveCard._id);
                        setCardModal((prev: any) => !prev);
                      }}
                      className="h-full w-full object-cover grayscale filter cursor-pointer"
                    />
                  </div>
                ))}
              </div>
            ) : (
              <h4 className="font-bold text-[12px] font-sora text-white">
                No deactivated card
              </h4>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CardModal;
