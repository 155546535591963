import React, { useEffect, useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { useAuth } from "../../context/auth-context";
import { BiImageAdd, BiErrorCircle } from "react-icons/bi";
import axios from "axios";
import { API_URL } from "../../constant";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { TailSpin } from "react-loader-spinner";
import { BsCheckCircle } from "react-icons/bs";

const UploadCardImage = ({ setUploadCardImage, setCardModal }: any) => {
  const { token } = useAuth();
  const [cardName, setCardName] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string>("");
  const [signedUrl, setSignedUrl] = useState<string>("");
  const [objectUrl, setObjectUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [urlLoading, setUrlLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files ? e.target.files[0] : null;
    setFile(selectedFile);
  };

  useEffect(() => {
    axios
      .post(
        `${API_URL}api/gift-cards/thumbnails/request-upload-url`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r: any) => {
        // console.log(r.data.data);
        setSignedUrl(r.data.data.signedURL);
        setObjectUrl(r.data.data.objectURL);
      })
      .catch((e: any) => {
        setError(e.response.data.message);
        //   setSuccess(false);
        console.log(e.respsonse.data);
      });
  }, [token]);

  useEffect(() => {
    if (file) {
      setUrlLoading(true);

      axios
        .put(`${signedUrl}`, file, {
          headers: {
            // Authorization: `Bearer ${token}`,
            "Content-Type": file.type,
          },
        })
        .then((r: any) => {
          // console.log(r);
          setUrlLoading(false);
          setSaved(true);
          // console.log(file);
        })
        .catch((e: any) => {
          setError(e.response.data.message);
          setUrlLoading(false);
          console.log(e.respsonse.data);
        });
    }
  }, [file, signedUrl]);

  const validateFields = (cardName: string, file: File | null) => {
    if (!cardName) {
      setError("Kindly add the card name");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (!file) {
      setError("Kindly add a file");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }

    setError("");
    return true;
  };

  useEffect(() => {
    if (!cardName || !file || !saved) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [cardName, file, saved]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateFields(cardName, file)) {
      return;
    }
    if (error) return;
    if (!loading && !urlLoading) {
      setLoading(true);
      axios
        .post(
          `${API_URL}api/gift-cards`,
          {
            name: cardName,
            thumbnail: objectUrl,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          setSuccess("Card uploaded successfully!!!");
          setLoading(false);
          setTimeout(() => {
            setSuccess("");
            setCardName("");
            setFile(null);
            setUploadCardImage(false);
            setCardModal((prev: any) => !prev);
          }, 3000);
        })
        .catch((e: any) => {
          setError(e.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setError("");
          }, 3000);
          console.log(e.response.data.message);
        });
    }
  };

  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-30 backdrop-blur-sm">
      <div className="w-11/12 lgss:w-1/3 transition-all p-8 ease-in duration-1000  rounded-xl bg-[#1F1F1F] ">
        <div className="flex   justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Add new card
          </h4>
          <FaTimesCircle
            onClick={() => {
              setUploadCardImage((prev: any) => !prev);
              setCardModal((prev: any) => !prev);
            }}
            className="text-[#78C2F7] text-[20px] cursor-pointer"
          />
        </div>
        <form className="mt-4" onSubmit={handleSubmit}>
          {success && (
            <div className="bg-green-600 w-full text-white  text-[12px] rounded-xl justify-start items-center gap-4 flex h-[40px] px-2 font-bold mt-4 mb-4">
              <AiOutlineCheckCircle className="text-3xl text-white" />
              {success}
            </div>
          )}
          <div className="w-full">
            <label htmlFor="" className="font-bold text-[14px] text-white">
              Card name
            </label>
            <input
              type="text"
              value={cardName}
              placeholder="Add the card name"
              onChange={(e) => setCardName(e.target.value)}
              name="Card Name"
              className="h-[40px] w-full mt-2 text-[14px] placeholder:text-[#A0A9AD] px-[16px] mb-4 font-manrope border rounded-lg border-[#141414] outline-none bg-[#1C2227]  text-[#A0A9AD] transition duration-200"
            />
            <div className="w-full ">
              <label htmlFor="" className="font-bold text-[14px] text-white">
                Card name
              </label>
              <div
                onClick={() => {
                  const input = document.getElementById(
                    "fileInput"
                  ) as HTMLInputElement;
                  input.click();
                }}
                className="h-[40px] cursor-pointer w-full mt-2 text-[14px] px-4 flex justify-between items-center  font-manrope border rounded-lg border-[#141414] outline-none bg-[#1C2227]  text-[#A0A9AD] transition duration-200"
              >
                <h4 className="font-medium text-[14px]">
                  {file ? file.name : "Upload image"}
                </h4>
                {!file && <BiImageAdd className="text-[#AEBCC6] text-xl" />}

                {file && urlLoading ? (
                  <TailSpin color="#0B84CB" height={25} width={25} />
                ) : file && !urlLoading ? (
                  <BsCheckCircle className="text-green-600 text-xl" />
                ) : null}
              </div>
              <input
                id="fileInput"
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileUpload}
              />
            </div>
            {error && (
              <div className="bg-red-600 text-white mb-4 text-[12px] rounded-xl justify-start items-center gap-2 flex h-[40px] px-2 font-bold mt-4">
                <BiErrorCircle className="text-xl" />
                {error}
              </div>
            )}
            <button
              type="submit"
              disabled={disabled}
              className={
                disabled
                  ? "w-full h-[42px] flex justify-center items-center font-manrope font-medium rounded-xl text-white bg-gray-400 mt-4 lgss:mt-8"
                  : "w-full h-[42px] flex justify-center items-center font-manrope font-medium rounded-xl text-white bg-secondary_button mt-4 lgss:mt-8"
              }
            >
              {loading ? (
                <TailSpin color="#FFFFFF" height={30} width={30} />
              ) : (
                "Add Card"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadCardImage;
