export const  formatNumber = (number: number)  => {
  const absNumber = Math.abs(number);
  if (absNumber >= 1e6) {
    // For millions
    return (number / 1e6).toFixed(2).replace(/\.0$/, '') + 'm';
  } else if (absNumber >= 1e3) {
    // For thousands
    return (number / 1e3).toFixed(2).replace(/\.00$/, '') + 'k';
  } else if (absNumber >= 100) {
    // For hundreds
    return (number / 100).toFixed(2).replace(/\.0$/, '') + 'h';
  } else {
    // For less than hundred
    return number;
  }
}
