import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { noTransaction } from "../../assets/images";
import { API_URL } from "../../constant";
import { useAuth } from "../../context/auth-context";

const Clients = ({
  refetchCount,
  setClientsNew,
  setClientId,
  setSuccessClientModal,
}: any) => {
  const { token, query } = useAuth();
  const [page, setPage] = useState<number>(1);
  const [currentpage, setCurrentPage] = useState<number>();
  const [clients, setClients] = useState<any[]>([]);
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [customerLoader, setCustomerLoader] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalCustomers, setTotalCustomers] = useState<number>(1);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | "">("");
  const [sortOrderMail, setSortOrderMail] = useState<"asc" | "desc" | "">("");
  const [sortOrderDate, setSortOrderDate] = useState<"asc" | "desc" | "">("");
  const [sortOrderPhone, setSortOrderPhone] = useState<"asc" | "desc" | "">("");

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      sendSearchQuery(query);
    }, 600);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [query]);

  const sendSearchQuery = (query: string) => {
    setCustomerLoader(true);
    axios
      .get(
        `${API_URL}api/users/clients/?limit=25&page=${page}&search=${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r: any) => {
        setCustomerLoader(false);
        setTotalPages(r.data.data.meta.totalPages);
        setCurrentPage(r.data.data.meta.currentPage);
        setTotalCustomers(r.data.data.meta.totalUsers);
        setClients(r.data.data.clients);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  };
  useEffect(() => {
    setCustomerLoader(true);
    axios
      .get(`${API_URL}api/users/clients/?limit=25&page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        setCustomerLoader(false);
        setTotalPages(r.data.data.meta.totalPages);
        setCurrentPage(r.data.data.meta.currentPage);
        setTotalCustomers(r.data.data.meta.totalUsers);
        setClients(r.data.data.clients);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, [page, token, refetchCount]);

  useEffect(() => {
    if (sortOrder === "asc") {
      setSortedData(
        [...clients].sort((a, b) => {
          const nameA = `${a.name}`;
          const nameB = `${b.name}`;
          return nameA.localeCompare(nameB);
        })
      );
    } else if (sortOrder === "desc") {
      setSortedData(
        [...clients].sort((a, b) => {
          const nameA = `${a.name}`;
          const nameB = `${b.name}`;
          return nameB.localeCompare(nameA);
        })
      );
    } else {
      setSortedData(clients);
    }
  }, [sortOrder, clients]);
  useEffect(() => {
    if (sortOrder === "asc") {
      setSortedData(
        [...clients].sort((a, b) => {
          const nameA = `${a.name}`;
          const nameB = `${b.name}`;
          return nameA.localeCompare(nameB);
        })
      );
    } else if (sortOrder === "desc") {
      setSortedData(
        [...clients].sort((a, b) => {
          const nameA = `${a.name}`;
          const nameB = `${b.name}`;
          return nameB.localeCompare(nameA);
        })
      );
    } else {
      setSortedData(clients);
    }
  }, [sortOrder, clients]);
  useEffect(() => {
    if (sortOrderPhone === "asc") {
      setSortedData(
        [...clients].sort((a, b) => {
          const nameA = `${a.phone}`;
          const nameB = `${b.phone}`;
          return nameA.localeCompare(nameB);
        })
      );
    } else if (sortOrderPhone === "desc") {
      setSortedData(
        [...clients].sort((a, b) => {
          const nameA = `${a.phone}`;
          const nameB = `${b.phone}`;
          return nameB.localeCompare(nameA);
        })
      );
    } else {
      setSortedData(clients);
    }
  }, [sortOrderPhone, clients]);

  useEffect(() => {
    axios
      .get(`${API_URL}api/users/clients/?limit=${totalCustomers}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        setClientsNew(r.data.data.clients);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, [token, totalCustomers]);

  useEffect(() => {
    if (sortOrderDate === "asc") {
      setSortedData(
        [...clients].sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        )
      );
    } else if (sortOrderDate === "desc") {
      setSortedData(
        [...clients].sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      );
    } else {
      setSortedData(clients);
    }
  }, [sortOrderDate, clients]);

  //  useEffect(() => {
  //    axios
  //      .get(`${API_URL}api/users/?limit=${totalCustomers}`, {
  //        headers: {
  //          Authorization: `Bearer ${token}`,
  //        },
  //      })
  //      .then((r: any) => {
  //        setCustomersNew(r.data.data.users);
  //      })
  //      .catch((e: any) => {
  //        console.log(e.response.data.message);
  //      });
  //  }, [token, totalCustomers]);

  return (
    <div className="w-full   h-full">
      {customerLoader ? (
        <div className="lgss:flex hidden gap-5 w-full px-4 h-[480px]">
          <div className="relative w-full space-y-3 overflow-hidden  bg-[#5c6b73] bg-opacity-30 p-8 shadow before:absolute before:inset-0 before:-translate-x-full before:bg-gradient-to-r before:from-transparent before:via-white/40 hover:shadow-lg before:animate-[shimmer_1.3s_infinite]">
            <div className="h-36 w-full  bg-[#E7EBEE]"></div>

            <div className="grid grid-cols-6 gap-2 mt-4">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-6 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-6 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-6 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-6 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-6 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
          </div>
        </div>
      ) : clients.length >= 1 ? (
        <div>
          <div className="bg-[#0D0D0D] overflow-auto scrollbar-thumb-[#60B7F6] scrollbar-track-white   scrollbar-thin   w-full h-[652px]  ">
            <table className="w-full">
              <thead>
                <tr className="h-[56px] bg-[#1C2227] flex justify-start px-8  items-center ">
                  <th
                    onClick={() => {
                      if (sortOrder === "desc") {
                        setSortOrder("asc");
                      } else {
                        setSortOrder("desc");
                      }
                    }}
                    className="text-[#617889] gap-12 cursor-pointer mr-4 flex justify-start items-center text-[15px] w-3/12  whitespace-nowrap "
                  >
                    Name
                    <div className="flex flex-col justify-center items-center">
                      <TiArrowSortedUp
                        className={
                          sortOrder === "asc"
                            ? "text-[#BDBDBE] text-[14px]"
                            : "text-[#617889] text-[12px]"
                        }
                      />
                      <TiArrowSortedDown
                        className={
                          sortOrder === "desc"
                            ? "text-[#BDBDBE] text-[14px]"
                            : "text-[#617889] text-[12px]"
                        }
                      />
                    </div>
                  </th>
                  <th
                    onClick={() => {
                      if (sortOrderMail === "desc") {
                        setSortOrderMail("asc");
                      } else {
                        setSortOrderMail("desc");
                      }
                    }}
                    className="text-[#617889] gap-12 cursor-pointer mr-4 flex justify-start items-center text-[15px] w-3/12  whitespace-nowrap "
                  >
                    Client ID
                    <div className="flex flex-col justify-center items-center">
                      <TiArrowSortedUp
                        className={
                          sortOrderMail === "asc"
                            ? "text-[#BDBDBE] text-[14px]"
                            : "text-[#617889] text-[12px]"
                        }
                      />
                      <TiArrowSortedDown
                        className={
                          sortOrderMail === "desc"
                            ? "text-[#BDBDBE] text-[14px]"
                            : "text-[#617889] text-[12px]"
                        }
                      />
                    </div>
                  </th>
                  <th
                    onClick={() => {
                      if (sortOrderPhone === "desc") {
                        setSortOrderPhone("asc");
                      } else {
                        setSortOrderPhone("desc");
                      }
                    }}
                    className="text-[#617889] gap-6 cursor-pointer  flex justify-start items-center text-[15px] w-3/12  whitespace-nowrap "
                  >
                    Phone
                    <div className="flex flex-col justify-center items-center">
                      <TiArrowSortedUp
                        className={
                          sortOrderPhone === "asc"
                            ? "text-[#BDBDBE] text-[14px]"
                            : "text-[#617889] text-[12px]"
                        }
                      />
                      <TiArrowSortedDown
                        className={
                          sortOrderPhone === "desc"
                            ? "text-[#BDBDBE] text-[14px]"
                            : "text-[#617889] text-[12px]"
                        }
                      />
                    </div>
                  </th>

                  <th
                    onClick={() => {
                      if (sortOrderDate === "desc") {
                        setSortOrderDate("asc");
                      } else {
                        setSortOrderDate("desc");
                      }
                    }}
                    className="text-[#617889] gap-4 cursor-pointer flex mr-4 justify-center items-center text-[15px] w-3/12  whitespace-nowrap "
                  >
                    Date Created
                    <div className="flex flex-col justify-center items-center">
                      <TiArrowSortedUp
                        className={
                          sortOrderDate === "desc"
                            ? "text-[#BDBDBE] text-[12px]"
                            : "text-[#617889] text-[12px]"
                        }
                      />
                      <TiArrowSortedDown
                        className={
                          sortOrderDate === "asc"
                            ? "text-[#BDBDBE] text-[12px]"
                            : "text-[#617889] text-[12px]"
                        }
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              {sortedData.map((client, index) => (
                <tbody
                  onClick={() => {
                    setClientId(client._id);
                    setSuccessClientModal(true);
                  }}
                  key={index}
                  className="cursor-pointer"
                >
                  <tr className="h-[56px] w-full flex justify-start  px-8  items-center">
                    <td className="text-white text-[15px] w-3/12 whitespace-nowrap mr-4 text-left overflow-hidden">
                      {client.name}
                    </td>
                    <td className="text-white text-[15px] w-3/12 whitespace-nowrap mr-4 text-left overflow-hidden">
                      {client._id}
                    </td>

                    {/* <td className="flex items-center justify-center   w-2/12 text-[12px] font-bold">
                     <h4 className="text-[#60B7F6] bg-[#3A4852] text-center rounded-full w-1/2 py-2 ">
                       {customer.email.isVerified &&
                       customer.kyc.status === "VERIFIED"
                         ? "Level 2"
                         : customer.email.isVerified
                         ? "Level 1"
                         : "None"}
                     </h4>
                   </td> */}
                    <td className="text-white text-[15px] w-3/12 whitespace-nowrap mr-4 text-left overflow-hidden">
                      {client.phone}
                    </td>

                    <td className="text-white text-[15px] w-3/12 whitespace-nowrap mr-4 text-center overflow-hidden">
                      {(() => {
                        const dateString = client.createdAt;
                        const date = new Date(dateString);

                        const day = date.getDate();
                        const ordinalSuffix = (day: number) => {
                          switch (day) {
                            case 1:
                            case 21:
                            case 31:
                              return "st";
                            case 2:
                            case 22:
                              return "nd";
                            case 3:
                            case 23:
                              return "rd";
                            default:
                              return "th";
                          }
                        };

                        const formattedDate = `${date.toLocaleString(
                          "default",
                          {
                            month: "short",
                          }
                        )} ${day}${ordinalSuffix(
                          day
                        )}, ${date.getFullYear()}, ${date.toLocaleString(
                          "default",
                          {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          }
                        )}`;

                        return formattedDate;
                      })()}
                    </td>
                  </tr>
                  <tr className="w-full">
                    <td className="mt-1 w-full h-[1px] bg-[#13181B]" />
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        </div>
      ) : (
        <div className="w-full flex flex-col justify-start h-full mt-16 mb-20 items-center">
          <img src={noTransaction} alt="" />
          <h4 className="font-bold text-[#869AA9] pt-2">No client</h4>
          <p className=" text-[#869AA9] pt-2 text-[12px]">
            All clients will show up here
          </p>
        </div>
      )}
      {clients.length >= 1 && (
        <div className="flex gap-4 mt-14 justify-end items-center">
          <h4 className="font-bold font-sora text-[14px] text-white">
            Page {currentpage} of {totalPages}
          </h4>
          <button className="flex justify-center items-center">
            <BsArrowLeftSquare
              onClick={() => {
                if (page > 1) {
                  setPage((prev) => prev - 1);
                }
              }}
              className="text-3xl cursor-pointer text-[#C6D0D7] font-bold"
            />
          </button>

          <button>
            <BsArrowRightSquare
              onClick={() => {
                if (page < totalPages) {
                  setPage((prev) => prev + 1);
                }
              }}
              className="text-3xl cursor-pointer text-[#C6D0D7]"
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default Clients;
