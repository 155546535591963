import axios from "axios";
import React, { useState } from "react";
import { API_URL } from "../../../constant";
import { useAuth } from "../../../context/auth-context";

import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";

const Ongoing = ({ task, setRefetchCount }: any) => {
  const { token } = useAuth();
  const [applyloading, setApplyLoading] = useState<boolean>(false);
  const applyToTask = (id: string, taskName: string) => {
    if (!applyloading) {
      setApplyLoading(true);
      axios
        .post(
          `${API_URL}api/tasks/applications/${id}/approve`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          //   console.log(r.data.data);
          toast.success(`You have successfully approved ${taskName} task`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setTimeout(() => {
            setRefetchCount((prev: number) => prev + 1);
          }, 3000);
          setApplyLoading(false);
        })
        .catch((e: any) => {
          setApplyLoading(false);
          setRefetchCount((prev: number) => prev + 1);
          console.log(e.response.data.message);
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };
  return (
    <div className="flex justify-start  rounded-xl items-center">
      <div className="w-[30%] bg-[#323233] rounded-l-xl h-full flex flex-col justify-center items-center">
        <h4 className="font-sora font-semibold text-[36px] text-[#D9E6EF]">
          {task.points}
        </h4>
        <h4 className="  text-[#D9E6EF] text-[14px]">Points</h4>
      </div>
      <div className="w-[70%] bg-[#0D0D0D] rounded-r-xl h-full pt-8 px-6">
        <h4 className="font-medium text-white text-[14px]">{task.name}</h4>
        <h4 className=" text-[#617889] mt-2 text-[13px]">{task.description}</h4>
        <button
          onClick={() => applyToTask(task.applicationId, task.name)}
          className="w-[80px] h-[40px] flex justify-center items-center rounded-xl text-[12px] font-medium text-white mt-6 bg-secondary_button "
        >
          {applyloading ? (
            <TailSpin color="#FFFFFF" height={30} width={30} />
          ) : (
            "Approve"
          )}
        </button>
      </div>
    </div>
  );
};

export default Ongoing;
