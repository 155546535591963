import axios from "axios";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { BsArrowUpRight, BsDownload, BsFilter } from "react-icons/bs";
import { FaTimesCircle } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { API_URL } from "../../constant";
import { useAuth } from "../../context/auth-context";
import GrowthGraph from "./GrowthGraph";
import RevenueGraph from "./RevenueGraph";
import { DateRange } from "react-date-range";
import format from "date-fns/format";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const AllReports = ({ setShowAllReports, reportType, setReportType }: any) => {
  const { token, isNotified, isOpened, logout } = useAuth();
  const [userType, setUserType] = useState<string>("basic-user");
  const [activeUsers, setActiveUsers] = useState<number | any>();
  const [activatedUsers, setActivatedUsers] = useState<number | any>();
  const [totalUSD, setTotalUSD] = useState<number | any>();
  const [totalNGN, setTotalNGN] = useState<number | any>();
  const [cryptoPayoutNGN, setCryptoPayoutNGN] = useState<number | any>();
  const [cryptoPayoutUSD, setCryptoPayoutUSD] = useState<number | any>();
  const [giftcardPayoutNGN, setGiftcardPayoutNGN] = useState<number | any>();
  const [giftcardPayoutUSD, setGiftcardPayoutUSD] = useState<number | any>();
  const [totalCustomers, setTotalCustomers] = useState<number | any>();
  const [totalMerchants, setTotalMerchants] = useState<number | any>();
  const [newCustomers, setNewCustomers] = useState<number | any>();
  const [newMerchants, setNewMerchants] = useState<number | any>();
  const [upgradedUsers, setUpgradedUsers] = useState<number | any>();
  const [dropDown, setDropDown] = useState<boolean>(false);
  const [dropDownDate, setDropDownDate] = useState<boolean>(false);
  const [dropFilter, setDropFilter] = useState<boolean>(false);
  const [selectedRange, setSelectedRange] = useState<string>("");
  const [filterCount, setFilterCount] = useState<number>(0);
  const [filterDateCount, setFilterDateCount] = useState<number>(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };
  const handleMouseEnter3 = () => {
    setIsHovered3(true);
  };

  const handleMouseLeave3 = () => {
    setIsHovered3(false);
  };
  const handleMouseEnter4 = () => {
    setIsHovered4(true);
  };

  const handleMouseLeave4 = () => {
    setIsHovered4(false);
  };

  const fetchData = async () => {
    const today = new Date();
    let fromDate = "";
    let toDate = today.toISOString();

    if (selectedRange === "Today") {
      fromDate = today.toISOString();
    } else if (selectedRange === "Last 15 days") {
      const last15Days = new Date(today);
      last15Days.setDate(today.getDate() - 15);
      fromDate = last15Days.toISOString();
    } else if (selectedRange === "Last 30 days") {
      const last30Days = new Date(today);
      last30Days.setDate(today.getDate() - 30);
      fromDate = last30Days.toISOString();
    } else if (selectedRange === "Last 60 days") {
      const last60Days = new Date(today);
      last60Days.setDate(today.getDate() - 60);
      fromDate = last60Days.toISOString();
    } else if (selectedRange === "Last 90 days") {
      const last90Days = new Date(today);
      last90Days.setDate(today.getDate() - 90);
      fromDate = last90Days.toISOString();
    }

    if (selectedRange) {
      try {
        const response = await axios.get(
          `${API_URL}api/stats?from=${fromDate}&to=${toDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setActiveUsers(response.data.data.activeUsers);
        setTotalCustomers(response.data.data.totalCustomers);
        setTotalMerchants(response.data.data.totalMerchants);
        setTotalNGN(response.data.data.totalPayoutAmount);
        setTotalUSD(response.data.data.totalAmountInUSD);
        setNewCustomers(response.data.data.newCustomersCount);
        setNewMerchants(response.data.data.newMerchantsCount);
        setUpgradedUsers(response.data.data.upgradedUsers);
        setActivatedUsers(response.data.data.activatedUsers);
        setCryptoPayoutNGN(response.data.data.totalCryptoPayout);
        setCryptoPayoutUSD(response.data.data.totalCryptoPayoutInUSD);
        setGiftcardPayoutNGN(response.data.data.totalGiftCardPayout);
        setGiftcardPayoutUSD(response.data.data.totalGiftCardPayoutInUSD);
      } catch (e: any) {
        console.error("Error fetching data:", e);
        if (
          e.response.status === 401 &&
          e.response.data.message === "Inavlid token. Please login again!"
        ) {
          logout();
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedRange, logout]);

  const fetchDateData = async (startDate: any, endDate: any) => {
    console.log(startDate, endDate);

    if (endDate) {
      try {
        const response = await axios.get(
          `${API_URL}api/stats?from=${startDate}&to=${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setActiveUsers(response.data.data.activeUsers);
        setTotalCustomers(response.data.data.totalCustomers);
        setTotalMerchants(response.data.data.totalMerchants);
        setTotalNGN(response.data.data.totalPayoutAmount);
        setTotalUSD(response.data.data.totalAmountInUSD);
        setNewCustomers(response.data.data.newCustomersCount);
        setNewMerchants(response.data.data.newMerchantsCount);
        setUpgradedUsers(response.data.data.upgradedUsers);
        setActivatedUsers(response.data.data.activatedUsers);
        setCryptoPayoutNGN(response.data.data.totalCryptoPayout);
        setCryptoPayoutUSD(response.data.data.totalCryptoPayoutInUSD);
        setGiftcardPayoutNGN(response.data.data.totalGiftCardPayout);
        setGiftcardPayoutUSD(response.data.data.totalGiftCardPayoutInUSD);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const revenueData = {
    totalPayoutInNGN: totalNGN,
    totalPayoutInUSD: totalUSD,
    totalCryptoPayoutInNGN: cryptoPayoutNGN,
    totalCryptoPayoutInUSD: cryptoPayoutUSD,
    totalGiftcardPayoutInNGN: giftcardPayoutNGN,
    totalGiftcardPayoutInUSD: giftcardPayoutUSD,
  };
  const csvRevenueData = Object.entries(revenueData).map(([key, value]) => [
    key,
    value,
  ]);
  const growthData = {
    activeUsers: `${activeUsers}`,
    activatedUsers: `${activatedUsers}`,
    totalCustomers: `${totalCustomers}`,
    totalMerchants: `${totalMerchants}`,
    newCustomers: `${newCustomers}`,
    newMerchants: `${newMerchants}`,
    upgradedUsers: `${upgradedUsers}`,
  };
  const csvGrowthData = Object.entries(growthData).map(([key, value]) => [
    key,
    value,
  ]);

  const handleUserTypeChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setUserType(event.target.value);
  };
  const handleReportTypeChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setReportType(event.target.value);
  };

  useEffect(() => {
    if (selectedRange === "") {
      axios
        .get(`${API_URL}api/stats?userType=basic-user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r: any) => {
          setActiveUsers(r.data.data.activeUsers);
          setTotalCustomers(r.data.data.totalCustomers);
          setTotalMerchants(r.data.data.totalMerchants);
          setTotalNGN(r.data.data.totalPayoutAmount);
          setTotalUSD(r.data.data.totalAmountInUSD);
          setNewCustomers(r.data.data.newCustomersCount);
          setNewMerchants(r.data.data.newMerchantsCount);
          setUpgradedUsers(r.data.data.upgradedUsers);
          setActivatedUsers(r.data.data.activatedUsers);
          setCryptoPayoutNGN(r.data.data.totalCryptoPayout);
          setCryptoPayoutUSD(r.data.data.totalCryptoPayoutInUSD);
          setGiftcardPayoutNGN(r.data.data.totalGiftCardPayout);
          setGiftcardPayoutUSD(r.data.data.totalGiftCardPayoutInUSD);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
        });
    }
  }, [token, userType, selectedRange]);

  return (
    <div className="font-manrope w-full h-full">
      <div className="flex justify-between items-center">
        <h2 className="font-bold  font-sora text-[24px] text-white">
          {reportType === "Revenue"
            ? "Revenue"
            : reportType === "Growth"
            ? "Growth"
            : ""}
        </h2>
        <FaTimesCircle
          onClick={() => {
            setShowAllReports(false);
          }}
          className="text-[#78C2F7] text-[24px] cursor-pointer"
        />
      </div>
      <div className="relative flex flex-col items-end">
        <div className="w-full h-[180px] lgss:h-[110px] bg-[#1C2227] px-4 rounded-lg mt-8 gap-4 flex flex-col lgss:flex-row justify-center items-end pb-4">
          <div className="flex w-full lgss:w-2/3 gap-6 justify-center items-center">
            <div className="w-1/2 ">
              <label htmlFor="" className="font-bold text-[14px] text-[white]">
                User Type
              </label>
              <select
                value={userType}
                onChange={handleUserTypeChange}
                className="w-full outline-none font-bold mt-2 rounded-lg text-[14px] text-[#A0A9AD] h-[48px] bg-[#1C2227] border px-4 border-[#3A4852]"
              >
                <option value="all">All</option>
                <option value="basic-user">Customers</option>
                <option value="merchant">Merchants</option>
              </select>
            </div>
            <div className="w-1/2 ">
              <label htmlFor="" className="font-bold text-[14px] text-[white]">
                Report Type
              </label>
              <select
                value={reportType}
                onChange={handleReportTypeChange}
                className="w-full outline-none font-bold mt-2 rounded-lg text-[14px] text-[#A0A9AD] h-[48px] bg-[#1C2227] border px-4 border-[#3A4852]"
              >
                <option value="Revenue">Revenue</option>
                <option value="Growth">Growth</option>
              </select>
            </div>
          </div>
          <div className="w-full lgss:w-1/3 h-[48px] gap-6 flex justify-between lgss:gap-2">
            <div
              className={
                isNotified || isOpened
                  ? "lgss:w-2/3 w-1/2  "
                  : "lgss:w-2/3 w-1/2 relative "
              }
            >
              <div
                onClick={() => setDropFilter((prev) => !prev)}
                className=" border flex h-full justify-start cursor-pointer items-center gap-3 rounded-xl px-4 border-[#3A4852]"
              >
                <BsFilter className="text-[#F7F8F9] text-[24px]" />
                {filterCount > 0 || filterDateCount > 0 ? (
                  <div className="w-[24px] h-[24px] bg-[#3A4852] rounded-full flex justify-center items-center text-[11px] font-bold text-[#60B7F6]">
                    {filterCount + filterDateCount}
                  </div>
                ) : null}
                <h4 className="text-[15px] tracking-wider text-[#F7F8F9] font-medium">
                  Apply Filters
                </h4>
              </div>
            </div>
            <CSVLink
              data={`${
                reportType === "Revenue"
                  ? csvRevenueData
                  : reportType === "Growth"
                  ? csvGrowthData
                  : ""
              }`}
              filename={`${
                reportType === "Revenue"
                  ? "Revenue_Data"
                  : reportType === "Growth"
                  ? "Growth_Data"
                  : ""
              }`}
              className="lgss:w-1/3 w-1/2 h-full justify-center items-center rounded-md text-[#1C2227] bg-white gap-2 font-medium flex text-[16px]"
            >
              <BsDownload />
              Export
            </CSVLink>
          </div>
        </div>
        {dropFilter && (
          <div className="w-[35%] top-36 pb-4 absolute bg-[#273037] rounded-lg max-h-70 overflow-auto scrollbar-thumb-[#AEBCC6]    scrollbar-thin mt-2 ">
            <div
              onClick={() => setDropDown((prev) => !prev)}
              className={
                !dropDown
                  ? "flex justify-between items-center  cursor-pointer p-4 "
                  : "bg-[#3A4852] flex h-[52px] rounded-t-lg p-4 justify-between items-center  cursor-pointer"
              }
            >
              <h4 className="text-[14px] text-[#C6D0D7] font-medium">Period</h4>
              {dropDown ? (
                <IoIosArrowForward className="text-[20px] text-[#C6D0D7]" />
              ) : (
                <IoIosArrowDown className="text-[20px] text-[#C6D0D7]" />
              )}
            </div>
            {dropDown && (
              <div className="w-full p-4">
                <div className="flex justify-start gap-4 items-center">
                  <div
                    onClick={() => {
                      if (selectedRange === "Today") {
                        setSelectedRange("");
                        setDropDown((prev) => !prev);
                        setFilterCount(0);
                      } else {
                        setSelectedRange("Today");
                        setDropDown((prev) => !prev);
                        setFilterCount(1);
                      }
                    }}
                    className={
                      selectedRange === "Today"
                        ? "bg-secondary_button px-1 w-[20px] h-[20px] rounded-full border cursor-pointer border-[#828282]"
                        : "bg-transparent w-[20px] h-[20px] rounded-full border cursor-pointer border-[#828282]"
                    }
                  />
                  <h4 className="text-[#5a6c7a] text-[16px] font-medium">
                    Today
                  </h4>
                </div>
                <div className="flex justify-start mt-3 gap-4 items-center">
                  <div
                    onClick={() => {
                      if (selectedRange === "Last 15 days") {
                        setSelectedRange("");
                        setDropDown((prev) => !prev);

                        setFilterCount(0);
                      } else {
                        setSelectedRange("Last 15 days");
                        setDropDown((prev) => !prev);

                        setFilterCount(1);
                      }
                    }}
                    className={
                      selectedRange === "Last 15 days"
                        ? "bg-secondary_button px-1 w-[20px] h-[20px] rounded-full border cursor-pointer border-[#828282]"
                        : "bg-transparent w-[20px] h-[20px] rounded-full border cursor-pointer border-[#828282]"
                    }
                  />
                  <h4 className="text-[#5a6c7a] text-[16px] font-medium">
                    Last 15 days
                  </h4>
                </div>
                <div className="flex justify-start mt-3 gap-4 items-center">
                  <div
                    onClick={() => {
                      if (selectedRange === "Last 30 days") {
                        setSelectedRange("");
                        setDropDown((prev) => !prev);
                        setFilterCount(0);
                      } else {
                        setSelectedRange("Last 30 days");
                        setDropDown((prev) => !prev);

                        setFilterCount(1);
                      }
                    }}
                    className={
                      selectedRange === "Last 30 days"
                        ? "bg-secondary_button px-1 w-[20px] h-[20px] rounded-full border cursor-pointer border-[#828282]"
                        : "bg-transparent w-[20px] h-[20px] rounded-full border cursor-pointer border-[#828282]"
                    }
                  />
                  <h4 className="text-[#5a6c7a] text-[16px] font-medium">
                    Last 30 days
                  </h4>
                </div>
                <div className="flex justify-start mt-3 gap-4 items-center">
                  <div
                    onClick={() => {
                      if (selectedRange === "Last 60 days") {
                        setSelectedRange("");
                        setDropDown((prev) => !prev);
                        setFilterCount(0);
                      } else {
                        setSelectedRange("Last 60 days");
                        setDropDown((prev) => !prev);
                        setFilterCount(1);
                      }
                    }}
                    className={
                      selectedRange === "Last 60 days"
                        ? "bg-secondary_button px-1 w-[20px] h-[20px] rounded-full border cursor-pointer border-[#828282]"
                        : "bg-transparent w-[20px] h-[20px] rounded-full border cursor-pointer border-[#828282]"
                    }
                  />
                  <h4 className="text-[#5a6c7a] text-[16px] font-medium">
                    Last 60 days
                  </h4>
                </div>
                <div className="flex justify-start mt-3 gap-4 items-center">
                  <div
                    onClick={() => {
                      if (selectedRange === "Last 90 days") {
                        setSelectedRange("");
                        setDropDown((prev) => !prev);
                        setFilterCount(0);
                      } else {
                        setSelectedRange("Last 90 days");
                        setDropDown((prev) => !prev);
                        setFilterCount(1);
                      }
                    }}
                    className={
                      selectedRange === "Last 90 days"
                        ? "bg-secondary_button px-1 w-[20px] h-[20px] rounded-full border cursor-pointer border-[#828282]"
                        : "bg-transparent w-[20px] h-[20px] rounded-full border cursor-pointer border-[#828282]"
                    }
                  />
                  <h4 className="text-[#5a6c7a] text-[16px] font-medium">
                    Last 90 days
                  </h4>
                </div>
              </div>
            )}
            <div
              onClick={() => {
                if (dropDown) {
                  setDropDown(false);
                  setDropDownDate((prev) => !prev);
                } else {
                  setDropDownDate((prev) => !prev);
                }
              }}
              className={
                !dropDownDate
                  ? "flex justify-between items-center  cursor-pointer p-4 "
                  : "bg-[#3A4852] flex h-[52px]  p-4 justify-between items-center  cursor-pointer"
              }
            >
              <h4 className="text-[14px] text-[#C6D0D7] font-medium">
                Date Range
              </h4>
              {dropDownDate ? (
                <IoIosArrowForward className="text-[20px] text-[#C6D0D7]" />
              ) : (
                <IoIosArrowDown className="text-[20px] text-[#C6D0D7]" />
              )}
            </div>
            {dropDownDate && (
              <div className="w-full px-2 pt-6">
                <DateRange
                  date={new Date()}
                  onChange={(item) => {
                    setRange([item.selection as any]);
                    setFilterDateCount(1);

                    const startDate = format(
                      range[0].startDate,
                      "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
                    );
                    const endDate = format(
                      range[0].endDate,
                      "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
                    );

                    fetchDateData(startDate, endDate);
                  }}
                  editableDateInputs={true}
                  moveRangeOnFirstSelection={false}
                  ranges={range}
                  months={1}
                  maxDate={new Date()}
                  direction="horizontal"
                  className="w-full "
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="w-full grid grid-cols-2 lgss:grid-cols-4 gap-4 mt-8">
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className={
            isNotified || isOpened
              ? "px-6 pt-4 h-[130px]  bg-transparent border border-[#2C363E] rounded-lg "
              : "px-6 pt-4 h-[130px]  bg-transparent border border-[#2C363E] rounded-lg relative"
          }
        >
          <h4 className="text-[9.5px] xs:text[11.8px] font-bold text-[#0A6AAF]">
            {reportType === "Revenue"
              ? "Total Transactions processed"
              : reportType === "Growth"
              ? "Total Users"
              : ""}
          </h4>
          <h4 className="font-sora font-bold text-[15px] xs:text-[22px] text-[#BEE1FB] mt-2">
            {reportType === "Revenue"
              ? `₦ ${totalNGN ? totalNGN.toLocaleString("en-US") : 0}`
              : reportType === "Growth"
              ? `${
                  totalCustomers || totalMerchants
                    ? (totalCustomers + totalMerchants).toLocaleString("en-US")
                    : 0
                }`
              : ""}
          </h4>
          <div className="flex items-center justify-start mt-2 gap-4">
            <div className="w-[58px] h-[28px] rounded-md bg-[#273037] text-white flex justify-center items-center text-[11px]">
              {reportType === "Revenue"
                ? `$ ${totalUSD ? totalUSD.toLocaleString("en-US") : 0}`
                : reportType === "Growth"
                ? `${
                    newMerchants || newCustomers
                      ? (newMerchants + newCustomers).toLocaleString("en-US")
                      : 0
                  }`
                : ""}
            </div>
          </div>
          {isHovered && (
            <div className="absolute hidden lgss:flex w-[60%] h-[75px] transition-all transform ease-out duration-2000  top-32 left-24 rounded-lg bg-[#617889] text-[#F7F8F9] text-[12px] p-4">
              <div className="absolute -top-3  lgss:left-[50%] transform -translate-x-1/2">
                <div className="w-6 h-6 bg-[#617889] transform rotate-45 "></div>
              </div>
              {reportType === "Revenue"
                ? "Total worth of transaction received in crypto and giftcard"
                : reportType === "Growth"
                ? "Total no. of users acquired within a certain period of time"
                : ""}
            </div>
          )}
        </div>
        <div
          onMouseEnter={handleMouseEnter2}
          onMouseLeave={handleMouseLeave2}
          className={
            isHovered || isNotified || isOpened
              ? "px-6 pt-4 h-[130px]  bg-transparent border border-[#2C363E] rounded-lg"
              : "px-6 pt-4 h-[130px] relative bg-transparent border border-[#2C363E] rounded-lg"
          }
        >
          <h4 className="text-[9.5px] xs:text[11.8px] font-bold text-[#D6780B]">
            {reportType === "Revenue"
              ? "Total Crypto Payouts"
              : reportType === "Growth"
              ? "Active Users"
              : ""}
          </h4>
          <h4 className="font-sora font-bold text-[15px] xs:text-[22px] text-[#FBD8AE] mt-2">
            {reportType === "Revenue"
              ? `₦ ${
                  cryptoPayoutNGN ? cryptoPayoutNGN.toLocaleString("en-US") : 0
                }`
              : reportType === "Growth"
              ? `${activeUsers ? activeUsers.toLocaleString("en-US") : 0}`
              : ""}
          </h4>
          <div className="flex items-center justify-start mt-2 gap-4">
            <div className="w-[58px] h-[28px] rounded-md bg-[#273037] text-white flex justify-center items-center text-[11px]">
              {reportType === "Revenue"
                ? `$ ${
                    cryptoPayoutUSD
                      ? cryptoPayoutUSD.toLocaleString("en-US")
                      : 0
                  }`
                : reportType === "Growth"
                ? `${
                    activatedUsers ? activatedUsers.toLocaleString("en-US") : 0
                  }`
                : ""}
            </div>
          </div>
          {isHovered2 && (
            <div className="absolute hidden lgss:flex w-[60%] h-[75px] transition-all transform ease-out duration-2000  top-32 left-24 rounded-lg bg-[#617889] text-[#F7F8F9] text-[12px] p-4">
              <div className="absolute -top-3  lgss:left-[50%] transform -translate-x-1/2">
                <div className="w-6 h-6 bg-[#617889] transform rotate-45 "></div>
              </div>
              {reportType === "Revenue"
                ? "Total worth of payout in crypto"
                : reportType === "Growth"
                ? "No. of Users that have logged in within 30 days"
                : ""}
            </div>
          )}
        </div>
        <div
          onMouseEnter={handleMouseEnter3}
          onMouseLeave={handleMouseLeave3}
          className={
            isHovered2 || isNotified || isOpened || dropFilter
              ? "px-6 pt-4 h-[130px]   bg-transparent border border-[#2C363E] rounded-lg"
              : "px-6 pt-4 h-[130px] relative  bg-transparent border border-[#2C363E] rounded-lg"
          }
        >
          <h4 className="text-[9.5px] xs:text[11.8px] font-bold text-[#4E606E]">
            {reportType === "Revenue"
              ? "Total Giftcard payouts"
              : reportType === "Growth"
              ? "Activated Users"
              : ""}
          </h4>
          <h4 className="font-sora font-bold text-[15px] xs:text-[22px] text-[#C8D1D8] mt-2">
            {reportType === "Revenue"
              ? `₦ ${
                  giftcardPayoutNGN
                    ? giftcardPayoutNGN.toLocaleString("en-US")
                    : 0
                }`
              : reportType === "Growth"
              ? `${activatedUsers ? activatedUsers.toLocaleString("en-US") : 0}`
              : ""}
          </h4>
          <div className="flex items-center justify-start mt-2 gap-4">
            <div className="w-[58px] h-[28px] rounded-md bg-[#273037] text-white flex justify-center items-center text-[11px]">
              {reportType === "Revenue"
                ? `$ ${
                    giftcardPayoutUSD
                      ? giftcardPayoutUSD.toLocaleString("en-US")
                      : 0
                  }`
                : reportType === "Growth"
                ? `---`
                : ""}
            </div>
          </div>
          {isHovered3 && (
            <div className="absolute hidden lgss:flex w-[60%] h-[75px] transition-all transform ease-out duration-2000  top-32 left-24 rounded-lg bg-[#617889] text-[#F7F8F9] text-[12px] p-4">
              <div className="absolute -top-3  lgss:left-[50%] transform -translate-x-1/2">
                <div className="w-6 h-6 bg-[#617889] transform rotate-45 "></div>
              </div>
              {reportType === "Revenue"
                ? "Total worth of payout in Giftcard"
                : reportType === "Growth"
                ? "No of users that have done up to level 2 KYC"
                : ""}
            </div>
          )}
        </div>
        <div
          onMouseEnter={handleMouseEnter4}
          onMouseLeave={handleMouseLeave4}
          className={
            isHovered3 || isNotified || isOpened || dropFilter
              ? "px-6 pt-4 h-[130px]   bg-transparent border border-[#2C363E] rounded-lg"
              : "px-6 pt-4 h-[130px] relative  bg-transparent border border-[#2C363E] rounded-lg"
          }
        >
          <h4 className="text-[9.5px] xs:text[11.8px] font-bold text-[#8162DB]">
            {reportType === "Revenue"
              ? "Total spent on KYC"
              : reportType === "Growth"
              ? "Upgraded Users"
              : ""}
          </h4>
          <h4 className="font-sora font-bold text-[15px] xs:text-[22px] text-[#C8D1D8] mt-2">
            {reportType === "Revenue"
              ? `---`
              : reportType === "Growth"
              ? `${upgradedUsers ? upgradedUsers.toLocaleString("en-US") : 0}`
              : ""}
          </h4>
          <div className="flex items-center justify-start mt-2 gap-4">
            <div className="w-[58px] h-[28px] rounded-md bg-[#273037] text-white flex justify-center items-center text-[11px]">
              ---
            </div>
          </div>
          {isHovered4 && (
            <div className="absolute hidden lgss:flex w-[60%] h-[75px] transition-all transform ease-out duration-2000  top-32 left-10 rounded-lg bg-[#617889] text-[#F7F8F9] text-[12px] p-4">
              <div className="absolute -top-3  lgss:left-[50%] transform -translate-x-1/2">
                <div className="w-6 h-6 bg-[#617889] transform rotate-45 "></div>
              </div>
              {reportType === "Revenue"
                ? "Total money spent on KYC"
                : reportType === "Growth"
                ? "No. of users that have upgraded their account (AML)"
                : ""}
            </div>
          )}
        </div>
      </div>
      <div className="w-full flex  mt-6 gap-4 justify-center items-center">
        <div className="w-[75%] h-[400px] bg-[#1C2227] hidden lgss:flex flex-col rounded-lg px-6 pt-6 pb-4 ">
          <div className="flex justify-between items-start ">
            <div>
              <h4 className="text-[11.8px] xs:text[11.8px] font-bold text-[#0A6AAF]">
                {reportType === "Revenue"
                  ? "Total Transactions processed"
                  : reportType === "Growth"
                  ? "Total Users"
                  : ""}
              </h4>
              <h4 className="font-sora font-bold text-[15px] xs:text-[22px] text-[#BEE1FB] mt-2">
                {reportType === "Revenue"
                  ? `₦ ${totalNGN ? totalNGN.toLocaleString("en-US") : 0}`
                  : reportType === "Growth"
                  ? `${
                      totalCustomers || totalMerchants
                        ? (totalCustomers + totalMerchants).toLocaleString(
                            "en-US"
                          )
                        : 0
                    }`
                  : ""}
              </h4>
            </div>
          </div>
          {reportType === "Revenue" &&
          !isNotified &&
          !dropDownDate &&
          !dropDown ? (
            <RevenueGraph />
          ) : reportType === "Growth" &&
            !isNotified &&
            !dropDownDate &&
            !dropDown ? (
            <GrowthGraph />
          ) : null}
        </div>

        <div className="w-full mt-8 lgss:mt-0 lgss:w-[24%] h-[500px] lgss:h-[400px] px-4 rounded-xl gap-2 lgss:gap-0 flex flex-col items-start justify-center bg-[#1C2227]">
          <h4 className="text-[14px] font-bold text-white ">Quick Links</h4>
          <a
            href="http://"
            target="_blank"
            rel="noopener noreferrer"
            className="lgss:h-[40px] h-[52px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
          >
            One Liquidity Dashboard
            <BsArrowUpRight />
          </a>
          <a
            href="https://app.dojah.io/quick-actions"
            target="_blank"
            rel="noopener noreferrer"
            className="lgss:h-[40px] h-[52px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
          >
            Dojah Dashboard
            <BsArrowUpRight />
          </a>
          <a
            href="http://"
            target="_blank"
            rel="noopener noreferrer"
            className="lgss:h-[40px] h-[52px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
          >
            Aws Console
            <BsArrowUpRight />
          </a>
          <a
            href="https://join.slack.com/t/deexoptions/shared_invite/zt-1zm355s5v-5dITBJbJl5RuqtsmX2j4mg"
            target="_blank"
            rel="noopener noreferrer"
            className="lgss:h-[40px] h-[52px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
          >
            Slack
            <BsArrowUpRight />
          </a>
          <a
            href="https://wa.link/1zlp0c"
            target="_blank"
            rel="noopener noreferrer"
            className="lgss:h-[40px] h-[52px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
          >
            Customer care line
            <BsArrowUpRight />
          </a>
          <a
            href="http://"
            target="_blank"
            rel="noopener noreferrer"
            className="lgss:h-[40px] h-[52px] w-full mt-4 rounded-lg text-[#A0A9AD] font-medium text-[12px] border border-[#3A4852] flex justify-between items-center px-3"
          >
            How to use this dashboard
            <BsArrowUpRight />
          </a>
        </div>
      </div>
    </div>
  );
};

export default AllReports;
