import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { API_URL } from "../../constant";
import { useAuth } from "../../context/auth-context";
import CopyToClipboard from "react-copy-to-clipboard";

type Props = {
  currency: string;
  currencyUSD: number;
  currencyNGN: number;
  status: number;
  network: string;
  date: string;
  tsxnId: string;
  userId: string;
  setOrderModal: any;
  payoutStatus: any;
  setRefetchCount: any;
  setCryptoConfirmationModal: any;
  cryptoRate: any | number;
  orderss: any;
};
interface Bank {
  accountNumber: string;
  bankCode: string;
  accountName: string;
  isDefault: boolean;
  _id: string;
}

const OrderConfrimation = ({
  currency,
  currencyUSD,
  currencyNGN,
  status,
  network,
  date,
  userId,
  setOrderModal,
  payoutStatus,
  setCryptoConfirmationModal,
  cryptoRate,
  orderss,
}: Props) => {
  const { token } = useAuth();
  const [confirmed, setConfirmed] = useState<boolean>(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [banks, setBanks] = useState<Bank | null>(null);
  const [copyMail, setCopyMail] = useState<boolean>(false);
  const [copyAccount, setCopyAccount] = useState<boolean>(false);
  const [copyAmount, setCopyAmount] = useState<boolean>(false);
  const [allBanks, setAllBanks] = useState<any[]>([]);

  useEffect(() => {
    if (
      orderss.status === "COMPLETED" ||
      orderss.transactionType === "crypto-deposit" ||
      orderss.transactionType === "deex-crypto-withdrawals"
    ) {
      setConfirmed(true);
    } else {
      setConfirmed(false);
    }
  }, [orderss.status, orderss.transactionType]);

  useEffect(() => {
    axios
      .get(`${API_URL}api/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        // console.log(r.data.data);
        setEmail(r.data.data.user.email.address);
        setFirstName(r.data.data.user.profile.firstName);
        setLastName(r.data.data.user.profile.lastName);
        const bankList: Bank[] = r.data.data.user?.banks || [];

        // Find the bank with isDefault set to true
        const defaultBank =
          bankList.find((bank: Bank) => bank.isDefault) || null;
        setBanks(defaultBank);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, [token, userId]);

  useEffect(() => {
    axios
      .get(`${API_URL}api/banks`)
      .then((r: any) => {
        setAllBanks(r.data.data);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, []);

  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-60 backdrop-blur-sm">
      <div className="w-11/12 mds:10/12 lgss:w-2/5 transition-all p-6 ease-in duration-1000  rounded-xl bg-[#0D0D0D] ">
        <div className="flex  pt-4 justify-between items-center">
          <h4 className="font-bold text-[17px] font-sora text-white">
            New crypto order review
          </h4>
          <FaTimesCircle
            onClick={() => setOrderModal((prev: any) => !prev)}
            className="text-white text-[26px] cursor-pointer"
          />
        </div>
        <div className="w-full flex-col mt-12 justify-center   items-center ">
          <h4 className="text-[42px] text-[#DEE1E6] text-center font-bold font-sora ">
            {orderss.transactionType === "deex-crypto-withdrawals" &&
            network === "BTC"
              ? `${currencyUSD.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} BTC`
              : `$${currencyUSD.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
          </h4>
          <h4 className="text-[24px] capitalize text-[#DEE1E6] text-center font-bold font-sora ">
            {firstName} {lastName}
          </h4>
        </div>

        <div className="w-full h-[1px] bg-[#AEBCC6] mt-12 mb-4" />
        <div className="w-full mt-2 flex justify-between items-center">
          <h4 className="text-[#AEBCC6] text-[14px] font-semibold">
            Digital Asset
          </h4>
          <h4 className="text-[#869AA9] text-[15px] uppercase">{currency}</h4>
        </div>
        <div className="w-full mt-2 flex justify-between items-center">
          <h4 className="text-[#AEBCC6] text-[14px] font-semibold">Network</h4>
          <h4 className="text-[#869AA9] text-[15px] uppercase">{network}</h4>
        </div>
        <div className="w-full mt-2 flex justify-between items-center">
          <h4 className="text-[#AEBCC6] text-[14px] font-semibold">Rate</h4>
          <h4 className="text-[#869AA9] text-[15px] ">
            {orderss.transactionType === "deex-crypto-withdrawals"
              ? "----"
              : cryptoRate}
          </h4>
        </div>
        <div className="w-full mt-2 flex justify-between items-center">
          <h4 className="text-[#AEBCC6] text-[14px] font-semibold">
            Recipient address
          </h4>
          <CopyToClipboard
            text={email}
            onCopy={() => {
              setCopyMail(true);
              setTimeout(() => {
                setCopyMail(false);
              }, 3000);
            }}
          >
            {copyMail ? (
              <h4 className="text-[12px] xs:text-[14px] text-[#869AA9]">
                Copied
              </h4>
            ) : (
              <h4 className="text-[#0C84DA] text-[14px] cursor-pointer">
                {email}
              </h4>
            )}
          </CopyToClipboard>
        </div>
        <div className="w-full mt-2 flex justify-between items-center">
          <h4 className="text-[#AEBCC6] text-[14px] font-semibold">
            Amount to pay
          </h4>
          <CopyToClipboard
            text={currencyNGN.toString()}
            onCopy={() => {
              setCopyAmount(true);
              setTimeout(() => {
                setCopyAmount(false);
              }, 3000);
            }}
          >
            {copyAmount ? (
              <h4 className="text-[12px] xs:text-[14px] text-[#869AA9]">
                Copied
              </h4>
            ) : (
              <h4 className="text-[#869AA9] text-[16px] cursor-pointer">
                ₦{" "}
                {currencyNGN.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </h4>
            )}
          </CopyToClipboard>
        </div>
        <div className="w-full mt-2 flex justify-between items-center">
          <h4 className="text-[#AEBCC6] text-[14px] font-semibold">
            Cash destination
          </h4>
          <CopyToClipboard
            text={banks ? banks?.accountNumber : ""}
            onCopy={() => {
              setCopyAccount(true);
              setTimeout(() => {
                setCopyAccount(false);
              }, 3000);
            }}
          >
            {copyAccount ? (
              <h4 className="text-[12px] xs:text-[14px] text-[#869AA9]">
                Copied
              </h4>
            ) : (
              <div className="text-[#0C84DA] cursor-pointer flex gap-2 justify-center items-center font-bold text-[13px] ">
                {banks && banks.accountNumber}{" "}
                {allBanks
                  .filter((allBank) => allBank.code === banks?.bankCode)
                  .map((bank, index) => (
                    <h5 key={index} className="text-[#869AA9]">
                      {bank.name}
                    </h5>
                  ))}
              </div>
            )}
          </CopyToClipboard>
        </div>
        <div className="w-full mt-2 flex justify-between items-center">
          <h4 className="text-[#AEBCC6] text-[14px] font-semibold">Status</h4>
          {orderss.transactionType === "crypto-deposit" ||
          orderss.transactionType === "deex-crypto-withdrawals" ? (
            <td className="text-white text-[15px] w-2/12 whitespace-nowrap flex justify-end  overflow-hidden">
              <h4 className="text-[#03C668] flex justify-center items-center w-full bg-[#013019] tracking-wider h-[26px] rounded-full text-[9px] font-bold">
                COMPLETED
              </h4>
            </td>
          ) : (
            <div className="text-white text-[15px] w-2/12 whitespace-nowrap flex justify-end  overflow-hidden">
              <h4
                className={
                  orderss.status === "PENDING"
                    ? "text-[#CB9200] flex justify-center items-center w-full bg-[#181818] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                    : orderss.status === "IN PROGRESS"
                    ? "text-[#60B7F6] flex justify-center items-center w-full bg-[#181818] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                    : orderss.status === "COMPLETED"
                    ? "text-[#03C668] flex justify-center items-center w-full bg-[#0E2B1F] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                    : ""
                }
              >
                {orderss.status}
              </h4>
            </div>
          )}
        </div>
        <div className="w-full mt-2 flex justify-between items-center">
          <h4 className="text-[#AEBCC6] text-[14px] font-semibold">Date</h4>
          <h4 className="text-[#869AA9] text-[14px] ">{date}</h4>
        </div>
        <div className="flex flex-col w-full gap-4 mt-4">
          <button
            onClick={() => {
              if (status === 2) {
                setCryptoConfirmationModal((prev: any) => !prev);
                setOrderModal((prev: any) => !prev);
              }
            }}
            disabled={confirmed}
            className={
              confirmed
                ? "flex justify-center items-center h-[47px] rounded-lg bg-[#1E1E1E] text-white font-medium "
                : "flex justify-center items-center h-[47px] rounded-lg bg-[#0B84CB] text-white font-medium "
            }
          >
            <div>
              {confirmed ? (
                <h4>COMPLETED</h4>
              ) : (
                <h4>
                  {status === 1
                    ? "PENDING"
                    : status === 2
                    ? "Create Payout"
                    : ""}
                </h4>
              )}
            </div>
          </button>
          <a
            href={`mailto:${email}`}
            className="flex justify-center items-center h-[47px] rounded-lg border bg-transparent border-[#0B84CB] text-white font-medium "
          >
            Contact the client
          </a>
        </div>
      </div>
    </div>
  );
};

export default OrderConfrimation;
