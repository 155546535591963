import React, { useEffect, useState } from "react";
import { btc, eth, tether } from "../../../assets/images";
import QRCode from "react-qr-code";
import { BiCopy } from "react-icons/bi";
import { TiWarning } from "react-icons/ti";
import UsdtReceive from "./UsdtReceive";
import CopyToClipboard from "react-copy-to-clipboard";
import axios from "axios";
import { API_URL } from "../../../constant";
import { useAuth } from "../../../context/auth-context";

const Receive = ({ setRecieveModal }: any) => {
  const { token } = useAuth();
  const [btcWallet, setBtcWallet] = useState<string>("");

  useEffect(() => {
    axios
      .get(`${API_URL}api/wallets/admin?currency=all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        const walletBalance = r.data.data.quidax;
        const btcObject = walletBalance.find(
          (obj: any) => obj.name === "Bitcoin"
        );
        // const usdtObject = walletBalance.find(
        //   (obj: any) => obj.name === "USDT Tether"
        // );
        if (btcObject) {
          setBtcWallet(btcObject.deposit_address);
        }
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, [token]);
  const [ratePage, setRatePage] = useState<number>(1);
  const [copy, setCopy] = useState<boolean>(false);
  return (
    <div className="fixed inset-0 flex font-manrope justify-center lgss:justify-center items-center bg-black bg-opacity-80 pr-10 backdrop-blur-sm">
      <div className="w-11/12 lgss:w-2/5 transition-all p-6 pb-10 ease-in duration-1000  rounded-xl bg-[#1F1F1F]">
        <div className="flex   justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Receive
          </h4>
          <h4
            onClick={() => setRecieveModal((prev: any) => !prev)}
            className="text-[#78C2F7] text-[14px] font-medium cursor-pointer"
          >
            Close
          </h4>
        </div>

        <div className="w-full grid grid-cols-3 mt-8 justify-center items-center gap-4 ">
          <button
            onClick={() => setRatePage(1)}
            className={
              ratePage === 1
                ? "flex justify-center flex-col gap-3 items-center border-2 border-[#F9AA4B] rounded-xl h-[100px]"
                : "flex justify-center flex-col gap-3 items-center  rounded-xl h-[100px]"
            }
          >
            <img src={btc} alt="" />
            <h4 className="font-sora text-[14px] text-white ">BITCOIN</h4>
          </button>
          <button
            onClick={() => setRatePage(2)}
            className={
              ratePage === 2
                ? "flex justify-center flex-col gap-3 items-center border-2 border-[#F9AA4B] rounded-xl h-[100px]"
                : "flex justify-center flex-col gap-3 items-center  rounded-xl h-[100px]"
            }
          >
            <img src={tether} alt="" />
            <h4 className="font-sora text-[14px] text-white ">USDT</h4>
          </button>
          <button
            onClick={() => setRatePage(1)}
            className={
              ratePage === 3
                ? "flex justify-center flex-col gap-3 items-center border-2 border-[#F9AA4B] rounded-xl h-[100px]"
                : "flex justify-center flex-col gap-3 items-center  rounded-xl h-[100px]"
            }
          >
            <img src={eth} alt="" />
            <h4 className="font-sora text-[14px] text-white ">ETHERIUM</h4>
          </button>
        </div>
        <div className="w-full">
          {ratePage === 1 ? (
            <div className="w-full">
              {btcWallet && (
                <div className="mt-6 w-[42%] mx-auto p-2 flex justify-center bg-white items-center">
                  <QRCode value={btcWallet} size={150} />
                </div>
              )}
              <div className="flex justify-between mt-4 gap-4 items-center">
                <div className="w-4/5 border border-[#141414] text-[#A0A9AD] font-medium flex justify-start items-center px-4 text-[13px] rounded-xl h-[44px] bg-[#1C2227]">
                  {btcWallet
                    ? btcWallet.substring(0, 9) +
                      "..." +
                      btcWallet.substring(btcWallet.length - 8)
                    : "Loading"}
                </div>

                <CopyToClipboard
                  text={btcWallet}
                  onCopy={() => {
                    setCopy(true);
                    setTimeout(() => {
                      setCopy(false);
                    }, 3000);
                  }}
                >
                  <button className="w-[25%] text-secondary_button text-[12px] font-medium flex justify-center items-center gap-2 rounded-xl h-[40px] bg-[#D4EBFD]">
                    <BiCopy className="text-xl" />
                    {copy ? "Copied" : "Copy"}
                  </button>
                </CopyToClipboard>
              </div>
              <div className="w-full border  border-[#141414] text-[#A0A9AD] font-medium  px-4  text-[14px] mt-4 rounded-xl py-6 bg-[#1C2227]">
                <div className="flex justify-start items-start gap-4">
                  <TiWarning className="text-2xl text-secondary_button" />
                  <h4 className="">
                    Only send BTC to this address and on the BTC network.
                  </h4>
                </div>
                <div className="flex justify-start items-start mt-3 gap-4">
                  <div>
                    <TiWarning className="text-2xl text-secondary_button" />
                  </div>
                  <h4 className="">
                    Sending coins other than BTC or coins for a different
                    network other than BTC may result in loss.
                  </h4>
                </div>
              </div>
            </div>
          ) : ratePage === 2 ? (
            <UsdtReceive />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Receive;
