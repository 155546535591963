import React, { useEffect, useState } from "react";
import { RiCloseLine, RiSearchLine } from "react-icons/ri";
import { noTransaction, tether } from "../assets/images";
import axios from "axios";
import { API_URL } from "../constant";
import { useAuth } from "../context/auth-context";
import { Bars } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";

const SearchModal = ({ setOpenSearch }: any) => {
  const {
    token,
    setCustomerId,
    setUserActivities,
    setTransactionId,
    setClientId,
    setCustomer,
  } = useAuth();
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchLoader, setSearchLoader] = useState<boolean>(false);
  const [transactionsQuery, setTransactionsQuery] = useState<any[]>([]);
  const [giftcardTransactionsQuery, setGiftcardTransactionsQuery] = useState<
    any[]
  >([]);
  const [usersQuery, setUsersQuery] = useState<any[]>([]);
  const [recentSearches, setRecentSearches] = useState<any[]>([]);
  const [clientsQuery, setClientsQuery] = useState<any[]>([]);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchValue) {
        sendSearchQuery(searchValue);
      } else {
        handleEmptySearch();
      }
    }, 600);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchValue]);
  useEffect(() => {
    const storedRecentSearches = localStorage.getItem("recentSearches");
    if (storedRecentSearches) {
      setRecentSearches(JSON.parse(storedRecentSearches));
    }
  }, []);
  const handleEmptySearch = () => {
    setTransactionsQuery([]);
    setUsersQuery([]);
    setClientsQuery([]);
    setGiftcardTransactionsQuery([]);
  };

  const sendSearchQuery = (searchValue: string) => {
    if (searchValue) {
      setSearchLoader(true);
      axios
        .get(`${API_URL}api/stats/global-search?search=${searchValue}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r: any) => {
          setTransactionsQuery(r.data.data.transactions);
          setUsersQuery(r.data.data.users);
          setClientsQuery(r.data.data.clients);
          setGiftcardTransactionsQuery(r.data.data.giftCardTransactions);
          setSearchLoader(false);
          updateRecentSearches(searchValue);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
          setSearchLoader(false);
        });
    }
  };

  const updateRecentSearches = (query: string) => {
    const updatedSearches = [
      query,
      ...recentSearches.filter((item) => item !== query),
    ].slice(0, 5);
    setRecentSearches(updatedSearches);
    localStorage.setItem("recentSearches", JSON.stringify(updatedSearches));
  };
  const removeRecentSearch = (query: string) => {
    const updatedSearches = recentSearches.filter((item) => item !== query);
    setRecentSearches(updatedSearches);
    localStorage.setItem("recentSearches", JSON.stringify(updatedSearches));
  };

  const highlightText = (text: string, highlight: string) => {
    if (!highlight.trim()) {
      return text;
    }
    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text.split(regex);
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} className="text-blue-500 font-bold">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <div className="fixed inset-0 z-50 flex items-start px-8 lgss:px-0 pt-44 font-manrope justify-center bg-black bg-opacity-90">
      <div className="relative mx-auto w-full max-w-2xl rounded-lg bg-[#13181B] p-6">
        <div className="flex items-center space-x-3 border-b border-[#27303733] pb-4">
          <RiSearchLine className="h-5 w-5 text-[#667085]" />
          <input
            type="text"
            placeholder="Search name, email or trans. ID"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
            className="w-full bg-transparent text-[13px] lgss:text-[14px]  text-[#667085] placeholder-[#667085] focus:outline-none"
            aria-label="Search"
          />
        </div>
        {searchLoader ? (
          <div className="flex mt-8 justify-center items-center">
            <Bars color="#0B75C2" height={80} width={80} />
          </div>
        ) : (
          <div className="overflow-auto scrollbar-thumb-[#3A4852] scrollbar-track-transparent scrollbar-thin">
            {searchValue.length === 0 && recentSearches.length > 0 && (
              <div className="mt-6 space-y-4">
                <h3 className="mb-2 text-[13px] lgss:text-[14px]  font-medium text-[#475467]">
                  Recent Searches
                </h3>
                <div className="space-y-2">
                  {recentSearches.map((query, index) => (
                    <div
                      key={index}
                      onClick={() => setSearchValue(query)}
                      className="flex items-center justify-between cursor-pointer px-3 py-2 bg-[#1C2227] rounded-md text-white"
                    >
                      <span>{query}</span>
                      <RiCloseLine
                        className="h-5 w-5 text-[#667085] cursor-pointer"
                        onClick={() => removeRecentSearch(query)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {transactionsQuery.length >= 1 ||
            usersQuery.length >= 1 ||
            clientsQuery.length >= 1 ||
            giftcardTransactionsQuery.length >= 1 ? (
              <div className="mt-6 space-y-4 overflow-auto h-[400px] scrollbar-thumb-[#3A4852] scrollbar-track-transparent scrollbar-thin">
                {transactionsQuery.length >= 1 && (
                  <div className="border-b border-[#27303733] pb-4">
                    <h3 className="mb-2 text-[13px] lgss:text-[14px]  font-medium text-[#475467]">
                      Crypto transactions({transactionsQuery.length})
                    </h3>
                    <div className="space-y-2 max-h-60 overflow-y-auto">
                      {transactionsQuery.map((transaction, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            setTransactionId(transaction._id);
                            setOpenSearch(false);
                            setTimeout(() => {
                              if (location.pathname !== "/transactions") {
                                navigate("/transactions");
                              }
                            }, 800);
                          }}
                          className="grid grid-cols-3 lgss:grid-cols-5 items-center justify-between px-3 py-2 bg-[#1C2227] rounded-md"
                        >
                          <p className="text-[13px] lgss:text-[14px]  text-white">
                            {highlightText(
                              `${transaction.user[0].profile.firstName} ${transaction.user[0].profile.lastName}`,
                              searchValue
                            )}
                          </p>
                          <p className="text-[13px] lgss:text-[14px]  text-white">
                            {highlightText(
                              `${transaction._id.substring(0, 10)}`,
                              searchValue
                            )}
                            ...
                          </p>
                          <span className="text-[13px] lgss:text-[14px]  flex items-center gap-2 rounded">
                            <span className="w-[24px] h-[24px]">
                              <img
                                src={tether}
                                className="w-full h-full bg-cover"
                                alt=""
                              />
                            </span>
                            USDT
                          </span>
                          <p className="text-sm text-white">{`$${
                            transaction.usdtAmount
                              ? transaction.usdtAmount.toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0
                          }`}</p>
                          <span className="text-[#03C668] flex justify-center items-center py-2 px-3 bg-[#013019] tracking-wider rounded-full text-[11px] font-bold">
                            Completed
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {giftcardTransactionsQuery.length >= 1 && (
                  <div className="border-b border-[#27303733] pb-4">
                    <h3 className="mb-2 text-[13px] lgss:text-[14px]  font-medium text-[#475467]">
                      Giftcard transactions({giftcardTransactionsQuery.length})
                    </h3>
                    <div className="space-y-2 max-h-60 overflow-y-auto">
                      {giftcardTransactionsQuery.map((transaction, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            setTransactionId(transaction._id);
                            setOpenSearch(false);
                            setTimeout(() => {
                              if (location.pathname !== "/transactions") {
                                navigate("/transactions");
                              }
                            }, 800);
                          }}
                          className="grid grid-cols-3 lgss:grid-cols-5 items-center justify-between px-3 py-2 bg-[#1C2227] rounded-md"
                        >
                          <p className="text-[13px] lgss:text-[14px]  text-white">
                            {highlightText(
                              `${transaction.user[0].profile.firstName} ${transaction.user[0].profile.lastName}`,
                              searchValue
                            )}
                          </p>
                          <p className="text-[13px] lgss:text-[14px]  text-white">
                            {highlightText(
                              `${transaction._id.substring(0, 10)}`,
                              searchValue
                            )}
                            ...
                          </p>
                          <span className="text-[13.5px]">
                            {transaction.card.name}
                          </span>
                          <p className="text-sm text-white">
                            {`$${transaction.tradeValue
                              .reduce(
                                (total: any, item: { amount: any }) =>
                                  total + item.amount,
                                0
                              )
                              .toLocaleString("en-US")}`}
                          </p>
                          <span
                            className={
                              transaction.status === "PENDING"
                                ? "text-[#CB9200] flex justify-center items-center uppercase  bg-[#181818] tracking-wider py-2 px-3 rounded-full text-[11px] font-bold"
                                : transaction.status === "IN PROGRESS"
                                ? "text-[#60B7F6] flex justify-center items-center uppercase  bg-[#181818] tracking-wider py-2 px-3 rounded-full text-[11px] font-bold"
                                : transaction.status === "COMPLETED"
                                ? "text-[#03C668] flex justify-center items-center uppercase  bg-[#0E2B1F] tracking-wider py-2 px-3 rounded-full text-[11px] font-bold"
                                : ""
                            }
                          >
                            {transaction.status}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {usersQuery.length >= 1 && (
                  <div className="border-b border-[#27303733] pb-4">
                    <h3 className="mb-2 text-[13px] lgss:text-[14px]  font-medium text-[#475467]">
                      All customers ({usersQuery.length})
                    </h3>
                    <div className="space-y-2 max-h-60 overflow-y-auto">
                      {usersQuery.map((user, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            setCustomerId(user._id);
                            setOpenSearch(false);
                            setTimeout(() => {
                              if (location.pathname === "/users") {
                                setUserActivities(true);
                              } else {
                                navigate("/users");
                                setTimeout(() => {
                                  setUserActivities(true);
                                }, 200);
                              }
                            }, 800);
                          }}
                          className="flex items-center cursor-pointer px-3 py-2 bg-[#1C2227] rounded-md"
                        >
                          <div className="w-10 font-sora font-bold text-[13px] h-10 rounded-full bg-gradient-to-br from-gray-500 to-gray-800 flex items-center justify-center text-white">
                            <span>
                              {user.profile.firstName.charAt(0)}
                              {user.profile.lastName.charAt(0)}
                            </span>
                          </div>
                          <div className="ml-3 flex items-center gap-3">
                            <p className="text-[13px] lgss:text-[16px] font-medium text-white">
                              {highlightText(
                                `${user.profile.firstName} ${user.profile.lastName}`,
                                searchValue
                              )}
                            </p>
                            <p className="text-[13px] text-[#475467]">
                              {highlightText(user.email.address, searchValue)}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {clientsQuery.length >= 1 && (
                  <div className="border-b border-[#27303733] pb-4">
                    <h3 className="mb-2 text-[13px] lgss:text-[14px]  font-medium text-[#475467]">
                      All clients ({clientsQuery.length})
                    </h3>
                    <div className="space-y-2 max-h-60 overflow-y-auto">
                      {clientsQuery.map((client, index) => (
                        <div
                          key={index}
                          //   onClick={() => {
                          //     setClientId(client._id);
                          //     setOpenSearch(false);
                          //     setTimeout(() => {
                          //       if (location.pathname === "/users") {
                          //         setCustomer(3);
                          //       } else {
                          //         navigate("/users");
                          //         setTimeout(() => {
                          //           setCustomer(3);
                          //         }, 200);
                          //       }
                          //     }, 800);
                          //   }}
                          className="flex items-center cursor-pointer px-3 py-2 bg-[#1C2227] rounded-md"
                        >
                          <div className="w-10 font-sora font-bold text-[13px] h-10 rounded-full bg-gradient-to-br from-gray-500 to-gray-800 flex items-center justify-center text-white">
                            <span>{client.name.substring(0, 2)}</span>
                          </div>
                          <div className="ml-3 flex items-center gap-3">
                            <p className="text-[13px] lgss:text-[16px] font-medium text-white">
                              {highlightText(`${client.name}`, searchValue)}
                            </p>
                            <p className="text-[13px] text-[#475467]">
                              {highlightText(client.phone, searchValue)}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                {searchValue.length > 0 && recentSearches.length === 0 && (
                  <div className="flex flex-col justify-center mt-8 w-full items-center">
                    <img src={noTransaction} alt="" />
                    <h4 className="font-bold text-[#869AA9] pt-2 text-[13px] lgss:text-[14px] ">
                      No match for your query
                    </h4>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        <button
          onClick={() => setOpenSearch((prev: any) => !prev)}
          className="absolute top-6 right-4 text-[#78C2F7] font-medium text-[12px]"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default SearchModal;
