import React, { FC, useEffect, useState } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { BiErrorCircle } from "react-icons/bi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Register from "./Register";
import { API_URL, PROD_LOGIN_LINK, STAGING_LOGIN_LINK } from "../constant";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth-context";
import { useCookies, Cookies } from "react-cookie";
import { logo } from "../assets/images";

const Login: FC = () => {
  const { authenticate } = useAuth();
  const location = useLocation();
  const { state } = location;
  const { from = "/dashboard" } = state || {};
  const navigate = useNavigate();

  const [,] = useCookies(["admin-token"]);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const validateEmail = (email: string) => {
    // check if email is empty
    if (!email) {
      setError("Kindly tell us your mail");
      setTimeout(() => {
        setError("");
      }, 5000);
      return false;
    }
    // check if email is in the correct format using a regular expression
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (!emailRegex.test(email)) {
      setError("Your email is not in the correct format");
      setTimeout(() => {
        setError("");
      }, 6000);
      return false;
    }

    setError("");
    return true;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      return;
    }

    if (!loading) {
      setLoading(true);
      axios
        .post(`${API_URL}api/auth/request-signin-link`, {
          email,
          // linkFormat: `http://localhost:3000/verify-mail?token=<TOKEN>`,
          linkFormat: `${
            API_URL?.includes("staging") ? STAGING_LOGIN_LINK : PROD_LOGIN_LINK
          }/verify-mail?token=<TOKEN>`,
        })
        .then((r: any) => {
          setLoading(false);
          setEmail("");
          setSuccess("A login link has been sent to your mail, kindly check.");
          setTimeout(() => {
            setSuccess("");
          }, 3000);
        })
        .catch((e: any) => {
          setError(e.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setError("");
          }, 3000);
          console.log(e.response.data.message);
        });
    }
  };

  useEffect(() => {
    const cookies = new Cookies();
    const newCookie = cookies.get("admin-token");
    if (newCookie !== undefined) {
      authenticate(newCookie);
      navigate(from);
    }
  }, [authenticate, from, navigate]);

  return (
    <div className="w-full lgss:w-[60%] flex justify-start lgss:justify-center  items-center flex-col bg-[#13191C] font-manrope">
      <div className="lgss:hidden mt-24 ">
        <img src={logo} className="" alt="" />
      </div>
      <div className="flex w-full mt-6 lgss:mt-0 flex-col justify-center  items-center lgss:items-center lgss:px-20 lg:px-[70px]">
        <h2 className="text-white font-sora text-[32px] font-bold pt-4 lgss:pt-0">
          👋🏽 Welcome back Admin!
        </h2>
        <h4 className="font-manrope text-white font-medium text-[16px] pt-6">
          Sign in lets begin to help our clients
        </h4>
        <form onSubmit={handleSubmit} className="mt-12  w-10/12 ">
          {success && (
            <div className="bg-green-600 w-full text-white  text-[16px] rounded-xl justify-start items-center gap-4 flex h-[60px] px-2 font-bold mt-4 mb-4">
              <AiOutlineCheckCircle className="text-3xl text-white" />
              {success}
            </div>
          )}
          <label className="text-white font-bold text-[15px]">Email</label>
          <input
            type="email"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
            name="email"
            className="h-[58px] w-full mt-4  px-[16px]  font-manrope border-2 rounded-lg border-[#A0A9AD] outline-none bg-transparent  text-[#A0A9AD] transition duration-200"
          />

          {error && (
            <div className="bg-red-600 text-white mb-4 text-[12px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-bold mt-4">
              <BiErrorCircle className="text-xl" />
              {error}
            </div>
          )}
          <button
            type="submit"
            className="w-full h-[63px] flex justify-center items-center font-manrope font-medium rounded-xl text-white bg-secondary_button mt-8 lgss:mt-8"
          >
            {loading ? (
              <TailSpin color="#FFFFFF" height={30} width={30} />
            ) : (
              "Send a login link"
            )}
          </button>
        </form>
        <h4 className="font-manrope   text-white font-medium text-[16px] mt-10">
          Don’t have an account?{" "}
          <span
            // onClick={() => {
            //   navigate("/signup");
            // }}
            className="underline text-secondary_button cursor-pointer"
          >
            Request permission
          </span>
        </h4>
      </div>
    </div>
  );
};

const LoginPage = () => (
  <div className="flex flex-row h-screen bg-black">
    <Register />
    <Login />
  </div>
);

export default LoginPage;
