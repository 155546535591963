import axios from "axios";
import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import { API_URL } from "../../constant";
import { useAuth } from "../../context/auth-context";

const OngoingTasks = ({ task, setRefetchCount }: any) => {
  const { token } = useAuth();
  const [applyloading, setApplyLoading] = useState<boolean>(false);
  const applyToTask = (id: string, taskName: string) => {
    if (!applyloading) {
      setApplyLoading(true);
      axios
        .post(
          `${API_URL}api/tasks/applications/${id}/approve`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          //   console.log(r.data.data);
          toast.success(`You have successfully approved ${taskName} task`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setTimeout(() => {
            setRefetchCount((prev: number) => prev + 1);
          }, 3000);
          setApplyLoading(false);
        })
        .catch((e: any) => {
          setApplyLoading(false);
          console.log(e.response.data.message);
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };
  return (
    <tbody className="w-full">
      <tr className="h-[56px] flex justify-start px-16 items-center">
        <td className="text-white text-[15px] w-1/5 mr-4 overflow-hidden whitespace-nowrap ">
          {task.user.profile.firstName} {task.user.profile.lastName}
        </td>
        <td className="text-white text-[15px] w-1/5 mr-4 overflow-hidden whitespace-nowrap">
          {task.user.email.address}
        </td>
        <td className="text-white text-[15px] w-2/5 mr-4 overflow-hidden whitespace-nowrap">
          {task.task.name}
        </td>

        <td className="text-white text-[15px] w-1/5 overflow-hidden whitespace-nowrap ">
          {task.task.points}
        </td>
        <td
          onClick={() => applyToTask(task._id, task.task.name)}
          className="w-1/5   flex justify-center items-center  "
        >
          <h4 className=" px-4  py-3 rounded-full text-[#60B7F6] font-bold bg-[#3A4852] text-[11px] ">
            {applyloading ? (
              <TailSpin color="#FFFFFF" height={20} width={20} />
            ) : (
              "Approve"
            )}
          </h4>
        </td>
      </tr>
      <tr className="w-full">
        <td className="mt-1 w-full h-[1px] bg-[#13181B]" />
      </tr>
    </tbody>
  );
};

export default OngoingTasks;
