import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiErrorCircle, BiImageAdd } from "react-icons/bi";
import { IoIosArrowDown } from "react-icons/io";
import { API_URL } from "../../constant";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { TailSpin } from "react-loader-spinner";
import { useAuth } from "../../context/auth-context";
import MessageHistory from "./MessageHistory";

const SendMessage = ({ setMessageModal }: any) => {
  const { token } = useAuth();
  const [topic, setTopic] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [devices, setDevices] = useState<string[]>([]);
  const [recipients, setRecipients] = useState<string[]>([]);
  const [isChecked, setIsChecked] = useState(false);
  const [allDevicesChecked, setAllDevicesChecked] = useState(false);
  const [allRecipientChecked, setAllRecipientChecked] = useState(false);
  const [mobileChecked, setMobileChecked] = useState(false);
  const [webChecked, setWebChecked] = useState(false);
  const [leadsChecked, setLeadsChecked] = useState(false);
  const [merchantsChecked, setMerchantsChecked] = useState(false);
  const [customersChecked, setCustomersChecked] = useState(false);
  const [error, setError] = useState<string>("");
  const [dropDown, setDropDown] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [time, setTime] = useState<string>("");
  const [screen, setScreen] = useState<string>("News");
  const currentDate = new Date().toISOString().split("T")[0];
  const currentTime = new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  // const [file, setFile] = useState<File | null>(null);

  // const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const selectedFile = e.target.files ? e.target.files[0] : null;
  //   setFile(selectedFile);
  // };

  const validate = (
    devices: string[],
    recipients: string[],
    message: string,
    isChecked: boolean,
    date: string,
    time: string,
    topic: string
  ) => {
    if (devices.length === 0) {
      setError("Kindly select a device");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (recipients.length === 0) {
      setError("Kindly select recipients");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (!message) {
      setError("Kindly add the push message");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (!topic) {
      setError("Kindly add the topic of the message");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }

    if (isChecked && !date) {
      setError("Kindly add the scheduled date");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (isChecked && !time) {
      setError("Kindly add the scheduled time");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    setError("");
    return true;
  };
  const addDevice = (device: string) => {
    let updatedDevices: string[] = [];

    if (device === "all") {
      updatedDevices = ["all"];
      setAllDevicesChecked(true);
      setMobileChecked(false);
      setWebChecked(false);
    } else if (device === "mobile") {
      updatedDevices = ["mobile"];
      setAllDevicesChecked(false);
      setMobileChecked(true);
      setWebChecked(false);
    } else if (device === "web") {
      updatedDevices = ["web"];
      setAllDevicesChecked(false);
      setMobileChecked(false);
      setWebChecked(true);
    }

    setDevices(updatedDevices);
  };
  const addRecipient = (recipient: string) => {
    let updatedRecipient: string[] = [];

    if (recipient === "all") {
      updatedRecipient = ["all"];
      setAllRecipientChecked(true);
      setLeadsChecked(false);
      setCustomersChecked(false);
      setMerchantsChecked(false);
    } else if (recipient === "customers") {
      updatedRecipient = ["customers"];
      setAllRecipientChecked(false);
      setLeadsChecked(false);
      setCustomersChecked(true);
      setMerchantsChecked(false);
    } else if (recipient === "merchant") {
      updatedRecipient = ["merchant"];
      setAllRecipientChecked(false);
      setLeadsChecked(false);
      setCustomersChecked(false);
      setMerchantsChecked(true);
    } else if (recipient === "leads") {
      updatedRecipient = ["leads"];
      setAllRecipientChecked(false);
      setLeadsChecked(true);
      setCustomersChecked(false);
      setMerchantsChecked(false);
    }

    setRecipients(updatedRecipient);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validate(devices, recipients, message, isChecked, date, time, topic)) {
      return;
    }

    if (!loading) {
      setLoading(true);
      const data: { [key: string]: any } = {
        devices: devices,
        recipients: recipients,
        title: topic,
        content: message,
      };
      if (date && time) {
        const combinedDateTime = new Date(`${date}T${time}`);
        data.schedule = combinedDateTime.toISOString();
      }
      axios
        .post(`${API_URL}api/notifications/bc`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r: any) => {
          setLoading(false);
          setTopic("");
          setMessage("");
          setDevices([]);
          setRecipients([]);
          if (isChecked) {
            setSuccess("Broadcast message scheduled successfully");
          } else {
            setSuccess("Broadcast message sent successfully");
          }
          setTimeout(() => {
            setSuccess("");
            setMessageModal((prev: any) => !prev);
          }, 3000);
        })
        .catch((e: any) => {
          setError(e.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setError("");
          }, 6000);
          console.log(e.response.data.message);
        });
    }
  };

  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-80 pr-10 backdrop-blur-sm">
      <div
        className={
          " w-11/12 lgss:w-1/3 transition-all p-6 ease-in duration-1000  rounded-xl bg-[#1F1F1F] "
        }
      >
        <div className="flex   justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Send message
          </h4>
          <h4
            onClick={() => setMessageModal((prev: any) => !prev)}
            className="text-[#78C2F7] text-[14px] font-medium cursor-pointer"
          >
            Close
          </h4>
        </div>
        <div className="w-full flex mt-4 justify-start items-center gap-4">
          <h4
            onClick={() => setScreen("News")}
            className={
              screen === "News"
                ? " text-[16px] text-[#4E606E] cursor-pointer font-bold "
                : " text-[14px] text-[#333F48] cursor-pointer font-bold "
            }
          >
            New
          </h4>
          <h4
            onClick={() => setScreen("History")}
            className={
              screen === "History"
                ? " text-[16px] text-[#4E606E] cursor-pointer font-bold "
                : " text-[14px] text-[#333F48] cursor-pointer font-bold "
            }
          >
            History
          </h4>
        </div>
        {screen === "News" ? (
          <form className="w-full mt-4" onSubmit={handleSubmit}>
            {success && (
              <div className="bg-green-600 w-full text-white  text-[16px] rounded-xl justify-start items-center gap-4 flex h-[60px] px-2 font-bold mt-4 mb-4">
                <AiOutlineCheckCircle className="text-3xl text-white" />
                {success}
              </div>
            )}
            <div className="w-full flex justify-between items-center gap-2">
              <div className="w-1/2">
                <label className="text-[14px] font-bold text-white">
                  Select Device
                </label>
                <div
                  onClick={() => {
                    if (dropDown === 1) {
                      setDropDown(0);
                    } else {
                      setDropDown(1);
                    }
                  }}
                  className={
                    dropDown === 1
                      ? "flex  justify-between items-center w-full mt-2  capitalize font-bold text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-lg border border-secondary_button"
                      : "flex  justify-between items-center w-full mt-2  capitalize font-bold text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-lg border border-[#141414]"
                  }
                >
                  {devices.length >= 1 ? (
                    devices.map((device, index) => (
                      <h4 key={index} className="text-[12px]">
                        {device === "all"
                          ? "All devices"
                          : device === "web"
                          ? "Web App users only"
                          : device === "mobile"
                          ? "Mobile App users only"
                          : ""}
                      </h4>
                    ))
                  ) : (
                    <h4>Choose</h4>
                  )}

                  <IoIosArrowDown
                    className={dropDown === 1 ? "text-secondary_button" : ""}
                  />
                </div>
              </div>
              <div className="w-1/2">
                <label className="text-[14px] font-bold text-white">
                  Select Recipient
                </label>
                <div
                  onClick={() => {
                    if (dropDown === 2) {
                      setDropDown(0);
                    } else {
                      setDropDown(2);
                    }
                  }}
                  className={
                    dropDown === 2
                      ? "flex  justify-between items-center w-full mt-2  capitalize font-bold text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-lg border border-secondary_button"
                      : "flex  justify-between items-center w-full mt-2  capitalize font-bold text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-lg border border-[#141414]"
                  }
                >
                  {recipients.length >= 1 ? (
                    recipients.map((recipient, index) => (
                      <h4 key={index} className="text-[12px]">
                        {recipient === "all"
                          ? "All users"
                          : recipient === "merchant"
                          ? "Merchants only"
                          : recipient === "customers"
                          ? "Customers only"
                          : recipient === "leads"
                          ? "Leads only"
                          : ""}
                      </h4>
                    ))
                  ) : (
                    <h4>Choose</h4>
                  )}
                  <IoIosArrowDown
                    className={dropDown === 2 ? "text-secondary_button" : ""}
                  />
                </div>
              </div>
            </div>
            <div
              className={
                dropDown === 2
                  ? "w-full mt-4 flex justify-end items-start gap-2"
                  : "w-full mt-4 flex justify-between items-start gap-2"
              }
            >
              {dropDown === 1 && (
                <div className="w-1/2">
                  <div className="flex  justify-start mt-2 w-full items-center gap-2">
                    <input
                      type="checkbox"
                      checked={allDevicesChecked}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setAllDevicesChecked(isChecked);
                        addDevice("all");
                        setDropDown(0);
                      }}
                      className="h-[16px] w-[16px] bg-transparent"
                    />
                    <h4 className="text-[14px] font-semibold text-white">
                      All
                    </h4>
                  </div>
                  <div className="flex  justify-start mt-2 w-full items-center gap-2">
                    <input
                      type="checkbox"
                      checked={mobileChecked}
                      onChange={(e) => {
                        setMobileChecked(e.target.checked);
                        addDevice("mobile");
                        setDropDown(0);
                      }}
                      className="h-[16px] w-[16px] bg-transparent"
                    />
                    <h4 className="text-[14px] font-semibold text-white">
                      Mobile App users only
                    </h4>
                  </div>
                  <div className="flex  justify-start mt-2 w-full items-center gap-2">
                    <input
                      type="checkbox"
                      checked={webChecked}
                      onChange={(e) => {
                        setWebChecked(e.target.checked);
                        addDevice("web");
                        setDropDown(0);
                      }}
                      className="h-[16px] w-[16px] bg-transparent"
                    />
                    <h4 className="text-[14px] font-semibold text-white">
                      Web App users only
                    </h4>
                  </div>
                </div>
              )}
              {dropDown === 2 && (
                <div className="w-1/2">
                  <div className="flex  justify-start mt-2 w-full items-center gap-2">
                    <input
                      type="checkbox"
                      checked={allRecipientChecked}
                      onChange={(e) => {
                        setAllRecipientChecked(e.target.checked);
                        setDropDown(0);
                        addRecipient("all");
                      }}
                      className="h-[16px] w-[16px] bg-transparent"
                    />
                    <h4 className="text-[14px] font-semibold text-white">
                      All
                    </h4>
                  </div>
                  <div className="flex  justify-start mt-2 w-full items-center gap-2">
                    <input
                      type="checkbox"
                      checked={merchantsChecked}
                      onChange={(e) => {
                        setMerchantsChecked(e.target.checked);
                        setDropDown(0);
                        addRecipient("merchant");
                      }}
                      className="h-[16px] w-[16px] bg-transparent"
                    />
                    <h4 className="text-[14px] font-semibold text-white">
                      Merchants
                    </h4>
                  </div>
                  <div className="flex  justify-start mt-2 w-full items-center gap-2">
                    <input
                      type="checkbox"
                      checked={customersChecked}
                      onChange={(e) => {
                        setCustomersChecked(e.target.checked);
                        setDropDown(0);
                        addRecipient("customers");
                      }}
                      className="h-[16px] w-[16px] bg-transparent"
                    />
                    <h4 className="text-[14px] font-semibold text-white">
                      Customers
                    </h4>
                  </div>
                  <div className="flex  justify-start mt-2 w-full items-center gap-2">
                    <input
                      type="checkbox"
                      checked={leadsChecked}
                      onChange={(e) => {
                        setLeadsChecked(e.target.checked);
                        setDropDown(0);
                        addRecipient("leads");
                      }}
                      className="h-[16px] w-[16px] bg-transparent"
                    />
                    <h4 className="text-[14px] font-semibold text-white">
                      Leads
                    </h4>
                  </div>
                </div>
              )}
            </div>
            <div className="w-full ">
              <label className="text-[14px] font-bold text-white">Topic</label>
              <input
                type="text"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
                placeholder="Say something cool"
                className="w-full mt-2 text-[15px] tracking-wider placeholder:text-[15px]  text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]"
              />
            </div>
            {/* <div className="w-full mt-2">
            <label htmlFor="" className="font-bold text-[14px] text-white">
              Card name
            </label>
            <div
              onClick={() => {
                const input = document.getElementById(
                  "fileInput"
                ) as HTMLInputElement;
                input.click();
              }}
              className="h-[40px] cursor-pointer w-full mt-2 text-[14px] px-4 flex justify-between items-center  font-manrope border rounded-lg border-[#141414] outline-none bg-[#1C2227]  text-[#A0A9AD] transition duration-200"
            >
              <h4 className="font-medium text-[14px]">
                {file ? file.name : "Upload image"}
              </h4>
              {!file && <BiImageAdd className="text-[#AEBCC6] text-xl" />}

              {file && urlLoading ? (
                <TailSpin color="#0B84CB" height={25} width={25} />
              ) : file && !urlLoading ? (
                <BsCheckCircle className="text-green-600 text-xl" />
              ) : null}
            </div>
            <input
              id="fileInput"
              type="file"
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleFileUpload}
            />
          </div> */}
            <div className="w-full mt-2">
              <label className="text-[14px] font-bold text-white">
                Message
              </label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type here..."
                className="w-full mt-2 text-[15px] tracking-wider placeholder:text-[15px]  text-[#A0A9AD] pt-2 bg-[#1C2227] px-4 outline-none h-[80px] rounded-xl border border-[#141414]"
              />
            </div>
            <div className="flex  justify-start mt-4 w-full items-center gap-4">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
                className="h-[20px] w-[20px]"
              />
              <h4 className="text-[16px] font-bold text-white">
                Schedule message
              </h4>
            </div>

            {isChecked && (
              <div className="w-full flex justify-center gap-4 items-center mt-4">
                <div className="w-[55%]">
                  <label
                    htmlFor=""
                    className="text-[13px] font-bold text-white"
                  >
                    Select date
                  </label>
                  <input
                    type="date"
                    value={date}
                    min={currentDate}
                    onChange={(e) => setDate(e.target.value)}
                    className="w-full mt-2 text-[15px] tracking-wider placeholder:text-[15px]  text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]"
                  />
                </div>
                <div className="w-[45%]">
                  <label
                    htmlFor=""
                    className="text-[13px] font-bold text-white"
                  >
                    Select time
                  </label>
                  <input
                    type="time"
                    min={currentTime}
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    className=" w-full mt-2 text-[15px] tracking-wider placeholder:text-[15px]  text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]"
                  />
                </div>
              </div>
            )}
            {error && (
              <div className="bg-red-600 text-white  text-[12px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-bold mt-4">
                <BiErrorCircle className="text-xl" />
                {error}
              </div>
            )}
            <button
              type="submit"
              className="w-full h-[46px] flex justify-center items-center font-manrope font-medium rounded-xl text-white bg-secondary_button mt-4 lgss:mt-4"
            >
              {loading ? (
                <TailSpin color="#FFFFFF" height={30} width={30} />
              ) : (
                "Send now"
              )}
            </button>
          </form>
        ) : screen === "History" ? (
          <MessageHistory />
        ) : null}
      </div>
    </div>
  );
};

export default SendMessage;
