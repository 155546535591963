import React, { useEffect, useState } from "react";
import { btc, doge, ethh, shiba, tether } from "../../../assets/images";
import axios from "axios";
import { API_URL } from "../../../constant";
import { useAuth } from "../../../context/auth-context";
import { TailSpin } from "react-loader-spinner";

const CryptoRate = () => {
  const { token } = useAuth();
  const [usdtAdd, setUsdtAdd] = useState<boolean>(false);
  const [btcAdd, setBtcAdd] = useState<boolean>(false);
  const [ethAdd, setEthAdd] = useState<boolean>(false);
  const [shibaAdd, setShibaAdd] = useState<boolean>(false);
  const [dogeAdd, setDogeAdd] = useState<boolean>(false);

  const [usdtRate, setUsdtRate] = useState<number | string>("");
  const [btcRate, setBtcRate] = useState<number | string>("");
  const [shibaRate, setShibaRate] = useState<number | string>("");
  const [ethRate, setEthRate] = useState<number | string>("");
  const [dogeRate, setDogeRate] = useState<number | string>("");
  const [usdtOldRate, setUsdtOldRate] = useState<number | any>();
  const [btcOldRate, setBtcOldRate] = useState<number | any>();
  const [shibaOldRate, setShibaOldRate] = useState<number | any>();
  const [ethOldRate, setEthOldRate] = useState<number | any>();
  const [dogeOldRate, setDogeOldRate] = useState<number | any>();
  const [loader, setLoader] = useState<boolean>(false);
  const [btcloader, setBtcLoader] = useState<boolean>(false);
  const [ethloader, setEthLoader] = useState<boolean>(false);
  const [shibaloader, setShibaLoader] = useState<boolean>(false);
  const [dogeloader, setDogeLoader] = useState<boolean>(false);
  const [usdtRefetchCount, setUsdtRefetchCount] = useState<number>(0);
  const [btcRefetchCount, setBtcRefetchCount] = useState<number>(0);
  const [ethRefetchCount, setEthRefetchCount] = useState<number>(0);
  const [shibaRefetchCount, setShibaRefetchCount] = useState<number>(0);
  const [dogeRefetchCount, setDogeRefetchCount] = useState<number>(0);
  useEffect(() => {
    axios
      .get(`${API_URL}api/rates`)
      .then((r: any) => {
        if (r.data.data.rates.length > 0) {
          // console.log(r.data.data.rates);

          r.data.data.rates.forEach((rate: { abbr: any; rate: any }) => {
            switch (rate.abbr) {
              case "USDT":
                setUsdtOldRate(rate.rate);
                break;
              case "BTC":
                setBtcOldRate(rate.rate);
                break;
              case "ETH":
                setEthOldRate(rate.rate);
                break;
              case "SHIB":
                setShibaOldRate(rate.rate);
                break;
              case "DOGE":
                setDogeOldRate(rate.rate);
                break;
              default:
                console.warn(`Unknown abbreviation: ${rate.abbr}`);
            }
          });
        }
      })
      .catch((e: any) => {
        console.log(e.response.data);
      });
  }, [
    usdtRefetchCount,
    btcRefetchCount,
    ethRefetchCount,
    shibaRefetchCount,
    dogeRefetchCount,
  ]);
  const uploadUsdtRates = () => {
    if (!loader) {
      setLoader(true);
      axios
        .post(
          `${API_URL}api/rates`,
          {
            USDT: usdtRate,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          setLoader(false);
          setUsdtRate("");
          setUsdtRefetchCount((prev) => prev + 1);
          setUsdtAdd((prev) => !prev);
          // console.log(r.data.data);
        })
        .catch((e: any) => {
          setLoader(false);
          console.log(e.response.data.message);
        });
    }
  };
  const uploadBtcRates = () => {
    if (!btcloader) {
      setBtcLoader(true);
      axios
        .post(
          `${API_URL}api/rates`,
          {
            BTC: btcRate,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          setBtcLoader(false);
          setBtcRate("");
          setBtcRefetchCount((prev) => prev + 1);
          setBtcAdd((prev) => !prev);
          // console.log(r.data.data);
        })
        .catch((e: any) => {
          setBtcLoader(false);
          console.log(e.response.data.message);
        });
    }
  };
  const uploadEthRates = () => {
    if (!ethloader) {
      setEthLoader(true);
      axios
        .post(
          `${API_URL}api/rates`,
          {
            ETH: ethRate,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          setEthLoader(false);
          setEthRate("");
          setEthRefetchCount((prev) => prev + 1);
          setEthAdd((prev) => !prev);
          // console.log(r.data.data);
        })
        .catch((e: any) => {
          setEthLoader(false);
          console.log(e.response.data.message);
        });
    }
  };
  const uploadShibaRates = () => {
    if (!shibaloader) {
      setShibaLoader(true);
      axios
        .post(
          `${API_URL}api/rates`,
          {
            SHIB: shibaRate,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          setShibaLoader(false);
          setShibaRate("");
          setShibaRefetchCount((prev) => prev + 1);
          setShibaAdd((prev) => !prev);
          // console.log(r.data.data);
        })
        .catch((e: any) => {
          setShibaLoader(false);
          console.log(e.response.data.message);
        });
    }
  };
  const uploadDogeRates = () => {
    if (!dogeloader) {
      setDogeLoader(true);
      axios
        .post(
          `${API_URL}api/rates`,
          {
            DOGE: dogeRate,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          setDogeLoader(false);
          setDogeRate("");
          setDogeRefetchCount((prev) => prev + 1);
          setDogeAdd((prev) => !prev);
          // console.log(r.data.data);
        })
        .catch((e: any) => {
          setDogeLoader(false);
          console.log(e.response.data.message);
        });
    }
  };
  const isNumber = (str: any) => {
    if (str.trim() === "") {
      return false;
    }

    return !isNaN(str);
  };
  const handleUsdtChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNumber(value)) {
      setUsdtRate(Number(value));
    } else {
      setUsdtRate("");
    }
  };
  const handleBtcChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNumber(value)) {
      setBtcRate(Number(value));
    } else {
      setBtcRate("");
    }
  };
  const handleEthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNumber(value)) {
      setEthRate(Number(value));
    } else {
      setEthRate("");
    }
  };
  const handleShibaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNumber(value)) {
      setShibaRate(Number(value));
    } else {
      setShibaRate("");
    }
  };
  const handleDogeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNumber(value)) {
      setDogeRate(Number(value));
    } else {
      setDogeRate("");
    }
  };
  return (
    <table className="w-full font-manrope">
      <thead className="bg-[#1C2227] h-[53px] ">
        <tr>
          <th className="text-[16px] text-[#617889] text-left p-3 pl-8">
            Coin
          </th>
          <th className="text-[16px] text-[#617889] text-left p-3">Network</th>
          <th className="text-[16px] text-[#617889] text-center p-3">
            Current Rate
          </th>
          <th className="text-[16px] text-[#617889] text-left p-3">New Rate</th>
        </tr>
      </thead>
      <tbody className=" bg-[#0D0D0D]">
        <tr className="border-b border-[#13181B]">
          <td className="p-4 text-left  text-[16px] pl-8 ">
            <div className="gap-2 flex  justify-start items-center">
              <div className="w-[24px] h-[24px] rounded-full">
                <img src={tether} alt="" className="h-full w-full bg-cover" />
              </div>
              Tether
            </div>
          </td>
          <td className="p-4 text-left text-[16px]">
            <div className="bg-[#1D1D1D]  px-2 w-[80px] h-[34px]  text-[15px] text-white flex justify-center items-center">
              USDT
            </div>
          </td>
          <td className="p-3 text-center text-[16px]">
            {usdtOldRate ? usdtOldRate : 0}
          </td>
          {usdtAdd ? (
            <td className="p-3 text-right w-1/12">
              <div className="flex justify-end items-center gap-2">
                <input
                  type="text"
                  value={usdtRate}
                  onChange={handleUsdtChange}
                  autoFocus
                  className="w-[100px] border outline-none bg-transparent rounded-lg border-gray-600 h-[32px] text-[14px] px-2"
                />
                <button
                  disabled={!usdtRate}
                  onClick={uploadUsdtRates}
                  className={
                    !usdtRate
                      ? "w-[100px] h-[32px] rounded-lg flex justify-center items-center bg-gray-600 text-gray-300 text-[14px] "
                      : "w-[100px] h-[32px] rounded-lg flex justify-center items-center bg-secondary_button text-white text-[14px] "
                  }
                >
                  {loader ? (
                    <TailSpin color="#FFFFFF" height={25} width={25} />
                  ) : (
                    "Confirm"
                  )}
                </button>
              </div>
            </td>
          ) : (
            <td
              className="p-3 text-left text-[16px] cursor-pointer"
              onClick={() => setUsdtAdd((prev) => !prev)}
            >
              Add
            </td>
          )}
        </tr>
        <tr className="border-b border-[#13181B]">
          <td className="p-3 text-left text-[15px] pl-8 ">
            <div className="gap-2 flex justify-start items-center">
              <div className="w-[24px] h-[24px] rounded-full">
                <img src={btc} alt="" className="h-full w-full bg-cover" />
              </div>
              BTC
            </div>
          </td>
          <td className="p-4 text-left text-[16px]">
            <div className="bg-[#1D1D1D] w-[80px] h-[34px]  text-[15px] text-white flex justify-center items-center">
              Bitcoin
            </div>
          </td>
          <td className="p-3 text-center text-[16px]">
            {btcOldRate ? btcOldRate : 0}
          </td>
          {btcAdd ? (
            <td className="p-3 text-right w-1/12">
              <div className="flex justify-end items-center gap-2">
                <input
                  type="text"
                  value={btcRate}
                  onChange={handleBtcChange}
                  autoFocus
                  className="w-[100px] border outline-none bg-transparent rounded-lg border-gray-600 h-[32px] text-[14px] px-2"
                />
                <button
                  disabled={!btcRate}
                  onClick={uploadBtcRates}
                  className={
                    !btcRate
                      ? "w-[100px] h-[32px] rounded-lg flex justify-center items-center bg-gray-600 text-gray-300 text-[14px] "
                      : "w-[100px] h-[32px] rounded-lg flex justify-center items-center bg-secondary_button text-white text-[14px] "
                  }
                >
                  {btcloader ? (
                    <TailSpin color="#FFFFFF" height={25} width={25} />
                  ) : (
                    "Confirm"
                  )}
                </button>
              </div>
            </td>
          ) : (
            <td
              className="p-3 text-left text-[16px] cursor-pointer"
              onClick={() => setBtcAdd((prev) => !prev)}
            >
              Add
            </td>
          )}
        </tr>
        <tr className="border-b border-[#13181B]">
          <td className="p-3 text-left text-[15px] pl-8 ">
            <div className="gap-2 flex justify-start items-center">
              <div className="w-[24px] h-[24px] rounded-full">
                <img src={ethh} alt="" className="h-full w-full bg-cover" />
              </div>
              ETH
            </div>
          </td>
          <td className="p-4 text-left text-[16px]">
            <div className="bg-[#1D1D1D] w-[80px] h-[34px]  text-[15px] text-white flex justify-center items-center">
              Ethereum
            </div>
          </td>
          <td className="p-3 text-center text-[16px]">
            {ethOldRate ? ethOldRate : 0}
          </td>
          {ethAdd ? (
            <td className="p-3 text-right w-1/12">
              <div className="flex justify-end items-center gap-2">
                <input
                  type="text"
                  value={ethRate}
                  onChange={handleEthChange}
                  autoFocus
                  className="w-[100px] border outline-none bg-transparent rounded-lg border-gray-600 h-[32px] text-[14px] px-2"
                />
                <button
                  disabled={!ethRate}
                  onClick={uploadEthRates}
                  className={
                    !ethRate
                      ? "w-[100px] h-[32px] rounded-lg flex justify-center items-center bg-gray-600 text-gray-300 text-[14px] "
                      : "w-[100px] h-[32px] rounded-lg flex justify-center items-center bg-secondary_button text-white text-[14px] "
                  }
                >
                  {ethloader ? (
                    <TailSpin color="#FFFFFF" height={25} width={25} />
                  ) : (
                    "Confirm"
                  )}
                </button>
              </div>
            </td>
          ) : (
            <td
              className="p-3 text-left text-[16px] cursor-pointer"
              onClick={() => setEthAdd((prev) => !prev)}
            >
              Add
            </td>
          )}
        </tr>
        <tr className="border-b border-[#13181B]">
          <td className="p-3 text-left text-[15px] pl-8 ">
            <div className="gap-2 flex justify-start items-center">
              <div className="w-[24px] h-[24px] rounded-full">
                <img src={shiba} alt="" className="h-full w-full bg-cover" />
              </div>
              SHIBA
            </div>
          </td>
          <td className="p-4 text-left text-[16px]">
            <div className="bg-[#1D1D1D] w-[80px] h-[34px]  text-[15px] text-white flex justify-center items-center">
              Shiba Inu
            </div>
          </td>
          <td className="p-3 text-center text-[16px]">
            {shibaOldRate ? shibaOldRate : 0}
          </td>
          {shibaAdd ? (
            <td className="p-3 text-right w-1/12">
              <div className="flex justify-end items-center gap-2">
                <input
                  type="text"
                  value={shibaRate}
                  onChange={handleShibaChange}
                  autoFocus
                  className="w-[100px] border outline-none bg-transparent rounded-lg border-gray-600 h-[32px] text-[14px] px-2"
                />
                <button
                  disabled={!shibaRate}
                  onClick={uploadShibaRates}
                  className={
                    !shibaRate
                      ? "w-[100px] h-[32px] rounded-lg flex justify-center items-center bg-gray-600 text-gray-300 text-[14px] "
                      : "w-[100px] h-[32px] rounded-lg flex justify-center items-center bg-secondary_button text-white text-[14px] "
                  }
                >
                  {shibaloader ? (
                    <TailSpin color="#FFFFFF" height={25} width={25} />
                  ) : (
                    "Confirm"
                  )}
                </button>
              </div>
            </td>
          ) : (
            <td
              className="p-3 text-left text-[16px] cursor-pointer"
              onClick={() => setShibaAdd((prev) => !prev)}
            >
              Add
            </td>
          )}
        </tr>
        <tr className="border-b border-[#13181B]">
          <td className="p-3 text-left text-[15px] pl-8 ">
            <div className="gap-2 flex justify-start items-center">
              <div className="w-[24px] h-[24px] rounded-full">
                <img src={doge} alt="" className="h-full w-full bg-cover" />
              </div>
              DOGE
            </div>
          </td>
          <td className="p-4 text-left text-[16px]">
            <div className="bg-[#1D1D1D] w-[80px] h-[34px]  text-[15px] text-white flex justify-center items-center">
              Doge Coin
            </div>
          </td>
          <td className="p-3 text-center text-[16px]">
            {dogeOldRate ? dogeOldRate : 0}
          </td>
          {dogeAdd ? (
            <td className="p-3 text-right w-1/12">
              <div className="flex justify-end items-center gap-2">
                <input
                  type="text"
                  value={dogeRate}
                  onChange={handleDogeChange}
                  autoFocus
                  className="w-[100px] border outline-none bg-transparent rounded-lg border-gray-600 h-[32px] text-[14px] px-2"
                />
                <button
                  disabled={!dogeRate}
                  onClick={uploadDogeRates}
                  className={
                    !dogeRate
                      ? "w-[100px] h-[32px] rounded-lg flex justify-center items-center bg-gray-600 text-gray-300 text-[14px] "
                      : "w-[100px] h-[32px] rounded-lg flex justify-center items-center bg-secondary_button text-white text-[14px] "
                  }
                >
                  {dogeloader ? (
                    <TailSpin color="#FFFFFF" height={25} width={25} />
                  ) : (
                    "Confirm"
                  )}
                </button>
              </div>
            </td>
          ) : (
            <td
              className="p-3 text-left text-[16px] cursor-pointer"
              onClick={() => setDogeAdd((prev) => !prev)}
            >
              Add
            </td>
          )}
        </tr>
      </tbody>
    </table>
  );
};

export default CryptoRate;
