import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { API_URL, PROD_LINK, STAGING_LINK } from "../../constant";
import { RiErrorWarningLine } from "react-icons/ri";
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from "react-icons/io";
import { BsArrowUpRight } from "react-icons/bs";
import { BiExport } from "react-icons/bi";
import Customers from "./Customers";
import CustomerModal from "./CustomerModal";
import UserActivity from "./UserActivity";
import { CSVLink } from "react-csv";
import UsersGraph from "./UsersGraph";
import { useAuth } from "../../context/auth-context";
import axios from "axios";
import { formatNumber } from "../../utils/FormatPerson";
import Clients from "./Clients";
import { FaArrowCircleRight } from "react-icons/fa";
import CreateClientModal from "./CreateClientModal";
import SuccessClientModal from "./SuccessClientModal";
import { getDateRange } from "../../utils/GetDateRange";

interface TotalPayoutState {
  amountChange: number | null;
  percentageChange: number | null;
}

const Users = () => {
  const {
    isOpened,
    token,
    setCustomerId,
    filter,
    userActivities,
    setUserActivities,
    clientId,
    setClientId,
    customer,
    setCustomer,
    logout,
  } = useAuth();

  const [kycLevel, setKycLevel] = useState<string>("");
  const [biggestClient, setBiggestClient] = useState<any>({});
  const [starUser, setStarUser] = useState<any>({});

  const [date, setDate] = useState<string>("");
  const [customerModal, setCustomerModal] = useState<boolean>(false);
  const [customersNew, setCustomersNew] = useState<any[]>([]);
  const [clientsNew, setClientsNew] = useState<any[]>([]);
  const [userStats, setUserStats] = useState<any>({});

  const [createClientModal, setCreateClientModal] = useState<boolean>(false);
  const [successClientModal, setSuccessClientModal] = useState<boolean>(false);
  const [refetchCount, setRefetchCount] = useState<number>(0);
  const [totalUsers, setTotalUsers] = useState<TotalPayoutState>({
    amountChange: null,
    percentageChange: null,
  });

  useEffect(() => {
    const fetchStats = async (filter: string) => {
      try {
        const { current, previous } = getDateRange(filter);

        // Fetch current period data
        const currentResponse = await axios.get(
          `${API_URL}${
            filter === ""
              ? `api/stats`
              : `api/stats?from=${current.from}&to=${current.to}`
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (filter === "") {
          setStarUser(currentResponse.data.data.starUser);
          setBiggestClient(currentResponse.data.data.biggestClient);
          setUserStats(currentResponse.data.data);
        }
        // Fetch previous period data
        const previousResponse = await axios.get(
          `${API_URL}${
            filter === ""
              ? `api/stats`
              : `api/stats?from=${previous.from}&to=${previous.to}`
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (
          currentResponse.data.status === "success" &&
          previousResponse.data.status === "success" &&
          filter !== ""
        ) {
          const currentData = currentResponse.data.data;
          const previousData = previousResponse.data.data;
          setStarUser(currentResponse.data.data.starUser);
          setBiggestClient(currentResponse.data.data?.biggestClient);
          setUserStats(currentResponse.data.data);
          const currentMetrics = {
            totalCustomers: currentData.totalCustomers,
            totalMerchants: currentData.totalMerchants,
          };

          const previousMetrics = {
            totalCustomers: previousData.totalCustomers,
            totalMerchants: previousData.totalMerchants,
          };

          const currentTotalUsers =
            currentMetrics.totalCustomers + currentMetrics.totalMerchants;
          const previousTotalUsers =
            previousMetrics.totalCustomers + previousMetrics.totalMerchants;

          // Compare metrics
          const calculatePercentageChange = (
            current: number,
            previous: number
          ) => {
            if (previous === 0) {
              return current === 0 ? 0 : 100; // Handle case where previous value is 0
            }
            return ((current - previous) / previous) * 100;
          };

          const totalUsersPercentageChange = calculatePercentageChange(
            currentTotalUsers,
            previousTotalUsers
          );

          setTotalUsers({
            amountChange: currentTotalUsers - previousTotalUsers,
            percentageChange: totalUsersPercentageChange,
          });
        }
      } catch (e: any) {
        console.error("Error fetching stats:", e);
        if (
          e.response.status === 401 &&
          e.response.data.message === "Inavlid token. Please login again!"
        ) {
          logout();
        }
      }
    };

    fetchStats(filter);
  }, [filter, token, logout]);

  const clientData = clientsNew.map((client) => ({
    name: `${client.name}`,
    phone: `${client.phone}`,
    clientID: `${client._id}`,
    dateCreated: `${(() => {
      const dateString = client.createdAt;
      const date = new Date(dateString);

      const day = date.getDate();
      const ordinalSuffix = (day: number) => {
        switch (day) {
          case 1:
          case 21:
          case 31:
            return "st";
          case 2:
          case 22:
            return "nd";
          case 3:
          case 23:
            return "rd";
          default:
            return "th";
        }
      };

      const formattedDate = `${date.toLocaleString("default", {
        month: "short",
      })} ${day}${ordinalSuffix(
        day
      )}, ${date.getFullYear()}, ${date.toLocaleString("default", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })}`;

      return formattedDate;
    })()}`,
  }));
  const data = customersNew.map((customer) => ({
    name: `${customer.profile.firstName} ${customer.profile.lastName}`,
    email: `${customer.email.address}`,
    contact: `${customer.phone.number ? customer.phone.number : "Not added"}`,
    kyc: `${customer.kyc.status}`,
    dateCreated: `${(() => {
      const dateString = customer.createdAt;
      const date = new Date(dateString);

      const day = date.getDate();
      const ordinalSuffix = (day: number) => {
        switch (day) {
          case 1:
          case 21:
          case 31:
            return "st";
          case 2:
          case 22:
            return "nd";
          case 3:
          case 23:
            return "rd";
          default:
            return "th";
        }
      };

      const formattedDate = `${date.toLocaleString("default", {
        month: "short",
      })} ${day}${ordinalSuffix(
        day
      )}, ${date.getFullYear()}, ${date.toLocaleString("default", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })}`;

      return formattedDate;
    })()}`,
    lastLogin: `${(() => {
      const dateString = customer.lastLogin;
      const date = new Date(dateString);

      const day = date.getDate();
      const ordinalSuffix = (day: number) => {
        switch (day) {
          case 1:
          case 21:
          case 31:
            return "st";
          case 2:
          case 22:
            return "nd";
          case 3:
          case 23:
            return "rd";
          default:
            return "th";
        }
      };

      const formattedDate = `${date.toLocaleString("default", {
        month: "short",
      })} ${day}${ordinalSuffix(
        day
      )}, ${date.getFullYear()}, ${date.toLocaleString("default", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })}`;

      return formattedDate;
    })()}`,
  }));

  return (
    <div className="w-full">
      <div className="w-full h-[31px] bg-[#1B1B1B] flex justify-center items-center">
        {API_URL?.includes("staging") ? (
          <h4 className="font-medium text-[#AEBCC6] text-[12px]">
            You are in Staging:{" "}
            <span>
              <a href={PROD_LINK} className="text-[#03C668]">
                Switch to Prod
              </a>
            </span>
          </h4>
        ) : (
          <h4 className="font-medium text-[#AEBCC6] text-[12px]">
            You are in PROD:{" "}
            <span>
              <a href={STAGING_LINK} className="text-[#DF4E4E]">
                Switch to Staging
              </a>
            </span>
          </h4>
        )}
      </div>
      <div className="w-full lgss:flex lgss:flex-row  h-screen">
        <Sidebar />
        <div className="w-full lgss:w-10/12 h-full overflow-auto bg-[#13181B]">
          <Navbar />
          <div
            className={
              userActivities
                ? "w-full px-5 h-full pt-10 mds:px-12 font-manrope lgss:px-10 pb-24 bg-[#13181B]"
                : "w-full px-5 h-full pt-16 mds:px-12 font-manrope lgss:px-10 pb-24 bg-[#13181B]"
            }
          >
            {!userActivities ? (
              <>
                <div className="w-full flex flex-col  gap-4 lgss:flex-row items-start justify-center">
                  <div className="lgss:w-1/2 w-full   p-6 bg-[#1C2227]">
                    <div className="w-full flex  justify-between items-center">
                      <h4 className="text-[12px] text-[#E7EBEE] font-bold">
                        Total users
                      </h4>
                      <RiErrorWarningLine className="text-[15px] text-[#AEBCC6]" />
                    </div>
                    <div className="flex justify-start gap-3 items-start mt-2">
                      <h4 className="font-sora font-bold text-[24px] text-[#BEE1FB] ">
                        {userStats.totalCustomers || userStats.totalMerchants
                          ? userStats.totalCustomers + userStats.totalMerchants
                          : 0}
                      </h4>
                      {filter !== "" && (
                        <div className="flex flex-col items-start justify-center">
                          <div className="flex justify-start items-center">
                            {totalUsers.percentageChange! > 0 && (
                              <IoIosArrowRoundUp className="text-[15px] text-[#5EAE68]" />
                            )}

                            {totalUsers.percentageChange! > 0 ? (
                              <h4 className="font-sora font-semibold text-[#869AA9] text-[10px]">
                                {Math.abs(totalUsers.percentageChange!).toFixed(
                                  2
                                )}
                                %
                              </h4>
                            ) : null}
                          </div>
                          <div className="flex justify-start items-center mt-3">
                            {totalUsers.percentageChange! < 0 && (
                              <IoIosArrowRoundDown className="text-[15px] text-[#DF4E4E]" />
                            )}
                            {totalUsers.percentageChange! < 0 ? (
                              <h4 className="font-sora font-semibold text-[#869AA9] text-[10px]">
                                {Math.abs(totalUsers.percentageChange!).toFixed(
                                  2
                                )}
                                %
                              </h4>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex justify-start items-center mt-4 gap-2">
                      <div className="w-[10px] h-[10px] rounded-full bg-[#5EAE68]" />
                      <h4 className="font-sora font-semibold text-[#869AA9] text-[10px]">
                        CURRENTLY ONLINE
                      </h4>
                    </div>

                    <div className="w-full flex justify-center gap-1 mt-6 ">
                      {starUser && starUser !== null && (
                        <div
                          className={
                            biggestClient && biggestClient !== null
                              ? "bg-[#13181B] p-4 w-1/2"
                              : "bg-[#13181B] p-4 w-full"
                          }
                        >
                          <div className="flex justify-between items-center">
                            <h4 className="text-[12px] text-[#AEBCC6] ">
                              Star Customer
                            </h4>
                            {starUser && (
                              <h4 className="text-[12px] text-[#E7EBEE] font-bold ">
                                {starUser.referralCount} Referral
                                {starUser.referralCount > 1 && "s"}
                              </h4>
                            )}
                          </div>
                          <div className="flex justify-between items-center mt-4">
                            {starUser !== null &&
                              starUser.firstName &&
                              starUser.lastName && (
                                <div className="flex justify-start items-center gap-2">
                                  <div className="w-[35px] h-[35px] uppercase font-sora font-bold text-[13px] rounded-full flex justify-center items-center bg-indigo-300">
                                    {starUser.firstName.charAt(0)}
                                    {starUser.lastName.charAt(0)}
                                  </div>
                                  <h4
                                    className={`font-sora font-extrabold text-[#DEE1E6] ${
                                      starUser.firstName.length +
                                        starUser.lastName.length >
                                      20
                                        ? "text-[12px]"
                                        : "text-[14px]"
                                    }`}
                                  >
                                    {starUser.firstName} {starUser.lastName}
                                  </h4>
                                </div>
                              )}
                            <button
                              onClick={() => {
                                setCustomerId(starUser.userId);
                                setTimeout(() => {
                                  setUserActivities(true);
                                }, 800);
                              }}
                              className="cursor-pointer"
                            >
                              <BsArrowUpRight className="text-[14px] text-[#279DF3]" />
                            </button>
                          </div>
                        </div>
                      )}
                      {biggestClient && biggestClient !== null && (
                        <div
                          className={
                            starUser && starUser !== null
                              ? "bg-[#13181B] p-4 w-1/2"
                              : "bg-[#13181B] p-4 w-full"
                          }
                        >
                          <div className="flex justify-between items-center">
                            <h4 className="text-[12px] text-[#AEBCC6] ">
                              Biggest Client
                            </h4>
                            {biggestClient !== null &&
                              biggestClient.totalAmount && (
                                <h4 className="text-[12px] text-[#E7EBEE] font-bold ">
                                  $
                                  {biggestClient.totalAmount
                                    ? biggestClient.totalAmount.toLocaleString(
                                        "en-US",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )
                                    : 0}
                                </h4>
                              )}
                          </div>
                          {biggestClient.profile && (
                            <div className="flex justify-between items-center mt-4">
                              <div className="flex justify-start items-center gap-2">
                                <div className="w-[35px] h-[35px] uppercase font-sora font-bold text-[13px] gap-1 rounded-full flex justify-center items-center bg-indigo-300">
                                  {biggestClient.profile.firstName.charAt(0)}
                                  {biggestClient.profile.lastName.charAt(0)}
                                </div>
                                <h4
                                  className={`font-sora capitalize font-extrabold text-[#DEE1E6] ${
                                    biggestClient.profile.firstName.length +
                                      biggestClient.profile.lastName.length >
                                    20
                                      ? "text-[12px]"
                                      : "text-[14px]"
                                  }`}
                                >
                                  {biggestClient.profile.firstName}{" "}
                                  {biggestClient.profile.lastName}
                                </h4>
                              </div>
                              <button
                                onClick={() => {
                                  setCustomerId(biggestClient.userId);

                                  setTimeout(() => {
                                    setUserActivities(true);
                                  }, 800);
                                }}
                                className="cursor-pointer"
                              >
                                <BsArrowUpRight className="text-[14px] text-[#279DF3]" />
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {userStats && (
                      <div className="w-full mt-6 grid grid-cols-1 gap-2">
                        <div className="w-full  flex justify-between items-center  h-[23px] bg-[#273037]">
                          <div className="h-full pl-2 w-[1%] bg-[#3A4852] flex justify-start items-center">
                            <h4 className="mr-2 text-[12px] text-[#617889] ">
                              Leads
                            </h4>
                          </div>
                          <h4 className="mr-2 text-[12px] font-bold text-[#617889]">
                            0
                          </h4>
                        </div>
                        <div className="w-full  flex justify-between items-center  h-[23px] bg-[#273037]">
                          <div
                            style={{
                              width: `${
                                (userStats.totalCustomers /
                                  (userStats.totalCustomers +
                                    userStats.totalMerchants)) *
                                100
                              }%`,
                            }}
                            className="h-full pl-2  bg-[#3A4852] flex justify-start items-center"
                          >
                            <h4 className="mr-2 text-[12px] text-[#617889] ">
                              Customers
                            </h4>
                          </div>
                          <h4 className="mr-2 text-[12px] font-bold text-[#617889]">
                            {formatNumber(userStats.totalCustomers)}
                          </h4>
                        </div>
                        <div className="w-full  flex justify-between items-center  h-[23px] bg-[#273037]">
                          <div
                            style={{
                              width: `${
                                (userStats.totalMerchants /
                                  (userStats.totalCustomers +
                                    userStats.totalMerchants)) *
                                100
                              }%`,
                            }}
                            className="h-full pl-2 w-4/5 bg-[#3A4852] flex justify-start items-center"
                          >
                            <h4 className="mr-2 text-[12px] text-[#617889] ">
                              Merchants
                            </h4>
                          </div>
                          <h4 className="mr-2 text-[12px] font-bold text-[#617889]">
                            {formatNumber(userStats.totalMerchants)}
                          </h4>
                        </div>
                        <div className="w-full  flex justify-between items-center  h-[23px] bg-[#273037]">
                          <div
                            style={{
                              width: `${
                                (userStats.totalClients /
                                  (userStats.totalCustomers +
                                    userStats.totalMerchants)) *
                                100
                              }%`,
                            }}
                            className="h-full pl-2 w-1/5 bg-[#3A4852] flex justify-start items-center"
                          >
                            <h4 className="mr-2 text-[12px] text-[#617889] ">
                              Clients
                            </h4>
                          </div>
                          <h4 className="mr-2 text-[12px] font-bold text-[#617889]">
                            {formatNumber(userStats.totalClients)}
                          </h4>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="lgss:w-1/2 w-full   p-6 bg-[#1C2227]">
                    <div className="w-full flex justify-start items-center">
                      <h4 className="text-[12px] text-[#E7EBEE] font-bold">
                        Sign up count
                      </h4>
                    </div>
                    {!isOpened && (
                      <div>
                        <UsersGraph />
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full flex justify-between items-center mt-28">
                  <h4 className="text-white font-sora font-semibold text-[12px]">
                    Activity
                  </h4>
                  <CSVLink
                    data={
                      customer === 1
                        ? data
                        : customer === 2
                        ? ""
                        : customer === 3
                        ? clientData
                        : ""
                    }
                    filename={`${
                      customer === 1
                        ? "customers-list.csv"
                        : customer === 2
                        ? "merchants-list.csv"
                        : customer === 3
                        ? "clients-list.csv"
                        : ""
                    }`}
                    className="flex gap-1 items-center"
                  >
                    <BiExport className="text-[white]" />
                    <h4 className="uppercase font-sora font-semibold mt-1 text-[#869AA9] text-[10px]">
                      Export
                    </h4>
                  </CSVLink>
                </div>
                <div className="bg-[#0D0D0D] w-full flex justify-between px-5 items-center h-[67px] mt-4">
                  <div className="flex justify-start h-full items-center gap-6">
                    <button
                      onClick={() => setCustomer(1)}
                      className={
                        customer === 1
                          ? "border-secondary_button h-full px-6 text-[16px] font-semibold text-secondary_button border-b-2 "
                          : " h-full px-6 text-[14px]  text-[#C6D0D7]  "
                      }
                    >
                      Customers
                    </button>
                    <button
                      onClick={() => setCustomer(2)}
                      className={
                        customer === 2
                          ? "border-secondary_button h-full px-6 text-[16px] font-semibold text-secondary_button border-b-2 "
                          : " h-full px-6 text-[14px]  text-[#C6D0D7]  "
                      }
                    >
                      Merchants
                    </button>
                    <button
                      onClick={() => setCustomer(3)}
                      className={
                        customer === 3
                          ? "border-secondary_button h-full px-6 text-[16px] font-semibold text-secondary_button border-b-2 "
                          : " h-full px-6 text-[14px]  text-[#C6D0D7]  "
                      }
                    >
                      Clients
                    </button>
                  </div>
                  {customer === 3 && (
                    <button
                      onClick={() => setCreateClientModal(true)}
                      className="px-4 py-2 rounded-xl gap-2 bg-secondary_button text-white text-[15px] justify-center items-center flex"
                    >
                      <h4>Create new client</h4>
                      <FaArrowCircleRight className="text-[11px]" />
                    </button>
                  )}
                </div>

                {customer === 1 ? (
                  <Customers
                    setKycLevel={setKycLevel}
                    setDate={setDate}
                    setCustomerModal={setCustomerModal}
                    setCustomersNew={setCustomersNew}
                  />
                ) : customer === 3 ? (
                  <Clients
                    refetchCount={refetchCount}
                    setClientId={setClientId}
                    setSuccessClientModal={setSuccessClientModal}
                    setClientsNew={setClientsNew}
                  />
                ) : null}
              </>
            ) : (
              <UserActivity
                setUserActivities={setUserActivities}
                kycLevel={kycLevel}
              />
            )}
          </div>
        </div>
      </div>
      {customerModal ? (
        <CustomerModal
          date={date}
          setUserActivities={setUserActivities}
          kycLevel={kycLevel}
          setCustomerModal={setCustomerModal}
        />
      ) : null}
      {createClientModal && (
        <CreateClientModal
          setCreateClientModal={setCreateClientModal}
          setSuccessClientModal={setSuccessClientModal}
          setClientId={setClientId}
        />
      )}
      {successClientModal && (
        <SuccessClientModal
          setSuccessClientModal={setSuccessClientModal}
          clientId={clientId}
          setRefetchCount={setRefetchCount}
        />
      )}
    </div>
  );
};

export default Users;
