import axios from "axios";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { noTransaction } from "../../../assets/images";
import { API_URL } from "../../../constant";
import { useAuth } from "../../../context/auth-context";
import Ongoing from "./Ongoing";
import Past from "./Past";

const Tasks = ({ customerId }: any) => {
  const [activityScreen, setActivityScreen] = useState<number>(1);
  const { token } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [tasks, setTasks] = useState<any[]>([]);
  const [refetchCount, setRefetchCount] = useState<number>(0);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}api/tasks/${customerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        // console.log(r.data.data);
        const filtered = r.data.data.tasks.filter((item: any) => {
          return item.applied === true && item.applicationStatus === "pending";
        });
        setTasks(filtered);
        setLoading(false);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
        setLoading(false);
      });
  }, [token, refetchCount, customerId]);
  return (
    <div className="w-full pb-20 overflow-auto font-manrope mt-4 bg-transparent scrollbar-thumb-[#AEBCC6]    scrollbar-thin">
      <div className="w-full flex  justify-start items-center lgss:w-2/5 px-4 gap-8  bg-transparent  rounded-lg  h-[50px]">
        <h4
          onClick={() => setActivityScreen(1)}
          className={
            activityScreen === 1
              ? "text-[#C6D0D7] cursor-pointer font-bold text-[16px]"
              : "text-[#4E606E] cursor-pointer font-bold text-[15px]"
          }
        >
          Ongoing
        </h4>
        <h4
          onClick={() => setActivityScreen(2)}
          className={
            activityScreen === 2
              ? "text-[#C6D0D7] cursor-pointer font-bold text-[16px]"
              : "text-[#4E606E] cursor-pointer font-bold text-[15px]"
          }
        >
          Past
        </h4>
      </div>
      {activityScreen === 1 ? (
        <div className="w-full">
          {loading ? (
            <div className="mt-12 w-full h-[600px] lgss:h-[400px] flex justify-center items-start">
              <TailSpin color="#0B75C2" height={70} width={90} />
            </div>
          ) : (
            <div className="w-full">
              {tasks.length >= 1 ? (
                <div className="w-full mt-12 pt-6 pb-20 grid gap-6 grid-cols-1 md:grid-cols-2 overflow-auto scrollbar-thumb-[#60B7F6] dark:scrollbar-thumb-[#AEBCC6]    scrollbar-thin lg:grid-cols-3">
                  {tasks.map((task, index) => (
                    <Ongoing
                      task={task}
                      key={index}
                      setRefetchCount={setRefetchCount}
                    />
                  ))}
                </div>
              ) : (
                <div className="flex flex-col justify-center mt-8 h-[600px] lgss:h-[400px]  lgss:mt-12 items-center">
                  <img src={noTransaction} alt="" />
                  <h4 className="font-bold text-[#869AA9] pt-2">
                    No task at the moment
                  </h4>
                  <p className=" text-[#869AA9] pt-2 text-[12px]">
                    All user available tasks will show up here
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      ) : activityScreen === 2 ? (
        <Past customerId={customerId} />
      ) : null}
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default Tasks;
