import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { BiCopy } from "react-icons/bi";
import { TiWarning } from "react-icons/ti";
import QRCode from "react-qr-code";
import { useAuth } from "../../../context/auth-context";
import axios from "axios";
import { API_URL } from "../../../constant";

const UsdtReceive = () => {
  const { token } = useAuth();
  const [selectedNetwork, setSelectedNetwork] = useState<string>("BEP-20");
  const [bepWallet, setBepWallet] = useState<string>("");
  const [ercWallet, setErcWallet] = useState<string>("");
  const [trcWallet, setTrcWallet] = useState<string>("");
  const [copy, setCopy] = useState<boolean>(false);

  const handleTypeChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedNetwork(event.target.value);
  };

  useEffect(() => {
    axios
      .get(`${API_URL}api/wallets/admin?currency=usdt`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        const walletBalance = r.data.data.quidax;
        const usdtObject = walletBalance.find(
          (obj: any) => obj.name === "USDT Tether"
        );

        setBepWallet(usdtObject.deposit_address);
        // setErcWallet(r.data.data.addresses[1].address);
        // setTrcWallet(r.data.data.addresses[2].address);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, [token]);

  return (
    <div className="w-full">
      {/* {walletAddress && ( */}
      <select
        value={selectedNetwork}
        onChange={handleTypeChange}
        className="w-full mt-2 capitalize font-bold text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]"
      >
        <option value="BEP-20">Binance Smart Chain (BEP-20)</option>
        {/* <option value="ERC-20">Etherium Smart Chain (ERC-20)</option>
        <option value="TRC-20">Tron Smart Chain (TRC-20)</option> */}
      </select>
      {bepWallet && (
        <div className="mt-6 w-[42%] mx-auto p-2 flex justify-center bg-white items-center">
          <QRCode
            value={
              selectedNetwork === "BEP-20"
                ? bepWallet
                : selectedNetwork === "ERC-20"
                ? ercWallet
                : selectedNetwork === "TRC-20"
                ? trcWallet
                : ""
            }
            size={150}
          />
        </div>
      )}
      <div className="flex justify-between mt-4 gap-4 items-center">
        <div className="w-4/5 border border-[#141414] text-[#A0A9AD] font-medium flex justify-start items-center px-4 text-[13px] rounded-xl h-[44px] bg-[#1C2227]">
          {selectedNetwork === "BEP-20"
            ? bepWallet.substring(0, 9) +
              "..." +
              bepWallet.substring(bepWallet.length - 8)
            : selectedNetwork === "ERC-20"
            ? ercWallet.substring(0, 9) +
              "..." +
              ercWallet.substring(ercWallet.length - 8)
            : selectedNetwork === "TRC-20"
            ? trcWallet.substring(0, 9) +
              "..." +
              trcWallet.substring(trcWallet.length - 8)
            : "Loading"}
        </div>
        <CopyToClipboard
          text={
            selectedNetwork === "BEP-20"
              ? bepWallet
              : selectedNetwork === "ERC-20"
              ? ercWallet
              : selectedNetwork === "TRC-20"
              ? trcWallet
              : "Loading"
          }
          onCopy={() => {
            setCopy(true);
            setTimeout(() => {
              setCopy(false);
            }, 3000);
          }}
        >
          <button className="w-[25%] text-secondary_button text-[12px] font-medium flex justify-center items-center gap-2 rounded-xl h-[40px] bg-[#D4EBFD]">
            <BiCopy className="text-xl" />
            {copy ? "Copied" : "Copy"}
          </button>
        </CopyToClipboard>
      </div>
      <div className="w-full border  border-[#141414] text-[#A0A9AD] font-medium  px-4  text-[14px] mt-4 rounded-xl py-6 bg-[#1C2227]">
        <div className="flex justify-start items-start gap-4">
          <TiWarning className="text-2xl text-secondary_button" />
          <h4 className="">
            Only send USDT to this address and on the {selectedNetwork} network.
          </h4>
        </div>
        <div className="flex justify-start items-start mt-3 gap-4">
          <div>
            <TiWarning className="text-2xl text-secondary_button" />
          </div>
          <h4 className="">
            Sending coins other than USDT or coins for a different network other
            than {selectedNetwork} may result in loss.
          </h4>
        </div>
      </div>
    </div>
  );
};

export default UsdtReceive;
