import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { API_URL, PROD_LINK, STAGING_LINK, reports } from "../../constant";
import AllReports from "./AllReports";

const Reports = () => {
  const [showAllReports, setShowAllReports] = useState<boolean>(false);

  const [reportType, setReportType] = useState<string>("Revenue");
  return (
    <div className="w-full">
      <div className="w-full h-[31px] bg-[#1B1B1B] flex justify-center items-center">
        {API_URL?.includes("staging") ? (
          <h4 className="font-medium text-[#AEBCC6] text-[12px]">
            You are in Staging:{" "}
            <span>
              <a href={PROD_LINK} className="text-[#03C668]">
                Switch to Prod
              </a>
            </span>
          </h4>
        ) : (
          <h4 className="font-medium text-[#AEBCC6] text-[12px]">
            You are in PROD:{" "}
            <span>
              <a href={STAGING_LINK} className="text-[#DF4E4E]">
                Switch to Staging
              </a>
            </span>
          </h4>
        )}
      </div>
      <div className="w-full lgss:flex lgss:flex-row  h-screen">
        <Sidebar />
        <div className="w-full lgss:w-10/12 h-full overflow-auto bg-[#13181B]">
          <Navbar />

          <div className="w-full px-5 h-full pt-8 mds:px-12 font-manrope lgss:px-10 pb-24 bg-[#13181B]">
            {showAllReports ? (
              <AllReports
                setShowAllReports={setShowAllReports}
                reportType={reportType}
                setReportType={setReportType}
              />
            ) : (
              <div className="w-full grid grid-cols-1 mds:grid-cols-2 lgss:grid-cols-3 gap-6">
                {reports.map((report, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      if (
                        report.title === "Revenue" ||
                        report.title === "Growth"
                      ) {
                        setReportType(report.title);
                        setShowAllReports(true);
                      } else {
                        setShowAllReports(false);
                      }
                    }}
                    className="h-[310px] rounded-[6px] border border-[#2C363E] px-7 flex flex-col justify-center items-start bg-transparent"
                  >
                    <div className="w-full h-[169.7px]">
                      <img
                        src={report.image}
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <h4 className="font-bold text-[#0A6AAF] text-[12px] mt-3">
                      {report.title}
                    </h4>
                    <h4 className="text-[#A0A9AD] font-medium text-[15px] mt-3">
                      {report.content}
                    </h4>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
