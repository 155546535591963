import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/auth-context";
import { API_URL } from "../../constant";
import axios from "axios";
import { noTransaction } from "../../assets/images";
import { TailSpin } from "react-loader-spinner";
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { useLocation } from "react-router-dom";

const Payouts = ({
  setStatus,
  setCurrencyUSD,
  setCurrencyNGN,
  setCurrency,
  setDate,
  setGiftcardPayoutModal,
  setNewPayout,
}: any) => {
  const {
    token,
    setPayoutUserId,
    setCryptoModal,
    urlStatus,
    setFailedpayouts,
    transactionId,
  } = useAuth();
  const [payouts, setPayouts] = useState<any[]>([]);
  const location = useLocation();
  const [payoutLoader, setPayoutLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [sortedData, setSortedData] = useState<any[]>([]);

  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | "">("");
  const [sortOrderType, setSortOrderType] = useState<"asc" | "desc" | "">("");
  const [sortOrderDate, setSortOrderDate] = useState<"asc" | "desc" | "">("");
  const [sortOrderAmount, setSortOrderAmount] = useState<"asc" | "desc" | "">(
    ""
  );

  const [currentpage, setCurrentPage] = useState<number>();

  const [totalPages, setTotalPages] = useState<number>(1);
  const [transactionCount, setTransactionCount] = useState<number>(1);
  const timeout = transactionId ? 500 : 1000;

  useEffect(() => {
    setPayoutLoader(true);
    setTimeout(() => {
      axios
        .get(
          `${API_URL}api/payouts?page=${page}&limit=${
            location.pathname === "/dashboard"
              ? 10
              : transactionId
              ? transactionCount
              : 25
          }${urlStatus === "FAILED" ? `&status=FAILED` : ``}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          const transactions = r.data.data.payouts;
          if (urlStatus === "FAILED") {
            setFailedpayouts(r.data.data.payouts);
          }
          if (transactionId) {
            const newTransaction = transactions.find(
              (txn: any) => txn._id === transactionId
            );

            setPayouts(newTransaction ? [newTransaction] : []);
          } else {
            setPayouts(transactions);
          }
          setTotalPages(r.data.data.meta.totalPages);
          setCurrentPage(r.data.data.meta.currentPage);
          setTransactionCount(r.data.data.meta.totalPayouts);
          setPayoutLoader(false);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
        });
    }, timeout);
  }, [
    location.pathname,
    page,
    setFailedpayouts,
    timeout,
    token,
    transactionCount,
    transactionId,
    urlStatus,
  ]);
  useEffect(() => {
    axios
      .get(
        `${API_URL}api/payouts?limit=${transactionCount}${
          urlStatus === "FAILED" ? `&status=FAILED` : ``
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r: any) => {
        setNewPayout(r.data.data.payouts);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, [token, transactionCount, urlStatus]);

  useEffect(() => {
    if (sortOrder === "asc") {
      setSortedData(
        [...payouts].sort((a, b) => {
          const nameA = `${a.user.profile.firstName} ${a.user.profile.lastName}`;
          const nameB = `${b.user.profile.firstName} ${b.user.profile.lastName}`;
          return nameA.localeCompare(nameB);
        })
      );
    } else if (sortOrder === "desc") {
      setSortedData(
        [...payouts].sort((a, b) => {
          const nameA = `${a.user.profile.firstName} ${a.user.profile.lastName}`;
          const nameB = `${b.user.profile.firstName} ${b.user.profile.lastName}`;
          return nameB.localeCompare(nameA);
        })
      );
    } else {
      setSortedData(payouts);
    }
  }, [sortOrder, payouts]);
  useEffect(() => {
    if (sortOrderType === "asc") {
      setSortedData(
        [...payouts].sort((a, b) => {
          const nameA = `${a.asset.name}`;
          const nameB = `${b.asset.name}`;
          return nameA.localeCompare(nameB);
        })
      );
    } else if (sortOrderType === "desc") {
      setSortedData(
        [...payouts].sort((a, b) => {
          const nameA = `${a.asset.name}`;
          const nameB = `${b.asset.name}`;
          return nameB.localeCompare(nameA);
        })
      );
    } else {
      setSortedData(payouts);
    }
  }, [sortOrderType, payouts]);

  useEffect(() => {
    if (sortOrderAmount === "asc") {
      setSortedData(
        [...payouts].sort((a, b) => {
          const amountA = a.asset.amountInUSD.toFixed(2);
          const amountB = b.asset.amountInUSD.toFixed(2);
          return amountA - amountB;
        })
      );
    } else if (sortOrderAmount === "desc") {
      setSortedData(
        [...payouts].sort((a, b) => {
          const amountA = a.asset.amountInUSD.toFixed(2);
          const amountB = b.asset.amountInUSD.toFixed(2);
          return amountB - amountA;
        })
      );
    } else {
      setSortedData(payouts);
    }
  }, [sortOrderAmount, payouts]);

  useEffect(() => {
    if (sortOrderDate === "asc") {
      setSortedData(
        [...payouts].sort(
          (a, b) =>
            new Date(a.createdAt || a.processedAt).getTime() -
            new Date(b.createdAt || b.processedAt).getTime()
        )
      );
    } else if (sortOrderDate === "desc") {
      setSortedData(
        [...payouts].sort(
          (a, b) =>
            new Date(b.createdAt || b.processedAt).getTime() -
            new Date(a.createdAt || a.processedAt).getTime()
        )
      );
    } else {
      setSortedData(payouts);
    }
  }, [sortOrderDate, payouts]);

  return (
    <div className="w-full h-full">
      {payoutLoader ? (
        <div className="lgss:flex hidden gap-5 w-full  h-[480px] mb-20 ">
          <div className="relative w-full space-y-3 overflow-hidden  bg-[#5c6b73] bg-opacity-30 p-8 shadow before:absolute before:inset-0 before:-translate-x-full before:bg-gradient-to-r before:from-transparent before:via-white/40 hover:shadow-lg before:animate-[shimmer_1.3s_infinite]">
            <div className="h-16 w-full  bg-[#E7EBEE]"></div>

            <div className="grid grid-cols-4 gap-2 mt-4">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-4 gap-2 mt-4">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-4 gap-2 mt-4">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-4 gap-2 mt-4">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-4 gap-2 mt-4">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-4 gap-2 mt-4">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-4 gap-2 mt-4">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-4 gap-2 mt-4">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
          </div>
        </div>
      ) : sortedData.length >= 1 ? (
        <div className="bg-[#0D0D0D] hidden overflow-auto scrollbar-thumb-[#60B7F6] mb-10 scrollbar-track-white  scrollbar-thin   cursor-pointer lgss:flex lgss:flex-col w-full h-[612px] ">
          <table className="w-full">
            <thead>
              <tr className="h-[56px] bg-[#1C2227] flex justify-start px-8 items-center ">
                <th
                  onClick={() => {
                    if (sortOrder === "desc") {
                      setSortOrder("asc");
                    } else {
                      setSortOrder("desc");
                    }
                  }}
                  className="text-[#617889] gap-12 cursor-pointer mr-4 flex justify-start items-center text-[15px] w-2/12  whitespace-nowrap "
                >
                  Name
                  <div className="flex flex-col justify-center items-center">
                    <TiArrowSortedUp
                      className={
                        sortOrder === "asc"
                          ? "text-[#BDBDBE] text-[14px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                    <TiArrowSortedDown
                      className={
                        sortOrder === "desc"
                          ? "text-[#BDBDBE] text-[14px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                  </div>
                </th>
                <th
                  onClick={() => {
                    if (sortOrderType === "desc") {
                      setSortOrderType("asc");
                    } else {
                      setSortOrderType("desc");
                    }
                  }}
                  className="text-[#617889] gap-12 cursor-pointer flex justify-start items-center text-[15px] w-2/12  whitespace-nowrap "
                >
                  Asset
                  <div className="flex flex-col justify-center items-center">
                    <TiArrowSortedUp
                      className={
                        sortOrderType === "asc"
                          ? "text-[#BDBDBE] text-[14px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                    <TiArrowSortedDown
                      className={
                        sortOrderType === "desc"
                          ? "text-[#BDBDBE] text-[14px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                  </div>
                </th>
                <th className="text-[#617889] text-[15px] whitespace-nowrap w-2/12 text-left">
                  Type
                </th>
                <th
                  onClick={() => {
                    if (sortOrderAmount === "desc") {
                      setSortOrderAmount("asc");
                    } else {
                      setSortOrderAmount("desc");
                    }
                  }}
                  className="text-[#617889] gap-8 cursor-pointer flex justify-start items-center text-[15px] w-2/12  whitespace-nowrap "
                >
                  Amount
                  <div className="flex flex-col justify-center items-center">
                    <TiArrowSortedUp
                      className={
                        sortOrderAmount === "asc"
                          ? "text-[#BDBDBE] text-[14px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                    <TiArrowSortedDown
                      className={
                        sortOrderAmount === "desc"
                          ? "text-[#BDBDBE] text-[14px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                  </div>
                </th>
                <th className="text-[#617889] text-[15px] mr-3 whitespace-nowrap w-2/12 text-left">
                  Trans ID
                </th>
                <th
                  onClick={() => {
                    if (sortOrderDate === "desc") {
                      setSortOrderDate("asc");
                    } else {
                      setSortOrderDate("desc");
                    }
                  }}
                  className="text-[#617889] gap-12 cursor-pointer flex justify-start items-center text-[15px] w-2/12  whitespace-nowrap "
                >
                  Date
                  <div className="flex flex-col justify-center items-center">
                    <TiArrowSortedUp
                      className={
                        sortOrderDate === "desc"
                          ? "text-[#BDBDBE] text-[12px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                    <TiArrowSortedDown
                      className={
                        sortOrderDate === "asc"
                          ? "text-[#BDBDBE] text-[12px]"
                          : "text-[#617889] text-[12px]"
                      }
                    />
                  </div>
                </th>
                <th className="text-[#617889] text-[15px] whitespace-nowrap w-2/12 text-center">
                  Status
                </th>
              </tr>
            </thead>
            {sortedData.map((payout, index) => (
              <tbody
                key={index}
                onClick={() => {
                  // setUserId(payout.userId);
                  setPayoutUserId(payout.userId);
                  setStatus(payout.status);
                  setCurrencyUSD(payout.asset.amountInUSD);
                  setCurrencyNGN(payout.payoutAmount);
                  setCurrency(payout.asset.name);
                  setDate(() => {
                    const dateString = payout.createdAt || payout.processedAt;
                    const date = new Date(dateString);

                    const day = date.getDate();
                    const ordinalSuffix = (day: number) => {
                      switch (day) {
                        case 1:
                        case 21:
                        case 31:
                          return "st";
                        case 2:
                        case 22:
                          return "nd";
                        case 3:
                        case 23:
                          return "rd";
                        default:
                          return "th";
                      }
                    };

                    const formattedDate = `${date.toLocaleString("default", {
                      month: "short",
                    })} ${day}${ordinalSuffix(
                      day
                    )}, ${date.getFullYear()} | ${date.toLocaleString(
                      "default",
                      {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      }
                    )}`;

                    return formattedDate;
                  });
                  if (payout.transaction.type === "GIFT-CARD") {
                    setGiftcardPayoutModal((prev: any) => !prev);
                  } else {
                    setCryptoModal((prev: any) => !prev);
                  }
                }}
                className="w-full cursor-pointer"
              >
                <tr className="h-[56px] w-full flex justify-start px-8   items-center">
                  <td className="text-white text-[14px] w-2/12 mr-4 whitespace-nowrap text-left overflow-hidden">
                    {payout.user.profile.firstName}{" "}
                    {payout.user.profile.lastName}
                  </td>
                  <td className="text-white text-[14px] w-2/12 uppercase whitespace-nowrap text-left overflow-hidden">
                    {payout.asset.name}
                  </td>
                  <td className="text-white text-[14px] w-2/12 whitespace-nowrap text-left overflow-hidden">
                    Sell
                  </td>
                  <td className="text-white text-[14px] w-2/12 whitespace-nowrap text-left overflow-hidden">
                    $ {payout.asset.amountInUSD.toFixed(2)}
                  </td>
                  <td className="text-white text-[14px] mr-4 w-2/12 whitespace-nowrap text-left overflow-hidden">
                    {payout._id}
                  </td>

                  <td className="text-white text-[14px] w-2/12 whitespace-nowrap text-left overflow-hidden">
                    {(() => {
                      const dateString = payout.createdAt || payout.processedAt;
                      const date = new Date(dateString);

                      const day = date.getDate();
                      const ordinalSuffix = (day: number) => {
                        switch (day) {
                          case 1:
                          case 21:
                          case 31:
                            return "st";
                          case 2:
                          case 22:
                            return "nd";
                          case 3:
                          case 23:
                            return "rd";
                          default:
                            return "th";
                        }
                      };

                      const formattedDate = `${date.toLocaleString("default", {
                        month: "short",
                      })} ${day}${ordinalSuffix(
                        day
                      )}, ${date.getFullYear()} | ${date.toLocaleString(
                        "default",
                        {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }
                      )}`;

                      return formattedDate;
                    })()}
                  </td>
                  <td className="text-white text-[15px] w-2/12 whitespace-nowrap flex justify-end  overflow-hidden">
                    <h4
                      className={
                        payout.status === "PENDING"
                          ? "text-[#CB9200] flex justify-center items-center w-4/5 bg-[#181818] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                          : payout.status === "FAILED"
                          ? "text-[#e37979] flex justify-center items-center w-4/5 bg-[#6d3030] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                          : payout.status === "COMPLETED"
                          ? "text-[#03C668] flex justify-center items-center w-4/5 bg-[#013019] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                          : ""
                      }
                    >
                      {payout.status}
                    </h4>
                  </td>
                </tr>
                <tr className="w-full">
                  <td className="mt-1 w-full h-[1px] bg-[#13181B]" />
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      ) : (
        <div className="flex flex-col justify-center w-full mt-16 mb-60 lgss:mt-24 items-center">
          <img src={noTransaction} alt="" />
          <h4 className="font-bold text-[#869AA9] text-[14px] pt-2">
            No recent payout
          </h4>
          <p className=" text-[#869AA9] pt-2 text-[12px]">
            All payouts will show up here
          </p>
        </div>
      )}
      <div className="bg-[#1C2227] overflow-auto scrollbar-thumb-[#AEBCC6]  scrollbar-thin  rounded-tl-lg  lgss:hidden flex flex-col w-full h-[652px]  mb-6  rounded-lg px-5 xs:px-8 pt-4  ">
        {payoutLoader ? (
          <div className="mt-6 flex lgss:hidden justify-center items-center">
            <TailSpin color="#0B75C2" height={50} width={70} />
          </div>
        ) : sortedData.length >= 1 ? (
          <div>
            {sortedData.map((payout, index) => (
              <div
                className="w-full mt-4 cursor-pointer"
                key={index}
                onClick={() => {
                  setPayoutUserId(payout.userId);
                  setStatus(payout.status);
                  setCurrencyUSD(payout.asset.amountInUSD);
                  setCurrencyNGN(payout.payoutAmount);
                  setCurrency(payout.asset.name);
                  setDate(() => {
                    const dateString = payout.createdAt || payout.processedAt;
                    const date = new Date(dateString);

                    const day = date.getDate();
                    const ordinalSuffix = (day: number) => {
                      switch (day) {
                        case 1:
                        case 21:
                        case 31:
                          return "st";
                        case 2:
                        case 22:
                          return "nd";
                        case 3:
                        case 23:
                          return "rd";
                        default:
                          return "th";
                      }
                    };

                    const formattedDate = `${date.toLocaleString("default", {
                      month: "short",
                    })} ${day}${ordinalSuffix(
                      day
                    )}, ${date.getFullYear()} | ${date.toLocaleString(
                      "default",
                      {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      }
                    )}`;

                    return formattedDate;
                  });
                  if (payout.transaction.type === "GIFT-CARD") {
                    setGiftcardPayoutModal((prev: any) => !prev);
                  } else {
                    setCryptoModal((prev: any) => !prev);
                  }
                }}
              >
                <div className="w-full justify-between flex items-center">
                  <div>
                    <h4 className="text-white">{payout.asset.name}</h4>
                    <p className="mt-4 font-medium text-[13px] text-[#869AA9]">
                      {(() => {
                        const dateString =
                          payout.createdAt || payout.processedAt;
                        const date = new Date(dateString);

                        const day = date.getDate();
                        const ordinalSuffix = (day: number) => {
                          switch (day) {
                            case 1:
                            case 21:
                            case 31:
                              return "st";
                            case 2:
                            case 22:
                              return "nd";
                            case 3:
                            case 23:
                              return "rd";
                            default:
                              return "th";
                          }
                        };

                        const formattedDate = `${date.toLocaleString(
                          "default",
                          {
                            month: "short",
                          }
                        )} ${day}${ordinalSuffix(
                          day
                        )}, ${date.getFullYear()} | ${date.toLocaleString(
                          "default",
                          {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          }
                        )}`;

                        return formattedDate;
                      })()}
                    </p>
                  </div>
                  <div className="w-2/12">
                    <h4 className="text-white text-right">
                      $ {payout.asset.amountInUSD.toFixed(2)}
                    </h4>

                    <h4
                      className={
                        payout.status === "PENDING"
                          ? "text-[#CB9200] flex justify-center items-center w-full mt-2 bg-[#181818] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                          : payout.status === "FAILED"
                          ? "text-[#e37979] flex justify-center items-center w-full mt-2 bg-[#6d3030] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                          : payout.status === "COMPLETED"
                          ? "text-[#03C668] flex justify-center items-center w-full mt-2 bg-[#013019] tracking-wider h-[26px] rounded-full text-[9px] font-bold"
                          : ""
                      }
                    >
                      {payout.status}
                    </h4>
                  </div>
                </div>
                <div className="mt-6 w-full h-[1px] bg-[#273037]" />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col w-full justify-center mt-16 lgss:mt-24 items-center">
            <img src={noTransaction} alt="" />
            <h4 className="font-bold text-[#869AA9] text-[14px] pt-2">
              No recent payout
            </h4>
            <p className=" text-[#869AA9] pt-2 text-[12px]">
              All payouts will show up here
            </p>
          </div>
        )}
      </div>

      {payouts.length >= 1 && (
        <div className="flex gap-4 pb-10 justify-end items-center">
          <h4 className="font-bold font-sora text-[14px] text-white">
            Page {currentpage} of {totalPages}
          </h4>
          <button className="flex justify-center items-center">
            <BsArrowLeftSquare
              onClick={() => {
                if (page > 1) {
                  setPage((prev) => prev - 1);
                }
              }}
              className="text-3xl cursor-pointer text-[#C6D0D7] font-bold"
            />
          </button>

          <button>
            <BsArrowRightSquare
              onClick={() => {
                if (page < totalPages) {
                  setPage((prev) => prev + 1);
                }
              }}
              className="text-3xl cursor-pointer text-[#C6D0D7]"
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default Payouts;
