import React, { useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useAuth } from "../../context/auth-context";
import axios from "axios";
import { API_URL } from "../../constant";
import { ApexOptions } from "apexcharts";

const WalletGraph = () => {
  const { token } = useAuth();
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); // Get the current month (0-indexed)

  const [cryptoYearlyPayout, setCryptoYearlyPayout] = useState<any[]>([]);
  const [giftcardYearlyPayout, setGiftcardYearlyPayout] = useState<any[]>([]);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const xAxisCategories = months.slice(0, currentMonth + 1);

  useEffect(() => {
    setTimeout(() => {
      axios
        .get(`${API_URL}api/stats/graph-data/CRYPTO/YEAR?userType=basic-user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r: any) => {
          setCryptoYearlyPayout(r.data.data);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
        });
    }, 2000);
  }, [token]);
  useEffect(() => {
    setTimeout(() => {
      axios
        .get(
          `${API_URL}api/stats/graph-data/GIFT-CARD/YEAR?userType=basic-user`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          setGiftcardYearlyPayout(r.data.data);
        })
        .catch((e: any) => {
          console.log(e.response.data.message);
        });
    }, 2000);
  }, [token]);

  const returnMonthData = (arr: any[]) => {
    const emptyArr = Array(12).fill(0);

    for (let i = 1; i <= emptyArr.length; i++) {
      const monthItem = arr.find(
        (item: { _id: { month: number } }) => item._id.month === i
      );
      emptyArr[i - 1] = monthItem ? monthItem.total.toFixed(2) : 0;
    }

    return emptyArr;
  };

  const chartData = useMemo(
    () => ({
      options: {
        chart: {
          type: "bar",
          stacked: true,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          intersect: false,
          theme: "dark",
          y: {
            formatter: (value: any) =>
              // `${
              //   payoutCurrency === "USD"
              //     ? `$${value}k`
              //     : payoutCurrency === "NGN"
              //     ? `N${value}k`
              //     : null
              // }}`
              `${`$${value.toLocaleString("en-US")}`}`,
          },
        },
        stroke: {
          curve: "smooth",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        xaxis: {
          type: "category",
          categories: xAxisCategories,
          tickAmount: 12, // Show all 12 months on the x-axis
          labels: {
            style: {
              fontSize: "12px", // Adjust font size
              fontFamily: "Manrope, sans-serif", // Change font family
              fontWeight: "normal", // Set font weight
              colors: "#617889", // Set font color
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "12px", // Adjust font size
              fontFamily: "Manrope, sans-serif", // Change font family
              fontWeight: "normal", // Set font weight
              colors: "#617889", // Set font color
            },
            formatter: (value: any) => `${`$${value.toLocaleString("en-US")}`}`,
          },
          min: 0,
          max:
            Math.max(
              ...returnMonthData(cryptoYearlyPayout),
              ...returnMonthData(giftcardYearlyPayout)
            ) + 100,
          stepSize: 5,
        },

        fill: {
          opacity: 1,
        },
        legend: {
          position: "bottom",
          offsetX: 0,
          offsetY: 6,
          fontSize: "12px",
          fontFamily: "Manrope, sans-serif",
          fontWeight: 500,

          labels: {
            colors: "#617889",
            useSeriesColors: false,
          },
        },

        grid: {
          show: false, // Remove the horizontal and vertical grid lines
        },
      },
      series: [
        {
          name: "Crypto",
          data: returnMonthData(cryptoYearlyPayout).slice(0, currentMonth + 1),
          color: "#2385DF",
        },
        {
          name: "Giftcard",
          data: returnMonthData(giftcardYearlyPayout).slice(
            0,
            currentMonth + 1
          ),
          color: "#9DD0FF",
        },
      ],
    }),
    [cryptoYearlyPayout, currentMonth, giftcardYearlyPayout, xAxisCategories]
  );

  return (
    <div id="chart" className="w-full">
      <ReactApexChart
        options={chartData.options as ApexOptions}
        series={chartData.series}
        type="bar"
        height={360}
      />
    </div>
  );
};

export default WalletGraph;
