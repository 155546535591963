import axios from "axios";
import React, { useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BiErrorCircle } from "react-icons/bi";
import { TailSpin } from "react-loader-spinner";
import { API_URL } from "../../constant";
import { useAuth } from "../../context/auth-context";

const SendUserMessage = ({ setOpenSendUserMessageModal, customerId }: any) => {
  const { token } = useAuth();
  const [error, setError] = useState<string>("");

  const [topic, setTopic] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [isChecked, setIsChecked] = useState(false);

  const [time, setTime] = useState<string>("");
  const currentDate = new Date().toISOString().split("T")[0];
  const currentTime = new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  const validate = (
    message: string,
    isChecked: boolean,
    date: string,
    time: string,
    topic: string
  ) => {
    if (!message) {
      setError("Kindly add the push message");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (!topic) {
      setError("Kindly add the message topic");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }

    if (isChecked && !date) {
      setError("Kindly add the scheduled date");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    if (isChecked && !time) {
      setError("Kindly add the scheduled time");
      setTimeout(() => {
        setError("");
      }, 3000);
      return false;
    }
    setError("");
    return true;
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validate(message, isChecked, date, time, topic)) {
      return;
    }
    if (!loading) {
      setLoading(true);
      const data: { [key: string]: any } = {
        userId: customerId,
        title: topic,
        content: message,
      };
      if (date && time) {
        const combinedDateTime = new Date(`${date}T${time}`);
        data.schedule = combinedDateTime.toISOString();
      }
      axios
        .post(`${API_URL}api/notifications`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r: any) => {
          setLoading(false);
          setTopic("");
          setMessage("");
          if (isChecked) {
            setSuccess("Message scheduled successfully");
          } else {
            setSuccess("Message sent successfully");
          }
          setTimeout(() => {
            setSuccess("");
            setOpenSendUserMessageModal((prev: any) => !prev);
          }, 3000);
        })
        .catch((e: any) => {
          setError(e.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setError("");
          }, 6000);
          console.log(e.response.data.message);
        });
    }
  };
  return (
    <div className="fixed inset-0 flex font-manrope justify-center items-center bg-black bg-opacity-80 pr-10 backdrop-blur-sm">
      <div className=" w-11/12 lgss:w-1/3 transition-all p-6 ease-in duration-1000  rounded-xl bg-[#1F1F1F] h-[full]">
        <div className="flex   justify-between items-center">
          <h4 className="font-bold text-[20px] font-sora text-white">
            Send message
          </h4>
          <h4
            onClick={() => setOpenSendUserMessageModal((prev: any) => !prev)}
            className="text-[#78C2F7] text-[14px] font-medium cursor-pointer"
          >
            Close
          </h4>
        </div>

        <form className="w-full mt-4" onSubmit={handleSubmit}>
          {success && (
            <div className="bg-green-600 w-full text-white  text-[16px] rounded-xl justify-start items-center gap-4 flex h-[60px] px-2 font-bold mt-4 mb-4">
              <AiOutlineCheckCircle className="text-3xl text-white" />
              {success}
            </div>
          )}

          <div className="w-full ">
            <label className="text-[14px] font-bold text-white">Topic</label>
            <input
              type="text"
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              placeholder="Say something cool"
              className="w-full mt-2 text-[15px] tracking-wider placeholder:text-[15px]  text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]"
            />
          </div>
          <div className="w-full mt-2">
            <label className="text-[14px] font-bold text-white">Message</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type here..."
              className="w-full mt-2 text-[15px] tracking-wider placeholder:text-[15px]  text-[#A0A9AD] pt-2 bg-[#1C2227] px-4 outline-none h-[120px] rounded-xl border border-[#141414]"
            />
          </div>
          <div className="flex  justify-start mt-4 w-full items-center gap-4">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
              className="h-[20px] w-[20px]"
            />
            <h4 className="text-[16px] font-bold text-white">
              Schedule message
            </h4>
          </div>

          {isChecked && (
            <div className="w-full flex justify-center gap-4 items-center mt-4">
              <div className="w-[55%]">
                <label htmlFor="" className="text-[13px] font-bold text-white">
                  Select date
                </label>
                <input
                  type="date"
                  value={date}
                  min={currentDate}
                  onChange={(e) => setDate(e.target.value)}
                  className="w-full mt-2 text-[15px] tracking-wider placeholder:text-[15px]  text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]"
                />
              </div>
              <div className="w-[45%]">
                <label htmlFor="" className="text-[13px] font-bold text-white">
                  Select time
                </label>
                <input
                  type="time"
                  min={currentTime}
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  className=" w-full mt-2 text-[15px] tracking-wider placeholder:text-[15px]  text-[#A0A9AD] bg-[#1C2227] px-4 outline-none h-[48px] rounded-xl border border-[#141414]"
                />
              </div>
            </div>
          )}
          {error && (
            <div className="bg-red-600 text-white  text-[12px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-bold mt-4">
              <BiErrorCircle className="text-xl" />
              {error}
            </div>
          )}
          <button
            type="submit"
            className="w-full h-[46px] flex justify-center items-center font-manrope font-medium rounded-xl text-white bg-secondary_button mt-4 lgss:mt-4"
          >
            {loading ? (
              <TailSpin color="#FFFFFF" height={30} width={30} />
            ) : (
              "Send now"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SendUserMessage;
