import React, { useEffect, useState } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { TailSpin } from "react-loader-spinner";
import { API_URL } from "../../constant";
import axios from "axios";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useAuth } from "../../context/auth-context";
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";
import { noTransaction } from "../../assets/images";

const Admin = () => {
  const { token } = useAuth();
  const [email, setEmail] = useState<string>("");
  const [selectedAdmin, setSelectedAdmin] = useState<string>("Admin-access");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [currentpage, setCurrentPage] = useState<number>();
  const [newAdmin, setNewAdmin] = useState<any[]>([]);
  const [customerLoader, setCustomerLoader] = useState<boolean>(false);

  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalNewAdmin, setTotalNewAdmin] = useState<number>(1);

  const validateEmail = (email: string) => {
    // check if email is empty
    if (!email) {
      setError("Kindly tell us your mail");
      setTimeout(() => {
        setError("");
      }, 5000);
      return false;
    }
    // check if email is in the correct format using a regular expression
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (!emailRegex.test(email)) {
      setError("Your email is not in the correct format");
      setTimeout(() => {
        setError("");
      }, 6000);
      return false;
    }

    setError("");
    return true;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      return;
    }
    if (!loading) {
      setLoading(true);
      axios
        .patch(
          `${API_URL}api/users/${email}/make-admin`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((r: any) => {
          setLoading(false);
          setEmail("");
          setSuccess(`Successfully added ${email} as an admin`);
          setTimeout(() => {
            setSuccess("");
            setEmail("");
          }, 3000);
        })
        .catch((e: any) => {
          setError(e.response.data.message);
          setLoading(false);
          setTimeout(() => {
            setError("");
          }, 4000);
          console.log(e.response.data.message);
        });
    }
  };
  useEffect(() => {
    setCustomerLoader(true);
    axios
      .get(`${API_URL}api/users/?role=admin&limit=10&page=1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r: any) => {
        setCustomerLoader(false);
        setTotalPages(r.data.data.meta.totalPages);
        setCurrentPage(r.data.data.meta.currentPage);
        setTotalNewAdmin(r.data.data.meta.totalUsers);
        setNewAdmin(r.data.data.users);
      })
      .catch((e: any) => {
        console.log(e.response.data.message);
      });
  }, [page, token]);
  return (
    <div className="px-8 mds:px-12 lgss:px-20 pt-16 font-manrope">
      <form className="w-full lgss:w-1/2" onSubmit={handleSubmit}>
        {success && (
          <div className="bg-green-600 w-full text-white  text-[16px] rounded-xl justify-start items-center gap-4 flex h-[60px] px-2 font-bold mt-4 mb-4">
            <AiOutlineCheckCircle className="text-3xl text-white" />
            {success}
          </div>
        )}
        <label htmlFor="" className="text-[16px] font-semibold text-white">
          Add new admin
        </label>
        <input
          type="email"
          value={email}
          placeholder="Email address"
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          name="email"
          className="h-[50px] w-full mt-4  px-[16px] mb-4 font-manrope border-2 rounded-xl bg-[#1C2227] outline-none border-[#333F48]  text-[#A0A9AD] transition duration-200"
        />
        <select
          onChange={(e) => setSelectedAdmin(e.target.value)}
          value={selectedAdmin}
          className="h-[50px] w-full mt-2  px-[16px] mb-4 font-manrope border-2 rounded-xl bg-[#1C2227] outline-none border-[#333F48]  text-[#A0A9AD] transition duration-200"
        >
          {/* <option value="Full-access">Full access</option> */}
          <option value="Limited-access">Limited access</option>
        </select>

        {error && (
          <div className="bg-red-600 text-white mb-4 text-[12px] rounded-xl justify-start items-center gap-2 flex h-[50px] px-2 font-bold mt-4">
            <BiErrorCircle className="text-xl" />
            {error}
          </div>
        )}
        <button
          type="submit"
          className="w-1/2 h-[50px] flex justify-center items-center font-manrope font-medium rounded-xl text-white bg-secondary_button mt-2 "
        >
          {loading ? (
            <TailSpin color="#FFFFFF" height={30} width={30} />
          ) : (
            "Send Invite"
          )}
        </button>
      </form>

      {customerLoader ? (
        <div className="flex gap-5 w-full px-4 h-[380px]  mt-12">
          <div className="relative w-full space-y-3 overflow-hidden rounded-md bg-[#5c6b73] bg-opacity-30 p-8 shadow before:absolute before:inset-0 before:-translate-x-full before:bg-gradient-to-r before:from-transparent before:via-white/40 hover:shadow-lg before:animate-[shimmer_1.3s_infinite]">
            <div className="h-36 w-full rounded-lg bg-[#E7EBEE]"></div>

            <div className="grid grid-cols-6 gap-2 mt-4">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-6 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-6 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
            <div className="grid grid-cols-6 gap-2">
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
              <div className="h-8 w-full rounded-full bg-[#E7EBEE]"></div>
            </div>
          </div>
        </div>
      ) : newAdmin.length >= 1 ? (
        <div className="bg-[#0e0d0d] overflow-auto scrollbar-thumb-[#60B7F6] scrollbar-track-white   scrollbar-thin  rounded-tl-lg  w-full h-[350px] mt-16 ">
          <table className="w-full">
            <thead>
              <tr className="h-[56px] bg-[#1C2227] flex justify-start pl-16 items-center rounded-t-lg">
                <th className="text-[#617889] text-[16px]">Name</th>
                <th className="text-[#617889] text-[16px] ml-28">Email</th>
                <th className="text-[#617889] text-[16px] ml-40">Last Login</th>
                <th className="text-[#617889] text-[16px] ml-36">
                  Admin access
                </th>
                <th className="text-[#617889] text-[16px] ml-16">Action</th>
              </tr>
            </thead>
            {newAdmin.map((admin, index) => (
              <tbody className="cursor-pointer" key={index}>
                <tr className="h-[56px] w-full flex justify-start pl-12  items-center">
                  <td className="text-white text-[16px] w-[130px] overflow-hidden ">
                    {admin.profile.firstName} {admin.profile.lastName}
                  </td>
                  <td className="text-white text-[16px] overflow-hidden  w-[180px] ml-10">
                    {admin.email.address}
                  </td>
                  <td className="text-white text-[16px]  w-[200px] ml-8 overflow-hidden ">
                    {(() => {
                      const dateString = admin.lastLogin;
                      const date = new Date(dateString);

                      const day = date.getDate();
                      const ordinalSuffix = (day: number) => {
                        switch (day) {
                          case 1:
                          case 21:
                          case 31:
                            return "st";
                          case 2:
                          case 22:
                            return "nd";
                          case 3:
                          case 23:
                            return "rd";
                          default:
                            return "th";
                        }
                      };

                      const formattedDate = `${date.toLocaleString("default", {
                        month: "short",
                      })} ${day}${ordinalSuffix(
                        day
                      )}, ${date.getFullYear()} | ${date.toLocaleString(
                        "default",
                        {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        }
                      )}`;

                      return formattedDate;
                    })()}
                  </td>
                  <td className="text-white text-[16px]  w-[140px] ml-8 overflow-hidden ">
                    {admin.email.address === "admin@deexoptions.com"
                      ? "Full access"
                      : "Limited access"}
                  </td>
                  <td className="text-white text-[16px] ml-8">.....</td>
                </tr>
                <tr className="w-full">
                  <td className="mt-1 w-full h-[1px] bg-[#13181B]" />
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      ) : (
        <div className="flex flex-col justify-center mt-16 mb-60 lgss:mt-24 items-center">
          <img src={noTransaction} alt="" />
          <h4 className="font-bold text-[#869AA9] pt-2">No new admin</h4>
          <p className=" text-[#869AA9] pt-2 text-[12px]">
            All your added admin will show up here
          </p>
        </div>
      )}

      <div className="flex gap-4 mt-4 justify-center lgss:justify-end items-center">
        <h4 className="font-bold font-sora text-[14px] text-white">
          Page {currentpage} of {totalPages}
        </h4>
        <button className="flex justify-center items-center">
          <BsArrowLeftSquare
            onClick={() => {
              if (page > 1) {
                setPage((prev) => prev - 1);
              }
            }}
            className="text-3xl cursor-pointer text-[#C6D0D7] font-bold"
          />
        </button>

        <button>
          <BsArrowRightSquare
            onClick={() => {
              if (page < totalPages) {
                setPage((prev) => prev + 1);
              }
            }}
            className="text-3xl cursor-pointer text-[#C6D0D7]"
          />
        </button>
      </div>
    </div>
  );
};

export default Admin;
